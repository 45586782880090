<div class="modal-content">
<div class="modal-header pt-5">
  <h2 class="text-1 fs-4 text-center" style="color:#03c0fc;">{{"MERCHANT_LOGIN.ALREADY_LOGIN" | translate}}</h2>
</div>
<div class="modal-body bg-gris-1 px-5 text-center">
  <p>{{"MERCHANT_LOGIN.OTHER_DEVICE" | translate}}</p>
  <p>{{"MERCHANT_LOGIN.WANT_LOGIN" | translate}}</p>
</div>
<div class="modal-footer j-c-center mt-4 pb-4">
  <button class="btn btn-2 mx-2" (click)="dialogRef.close(false)">{{"MERCHANT_LOGIN.NO" | translate}}</button>
  <button type="submit" class="btn btn-1 mx-2" (click)="dialogRef.close(true)">
    {{"MERCHANT_LOGIN.YES" | translate}}
  </button>
</div>
</div>