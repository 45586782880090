import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfileService {
  BASE_URL: string;
  QUOTE_TEMPLATE_URL:string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.QUOTE_TEMPLATE_URL= environment.QUOTE_MANAGEMENT_URL;

  }

  getBusinessProfile(data: any, merchantId): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantinfo/${merchantId}`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  industriesList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/industry/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  timeZone(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/timezone/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  phoneCode(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/phonecode/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  countryList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/country/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getDateTime(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/getdatetime`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  currency(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/currency/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  roleList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/role/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  merRoleList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantrole/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addCurrencyConversion(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addmerchantcurrencyconversion`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  removeCurrencyConversion(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removemerchantcurrencyconversion/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addCurrency(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addmerchantcurrency`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  removeCurrency(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removemerchantcurrency/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  deleteDocument(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removedocument/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  changeMerchantDetails(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/changestartingday`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  update_Quote_Id(data: any=''): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/updateQuoteId`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  addClauses(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addmerchantclauses`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addCustomCost(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addmerchantcustomcost`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  customCostMeasurmentsList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchant/getCustomCostMeasurements', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  customCostSymbolsList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchant/getCustomCostSymbols', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }


  addCustomCostMeasurments(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchant/addCustomCostMeasurements', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  addCustomCostSymbols(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchant/addCustomCostSymbols', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }



  removeCustomCostMeasurments(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removeCustomCostMeasurements/` + data.id, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  removeCustomCostSymbols(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removeCustomCostSymbols/` + data.id, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }
  deleteCustomCost(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removecustomcost/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateClauses(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/updatemerchantclauses/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  saveProfileChange(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/updatemerchantprofile`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  removeClauses(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/removemerchantclauses/` + data.id, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateAddressFormat(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/updateaddressformate`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadDocument(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/merchant_document`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadProfilePhoto(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/profile_profile`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadProfilePhotoNew(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantuser/profile_pic`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  citiesList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/cities/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }



  uploadCoverPhoto(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant/cover_image`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadSocialImage(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/socialImage`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadUserProfilePhoto(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantuser/profile_pic`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  updateUserPassword(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantuser/updatepassword`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }



  changeLanguagesDetails(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantuser/languages`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  createPassword(user: any, password): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }

  getCustomerCards(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/getcustomercard`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getTransactions(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/gettransactions`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getInvoices(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/getinvoice`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  addCustmerCard(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addcreditcard`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  deleteCustmerCard(data: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/deletecustomercards/` + data.cardId, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getMerchantBusinessProfile(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/merchantprofile`, {}).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getMerchantRoles(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/role/list`, {}).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  sendInvitationToUser(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/sendinvitationtouser`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  socialLinks(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/sociallinks/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getAvailablePlans(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/plan/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getAvailableAddons(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/addon/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  deleteMerchantUser(user: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/deletemerchantuser/` + user.id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  deleteMerchantInviteUser(email: any): Observable<any> {
    const deleteData: any = { 'email': email };
    return this.http.post(`${this.BASE_URL}/merchant/deletemerchantinviteuser`, deleteData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getBusinessProfileDetails(data: any, merchantId): Observable<any> {
    return this.http.get(`${this.BASE_URL}/merchant/merchantprofile/details/${merchantId}`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  validateCoupon(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/updatemerchantplan`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateMerchantData(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchant`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
}
