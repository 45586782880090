<div  class="d-flex country_mobile_dropdown  align-items-baseline" fxLayoutGap="5">

  <div fxFlex="50" style="background: white">
    <mat-select
    class="mat-select-country-code form-select-mat text-1 border-gris"
    (selectionChange)="getcode($event.value)"
    [formControl]="country_code"
  >
  <mat-option>
    <ngx-mat-select-search [formControl]="codeFilterCtrl" placeholderLabel='Search' noEntriesFoundLabel="'No match found'"></ngx-mat-select-search>
  </mat-option>
      <mat-option
        class="text-1"
        *ngFor="let phonecode of newcountryCodedata"
        value="{{ phonecode.id }}"
      >
        {{ phonecode.name }} (+{{ phonecode.phone_code }})
      </mat-option>
  </mat-select>
    <!-- <mat-select
      class="mat-select-country-code form-select-mat text-1 border-gris"
      (selectionChange)="getcode($event.value)"
      placeholder="{{ 'GENRIC_PLACEHOLDER.COUNTRY_CODE' | translate }}"
      (openedChange)="selectClick()"
      formControlName="country_code"
    >
      <input
      #countryselect
        class="form-control border-0 select-input-value country-code-search-input"
        type="text"
        placeholder="Search Country"
        aria-label="Number"
        matInput
        (keyup)="getcodenumber($event)"
      />
      <mat-option
        class="text-1"
        *ngFor="let item of countryListArr"
        value="{{ item.id }}"
      >
        {{ item.name }} (+{{ item.phone_code }})
      </mat-option>
    </mat-select> -->
  </div>

  <div fxFlex>
    <input
      placeholder="Enter phone number"
      type="text"
      [formControl]="mobile_number"
      appNumbersOnly
      [ngClass]="{'border-danger' : inValidMobileNumber}"
      class="form-control br-color-2 ph-1"
    />
    <span class="error-message ms-1 mt-2"
        *ngIf="inValidMobileNumber">
    {{"ON_BOARDING_ERR.ERRORMOBILEVALIDATION" | translate}}
    </span>
  </div>

  <div></div>
</div>
