import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  BASE_URL: string;
  private taskIdData = new BehaviorSubject<string>('0');
  public organization$:Subject<any> = new Subject();
  tId = this.taskIdData.asObservable();
  BASE_URL_Lead: string;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.BASE_URL_Lead = environment.LEAD_MANAGEMENT_URL;
  }

  taskId(id: any) {
    this.taskIdData.next(id)
  }

  createPassword(user: any, password): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }
  getCustomerCards(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/getcustomercard`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getTransactions(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/gettransactions`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  deleteMerchant(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/merchantprofile`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getInvoices(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/getinvoice`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  addCustmerCard(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/addcreditcard`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  deleteCustmerCard(data: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchant/deletecustomercards/` + data.cardId, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getMerchantProfile(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/merchantprofile`, {}).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getAvailablePlans(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/plan/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }



  customerDetails(id) {
    return this.http.get(this.BASE_URL + '/merchantcustomer/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  merchantcustomerInfo(id) {
    return this.http.get(this.BASE_URL + '/merchantcustomer/info/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  uploadProfilePhoto(formData: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/updateProfilePhoto`, formData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getAvailableAddons(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/addon/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getCustomerTasks(resource: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/task/list`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getleadData(customerId){
    return this.http.get(this.BASE_URL_Lead + '/get/lead/detail/' + customerId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }



}
