import { Directive, HostListener, Input } from '@angular/core';
import { AddressMapComponent } from './address-map/address-map.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[appAddressMap]'
})
export class AddressMapDirective {

  @Input() address: AbstractControl;
  @Input() country?: AbstractControl;
  @Input() region?: AbstractControl;
  @Input() city?: AbstractControl;
  @Input() province?: AbstractControl;
  @Input() postalCode?: AbstractControl;
  @Input() street_name?: AbstractControl;
  @Input() streetNumber?: AbstractControl;
  @Input() latitude?: AbstractControl;
  @Input() longitude?: AbstractControl;
  @Input() showButton?: boolean
  constructor(
    public _matDialog: MatDialog
  ) {
  }

  @HostListener('click') onClick(): void {
    // console.log("this.address", this.address)

    const dialogRef = this._matDialog.open(AddressMapComponent, {
      panelClass: 'map-dialog',
      data: {
        address: this.address?.value? this.address.value : this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        showButton: this.showButton
      },
    });
    dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (response) {          
          const address = response[0];
          const compAddress = response[1];
          const coordinates = response[2];
          // this.country.setValue(1);
          let countryName: any;
          let cityName: any;
          let ProvinceName: any;
          let postalCode: any;
          let streetName: any;
          let streetNumber: any;
          let regionName: any;
          this.address.setValue(compAddress);
          postalCode = _.find(address, (component: any) => component.types.indexOf('postal_code') > -1);
          streetName = _.find(address, (component: any) => component.types.indexOf('route') > -1);
          streetNumber = _.find(address, (component: any) => component.types.indexOf('street_number') > -1);
          if (postalCode && this.postalCode) { this.postalCode.setValue(postalCode.long_name); }
          if (streetName && this.street_name) { 
            this.street_name.setValue(streetName.long_name);
           }else{
              this.street_name.setValue('');
            }
          if (streetNumber && this.streetNumber) { this.streetNumber.setValue(streetNumber.long_name); }
          if (compAddress && this.address) { this.address.setValue(compAddress); }
          if (this.latitude) { this.latitude.setValue(coordinates['lat']); }
          if (this.longitude) { this.longitude.setValue(coordinates['lng']); }

          countryName = _.find(address, (component: any) => component.types.indexOf('country') > -1);
          cityName = _.find(address, (component: any) => component.types.indexOf('administrative_area_level_2') > -1);
          ProvinceName = _.find(address, (component: any) => component.types.indexOf('administrative_area_level_2') > -1);
          regionName = _.find(address, (component: any) => component.types.indexOf('administrative_area_level_1') > -1);

          this.country.setValue(countryName?.long_name || null);
          this.region.setValue(regionName?.long_name || null);
          this.province.setValue(ProvinceName?.long_name || null);
          this.city.setValue(cityName?.long_name || null);

        }
      });
  }

}
