import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { GooglePlacesDirective } from './google-places/google-places.directive';
import { AddressMapDirective } from './maps/address-map.directive';
import { NumericNumbersDirective } from './numeric-values/numeric-numbers.directive';
import { AwesomeTooltipComponent } from './tooltip/tooltip.component';
import { AwesomeTooltipDirective } from './tooltip/tooltip.directive';
import { MatSelectScrollBottomDirective } from './loadItemsOnScroll/load.directive';

@NgModule({
    declarations: [
        GooglePlacesDirective,
        AddressMapDirective,
        NumericNumbersDirective,
        AwesomeTooltipDirective,
        MatSelectScrollBottomDirective,
        AwesomeTooltipComponent
    ],
    imports: [],
    exports: [
        GooglePlacesDirective,
        AddressMapDirective,
        NumericNumbersDirective,
        AwesomeTooltipDirective,
        MatSelectScrollBottomDirective,
        AwesomeTooltipComponent,
    ],
    providers:[DecimalPipe]
})
export class AppDirectivesModule {
}
