<div style="background-color:#E3ECF1 ;">
    <div class="container-fluid pb-3">
        
        <ng-container *ngFor="let embed of embedFromData?.data;">
            <div class="box-content-st mx-auto ">
    
                <div class="box-content-elements">
    
                    <div *ngIf="embed?.name==='Heading'" class="d-flex heading-{{ headingCount }}" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
                    }">
                        <h1 class="f-responsive" [ngStyle]="{
                            'font-size':heading_Size?heading_Size+'px':embed?.font_size+'px',
                            'color': '#333',
                        'margin-bottom':'0px',
                        'font-family': selectedFont ? selectedFont :embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
                    }">{{embed?.value}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></h1>
                    </div>
    <!-- 
                    <div *ngIf="embed?.name==='Total Due'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'color': '#333',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }"> {{ embed.currency_symbol }}{{ embed.value }} {{embed.currency}}</p>
                        </div> -->
    
                        <div *ngIf="embed?.name === 'Total Due'" class="d-flex" [ngStyle]="{
                            'justify-content': embed?.align,
                            'margin-bottom': embed?.mrbottum + 'px',
                            'font-size': embed?.font_size + 'px',
                            'color': '#333',
                            'font-family': embed?.fontFamily,
                            'font-weight': embed?.font_Weight,
                            'padding-bottom': embed?.padding + 'px',
                            'padding-top': embed?.paddingT + 'px',
                            'padding-right': embed?.paddingR + 'px',
                            'padding-left': embed?.paddingL + 'px',
                            'margin-top': embed?.mrtop + 'px',
                            'margin-right': embed?.mrright + 'px',
                            'margin-left': embed?.mrleft + 'px',
                            'text-decoration': embed?.textDecoration,
                            'font-style': embed?.font_italic
                          }">
                            <p class="txt-res" [ngStyle]="{
                              'font-size': embed?.font_size + 'px',
                              'color': '#333',
                              'margin-bottom': embed?.mrbottum + 'px',
                              'font-family': embed?.fontFamily,
                              'font-weight': embed?.font_Weight,
                              'padding-bottom': embed?.padding + 'px',
                              'padding-top': embed?.paddingT + 'px',
                              'padding-right': embed?.paddingR + 'px',
                              'padding-left': embed?.paddingL + 'px',
                              'margin-top': embed?.mrtop + 'px',
                              'margin-right': embed?.mrright + 'px',
                              'margin-left': embed?.mrleft + 'px',
                              'text-decoration': embed?.textDecoration,
                              'font-style': embed?.font_italic
                            }">
                            {{ embed?.user_currency?embed?.currency_symbol:crSymbol }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed.value }}</span>
                            {{embed?.user_currency?embed?.currency:selectedCurrency?.currency_code}}
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              <!-- <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                            </p>
                          </div>
    
                          <div *ngIf="embed?.name === 'Currency'" class="d-flex" [ngStyle]="{
                            'justify-content': embed?.align,
                            'margin-bottom': embed?.mrbottum + 'px',
                            'font-size': embed?.font_size + 'px',
                            'color': '#333',
                            'font-family': embed?.fontFamily,
                            'font-weight': embed?.font_Weight,
                            'padding-bottom': embed?.padding + 'px',
                            'padding-top': embed?.paddingT + 'px',
                            'padding-right': embed?.paddingR + 'px',
                            'padding-left': embed?.paddingL + 'px',
                            'margin-top': embed?.mrtop + 'px',
                            'margin-right': embed?.mrright + 'px',
                            'margin-left': embed?.mrleft + 'px',
                            'text-decoration': embed?.textDecoration,
                            'font-style': embed?.font_italic
                          }">
                            <p class="txt-res" [ngStyle]="{
                              'font-size': embed?.font_size + 'px',
                              'color': '#333',
                              'margin-bottom': embed?.mrbottum + 'px',
                              'font-family': embed?.fontFamily,
                              'font-weight': embed?.font_Weight,
                              'padding-bottom': embed?.padding + 'px',
                              'padding-top': embed?.paddingT + 'px',
                              'padding-right': embed?.paddingR + 'px',
                              'padding-left': embed?.paddingL + 'px',
                              'margin-top': embed?.mrtop + 'px',
                              'margin-right': embed?.mrright + 'px',
                              'margin-left': embed?.mrleft + 'px',
                              'text-decoration': embed?.textDecoration,
                              'font-style': embed?.font_italic
                            }">
                            {{ embed?.user_currency?embed?.currency_symbol:crSymbol }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed.value }}</span>
                            {{embed?.user_currency?embed?.currency:selectedCurrency?.currency_code}}
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              <!-- <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                            </p>
                          </div>
                    
                    <div *ngIf="embed?.name==='Date' || embed?.name=='Forecast Date'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
    
                    }">
                        <h1 class="txt-res" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
    
    
                    }"> {{embed?.dateTitle}}<span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></h1>
                    </div>
    
                    <div *ngIf="embed?.name==='Text'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">
                        <p class="txt-res" [ngStyle]="{
                            'font-size':embed?.font_size+'px',
                            'color': '#333',
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                    }">{{embed?.value}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></p>
                    </div>
                    <div *ngIf="embed?.name === 'Organization N.' || embed?.name === 'Item name'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align,
                        'margin-bottom': embed?.mrbottum + 'px',
                        'font-size': embed?.font_size + 'px',
                        'color': '#333',
                        'font-family': embed?.fontFamily,
                        'font-weight': embed?.font_Weight,
                        'padding-bottom': embed?.padding + 'px',
                        'padding-top': embed?.paddingT + 'px',
                        'padding-right': embed?.paddingR + 'px',
                        'padding-left': embed?.paddingL + 'px',
                        'margin-top': embed?.mrtop + 'px',
                        'margin-right': embed?.mrright + 'px',
                        'margin-left': embed?.mrleft + 'px',
                        'text-decoration': embed?.textDecoration,
                        'font-style': embed?.font_italic
                      }">
                        <p class="txt-res" [ngStyle]="{
                          'font-size': embed?.font_size + 'px',
                          'color': '#333',
                          'margin-bottom': embed?.mrbottum + 'px',
                          'font-family': embed?.fontFamily,
                          'font-weight': embed?.font_Weight,
                          'padding-bottom': embed?.padding + 'px',
                          'padding-top': embed?.paddingT + 'px',
                          'padding-right': embed?.paddingR + 'px',
                          'padding-left': embed?.paddingL + 'px',
                          'margin-top': embed?.mrtop + 'px',
                          'margin-right': embed?.mrright + 'px',
                          'margin-left': embed?.mrleft + 'px',
                          'text-decoration': embed?.textDecoration,
                          'font-style': embed?.font_italic
                        }">
                          {{ embed?.value }}
                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                </p>
                      </div>
                      
                      <div *ngIf="embed?.name === 'Item code'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align,
                        'margin-bottom': embed?.mrbottum + 'px',
                        'font-size': embed?.font_size + 'px',
                        'color': '#333',
                        'font-family': embed?.fontFamily,
                        'font-weight': embed?.font_Weight,
                        'padding-bottom': embed?.padding + 'px',
                        'padding-top': embed?.paddingT + 'px',
                        'padding-right': embed?.paddingR + 'px',
                        'padding-left': embed?.paddingL + 'px',
                        'margin-top': embed?.mrtop + 'px',
                        'margin-right': embed?.mrright + 'px',
                        'margin-left': embed?.mrleft + 'px',
                        'text-decoration': embed?.textDecoration,
                        'font-style': embed?.font_italic
                      }">
                        <p class="txt-res" [ngStyle]="{
                          'font-size': embed?.font_size + 'px',
                          'color': '#333',
                          'margin-bottom': embed?.mrbottum + 'px',
                          'font-family': embed?.fontFamily,
                          'font-weight': embed?.font_Weight,
                          'padding-bottom': embed?.padding + 'px',
                          'padding-top': embed?.paddingT + 'px',
                          'padding-right': embed?.paddingR + 'px',
                          'padding-left': embed?.paddingL + 'px',
                          'margin-top': embed?.mrtop + 'px',
                          'margin-right': embed?.mrright + 'px',
                          'margin-left': embed?.mrleft + 'px',
                          'text-decoration': embed?.textDecoration,
                          'font-style': embed?.font_italic
                        }">
                          {{ embed?.value }}
                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                </p>
                      </div>
    
                      <!-- <div *ngIf="embed?.name === 'Number List'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align
                      }">
                        <ol >
                          <li *ngFor="let listItem of numberList; let i = index">
                            <input type="text" [(ngModel)]="numberList[i]" class="borderless-input" [ngStyle]="{
                                'font-size': embed?.font_size + 'px',
                                'color': '#333',
                                'margin-bottom': embed?.mrbottum + 'px',
                                'font-family': embed?.fontFamily,
                                'font-weight': embed?.font_Weight,
                                'padding-bottom': embed?.padding + 'px',
                                'padding-top': embed?.paddingT + 'px',
                                'padding-right': embed?.paddingR + 'px',
                                'padding-left': embed?.paddingL + 'px',
                                'margin-top': embed?.mrtop + 'px',
                                'margin-right': embed?.mrright + 'px',
                                'margin-left': embed?.mrleft + 'px',
                                'text-decoration': embed?.textDecoration,
                                'font-style': embed?.font_italic
                              }">
                          </li>
                        </ol>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                      </div> -->
    
    
                      <div *ngIf="embed?.name =='Number List'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p class="txt-res" readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':'bolder',
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{ embed?.label}}
                        <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                            <div *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue">
                                <ol>
                                  <li *ngFor="let coun of embed?.custom_value?.value; let i = index" [ngStyle]="{
                                      'justify-content': embed?.align,
                                      'margin-bottom': embed?.mrbottum + 'px',
                                      'font-size': embed?.font_size + 'px',
                                      'color': '#333',
                                      'font-family': embed?.fontFamily,
                                      'padding-bottom': embed?.padding + 'px',
                                      'padding-top': embed?.paddingT + 'px',
                                      'padding-right': embed?.paddingR + 'px',
                                      'padding-left': embed?.paddingL + 'px',
                                      'color': embed?.color,
                                      'margin-top': embed?.mrtop + 'px',
                                      'margin-right': embed?.mrright + 'px',
                                      'margin-left': embed?.mrleft + 'px',
                                      'text-decoration': embed?.textDecoration,
                                      'font-style': embed?.font_italic
                                  }">
                                    <span style="font-weight: normal;">{{ coun?.name }}</span>
                                    <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }}</span>
                                  </li>
                                </ol>
                              </div>
                                                                                </ng-container>
                        <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                        <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                        
             </p>
             <br>
             <!-- <div *ngIf="embed?.custom_value?.value?.length">
                <span  [value]="coun?.name"
                *ngFor="let coun of embed?.custom_value?.value"
                [selected]="coun?.selected">{{coun?.name}}</span>
            </div> -->
                        </div>
    
                      
                        <div *ngIf="embed?.name =='Order List'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p class="txt-res" readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':'bolder',
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.label}}
                            <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                <div *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue">
                                    <ul>
                                      <li *ngFor="let coun of embed?.custom_value?.value; let i = index" [ngStyle]="{
                                          'justify-content': embed?.align,
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'font-family': embed?.fontFamily,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'color': embed?.color,
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                      }">
                                        <span style="font-weight: normal;">{{ coun?.name }}</span>
                                        <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                                                                    </ng-container>
                            <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                            <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                            
                 </p>
                 <br>
                 <!-- <div *ngIf="embed?.custom_value?.value?.length">
                    <span  [value]="coun?.name"
                    *ngFor="let coun of embed?.custom_value?.value"
                    [selected]="coun?.selected">{{coun?.name}}</span>
                </div> -->
                            </div>
                          
                                                                  
                            <div *ngIf="embed?.name=='Table'">
                                <div style="width: 100%; overflow-x: auto;">
                                <table style="width:85%" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic

                                }">
                                    <tr >
                                      <!-- Generate table headers based on selectedColumnCount -->
                                      <th *ngFor="let headerIndex of embed?.displayedColumns; let i = index">
                                        <input [ngStyle]="{
                                        'align-items':embed?.align,
                                        'border':'none',
                                        'margin-bottom':'0px',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'font-size': embed?.font_size + 'px',
                                        'font-family': embed?.fontFamily,
                                        'font-weight': embed?.font_Weight,
                                        'width':'185px',
                                        'color':embed?.color !=='#000000'?embed?.color:tableStyleClass + ' ' + tableColorClass,
                                        'background-color':embed?.backGroundColor
                                         }" style="text-align: center; min-width: 110px !important; font-weight: bold;" type="text" [(ngModel)]="headerData[i]" (blur)="logTableData()"
                                          [(ngModel)]="headerIndex.name"  class="no-border"/>    
                               
                                </th>
                                    </tr>
                                    <tr *ngFor="let row of [].constructor(selectedRowCount); let i = index">
                                      <!-- Generate editable table cells based on selectedColumnCount -->
                                      <td *ngFor="let cellIndex of [].constructor(selectedColumnCount); let j = index">
                                        <input  type="text"  class="no-border"
                                        
                                        [ngStyle]="{
                                        'border':'none',
                                        'margin-bottom':'0px',
                                        'width':'185px'
                                        }" />
                                      </td>
                                    </tr>
                                  </table>
                                  </div>
                                  <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              </div>

    
    
    
    
    
                    <div *ngIf="embed?.name === 'embed table'">
                        <div style="width: 100%; overflow-x: auto;">
                            <!-- <table [ngClass]="tableStyleClass + ' ' + tableColorClass" mat-table [dataSource]="data" class="mat-elevation-z8">
                                                                        <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumns11">
                                                                          <th *matHeaderCellDef>
                                                                            <div style="text-align: center; min-width: 110px !important; font-weight: bold;" *ngIf="column.orientation =='column'">{{column.name}}</div>
                                                                          </th>
                                                                          <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
                                                                        </ng-container> -->
                            <table [ngClass]="tableStyleClass + ' ' + tableColorClass" mat-table [dataSource]="data" multiTemplateDataRows
                                class="mat-elevation-z8">
                                <ng-container matColumnDef="{{column.name}}" *ngFor="let column of displayedColumns11">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div style="text-align: center; min-width: 110px !important; font-weight: bold;"
                                            *ngIf="column.orientation =='column'">{{column.name}}</div>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element[column.name]}} </td>
                                </ng-container>
                    
                                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                <ng-container matColumnDef="expandedDetail">
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                      <div class="example-element-detail flex-column gap-2">
                                        <div *ngFor="let column of displayedColumns11" [@detailExpand]="true">
                                          <div *ngIf="column.orientation == 'row' && columnsToDisplay.includes(column.name)">
                                            {{column.name}}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </ng-container>
                                                                                                                                                                                                                                                                                                                                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                    [class.example-expanded-row]="expandedElement === element"
                                    (click)="expandedElement = expandedElement === element ? null : element">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    
                            </table>
                        </div>
                    
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                    </div>
                                                                                                                                                                                                                                                                                                          
                                                                    
                        <div *ngIf="embed?.name==='Image' || embed?.name=== 'Organization Logo ' || embed?.name === 'Workspace Logo' || embed?.name=== 'Item image'" class="d-flex"
                        [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                        <img [src]="getLogo?getLogo:staticLogo" alt="logo"
                            [ngStyle]="{'width':embed?.width+'%', 'border-radius':embed?.brradius+'px'}">
                    </div>
                     <div *ngIf="embed?.name==='Divider'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <hr style="height: 2px; margin: 10px 0;"
                            [ngStyle]="{
                            'width':embed?.width+'%',
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    
                    <div *ngIf="embed?.name==='Name' || embed?.name === 'First Name' || embed?.name === 'Business Name' || embed?.name === 'Deal Title' || embed?.name === 'Commercial Name'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res"  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'color': '#333',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{ embed?.value }}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
                        <div *ngIf="embed?.name==='Last Name'"  class="d-flex txt-res" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.value }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
                            <div *ngIf="embed?.name === 'tax id' || embed?.name === 'Quote ID' || embed?.name === 'Invoice ID' || embed?.name ==='Sales Order ID'"  class="d-flex txt-res" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                            <div *ngIf="embed?.name==='Item description' || embed?.name === 'Deal Description'"  class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                                <div *ngIf="embed?.name==='Deal Stage'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item price' || embed?.name=== 'Deal Value'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item Unit' || embed?.name === 'Item quantity per click'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item comments'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                            <div *ngIf="embed?.name==='Member'"  class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
    
                            <div *ngIf="embed?.name==='Email'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                </div>
                                <div *ngIf="embed?.name==='Email(s)' || embed?.name ==='Business email'" class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res"  [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                    </div>
                           
                                <div *ngIf="embed?.name === 'Phone number(s)' || embed?.name=== 'Phone Number'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed.phone_number && embed.phone_number.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.phone_number; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p class="txt-res" readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Phone'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed.phone_number && embed.phone_number.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.phone_number; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  
    
                 
                                  <div *ngIf="embed?.name === 'Address'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Source of contact'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Language'" class="d-flex txt-res" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ phoneNumber?.module_name?phoneNumber?.module_name:embed?.value }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Contact custom fields' || embed?.name === 'Custom embed fields'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ phoneNumber?.module_name?phoneNumber?.module_name:embed?.value}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
    
                        <div *ngIf="embed?.name==='Website' || embed?.name === 'Video URL' || embed?.name === 'Website URL'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.value }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.url?embed?.url:embed?.tag }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
                            <div *ngIf="embed?.name==='Social links'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.url?embed?.url:embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                        <!-- <div *ngIf="embed?.name==='Organization N.'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="business" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="url" class="form-control" id="website" name="website"
                        <input type="text" class="form-control" id="business" name="business"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                    </div>
                  
                    </div> -->
                    <!-- <div *ngIf="embed?.name==='Organization N.'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="business" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="text" [value]="embed?.tag" readonly class="form-control" id="business" name="business"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                    </div> -->
    
    
                    <div *ngIf="embed?.name==='Company'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="company" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="text" class="form-control" id="company" name="company"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Industry'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label class="lebel_res" for="industry" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont }">{{embed?.label}} <small
                                *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont }">(Optional)</small></label>
                        <input  type="text" class="form-control lebel_res" id="industry" name="industry"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Textbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="textbox" class="lebel_res" [ngStyle]="{
                            'font-size':bodying_Size+'px',
                            'font-weight':'500',
                            'font-family':selectedFont || 'Arial, sans-serif' }">{{embed?.label}} </label>
                        <input type="text" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'width': '80%',
                    'font-family':selectedFont || 'Arial, sans-serif',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                
                }" class="form-control txt-res" id="textbox"
                            name="textbox" [placeholder]="embed?.placeholder">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                        <p class="m-0 label_res2" [ngStyle]="{
                    'font-size': help_txt_Size+'px',
                    'font-weight': '400',
                    'font-family':selectedFont || 'Arial, sans-serif',
                    'color': '#333'}">{{embed?.help_txt}}</p>
                    </div>
                    <div *ngIf="embed?.name==='Button'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <button class="txt-res" type="submit" [ngStyle]="{
                    'width':embed?.width+'%',
                    'background': embed?.backgroundcolor,
                    'height':embed?.height+'px',
                    'border': 'none',
                    'border-radius': '5px',
                    'font-weight': '600',
                    'font-size': bodying_Size+'px',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                        'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">{{embed?.value}}</button>
                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Selectbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="selectbox" class="lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{labelName}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <select class="form-select txt-res" name="selectbox" id="selectbox"
                            [ngStyle]="{'width':'80%','font-family':selectedFont}">
                            <option [value]="coun?.value" class="txt-res"
                                *ngFor="let coun of filteredCustomFieldList;"
                                [selected]="coun?.selected">{{coun?.value}}</option>
                        </select>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Textarea'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label  for="textarea" class="lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <textarea rows="3" class="form-control txt-res" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'width': '125%',
                    'font-family':selectedFont,
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                
                }" id="textbox" name="textbox"
                            [placeholder]="embed?.placeholder"></textarea>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Checkbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <p class="m-0 lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }">{{embed?.label}} </p>
                        <div class="d-flex flex-wrap" style="gap: 14px;">
                            <div style="
                        display: flex;
                        align-items: center;
                        " *ngFor="let check of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                <input type="checkbox" [checked]="check?.selected"
                                    id="{{check?.name}}" name="{{check?.name}}"
                                    value="{{check?.name}}" [ngStyle]="{
                            'margin-right': '4px',
                            'margin-right': '4px',
                            'border-radius': '2px',
                            'width': '15px',
                            'flex-shrink': '0',
                            'border': '1px solid #B6C9D4',
                            'height': '15px',
                            'background': '#FFF',
                            'font-family':selectedFont}">
                                <label class="lebel_res" for="{{check?.name}}" [ngStyle]="{
                            'color': '#333',
                            'font-size': bodying_Size+'px',
                            'font-weight': '500',
                            'font-family':selectedFont}"> {{check?.name}}  <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="embed?.name==='Radio button'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <p class="m-0 lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'font-family':selectedFont}">(Optional)</small></p>
                        <div class="d-flex flex-wrap" style="gap: 14px;">
                            <div style="
                        display: flex;
                        align-items: center;
                        " *ngFor="let radio of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                <input type="radio" [checked]="radio?.selected"
                                    id="{{radio?.name}}" name="radio_button"
                                    value="{{radio?.name}}" [ngStyle]="{
                            'margin-right': '4px',
                            'margin-right': '4px',
                            'border-radius': '2px',
                            'width': '15px',
                            'flex-shrink': '0',
                            'border': '1px solid #B6C9D4',
                            'height': '15px',
                            'background': '#FFF',
                            'font-family':selectedFont,
                            'font-size':bodying_Size+'px'}">
                                <label class="lebel_res" for="{{radio?.name}}" [ngStyle]="{
                            'color': '#333',
                            'font-size': bodying_Size+'px',
                            'font-weight': '500',
                            'font-family':selectedFont}"> {{radio?.name}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></label>
                            </div>
                        </div>
                    </div>
    
    
    
                    <div *ngIf="embed?.quote_currency =='Quote Currency'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.quote_currency}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
    
                    <div *ngIf="embed?.sub_total =='Subtotal'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.sub_total}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
                     <div *ngIf="embed?.discount =='Discount'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.discount}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
                        <div *ngIf="embed?.name =='Discount'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.discount}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                            </div>
    
    
    
    
                     
    
    
    
                     <div *ngIf="embed?.taxes =='Taxes' || embed?.name === 'Applicable taxes'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.taxes}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
                        <div *ngIf="embed?.name =='Tax Exempt indicator' || embed?.name === 'Country'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.taxes}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                            </div>
    
                     <div *ngIf="embed?.total =='Total' || embed?.name =='Total'" class="d-flex txt-res" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }"><span *ngIf="embed?.total =='Total'">{{embed?.total}}</span>
                        <span *ngIf="embed?.name == 'Total' && (embed?.total !=='Total' && embed?.discount !=='Discount' && embed?.due_now !=='Due Now' && embed?.taxes !=='Taxes' && embed?.additional_cost !=='Add Additional Cost' && embed?.sub_total !=='Subtotal' && embed?.quote_currency !=='Quote Currency')">please select values into properties</span>
                        <span *ngIf="embed?.name == 'Total' && (embed?.total =='Total')" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
                        <div *ngIf="embed?.additional_cost =='Additional Field'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':'bolder',
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.additional_cost}}
                            <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                <div *ngFor="let coun of embed?.custom_value?.value"[ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                  <span style="font-weight: normal;" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                </span>
                           <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
    
                                </div>
                              </ng-container>
                            <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                            <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                            
                 </p>
                 <br>
                 <!-- <div *ngIf="embed?.custom_value?.value?.length">
                    <span  [value]="coun?.name"
                    *ngFor="let coun of embed?.custom_value?.value"
                    [selected]="coun?.selected">{{coun?.name}}</span>
                </div> -->
                            </div>
    
                            
    
    
    <!-- 
                        <div *ngIf="due_now =='Due Now'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{due_now}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
                             <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                          <!-- </p>
                            </div> -->
    
    
    
                        <div *ngIf="embed?.name =='Due Now'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.label}}
                            <span *ngIf="!embed?.user_currency" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + crSymbol}}</span>
                            <span  *ngIf="embed?.user_currency" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + embed?.currency_symbol}}</span>
    
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
    
    
                            <div *ngIf="embed?.name =='Additional Field'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly  [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{embed?.label}}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0}}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                </div>
    
    
    
    
    
    
    
    
    
                    <div *ngIf="embed?.name==='Attachment'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                    }">
                        <p class="m-0 txt-res" [ngStyle]="{
                    'width': '80%',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">{{embed?.label}} 
                        </p>
                        <label for="attachment" class="attachment-label" [ngStyle]="{
                    'display': 'flex',
                    'border-radius': '5px',
                    'border': '1px dashed #C1C1C1',
                    'padding': bodying_Size+'px',
                    'background': '#F4F4F4',
                    'margin-top': '10px',
                    'justify-content': 'center',
                    'font-family':selectedFont }">
                            <img src="assets/svg-icons/attachment_icon.svg"
                                alt="attachment_icon">
                            <div class="lebel_res" [ngStyle]="{
                        'display': 'flex',
                        'align-items': 'center',
                        'flex-direction': 'column',
                        'margin-left': '8px',
                        'font-family':selectedFont,
                        'font-size':bodying_Size+'px' }">
                                <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                <p class="m-0">
                                    <span *ngFor="let file of embed?.value">
                                        <span *ngIf="file?.value">{{file?.name}},</span>
                                    </span>(MAX: 10 MB)
                                </p>
                            </div>
                        </label>
                        <input type="file" class="form-control" style="display: none;"
                            id="attachment" name="attachment">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <div class="preview-wrapper" *ngIf="embedFromData1">
          <div class="flex justify-end col-12">
            <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
              class="color-4 fs-2 far fa-times-circle m-3"></i>
          </div>
          <div class="preview-temp">
            <ng-container>
              
              <div class="box-content-st mx-auto " #previewContent>
      
                <div class="box-content-elements">
                  <div >
                    <div class="preview overflow-y-scroll flex justify-center" style="flex: 1;">
                      <div class="element-area" #formContent  id="parentId" style="width:840px" >
           
          
                          <div #dropField id="dropField" style=" width: 100% !important;" class=" borderColor mb-3 py-3 d-flex justify-content-center bg-00 rounded" [ngClass]="page_landscape? 'drop_field_landscape': 'drop-field inline-table'"  *ngFor="let dropPage of dropListData;let z = index" >
                              <!-- <div *ngIf="active_Tabs!=='submit_options'"> -->
                      
                              <div #temp_ref id="temp_ref" class="d-flex justify-center" style="min-height: 500px;width:810px !important;" [ngClass]="{'resp_width':!hideSidenav || isEleHide && !page_landscape, 'drop_element_landscape':page_landscape}">
                                  <div class="example-list d-flex justify-center" [id]="dropPage.page" cdkDropList 
                                      (cdkDropListDropped)="itemDropped($event,z,dropPage.page)" style="height: 100%; width: 794px;padding-top:16px;">
                                      <div class="d-flex flex-wrap w-full" [ngClass]="dropPage.page">
                                      <ng-container >
                                          <div class="droped-item mb-1 example-box" *ngFor="let item of dropPage.items;let i = index;" 
                                          [ngClass]="{'col-md-4 col-4 col-sm-4 col-lg-4': item?.col === 'three', 'col-md-3 col-3 col-sm-3 col-lg-3': item?.col === 'four', 'col-md-6 col-6 col-sm-6 col-lg-6': item?.col === 'two', 'col-md-12 col-12 col-sm-12 col-lg-12': item?.col == 'one'}"   [ngStyle]="{
                                              'justify-content':item?.align,
                                              'display':'flex'
                                              
                      
                                          }"
                                              >
                                              <div class="w-full"
                                                   [ngStyle]="{
                                                      'justify-content':item?.align,
                                                      'margin-bottom':item?.mrbottum+'px',
                                                      'margin-right':item?.mrright+'px',
                                                      'font-family':item?.fontFamily,
                                                      'font-weight':item?.font_Weight,
                                                      'padding-bottom':item?.padding+'px',
                                                      'padding-top':item?.paddingT+'px',
                                                      'padding-right':item?.paddingR+'px',
                                                      'padding-left':item?.paddingL+'px',
                                                      'color':item?.color,
                                                      'margin-top':item?.mrtop+'px',
                                                      'width' : item?.name == 'Space' || item?.name == 'Selectbox' || item?.name == 'Checkbox' || item?.name == 'Input Field' ||  item?.name == 'Divider' || item?.name == 'Text Area' || item?.name == 'Clauses' || item?.name == 'Textbox' || item?.name == 'Signature' ? '100%':'fit-content',
                                                      'margin-left':item?.mrleft+'px',
                                                      'text-decoration':item?.textDecoration,
                                                      'font-style':item?.font_italic
                      
                                                  }">
                                        
                      
                                                  <div class="box-content" [ngStyle]="{ 'width': item?.name === 'Text Area' ? '100%' :item?.name === 'Divider'?'100%': '100%' }" >
                                                      <div class="box-content-elements">
                                                          <div   *ngIf="item?.name==='Heading'" [ngStyle]="{
                                                              'justify-content': item?.align
                                                          
                                                            }" class="d-flex heading-{{ headingCount }}" >
                                                              <h1 style="margin: 0 !important;"  class="f-responsive" [ngStyle]="{
                                                                  'font-size':item?.style_font_size+'px',
                                                                  'color': '#333',
                                                                  'font-family': selectedFont ? selectedFont :item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'color':item?.color,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                                
                                                                 
                                                              }" >  {{item?.value}} </h1>
                                                          </div>
                                                          <div
                                      *ngIf="item?.name === 'Payment'"
                                      class="d-flex"
                                      [ngStyle]="{
                                        'justify-content': item?.align,
                                        'font-size': item?.font_size + 'px',
                                        color: '#333',
                                        'font-family': item?.fontFamily,
                                        'font-weight': item?.font_Weight,
                                        'padding-bottom': item?.padding + 'px',
                                        'padding-top': item?.paddingT + 'px',
                                        'padding-right': item?.paddingR + 'px',
                                        'padding-left': item?.paddingL + 'px',
                                        color: item?.color,
                                        'text-decoration': item?.textDecoration,
                                        'font-style': item?.font_italic
                                      }"
                                    >
                                      <div
                                        class="d-flex flex-column justify-between me-3 mb-3 w-100"
                                      >
                                        <label
                                          for="selectbox"
                                          class="lebel_res mb-1"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-size': item?.font_size + 'px',
                                            color: '#333',
                                            'font-family': item?.fontFamily,
                                            'font-weight': item?.font_Weight,
                                            margin: ' 0px 0px 4px 0px !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'text-align': item?.align
                                          }"
                                          >{{
                                            getTranslatedText(
                                              "QUOTE_TEMPLATE." + item.Title
                                            ) || item.Title
                                          }}</label
                                        >
                                        <select
                                          class="form-select txt-res max-w-44"
                                          name="selectbox"
                                          id="selectbox"
                                          [ngStyle]="{
                                            
                                            'text-align': item?.align
                                          }"
                                        >
                                          <option
                                            [value]="title.name"
                                            class="txt-res"
                                            *ngFor="let title of item?.paymentTitles"
                                            [selected]="title?.selected"
                                          >
                                            {{
                                              getTranslatedText(
                                                "QUOTE_TEMPLATE." + title?.name
                                              ) || title?.name
                                            }}
                                          </option>
                                        </select>
                                      </div>
                                      <div
                                        class="d-flex flex-column justify-between me-3 mb-3 w-100" id="date1"
                                      >
                                        <label
                                          for="selectbox"
                                          class="lebel_res mb-1"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-size': item?.font_size + 'px',
                                            color: '#333',
                                            'font-family': item?.fontFamily,
                                            'font-weight': item?.font_Weight,
                                            margin: ' 0px 0px 4px 0px !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'text-align': item?.align
                                          }"
                                          >{{
                                            getTranslatedText(
                                              "QUOTE_TEMPLATE." + item.Date
                                            ) || item.Date
                                          }}</label
                                        >
                                        <mat-form-field [ngStyle]="{
                                         
                                          'text-align': item?.align,
                                          'border':'1px solid !important'
                                        }"
                                        style="border:1px solid #cbdbe4 !important; background-color: #fff;border-radius:5px;"
                                         appearance="outline" 
                                         floatLabel="always" [placeholder]="item?.placeholder">
                                         <input [placeholder]="item?.placeholder"
                                         #input matInput [matDatepicker]="dateName" 
                                          [value]="item.selectedDate"  (click)="dateName.open()" readonly>
                                         <mat-datepicker-toggle matSuffix [for]="dateName"></mat-datepicker-toggle>
                                         <mat-datepicker #dateName></mat-datepicker>
                                        
                                     </mat-form-field>
                                        <!-- <input
                                          [type]="
                                            item?.required
                                              ? item?.datatype || 'date'
                                              : 'date'
                                          "
                                          [ngStyle]="{
                                            'border-color': item?.borderColor,
                                            'text-align': item?.align
                                          }"
                                          class="form-control mt-1"
                                          id="{{ item?.id }}"
                                          name="date"
                                          [placeholder]="item?.placeholder"
                                        /> -->
                                      </div>
                                      <div
                                        class="d-flex flex-column justify-between me-3 mb-3 w-100"
                                      >
                                        <label
                                          for="selectbox"
                                          class="lebel_res mb-1"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-size': item?.font_size + 'px',
                                            color: '#333',
                                            'font-family': item?.fontFamily,
                                            'font-weight': item?.font_Weight,
                                            margin: ' 0px 0px 4px 0px !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'text-align': item?.align
                                          }"
                                          >{{
                                            getTranslatedText(
                                              "QUOTE_TEMPLATE." + item.Method
                                            ) || item.Method
                                          }}</label
                                        >
                                        <select
                                          class="form-select txt-res max-w-44"
                                          name="selectbox"
                                          id="selectbox"
                                          [ngStyle]="{
                                           
                                            'text-align': item?.align
                                          }"
                                        >
                                          <option
                                            [value]="title.name"
                                            class="txt-res"
                                            *ngFor="let title of item?.paymentMethods"
                                            [selected]="title?.selected"
                                          >
                                            {{
                                              getTranslatedText(
                                                "QUOTE_TEMPLATE." + title?.name
                                              ) || title?.name
                                            }}
                                          </option>
                                        </select>
                                      </div>
                                      <div
                                        class="d-flex flex-column justify-between me-3 mb-3 w-100"
                                      >
                                        <label
                                          for="selectbox"
                                          class="lebel_res mb-1"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-size': item?.font_size + 'px',
                                            color: '#333',
                                            'font-family': item?.fontFamily,
                                            'font-weight': item?.font_Weight,
                                            margin: ' 0px 0px 4px 0px !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'text-align': item?.align
                                          }"
                                          >{{
                                            getTranslatedText(
                                              "QUOTE_TEMPLATE." + item.Amount
                                            ) || item.Amount
                                          }}</label
                                        >
                                        <input
                                          [type]="text"
                                          [ngStyle]="{
                                           
                                            'text-align': item?.align
                                          }"
                                          class="form-control mt-1"
                                          id="{{ item?.id }}"
                                          name="date"
                                          [placeholder]="item?.placeholder"
                                        />
                                      </div>
                                      <div
                                        class="d-flex flex-column justify-between me-3 mb-3 w-100"
                                      >
                                        <label
                                          for="selectbox"
                                          class="lebel_res mb-1"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-size': item?.font_size + 'px',
                                            color: '#333',
                                            'font-family': item?.fontFamily,
                                            'font-weight': item?.font_Weight,
                                            margin: ' 0px 0px 4px 0px !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'text-align': item?.align
                                          }"
                                          >{{
                                            getTranslatedText(
                                              "QUOTE_TEMPLATE." + item.Percentage
                                            ) || item.Percentage
                                          }}</label
                                        >
                                        <input
                                          [type]="text"
                                          [ngStyle]="{
                                           
                                            'text-align': item?.align
                                          }"
                                          class="form-control mt-1 max-w-36"
                                          id="{{ item?.id }}"
                                          name="date"
                                          [placeholder]="item?.placeholder"
                                        />
                                      </div>
                                      <span *ngIf="item?.hidden">
                                        <img
                                          [src]="'assets/svg-icons/eye-hidden.svg'"
                                          alt="logo"
                                        />
                                      </span>
                                    </div>
                      
                                                          <div *ngIf="item?.name==='Signature'" class="d-flex flex-column" [ngStyle]="{
                                                              'align-items': item?.align,
                                                              'margin-bottom': item?.mrbottum + 'px'
                                                            }">
                                                              <ng-container class="WidthMarginBottom">
                                                                <div class="properties-heading-size properties-ele my-3">
                                                                  <div style="margin: 0 !important;">
                                                                    <label for="textbox" class="lebel_res" [ngStyle]="{
                                                                      'font-weight': '500',
                                                                      'width': '80%',
                                                                      'font-family': selectedFont || 'Arial, sans-serif',
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align': item?.align,
                                                                      'margin': '0 !important',
                                                                      'width': '100%'
                                                                    }">{{ item?.label }}</label>
                                                                   
                                                                    <div class="ele">
                                                                      <app-signature-pad-create [Border_Color]="item?.Bdr_Dvdr" (signatureSaved)="handleSignatureSaved($event)"></app-signature-pad-create>
                                                                    </div>
                                                                    <!-- Display the captured signature image -->
                                                                    <img *ngIf="savedSignatureDataUrl" [src]="savedSignatureDataUrl" alt="Captured Signature">
                                                                  </div>
                                                                </div>
                                                                <p class="m-0 label_res2" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'margin-bottom': item?.mrbottum + 'px',
                                                                  'font-size': item?.help_font_size + 'px',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin': '0 !important',
                                                                  'color': item?.H_Txt_Notes ? item?.H_Txt_Notes : '#000000',
                                                                  'margin-top': item?.mrtop + 'px',
                                                                  'margin-right': item?.mrright + 'px',
                                                                  'margin-left': item?.mrleft + 'px',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align': item?.align,
                                                                  'width': '100%'
                                                                }">{{ item?.help_txt }}</p>
                                                              </ng-container>
                                                            </div>
                                                            
                                                            
                                                            
                      
                      
                      
                                                              <div *ngIf="item?.name === 'Total Due'" class="d-flex" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                               
                                                                  'font-style': item?.font_italic
                                                                 
                                                                }">
                                                                  <p class="txt-res"  style="margin: 0 !important " [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'margin':' 0 !important',
                                                                      'text-align':item?.align,
                                                                  'width':'100%'
                                                                    }">
                                                                  {{ item?.user_currency? item?.currency_symbol:crSymbol }}
                                                                  <span [ngStyle]="{
                                                                      'justify-content': item?.align
                                                                     
                                                                    
                                                                    }" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item.value }}</span>
                                                                  {{item?.user_currency?item?.currency:selectedCurrency?.currency_code}}
                                                                  
                                                                  </p>
                                                                </div>
                      
                                                                <div *ngIf="item?.name === 'Currency'" class="d-flex" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'margin-bottom': item?.mrbottum + 'px',
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                                  'margin-top': item?.mrtop + 'px',
                                                                  'margin-right': item?.mrright + 'px',
                                                                  'margin-left': item?.mrleft + 'px',
                                                                  'text-decoration': item?.textDecoration,
                                                              
                                                                  'font-style': item?.font_italic
                                                                }">
                                                                  <p class="txt-res" [ngStyle]="{
                                                                    'font-size': item?.font_size + 'px',
                                                                    'color': '#333',
                                                                    'margin-bottom': item?.mrbottum + 'px',
                                                                    'font-family': item?.fontFamily,
                                                                    'font-weight': item?.font_Weight,
                                                                    'padding-bottom': item?.padding + 'px',
                                                                    'padding-top': item?.paddingT + 'px',
                                                                    'padding-right': item?.paddingR + 'px',
                                                                    'padding-left': item?.paddingL + 'px',
                                                                    'margin-top': item?.mrtop + 'px',
                                                                    'margin-right': item?.mrright + 'px',
                                                                    'margin-left': item?.mrleft + 'px',
                                                                    'text-decoration': item?.textDecoration,
                                                                    'font-style': item?.font_italic,
                                                                    'text-align':item?.align,
                                                                    'margin':' 0 !important',
                                                                    'width':'100%'
                                                                  }">
                                                                  {{ item?.user_currency?item?.currency_symbol:crSymbol }}
                                                                  <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item.value }}</span>
                                                                  {{item?.user_currency?item?.currency:selectedCurrency?.currency_code}}
                                                                  
                                                                  </p>
                                                                </div>
                                                 
                                                           <div *ngIf="item?.name==='Date'"   class="d-flex align-items-center" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'text-decoration':item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              
                                                              'width' : item?.name == 'Date'? '100%':'fit-content',
                                                              'font-style':item?.font_italic
                                                          }">
                                                              <h1 class="txt-res " style= "margin: 0 !important;" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'color': item?.title_color,
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'line-height': '16px',
                                                                  'text-align':item?.align,
                                                                 
                                                                  'font-size':item?.font_size+'px',
                                                                  'font-size': item?.title_font_size + 'px'
                                                          }">{{item?.value}}</h1>  
                                                          
                                                              <div *ngIf="item?.dateTitle !=='Quote Creation Date'  && item?.dateTitle !=='Forecast Date'" class="d-flex gap-2 pe-2 py-1 pointer align-items-center ms-1" style=" border: 1px solid #CBDBE4;border-radius: 5px;" (click)="openDatePicker()" (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)">
                                                                  <input matInput (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)" style="width: 0px; border: none;"  id="{{item?.id}}" [ngModel]="dateValue"
                                                                  />
                                                                  <div  style="min-width:70px;"  id="{{item?.id}}">{{item?.itemDate? item?.itemDate: ''}}</div>
                                                                  <!-- <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                  <mat-datepicker #picker ></mat-datepicker> -->
                                                              </div>
                                                              <div *ngIf="item?.dateTitle =='Quote Creation Date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                                  <div  id="{{item?.id}}">{{item?.quoteCreationDate?item?.quoteCreationDate:getCurrentFormattedDate(item?.name,item?.id,z)}}</div>
                                                              </div>
                                                              <!-- <p>{{item?.dateTitle}}</p> -->
                      
                                                              <!-- <div *ngIf="item?.dateTitle =='invoice date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                                  <div  id="{{item?.id}}">{{getCurrentFormattedDate(item?.name,item?.id,z)}}</div>
                                                              </div> -->
                      
                                                              <div *ngIf="item?.dateTitle =='Forecast Date'" class="d-flex gap-2 ps-2 pointer align-items-center ms-1"  >
                                                                  <div  id="{{item?.id}}">{{item?.dateTitleForecast?commonFormat(item?.dateTitleForecast):''}}</div>
                                                              </div>
                                                       
                                                              
                                                          </div>
                      
                                                          <div *ngIf="item?.name==='Text'" class="d-flex"  >
                                                              <p style="margin: 0 !important;"  class="txt-res" [ngStyle]="{
                                                                  'font-size':item?.font_size+'px',
                                                                  'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'color':item?.color,
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'text-align':item?.align,
                                                                  'width':'100%'
                                                          }">{{item?.value}} </p>
                                                          </div>
                                                          <div *ngIf="item?.name === 'Organization N.'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                             
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="txt-res"  style="margin: 0 !important " [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.title_font_size + 'px',
                                                                  'color': item?.title_color,
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align
                                                                  
                                                                }">
                                                                {{ item?.value }}&nbsp;</p>
                                                                <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                               
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
                                                                  
                                                                }"  >{{ item?.tag }}</span>
                                                                
                                                            </div>
                                                            
                                                            <div *ngIf="item?.name === 'Item code'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'margin-bottom': item?.mrbottum + 'px',
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'margin-top': item?.mrtop + 'px',
                                                              'margin-right': item?.mrright + 'px',
                                                              'margin-left': item?.mrleft + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="txt-res" [ngStyle]="{
                                                              'font-size': item?.title_font_size + 'px',
                                                                'color': '#333',
                                                                'margin-bottom': item?.mrbottum + 'px',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'padding-bottom': item?.padding + 'px',
                                                                'padding-top': item?.paddingT + 'px',
                                                                'padding-right': item?.paddingR + 'px',
                                                                'padding-left': item?.paddingL + 'px',
                                                                'margin-top': item?.mrtop + 'px',
                                                                'margin-right': item?.mrright + 'px',
                                                                'margin-left': item?.mrleft + 'px',
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align,
                                                                'margin':' 0 !important',
                                                                  'width':'100%'
                                                              }">
                                                                {{ item?.value }}</p>
                                                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                
                                                            </div>
                      
                                                            <!-- <div *ngIf="item?.name === 'Number List'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align
                                                            }">
                                                              <ol >
                                                                <li *ngFor="let listItem of numberList; let i = index">
                                                                  <input type="text" [(ngModel)]="numberList[i]" class="borderless-input" [ngStyle]="{
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'margin-bottom': item?.mrbottum + 'px',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'padding-bottom': item?.padding + 'px',
                                                                      'padding-top': item?.paddingT + 'px',
                                                                      'padding-right': item?.paddingR + 'px',
                                                                      'padding-left': item?.paddingL + 'px',
                                                                      'margin-top': item?.mrtop + 'px',
                                                                      'margin-right': item?.mrright + 'px',
                                                                      'margin-left': item?.mrleft + 'px',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic
                                                                    }">
                                                                </li>
                                                              </ol>
                                                              
                                                            </div> -->
                      
                      
                                                            <div *ngIf="item?.name =='Number List'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'color':item?.color,
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                                
                                                          }">
                                                                  <p class="txt-res"   style="margin: 0 !important "   readonly  [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':'bolder',
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'width':'100%',
                                                                  ' list-style-position': 'inside'
                      
                                                              }">{{ item?.label}}
                                                              <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                                  <div *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue">
                                                                      <ol style="margin: 0 !important " >
                                                                        <li   style="margin: 0 !important "  *ngFor="let coun of item?.custom_value?.value; let i = index" [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'font-size': item?.font_size + 'px',
                                                                            'color': '#333',
                                                                            'font-family': item?.fontFamily,
                                                                            'margin':' 0 !important',
                                                                            'color': item?.color,
                                                                            'text-decoration': item?.textDecoration,
                                                                            'font-style': item?.font_italic,
                                                                            'text-align':item?.align,
                                                                                'width':'100%'
                                                                        }">
                                                                          <span style="font-weight: normal;" >{{ coun?.name }}</span>
                                                                          <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.value }}</span>
                                                                        </li>
                                                                      </ol>
                                                                    </div>
                                                                                                                      </ng-container>
                                                              <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span> -->
                                                              <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                                                              
                                                   </p>
                                                   <br>
                                                   <!-- <div *ngIf="item?.custom_value?.value?.length">
                                                      <span  [value]="coun?.name"
                                                      *ngFor="let coun of item?.custom_value?.value"
                                                      [selected]="coun?.selected">{{coun?.name}}</span>
                                                  </div> -->
                                                              </div>
                      
                                                            
                                                              <div *ngIf="item?.name =='Order List'" class="d-flex" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                     
                                                                      'color':item?.color,
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                              }">
                                                                      <p class="txt-res" style="margin: 0 !important "  readonly  [ngStyle]="{
                                                                          'font-size':item?.font_size+'px',
                                                                          'font-family':item?.fontFamily,
                                                                      'font-weight':'bolder',
                                                                      'margin':' 0 !important',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic,
                                                                      'text-align':item?.align,
                                                                  'width':'100%',
                                                                 ' list-style-position': 'inside'
                                                                  }">{{ item?.label}}
                                                                  <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                                      <div *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue">
                                                                          <ul style="margin: 0 !important " 
                                                                          [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'align-content':item?.align,
                                                                              'width':'100%'
                                                                          }"
                                                                          >
                                                                            <li *ngFor="let coun of item?.custom_value?.value; let i = index" [ngStyle]="{
                                                                                'justify-content': item?.align,
                                                                                'font-size': item?.font_size + 'px',
                                                                                'color': '#333',
                                                                                'font-family': item?.fontFamily,
                                                                                'margin':' 0 !important',
                                                                                'color': item?.color,
                                                                                'text-decoration': item?.textDecoration,
                                                                                'font-style': item?.font_italic,
                                                                                'text-align':item?.align,
                                                                                'width':'100%'
                                                                            }">
                                                                              <span style="font-weight: normal;">{{ coun?.name }}</span>
                                                                              <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.value }}</span>
                                                                            </li>
                                                                          </ul>
                                                                        </div>
                                                                                                                          </ng-container>
                                                                  <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span> -->
                                                                  <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                                                                  
                                                       </p>
                                                       <br>
                                                       <!-- <div *ngIf="item?.custom_value?.value?.length">
                                                          <span  [value]="coun?.name"
                                                          *ngFor="let coun of item?.custom_value?.value"
                                                          [selected]="coun?.selected">{{coun?.name}}</span>
                                                      </div> -->
                                                                  </div>
                                                                
                                                                                                        
                                                          <div *ngIf="item.name=='Table'">
                                                              <div style="width: 100%; overflow-x: auto;">
                                                              <table [ngStyle]="{
                                                                  'border': '1px solid ' + item?.borderColor,
                                                                  'border-collapse': 'collapse !important',
                                                                  'justify-content': item?.align
                                                                }">
                                                                  <tr >
                                                                    <th [ngStyle]="{
                                                                      'border': '1px solid ' + item?.borderColor,
                                                                      'border-collapse': 'collapse !important'
                                                                    }" *ngFor="let headerIndex of item?.tableDisplayedColumns; let i = index">
                                                                      <input [ngStyle]="{
                                                                      'align-items':item?.align,
                                                                      'border':'none',
                                                                      'margin-bottom':'0px',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'margin':' 0 !important',
                                                                      'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                                      'background-color':item?.backGroundColor,
                                                                     
                                                                      'justify-content': item?.align,
                                                                      'text-align':item?.text_align,
                                                                      'width':'100%'       
                                                                       }" style="text-align: center;  font-weight: bold; width: 100% !important;" type="text" [(ngModel)]="headerData[i]" (change)="logTableData(item?.name,item?.id,'header')"
                                                                        [(ngModel)]="headerIndex.name"  class="no-border"/>    
                                                             
                                                              </th>
                                                                  </tr>
                                                                  
                                                                <tr *ngFor="let row of item?.tableDisplayedRows; let rowIndex = index">
                                                             <td [ngStyle]="{
                                                          'border': '1px solid ' + item?.borderColor,
                                                          'border-collapse': 'collapse !important'
                                                        }" *ngFor="let column of item?.tableDisplayedColumns; let colIndex = index">
                                                          <input type="text" class="no-border"
                                                                 [ngStyle]="{
                                                                  'border':'none',
                                                                  'margin-bottom':'0px',
                                                                  'width':'185px',
                                                                  'justify-content': item?.align,
                                                                 
                                                                  'width':'100%'
                                                                 }"
                                                            [(ngModel)]="item?.tableDisplayedRows[rowIndex][column?.name]"  (change)="logTableData(item?.name,item?.id,'rows')" />
                                                                </td>
                                                             </tr>
                                                                </table>
                                                                </div>
                                                                
                                                            </div>
                      
                      
                      
                      
                      
                      
                                                            <div *ngIf="item.name === 'Item Table'" class="d-flex">
                                                              <div style="margin-top: 65px;">
                                                                 
                                                              </div>
          
                                                              <div id="tableDiv" style="width: 100%; overflow-x: auto;"
                                                              >
                                                              <div class="table-outer-border"  *ngFor="let table of item?.table ; let i = index">
                                                                <table class="mt-3" id="tableDiv" [ngClass]="item?.class_name ? item?.class_name  : tableStyleClass + ' ' + tableColorClass"
                                                                mat-table [dataSource]="getTableDataSource(i)" multiTemplateDataRows cdkDropList
                                                                    [ngStyle]="{
                                                                        'justify-content': item?.align
                                                                       
                                                
                                                                      }"
                                                                    (cdkDropListDropped)="dropItem($event,i)"
                                                                    >
                                                                    <ng-container matColumnDef="{{column.name}}" *ngFor="let column of getFilteredColumns(columnsToDisplayWithExpand, 'column')"
                                                
                                                                    >
                                                                    <ng-container *ngIf="column.name == 'expand'"
                                                                    >
                                                                        <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width:0px;" class="th_bg border-start-0 border-bottom-0 rounded-start"
                                                                        [ngStyle]="{
                                                                            'font-size': item?.font_size + 'px',
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.text_align,
                                                                            'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                                
                                                                          }">&nbsp;</th>
                                                                        <td mat-cell *matCellDef="let element" style="width:0px;" class="border_table border-end-0 rounded-start">
                                                                            <span id="expand" style="position: relative;" role="button" aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                                                                              <span style="position: absolute;left: -2.3rem;top: -8px;">
                                                                                <svg width="22" height="16" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{'fill':expandedElement === element?'#03C0FC':'grey'}">
                                                                                <rect x="0.970703" y="0.199219" width="20.0696" height="1.64544" rx="0.82272" />
                                                                                <rect x="0.970703" y="6.78125" width="20.0696" height="1.64544" rx="0.82272" />
                                                                                <rect x="0.970703" y="13.3633" width="20.0696" height="1.64544" rx="0.82272" />
                                                                                </svg>
                                                                              </span>
                                                                           </span>
                                                                           <span id="merge" class="removeInView" style="position: relative;" role="button" *ngIf="shouldDisplayMergeIcon(element)">
                                                                            <span class="merge-icon" style="position: absolute;left: -2.3rem;top: -40px;">
                                                                               
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.25713 0.322614C9.45687 0.124256 9.73529 0 10.042 0C10.6553 0 11.1516 0.492939 11.1516 1.1021V3.89141L12.6587 2.39456C13.0925 1.96374 13.7946 1.96374 14.2284 2.39456C14.6622 2.82538 14.6622 3.52271 14.2284 3.95353L10.8268 7.33196L10.8247 7.33398C10.6956 7.46221 10.5342 7.55841 10.3587 7.60851C10.3587 7.60851 10.3587 7.60851 10.3567 7.60851C10.1388 7.67261 9.90271 7.6666 9.68884 7.59646C9.52744 7.54236 9.37814 7.45018 9.25709 7.33196L5.85549 3.94952C5.42172 3.5187 5.42172 2.82137 5.85549 2.39055C6.28927 1.95973 6.99138 1.95973 7.42515 2.39055L8.93226 3.8874V1.10007C8.93232 0.797518 9.05741 0.520991 9.25713 0.322614Z" fill="#B6C9D5"/>
                                                                                    <path d="M9.25713 19.678C9.45687 19.8763 9.73529 20.0006 10.042 20.0006C10.6553 20.0006 11.1516 19.5077 11.1516 18.8985V16.1112L12.6587 17.608C13.0925 18.0389 13.7946 18.0389 14.2284 17.608C14.6622 17.1772 14.6622 16.4799 14.2284 16.0491L10.8268 12.6727L10.8247 12.6706C10.6956 12.5424 10.5342 12.4462 10.3587 12.3961C10.3587 12.3961 10.3587 12.3961 10.3567 12.3961C10.1388 12.332 9.90271 12.338 9.68884 12.4081C9.52744 12.4622 9.37814 12.5544 9.25709 12.6727L5.85549 16.0511C5.42172 16.4819 5.42172 17.1792 5.85549 17.6101C6.28927 18.0409 6.99138 18.0409 7.42515 17.6101L8.93226 16.1112V18.9005C8.93232 19.2031 9.05741 19.4796 9.25713 19.678Z" fill="#B6C9D5"/>
                                                                                    <path d="M18.5897 8.59896L1.41229 8.59896C0.631494 8.59896 0 9.22615 0 10.0016C0 10.7771 0.631494 11.4043 1.41229 11.4043L18.5877 11.4043C19.3685 11.4043 20 10.7771 20 10.0016C20 9.22615 19.3685 8.59896 18.5897 8.59896Z" fill="#B6C9D5"/>
                                                                                </svg>
                                                                                    
                                                                            </span>
                                                                           
                                                                          </span>
                                                
                                                                        
                                                                        </td>
                                                                      </ng-container>
                                                                      <ng-container *ngIf="column.key == 'Description'">
                                                                        <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                                        [ngStyle]="{
                                                                            'font-size': item?.font_size + 'px',
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.text_align,
                                                                            'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                                
                                                                          }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                          <div [ngStyle]="{
                                                                            'font-size': item?.font_size + 'px',
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.text_align,
                                                                            'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                                
                                                                          }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                           {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                                          </div>
                                                                        </th>
                                                                        <td class="border_table border-start-0"  [ngStyle]="{
                                                                          'font-size': item?.font_size + 'px',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'justify-content': item?.align,
                                                                          'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                                
                                                                        }" mat-cell *matCellDef="let element">
                                                                       
                                                                       <div id="description" *ngIf=" column.key =='Description'" class="px-2">
                                                                        {{element[column.name]}}
                                                                    </div>
                                                                    
                                                                         
                                                                        </td>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="column.key == 'Comments'">
                                                                            <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                                            [ngStyle]="{
                                                                                'font-size': item?.font_size + 'px',
                                                                                'font-family': item?.fontFamily,
                                                                                'font-weight': item?.font_Weight,
                                                                                'justify-content': item?.align,
                                                                                'text-align':item?.text_align,
                                                                                'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                                    
                                                                              }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                              <div [ngStyle]="{
                                                                                'font-size': item?.font_size + 'px',
                                                                                'font-family': item?.fontFamily,
                                                                                'font-weight': item?.font_Weight,
                                                                                'justify-content': item?.align,
                                                                                'text-align':item?.text_align,
                                                                                'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                                    
                                                                              }" style="text-align: center; font-weight: bold;width: 160.5px !important;">
                                                                               {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                                              </div>
                                                                            </th>
                                                                            <td class="border_table border-start-0"  [ngStyle]="{
                                                                              'font-size': item?.font_size + 'px',
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                              'justify-content': item?.align,
                                                                              'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto'),
                                                                              'max-width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                                
                                                    
                                                                            }" mat-cell *matCellDef="let element">
                                                                           
                                                                        <div id="description" *ngIf=" column.key =='Comments'" class="px-2">
                                                                         <div>              
                                                              
                                                                            <div class="col-12 " id="comment">
                                                                              <mat-form-field class="example-chip-list " appearance="outline">
                                                                                <mat-chip-list #chipList aria-label="Fruit selection">
                                                                                  <mat-chip *ngFor="let comment of element[column.name] ;let i = index" (removed)="removeComment(element,$event,'Comments',i,z,item,item?.name,item?.id,comment)">
                                                                                    {{comment.title}}
                                                                                    <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                                                                                  </mat-chip>
                                                                                  <input placeholder="Type to add a comment..." style="min-width: 555px;" [matChipInputFor]="chipList"
                                                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"  [matChipInputAddOnBlur]="addOnBlur"
                                                                                    (matChipInputTokenEnd)="addComment($event,element,'Comments')" >
                                                                                </mat-chip-list>
                                                                              </mat-form-field>
                                                                            </div>
                                                                          </div>                                
                                                                                   
                                                                        </div>
                                                                             
                                                                            </td>
                                                                            </ng-container>
                                                
                                                                    <ng-container *ngIf="column.name !=='expand' && column.key !=='Description' && column.key !=='Comments'">
                                                                        <th mat-header-cell *matHeaderCellDef class="th_bg border-bottom"
                                                                        [ngStyle]="{
                                                                            'font-size': item?.font_size + 'px',
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.text_align,
                                                                            'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto'),
                                                                            'background-color': item?.backGroundColor !== '#E3ECF1' ? item?.backGroundColor : tableStyleClass + ' ' + tableColorClass
                                                                          }">
                                                                          <div [ngStyle]="{
                                                                            'font-size': item?.font_size + 'px',
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.text_align,
                                                                            'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass
                                                
                                                                          }" style="text-align: center; font-weight: bold;" class="th_width">
                                                                            {{getTranslatedText ("QUOTE_TEMPLATE." + column?.name ) || column?.name | uppercase}}
                                                                          </div>
                                                                        </th>
                                                                        <td class="border_table border-start-0"  [ngStyle]="{
                                                                          'font-size': item?.font_size + 'px',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'justify-content': item?.align,
                                                                          'width': item?.columnWidths && item?.columnWidths[column?.name] ? item?.columnWidths[column?.name] + 'px' : (item?.width ? item?.width + 'px' : 'auto')
                                                
                                                                        }" mat-cell *matCellDef="let element" >
                                                
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key !== 'Quantity' && column.key !=='Item_image' && column.key !== 'QTY'  && column.key !=='Item image' && column.key !=='Total' && column.key !=='Price' && column.key !=='Video URL' && column.key !=='Website URL'"
                                                                        (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                                        {{ element[column.name] }}  
                                                                       
                                                                        </div>
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key =='Price'"
                                                                        (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                                        {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element[column.name]  | number:'1.2-2'}} 
                                                                        </div>
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key === 'Video URL'" (click)="openLink(element[column.key])">
                                                                            <i class="far fa-play-circle fs-4" style="cursor: pointer;"></i>
                                                                        </div>
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf="column.key === 'Website URL'" (click)="openLink(element[column.key])">
                                                                            <i class="fas fa-globe fs-4" style="cursor: pointer;"></i>
                                                                        </div>
                                                                        
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf=" column.key =='Total'"
                                                                        (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                                        <!-- | number:'1.2-2' -->
                                                                        <!-- {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element[column.name] | number:'1.2-2'}} -->
                                                                        {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ element[column.name] | number:'1.2-2'}}
                                                                        </div>
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" *ngIf=" column.key =='Item_image'  || column.key =='Item image'"
                                                                        (click)="openDiscDialog(element,$event,'quote',i,z,item,item?.name,item?.id)">
                                                                            <img style="width:55px;height: 55px;border-radius: 50%;object-fit:cover" [src]="element[column.name]" alt="" />
                                                                           
                                                                        </div>
                                                                        <div class="d-flex gap-2 align-item-start justify-content-center" style="color: #000000;" *ngIf="column.key == 'Quantity'  || column.key == 'QTY'">
                                                                         <span *ngIf="Invoice_ids ==''" id="decrement" (click)="decrease(element[column.name],column.key,i,element,rowIndex)" class="d-flex pointer justify-content-center align-items-center removeInView" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
                                                                           -                              
                                                                         </span>
                                                                         {{ element[column.name] }}
                                                                         <span *ngIf="Invoice_ids ==''" id="increment" (click)="increase(element[column.name],column.key,i,element,rowIndex)" class="d-flex pointer justify-content-center align-items-center removeInView" style="border-radius: 50%; height:23px;width:23px;background:#03C0FC;color:white;">
                                                                            +                             
                                                                         </span>
                                                                        </div>
                                                                        <span id="rowDelete" class="d-flex w-100 justify-content-end removeInView" *ngIf="column.name === columnsToDisplayWithExpand[columnsToDisplayWithExpand.length - 1].name" style="position: relative;" role="button" aria-label="delete row">
                                                                            <span *ngIf="Invoice_ids ==''" style="position: absolute;right: -16%;top: -30px;"  (click)="deleteElement(element, $event,i,z)">
                                                                      
                                                                              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                  <circle cx="17.5" cy="17.5" r="17.5" fill="#ECF7FF"/>
                                                                                  <path d="M19.6774 16.7494L26.2204 10.2061C26.4004 10.026 26.4997 9.78571 26.5 9.52947C26.5 9.27309 26.4007 9.0325 26.2204 8.85266L25.6471 8.27943C25.4668 8.09888 25.2265 8 24.97 8C24.7139 8 24.4736 8.09888 24.2933 8.27943L17.7503 14.8223L11.207 8.27943C11.027 8.09888 10.7866 8 10.5302 8C10.2741 8 10.0336 8.09888 9.85366 8.27943L9.28 8.85266C8.90667 9.226 8.90667 9.83323 9.28 10.2061L15.8232 16.7494L9.28 23.2924C9.09988 23.4728 9.00071 23.7132 9.00071 23.9694C9.00071 24.2256 9.09988 24.4659 9.28 24.6462L9.85352 25.2194C10.0335 25.3998 10.2741 25.4989 10.53 25.4989C10.7864 25.4989 11.0269 25.3998 11.2068 25.2194L17.7501 18.6764L24.2931 25.2194C24.4734 25.3998 24.7137 25.4989 24.9698 25.4989H24.9701C25.2263 25.4989 25.4666 25.3998 25.6469 25.2194L26.2203 24.6462C26.4003 24.4661 26.4996 24.2256 26.4996 23.9694C26.4996 23.7132 26.4003 23.4728 26.2203 23.2926L19.6774 16.7494Z" fill="#03C0FC"/>
                                                                                  </svg>                                    
                                                                            </span>
                                                                         </span>
                                                                        
                                                                         
                                                                        </td>
                                                                        </ng-container>
                                                                                              <!-- <ng-container  
                                                                      >
                                                                        <th id="delete" mat-header-cell *matHeaderCellDef aria-label="row actions" class="border-bottom-0 border-end-0 " style="display:none;">&nbsp;</th>
                                                                        <td id="delete" mat-cell *matCellDef="let element" style="width:0px;" class="border-0">
                                                
                                                                        
                                                                        </td>
                                                                      </ng-container> -->
                                                                      </ng-container>
                                                                                                                  
                                                
                                                                      <ng-container matColumnDef="expandedDetail">
                                                                        <td mat-cell *matCellDef="let element" style="padding: 0px 0px 0px 0px !important;" [attr.colspan]="columnsToDisplay?.length">
                                                                          <div >
                                                                            <div *ngFor="let column of item?.displayedColumns11">
                                                                            <div  *ngIf="column.orientation == 'row'">
                                                                                <div >
                                                                              <div [ngStyle]="{
                                                                                'font-size':item?.font_size+'px',
                                                                            'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                              'width':'100%',
                                                                            'padding': '10px 10px 10px 10px'
                                                                            
                                                                        }" *ngIf="column.orientation == 'row' && column.key !== 'Video URL' && column.key !== 'Website URL' && column.key !=='Description'&&column.key !=='Comments'">
                                                                        <p>{{element[column.name]|json}}</p>
                                                                                {{element[column.name]}}
                                                                                
                                                                              </div>
                                                                              <div [ngStyle]="{
                                                                                'font-size':item?.font_size+'px',
                                                                            'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                              'width':'100%',
                                                                            'padding': '10px 10px 10px 10px'
                                                                            
                                                                        }" id="description" *ngIf="column.orientation == 'row' && column.key =='Description'" class="px-2" style="border: 1px solid #E3ECF1; border-bottom: none;">
                                                                                
                                                                        {{element[column.name]}}
                                                                                    
                                                                                       
                                                                            </div>
                                                                            <div [ngStyle]="{
                                                                                'font-size':item?.font_size+'px',
                                                                            'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                              'width':'100%',
                                                                            'padding': '10px 10px 10px 10px'
                                                                            
                                                                        }" id="description" *ngIf="column.orientation == 'row' && column.key =='Comments'" class="px-2" style="border: 1px solid #E3ECF1; border-bottom: none;">
                                                
                                                                        <div >              
                                                              
                                                                            <div class="col-12 " id="comment">
                                                
                                                                                <div class="d-flex text-8 fw-500 align-items-center justify-center gap-3 mt-4">
                                                
                                                                                    <mat-form-field class="example-chip-list" appearance="fill">
                                                                                        <mat-label>{{ "QUOTE_TEMPLATE.search_comments" |translate}}</mat-label>
                                                                                        <mat-chip-list #chipList aria-label="Fruit selection">
                                                                                            <ng-container  *ngFor="let fruit of element?.fruits">
                                                                                                <ng-container *ngIf="fruit?.id ==element?.id">
                                                                                          <mat-chip style="word-break: break-word !important;" (removed)="removeComment(element, $event, 'Comments', i, z, item, item?.name, item?.id, fruit)">
                                                                                            <ng-container >
                                                                                                {{ fruit.title }}
                                                                                                <button matChipRemove>
                                                                                                  <mat-icon>cancel</mat-icon>
                                                                                                </button>
                                                                                            </ng-container>
                                                                                          </mat-chip>
                                                                                        </ng-container>
                                                                                        </ng-container>
                                                                                          <!-- <input
                                                                                            (input)="itemInputChange($event, element,i)"
                                                                                            placeholder="{{ 'QUOTE_TEMPLATE.add_comments' | translate }}"
                                                                                            #fruitInput
                                                                                            [formControl]="searchItem"
                                                                                            [matAutocomplete]="auto"
                                                                                            [matChipInputFor]="chipList"
                                                                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                                            (matChipInputTokenEnd)="add($event, element,i)"
                                                                                            (focus)="resetFilteredOptions(element)"
                                                                                          /> -->
                                                                                        </mat-chip-list>
                                                                                        <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, element,i)">
                                                                                          <mat-option *ngFor="let option of filteredOptions" [value]="option.title">
                                                                                            {{ option.title }}
                                                                                          </mat-option>
                                                                                        </mat-autocomplete> -->
                                                                                      </mat-form-field>
                                                                                      
                                                                                  </div>
                                                                           
                                                                            </div>
                                                                          </div>
                                                                                    
                                                                                       
                                                                            </div>
                                                                              <div class="d-flex gap-2 align-item-start " style="padding: 0px 0px 0px 15px;" *ngIf="column.key === 'Video URL'" (click)="openLink(element[column.key])">
                                                                                <i class="far fa-play-circle fs-4" style="cursor: pointer;"></i>
                                                                            </div>
                                                                            <div class="d-flex gap-2 align-item-start " style="padding: 0px 0px 0px 15px;" *ngIf="column.key === 'Website URL'" (click)="openLink(element[column.key])">
                                                                                <i class="fas fa-globe fs-4" style="cursor: pointer;"></i>
                                                                            </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                          </div>
                                                                        </td>
                                                                      </ng-container>
                                                                                                                                                                                                                                                                                                                                                                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                                                    <tr cdkDrag mat-row *matRowDef="let element; let rowIndex = index; columns: columnsToDisplay;" class="example-element-row cursor-move"
                                                                        [class.example-expanded-row]="expandedElement === element"
                                                                        (click)="decrease('','','','',rowIndex)">
                                                                    </tr>
                                                                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                                                                           
                                                                </table>
                                                                  <div class="d-flex flex-column justify-content-start " style="align-items: end;" *ngIf="item?.isTotalShow">
          
                             
                          
                                                                    <div *ngIf="item?.sub_total =='Subtotal' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                                        'justify-content':item?.align,
                                                                        
                                                                        'font-size':item?.font_size+'px',
                                                                            'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                           
                                                                            'padding-top':item?.paddingT+'px',
                                                                            'padding-right':item?.paddingR+'px',
                                                                            'padding-left':item?.paddingL+'px',
                                                                           
                                                                           
                                                                            'text-decoration':item?.textDecoration,
                                                                            'font-style':item?.font_italic,
                                                                            'min-width':'225px'
                                                                    }">
                                                                            <p readonly class="d-flex justify-content-between w-100"  [ngStyle]="{
                                                                                'font-size':item?.font_size+'px',
                                                
                                                                                'font-family':item?.fontFamily,
                                                                            'font-weight':item?.font_Weight,
                                                                         
                                                                            'margin':' 0 !important',
                                                                            'text-decoration':item?.textDecoration,
                                                                            'font-style':item?.font_italic
                                                                        }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.sub_total) || item?.sub_total}}</span>
                                                                        <!-- <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ item?.subTotalAmount?item?.subTotalAmount:getSubtotal(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span> -->
                                                                        <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getSubtotal(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                        <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                                <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>     -->
                                                                                                                                        </p>
                                                                        </div>
                                                
                                                                        <div *ngIf="item?.discount =='Discount' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                                            'justify-content':item?.align,
                                                                            
                                                                            'font-size':item?.font_size+'px',
                                                                                'font-family':item?.fontFamily,
                                                                                'font-weight':item?.font_Weight,
                                                                               
                                                                                'padding-right':item?.paddingR+'px',
                                                                                'padding-left':item?.paddingL+'px',
                                                                                
                                                                                'text-decoration':item?.textDecoration,
                                                                                'font-style':item?.font_italic,
                                                                                'min-width':'225px'
                                                                        }">
                                                                                <p readonly class="txt-res d-flex justify-content-between w-100" [ngStyle]="{
                                                                                    'font-size':item?.font_size+'px',
                                                                                    'margin':' 0 !important',
                                                                                    'font-family':item?.fontFamily,
                                                                                'font-weight':item?.font_Weight,
                                                                              
                                                                               
                                                                                'text-decoration':item?.textDecoration,
                                                                                'font-style':item?.font_italic
                                                                            }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.discount) || item?.discount}}</span>
                                                                            <span style="color:#1b8ef8">{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getDiscount(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                            <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                                <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                                           -->
                                                                                                  </p>
                                                                            </div>
                                                
                                                                            <div *ngIf="item?.subtotalAfter =='Subtotal after Discount' && item?.name =='Item Table'" class="d-flex" [ngStyle]="{
                                                                                'justify-content':item?.align,
                                                                                
                                                                                'font-size':item?.font_size+'px',
                                                                                    'font-family':item?.fontFamily,
                                                                                    'font-weight':item?.font_Weight,
                                                                                   
                                                                                    'padding-right':item?.paddingR+'px',
                                                                                    'padding-left':item?.paddingL+'px',
                                                                                    
                                                                                    'text-decoration':item?.textDecoration,
                                                                                    'font-style':item?.font_italic,
                                                                                    'min-width':'225px'
                                                                            }">
                                                                                    <p readonly class="txt-res d-flex justify-content-between w-100" [ngStyle]="{
                                                                                        'font-size':item?.font_size+'px',
                                                                                        'margin':' 0 !important',
                                                                                        'font-family':item?.fontFamily,
                                                                                    'font-weight':item?.font_Weight,
                                                                                  
                                                                                   
                                                                                    'text-decoration':item?.textDecoration,
                                                                                    'font-style':item?.font_italic
                                                                                }"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.subtotalAfter) || item?.subtotalAfter}}</span>
                                                                                <span style="color:#1b8ef8">{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ getSubtotalAfter(table.data)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                                <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                                <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                        -->
                                                                                                                     </p>
                                                                                </div>
                                                
                                                                           
                                                
                                                                        <div *ngIf="item?.taxes =='Taxes' && item?.name === 'Item Table'" class="d-flex" [ngStyle]="{
                                                                            'justify-content':item?.align,
                                                                          
                                                                            'font-size':item?.font_size+'px',
                                                                                'font-family':item?.fontFamily,
                                                                                'font-weight':item?.font_Weight,
                                                                                
                                                                                'padding-right':item?.paddingR+'px',
                                                                                'padding-left':item?.paddingL+'px',
                                                                                
                                                                                'text-decoration':item?.textDecoration,
                                                                                'font-style':item?.font_italic,
                                                                                'min-width':'225px'
                                                                        }">
                                                                                <p readonly class="txt-res d-flex flex-column justify-content-between w-100" [ngStyle]="{
                                                                                    'font-size':item?.font_size+'px',
                                                                                   
                                                                                    'font-family':item?.fontFamily,
                                                                                'font-weight':item?.font_Weight,
                                                                                'margin':' 0 !important',
                                                                                'text-decoration':item?.textDecoration,
                                                                                'font-style':item?.font_italic
                                                                            }">
                                                                            
                                                                              
                                                                              
                                                                              
                                                                            <span *ngIf="table?.data?.length" class="fw-600" style="padding-right:23px;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.taxes) || item?.taxes}}</span>
                                                                            <ng-container *ngIf="table?.data">
                                                                                <ng-container *ngIf="aggregateTaxes(table.data,item?.multiplyTax) | keyvalue as taxes">
                                                                                  <span style="display: flex;flex-direction: column;color:#000000!important;" *ngFor="let tax of taxes">
                                                                                    {{ tax?.key }} ({{ tax?.value?.value }}%) {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{ tax?.value?.amount | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}
                                                                                  </span>
                                                                                </ng-container>
                                                                              </ng-container>
                                                                            <span style="color:#1b8ef8;display: flex;justify-content: space-between;" >
                                                                                 <span style="color:#000000!important;">{{getTranslatedText ("QUOTE_TEMPLATE." + item?.taxes) || item?.taxes}}</span>
                                                                            {{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{getTotalTax(table.data,item?.multiplyTax)  | number:'1.2-2'}} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                            <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                                <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>                                       -->
                                                                                </p>
                                                                            </div>
                                                                
                                                                            
                                                                                <div  class="d-flex txt-res" [ngStyle]="{
                                                                                    'justify-content':item?.align,
                                                                                    
                                                                                    'font-size':item?.font_size+'px',
                                                                                        'font-family':item?.fontFamily,
                                                                                        'font-weight':item?.font_Weight,
                                                                                       
                                                                                        'padding-top':item?.paddingT+'px',
                                                                                        'padding-right':item?.paddingR+'px',
                                                                                        'padding-left':item?.paddingL+'px',
                                                                                       
                                                                                       
                                                                                        'text-decoration':item?.textDecoration,
                                                                                        'font-style':item?.font_italic,
                                                                                        'min-width':'225px'
                                                                                }">
                                                                                    <p readonly class="txt-res d-flex justify-content-between w-100"  [ngStyle]="{
                                                                                        'font-size':item?.font_size+'px',
                                                                                        
                                                                                        'font-family':item?.fontFamily,
                                                                                    'font-weight':item?.font_Weight,
                                                                                 
                                                                                    'margin':' 0 !important',
                                                                                    'text-decoration':item?.textDecoration,
                                                                                    'font-style':item?.font_italic
                                                                                }">
                                                                                <span [ngStyle]="{
                                                                                    'justify-content': item?.align
                                                                                    
                                                                                  }" *ngIf="item?.name =='Item Table'"><span style="width:48%;color:#000000!important;" >{{getTranslatedText ("QUOTE_TEMPLATE." + item?.total) || item?.total}}&nbsp;</span></span>
                                                                                <span *ngIf="item.name == 'Item Table' && (item?.total =='Total')" [ngStyle]="{
                                                                                    'justify-content': item?.align
                                                                                   
                                                                                  }" style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : showCurrencySymbolExchange?.currency_symbol?showCurrencySymbolExchange?.currency_symbol:crSymbol }} {{item?.dueAmount  | number:'1.2-2' }} {{item?.user_currency?item?.currency:showCurrencySymbolExchange?.currency_code?showCurrencySymbolExchange?.currency_code:crCode}}</span>
                                                                                <!-- <span *ngIf="item?.hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                                                                <span *ngIf="item?.element_hidden" style="cursor: pointer;" (click)=" hide_element($event,item?.name,item?.id,z)" ><img [src]="'assets/svg-icons/visibility.svg'" alt="logo" /></span>           -->
                                                                                                                                  </p>
                                                                                </div>
                                                                
                                                                
                                                
                                                                
                                                                
                                                                </div>
                                                                  
                                                              </div>
                                                                
                                                              </div>
                                                          
                                                             
                                                          </div>
                      
                      
                                                          <div *ngIf="item.name === 'Installment'">
                                                              <div style="width: 100%; overflow-x: auto;">
                                                                  <table mat-table  [dataSource]="dataSource" class="mat-elevation-z8 ui-table" style="border: none !important;"
                                                                  [ngStyle]="{
                                                                      'justify-content': item?.align
                                                                     
                      
                                                                    }"
                                                                  >
                                                                      <ng-container matColumnDef="position">
                                                                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                                        [ngStyle]="{
                                                                                'font-size': item?.font_size + 'px',
                                                                                'font-family': item?.fontFamily,
                                                                                'font-weight': item?.font_Weight,
                                                                                'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                                                'background-color':item?.backGroundColor,
                                                                                'justify-content': item?.align,
                                                                             
                                                                                'text-align':item?.text_align
                                                                              
                                                                             
                                                                                 }" class="th_width" style="text-align: center; font-weight: bold;">
                                                                        {{item?.displayedColumns12[0].name}}</th>
                                                                        <td mat-cell *matCellDef="let element" style="text-align: center;"
                                              
                                                                        > </td>
                                                                      </ng-container>
                                                                      <ng-container matColumnDef="name">
                                                                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                                        [ngStyle]="{
                                                                          'font-size': item?.font_size + 'px',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                                          'background-color':item?.backGroundColor,
                                                                          'text-align':item?.text_align,
                                                                          
                                                                          'justify-content': item?.align
                                                                              
                                                                             
                                                                           }" class="th_width" style="text-align: center;  font-weight: bold;">
                                                                        {{item?.displayedColumns12[1].name}}</th>
                                                                        <td mat-cell *matCellDef="let element" style="text-align: center;"> </td>
                                                                      </ng-container>
                                                                      <ng-container matColumnDef="weight">
                                                                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"
                                                                        [ngStyle]="{
                                                                          'font-size': item?.font_size + 'px',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'color':item?.color !=='#000000'?item?.color:tableStyleClass + ' ' + tableColorClass,
                                                                          'background-color':item?.backGroundColor,
                                                                          'justify-content': item?.align,
                                                                      
                                                                          'text-align':item?.text_align
                                                                              
                                                                            
                                                                           }" class="th_width" style="text-align: center;  font-weight: bold;">
                                                                        {{item?.displayedColumns12[2].name}}</th>
                                                                        <td mat-cell *matCellDef="let element" style="text-align: center;"> </td>
                                                                      </ng-container>
                                                                    
                                                                     
                                                                    
                                                                      <tr mat-header-row *matHeaderRowDef="displayedTableColumns"></tr>
                                                                      <tr mat-row *matRowDef="let row; columns: displayedTableColumns;"></tr>
                                                                    </table>
                                                              </div>
                                                          
                                                             
                                                          </div>
                                                                                                                                                                                                                                                                                                                                                
                                                                                                          
                                                            <div *ngIf="item?.name=== 'Organization Logo' || item?.name=== 'Image' || item?.name=== 'Workspace Logo' " class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                                  'font-size':item?.font_size+'px',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                 
                                                                  'color':item?.color,
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'justify-content':item?.align,
                                                                
                                                                  'text-align':item?.align,
                                                                  'width':'100%'
                                                          }">
                                                          <img *ngIf="item?.name == 'Image'" [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                                          [ngStyle]="{'width': item?.width+'px', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}" >
                                                          <img *ngIf="item?.name == 'Organization Logo'"  [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                                          [ngStyle]="{'width': item?.width+'%', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}">
                                                          <img *ngIf="item?.name == 'Workspace Logo'" [matTooltip]="'This Logo Will Dynamically change.'" [src]="item?.imageLogo?item?.imageLogo:staticLogo" alt="logo"
                                                          [ngStyle]="{'width': item?.width+'%', 'height': item.name === 'Image' ? item?.height+'%' : 'auto', 'border-radius': item.name === 'Image' ? '0%' : '50%'}">
                      
                                                          
                                                              </div>                                           
                                                           <div *ngIf="item?.name==='Divider'" class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'font-size':item?.font_size+'px',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                      }">
                                                              <hr style="height: 2px; margin: 0 !important ;" 
                                                                  [ngStyle]="{
                                                                  'width':item?.width+'%',
                                                                  'background-color':item?.color,
                                                                  'opacity':'1.25',
                                                                  'height':item?.height+'px'
                                                              }">
                                                              
                                                          </div>
                                                          
                                                          <div *ngIf="item?.name==='Name' || item?.name === 'First Name' || item?.name === 'Last Name' || item?.name === 'Commercial Name'" class="d-flex" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                             
                                                              'font-style': item?.font_italic
                                                            }">
                                                                  <p readonly class="txt-res" style="margin: 0 !important "   [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.title_font_size + 'px',
                                                                      'color': item?.title_color,
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                     'margin':' 0 !important',
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'text-align':item?.align
                                                                    }">{{ item?.value }}&nbsp;</p>
                                                              <span [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                               
                                                                  'text-align':item?.align,
                                                                  'color': item?.color
                                                                  
                                                                }"  >{{ item?.tag }}</span>
                                                              </div>
                                                              
                                                                  <div *ngIf="item?.name === 'Quote ID'"  class="d-flex txt-res" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'font-size':item?.font_size+'px',
                                                                      'color':item?.color,
                                                                      'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                         
                                                                          'color':item?.color,
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                  }">
                                                                          <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                              'font-size':item?.font_size+'px',
                                                                              'color':item?.color,
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'margin':' 0 !important',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic,
                                                                          'text-align':item?.align,
                                                                          'width':'100%'
                                                                      }">{{ item?.name }}
                                                                      <span style="color:#1b8ef8" [matTooltip]="'This ID Will Dynamically change.'">{{ item?.quoteId }}</span>
                                                                                                                        </p>
                                                                      </div>
                                                                  <div *ngIf="item?.name === 'Space'"  class="d-flex txt-res" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'font-size':item?.font_size+'px',
                                                                      'color':item?.color,
                                                                      'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          
                                                                          'color':item?.color,
                                                                          'min-height': '48px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                          }">
                                                                          <p readonly class="txt-res" [ngStyle]="{
                                                                              'font-size':item?.font_size+'px',
                                                                              'color':item?.color,
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                      
                                                                          'margin':' 0 !important',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic,
                                                                          'text-align':item?.align,
                                                                  'width':'100%'
                                                                      }"> </p>
                                                                      </div>
                                                                  <div *ngIf="item?.name==='Item description'"  class="d-flex" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-size':item?.font_size+'px',
                                                                          'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                         
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                  }">
                                                                          <p readonly class="txt-res" [ngStyle]="{
                                                                             'font-size': item?.title_font_size + 'px',
                                                                             'color': item?.title_color,
                                                                              'margin-bottom':item?.mrbottum+'px',
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic,
                                                                          'margin':' 0 !important',
                                                                          'text-align':item?.align,
                                                                  'width':'100%'
                                                                      }">{{ item?.value }}</p>
                                                                      <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                      
                                                                      </div>
                                                                      <!-- <div *ngIf="item?.name==='Deal Stage'"  class="d-flex" [ngStyle]="{
                                                                          'justify-content':item?.align,
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                              'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'color':item?.color,
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic
                                                                      }">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                  'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                                  'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic
                                                                          }">{{ item?.value }}
                                                                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                                                            </p>
                                                                          </div> -->
                                                                      <div *ngIf="item?.name==='Item price'"  class="d-flex" [ngStyle]="{
                                                                          'justify-content':item?.align,
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                              'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic
                                                                      }">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                 'font-size': item?.title_font_size + 'px',
                                                                                 'color': item?.title_color,
                                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                                  'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic,
                                                                              'margin':' 0 !important',
                                                                              'text-align':item?.align,
                                                                  'width':'100%'
                                                                          }">{{ item?.value }}</p>
                                                                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                           
                                                                          </div>
                                                                      <div *ngIf="item?.name==='Item Unit' || item?.name === 'Item quantity per click'"  class="d-flex" [ngStyle]="{
                                                                          'justify-content':item?.align,
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                              'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic
                                                                      }">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.title_color,
                                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                                  'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic,
                                                                              'text-align':item?.align,
                                                                              'margin':' 0 !important',
                                                                  'width':'100%'
                                                                          }">{{ item?.value }}</p>
                                                                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                           
                                                                          </div>
                                                                      <div *ngIf="item?.name==='Item comments'"  class="d-flex" [ngStyle]="{
                                                                          'justify-content':item?.align,
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                              'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                             
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic
                                                                      }">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                  'font-size':item?.font_size+'px',
                                                                                  'color': item?.title_color,
                                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                                  'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic,
                                                                              'text-align':item?.align,
                                                                              'margin':' 0 !important',
                                                                  'width':'100%'
                                                                          }">{{ item?.value }}
                                                                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                                                            </p>
                                                                          </div>
                                                                  <div *ngIf="item?.name==='Member'"  class="d-flex" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-size':item?.font_size+'px',
                                                                              'color': '#333',
                                                                          'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                        
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                  }">
                                                                          <p readonly class="txt-res" [ngStyle]="{
                                                                             'font-size': item?.title_font_size + 'px',
                                                                             'color': item?.title_color,
                                                                              'margin-bottom':item?.mrbottum+'px',
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic,
                                                                          'text-align':item?.align,
                                                                          'margin':' 0 !important',
                                                                  'width':'100%'
                                                                      }">{{ item?.value }}</p>
                                                                      <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                       
                                                                      </div>
                      
                                                                  <div *ngIf="item?.name==='Email' || item?.name==='Email(s)'" class="d-flex" [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'text-decoration': item?.textDecoration,
                                                                    
                                                                      'font-style': item?.font_italic
                                                                    }">
                                                                          <p readonly  class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                              'font-size': item?.title_font_size + 'px',
                                                                              'color': item?.title_color,
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                           
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic,
                                                                              'margin':' 0 !important',
                                                                              'text-align':item?.align
                                                                            }">{{ item?.value }}&nbsp;</p>
                                                                      <span  [ngStyle]="{
                                                                         
                                                                          'text-align':item?.align,
                                                                          'color': item?.color
                      
                                                                        }" >  {{ item?.tag }}</span>
                                                                      </div>
                                                                 
                                                                      <div *ngIf="item?.name === 'Phone number(s)' || item?.name=== 'Phone Number'" class="d-flex flex-column" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'text-decoration': item?.textDecoration,
                                                                         
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <ng-container *ngIf="item.phone_number && item.phone_number.length > 0; else noPhoneNumbers">
                                                                            <ng-container *ngFor="let phoneNumber of item.phone_number; let i = index">
                                                                              <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.title_color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align,
                                                                                  'margin':' 0 !important',
                                                                                  'width':'100%'
                                                                                }">
                                                                                {{ item?.value }}&nbsp;</p>
                                                                                 <span [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'text-align':item?.align,
                                                                                   'width':'100%'
                                                                                }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.phone_number?.length?item.label + " " + phoneNumber?.name: item?.tag }}</span>
                                                                                
                                                                              
                                                                            </ng-container>
                                                                          </ng-container>
                                                                          <ng-template #noPhoneNumbers>
                                                                              <p class="txt-res" style="margin: 0 !important " *ngIf="item?.value == '' " readonly [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.title_color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align,
                                                                                  'margin':' 0 !important',
                                                                                  'width':'100%'
                                                                                }"> 
                                                                                  {{ item?.value}}&nbsp;</p>
                                                                                  <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                       'text-align':item?.align,
                                                                                       'width':'100%'
                                                                                    }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                  
                                                                              
                                                                          </ng-template>
                                                                        </div>
                                                                        
                                                                        <div *ngIf="item?.name === 'Phone'  || item?.name=== 'Workspace Phone'" class="d-flex flex-column" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'margin':' 0 !important',
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <ng-container *ngIf="item.phone_number && item.phone_number.length > 0; else noPhoneNumbers">
                                                                            <ng-container *ngFor="let phoneNumber of item.phone_number; let i = index">
                                                                              <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'color': item?.title_color,
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                }">
                                                                                {{ item?.value }}</p> <span [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                 
                                                                                  'text-align':item?.align
                                                                                }" style="color: #1b8ef8" >{{phoneNumber?.name }}&nbsp;{{phoneNumber?.title}}</span>
                                                                              
                                                                            </ng-container>
                                                                          </ng-container>
                                                                          <!-- <ng-template #noPhoneNumbers  >
                                                                              <p readonly class="txt-res" *ngIf="item?.value == '' " [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                }">
                                                                                  {{ item?.value}}</p>
                                                                                   <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                    
                                                                                      'text-align':item?.align
                                                                                    }" style="color: #1b8ef8" >{{ item?.tag }}</span>
                                                                                
                                                                          </ng-template> -->
                                                                        </div>
                                                                        
                      
                                                       
                                                                        <div *ngIf="item?.name === 'Address' || item?.name === 'Workspace Address'" class="d-flex flex-column" [ngStyle]="{
                                                                           'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                         
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                      
                                                                          <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                            <ng-container  *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                              <p  style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                                                                                 'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'color': item?.title_color,
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                }">
                                                                                {{ item?.value }} </p>
                                                                                <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                      'margin-top':'0',
                                                                                 
                                                                                 'text-align':item?.align,
                                                                                 'color': item?.color
                      
                                                                                }"  >{{getAddress(phoneNumber)}}
                                                                                </span>
                                                                              
                                                                            </ng-container>
                                                                          </ng-container>
                                                                          <!-- <ng-template #noPhoneNumbers >
                                                                         
                                                                              <p readonly class="txt-res" *ngIf="item?.value == '' " style="margin: 0 !important "  [ngStyle]="{
                                                                                    'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                 
                                                                                }">
                                                                                  {{ item?.value}}</p>
                                                                                   <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                    
                                                                                      'text-align':item?.align
                      
                                                                                    }" style="color: #1b8ef8" >{{ item?.tag }}</span>
                      
                                                                                  
                                                                           
                                                                          </ng-template> -->
                                                                        </div>
                                                                        <!-- <div *ngIf="item?.name === 'Workspace Address'" class="d-flex flex-column" [ngStyle]="{
                                                                           'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                         
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                      
                                                                          <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                            <ng-container *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                 'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'color': item?.color,
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                }">
                                                                                {{ item?.value }}</p>
                                                                                <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                 
                                                                                 'text-align':item?.align
                                                                                }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.address_title?.length?phoneNumber?.name:item?.tag }}</span>
                                                                                
                                                                              
                                                                            </ng-container>
                                                                          </ng-container>
                                                                          <ng-template #noPhoneNumbers >
                                                                         
                                                                              <p readonly class="txt-res" *ngIf="item?.value == '' " style="margin: 0 !important " [ngStyle]="{
                                                                                    'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'margin':' 0 !important',
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                 
                                                                                }">
                                                                                  {{ item?.value}}</p>
                                                                                   <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                    
                                                                                      'text-align':item?.align
                      
                                                                                    }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                      
                                                                                  
                                                                           
                                                                          </ng-template>
                                                                        </div> -->
                                                                        <div *ngIf="item?.name==='Source'" class="d-flex" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'text-decoration': item?.textDecoration,
                                                                         
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                              <p readonly class="txt-res" style="margin: 0 !important "   [ngStyle]="{
                                                                                  'justify-content': item?.align,
                                                                                  'font-size': item?.title_font_size + 'px',
                                                                                  'color': item?.title_color,
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                 'margin':' 0 !important',
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'text-align':item?.align
                                                                                }">{{ item?.value }}&nbsp;</p>
                                                                          <span [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                           
                                                                              'text-align':item?.align,
                                                                              'color': item?.color
                                                                              
                                                                            }"  >{{ item?.tag }}</span>
                                                                          </div>
                                                                                    <div *ngIf="item?.name === 'Full Name'" class="d-flex" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <p class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'font-size': item?.title_font_size + 'px',
                                                                              'color': item?.title_color,
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                              'margin':' 0 !important',
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic,
                                                                            'text-align':item?.align
                                                                              }">
                                                                            {{ item?.value }}&nbsp;</p>
                                                                            <span [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                             
                                                                              'text-align':item?.align,
                                                                              'color': item?.color
                      
                                                                            }">{{ item?.tag }}</span>
                                                                        </div>
                          
                                                                        <div *ngIf="item?.name === 'Language'" class="d-flex" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                          'text-decoration': item?.textDecoration,
                                                                         
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <p class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'font-size': item?.title_font_size + 'px',
                                                                              'color': item?.title_color,
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                              'margin':' 0 !important',
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic,
                                                                              'text-align':item?.align
                                                                            }">
                                                                            {{ item?.value }}&nbsp; </p>
                                                                            <span [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              
                                                                              'text-align':item?.align,
                                                                              'color': item?.color
                      
                                                                            }"  >{{ item?.tag }}</span>
                                                                            
                                                                        </div>
                                                                        <div *ngIf="item?.name === 'Contact custom fields' || item?.name === 'Custom item fields'" class="d-flex" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'padding-bottom': item?.padding + 'px',
                                                                          'padding-top': item?.paddingT + 'px',
                                                                          'padding-right': item?.paddingR + 'px',
                                                                          'padding-left': item?.paddingL + 'px',
                                                                          'color': item?.color,
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <ng-container *ngIf="item?.address_title && item?.address_title?.length > 0; else noPhoneNumbers">
                                                                            <ng-container *ngFor="let phoneNumber of item.address_title; let i = index">
                                                                              <p readonly class="txt-res" [ngStyle]="{
                                                                                'font-size': item?.font_size + 'px',
                                                                                'color': '#333',
                                                                                'font-family': item?.fontFamily,
                                                                                'font-weight': item?.font_Weight,
                                                                                'padding-bottom': item?.padding + 'px',
                                                                                'padding-top': item?.paddingT + 'px',
                                                                                'padding-right': item?.paddingR + 'px',
                                                                                'padding-left': item?.paddingL + 'px',
                                                                                'text-decoration': item?.textDecoration,
                                                                                'font-style': item?.font_italic,
                                                                                'text-align':item?.align,
                                                                                'margin':' 0 !important',
                                                                                'width':'100%'
                                                                              }">
                                                                                {{ phoneNumber?.module_name?phoneNumber?.module_name:item?.value}} <span [ngStyle]="{
                                                                                  'padding-bottom': item?.padding + 'px',
                                                                                  'padding-top': item?.paddingT + 'px',
                                                                                  'padding-right': item?.paddingR + 'px',
                                                                                  'padding-left': item?.paddingL + 'px',
                                                                                  'text-align':item?.align,
                                                                                  'width':'100%'
                                                                                }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.address_title?.length?phoneNumber?.title:item?.tag }}</span>
                                                                                
                                                                              </p>
                                                                            </ng-container>
                                                                          </ng-container>
                                                                          <ng-template #noPhoneNumbers>
                                                                              <p readonly [ngStyle]="{
                                                                                  'font-size': item?.font_size + 'px',
                                                                                  'color': '#333',
                                                                                  'font-family': item?.fontFamily,
                                                                                  'font-weight': item?.font_Weight,
                                                                                  'padding-bottom': item?.padding + 'px',
                                                                                  'padding-top': item?.paddingT + 'px',
                                                                                  'padding-right': item?.paddingR + 'px',
                                                                                  'padding-left': item?.paddingL + 'px',
                                                                                  'text-decoration': item?.textDecoration,
                                                                                  'font-style': item?.font_italic,
                                                                                  'margin':' 0 !important',
                                                                                  'text-align':item?.align,
                                                                                'width':'100%'
                                                                                }">
                                                                                  {{ item?.value }} <span [ngStyle]="{
                                                                                      'justify-content': item?.align,
                                                                                      'padding-bottom': item?.padding + 'px',
                                                                                      'padding-top': item?.paddingT + 'px',
                                                                                      'padding-right': item?.paddingR + 'px',
                                                                                      'padding-left': item?.paddingL + 'px',
                                                                                      'text-align':item?.align,
                                                                                      'width':'100%'
                                                                                    }" style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span>
                                                                                  
                                                                                </p>
                                                                          </ng-template>
                                                                        </div>
                      
                                                                        <div *ngIf="item?.name==='Website' || item?.name === 'Video URL' || item?.name === 'Website URL'" class="d-flex" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'font-weight': item?.font_Weight,
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }">
                                                                          <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'font-size': item?.title_font_size + 'px',
                                                                            'color': item?.title_color,
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'margin':' 0 !important',
                                                                            'text-decoration': item?.textDecoration,
                                                                            'font-style': item?.font_italic,
                                                                            'text-align':item?.align
                                                                          }">{{ item?.value }}&nbsp;</p>
                                                                          <a *ngIf="item?.url" href="{{ item?.url }}" target="_blank" [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.align,
                                                                            'color': item?.color
                                                                          }">{{ item?.url }}</a>
                                                                          <span *ngIf="!item?.url" [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'text-align':item?.align,
                                                                            'color': item?.color
                                                                          }">{{ item?.tag }}</span>
                                                                          
                                                                        </div>
                      
                                                                  <div *ngIf="item?.name==='Social links'" class="d-flex flex-column" [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'padding-bottom': item?.padding + 'px',
                                                                            'padding-top': item?.paddingT + 'px',
                                                                            'padding-right': item?.paddingR + 'px',
                                                                            'padding-left': item?.paddingL + 'px',
                                                                      'font-style': item?.font_italic
                                                                    }">
                                                                    <!-- .. -->
                                                                    <ng-container *ngIf="item.social_link && item.social_link.length > 0; else noPhoneNumbers">
                                                                      <ng-container *ngFor="let phoneNumber of item.social_link; let i = index">
                                                                        <p readonly class="txt-res" [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'font-size': item?.title_font_size + 'px',
                                                                            'color': item?.title_color,
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'margin':' 0 !important',
                                                                            'text-decoration': item?.textDecoration,
                                                                            'font-style': item?.font_italic,
                                                                            'text-align':item?.align
                                                                          }">
                                                                          {{ phoneNumber?.name}}</p>
                                                                           <span [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                            
                                                                              'text-align':item?.align,
                                                                              'color': item?.color
                      
                                                                            }"  >
                                                                              <!-- {{item?.social_link?.length?phoneNumber?.url: item?.tag }} -->
                                                                           <a [attr.href]="item?.social_link?.length?phoneNumber?.url: item?.tag  " target="_blank">{{item?.social_link?.length?phoneNumber?.url: item?.tag }}</a></span>
                                                                           
                                                                        
                                                                      </ng-container>
                                                                    </ng-container>
                                                                    <ng-template #noPhoneNumbers>
                                                                        <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                                                                            'justify-content': item?.align,
                                                                            'font-size': item?.title_font_size + 'px',
                                                                            'color': item?.title_color,
                                                                            'font-family': item?.fontFamily,
                                                                            'font-weight': item?.font_Weight,
                                                                            'margin':' 0 !important',
                                                                            'text-decoration': item?.textDecoration,
                                                                            'font-style': item?.font_italic,
                                                                            'text-align':item?.align
                                                                          }"> 
                                                                            {{ item?.value }}</p>
                                                                            <span [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                            
                                                                              'text-align':item?.align,
                                                                              'color': item?.color
                      
                                                                            }"  >{{ item?.tag }}</span>
                                                                            
                                                                        
                                                                    </ng-template>
                                                                    <!-- .. -->
                                                                          <!-- <p readonly class="txt-res"  [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'margin-bottom': item?.mrbottum + 'px',
                                                                              'font-size': item?.title_font_size + 'px',
                      
                                                                              'color': item?.color,
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                              'padding-bottom': item?.padding + 'px',
                                                                              'padding-top': item?.paddingT + 'px',
                                                                              'padding-right': item?.paddingR + 'px',
                                                                              'padding-left': item?.paddingL + 'px',
                                                                            
                                                                              'margin-top': item?.mrtop + 'px',
                                                                              'margin-right': item?.mrright + 'px',
                                                                              'margin-left': item?.mrleft + 'px',
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic
                                                                            }">{{ item?.value }}</p> -->
                                                                      <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item?.tag }}</span> -->
                                                                      
                                                                      </div>
                      
                      
                      
                      
                                                                      <!-- .. -->
                                                              <!-- <div *ngIf="item?.name==='Organization N.'" class="d-flex flex-column"
                                                              [ngStyle]="{
                                                              'align-items':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px'
                                                      }">
                                                              <label for="business" [ngStyle]="{
                                                              'font-size':bodying_Size+'px',
                                                              'font-weight':'500',
                                                              'font-family':selectedFont
                                                          }">{{item?.label}} <small *ngIf="!item?.required" [ngStyle]="{
                                                              'color': '#92A0A8',
                                                              'font-size': bodying_Size+'px',
                                                              'font-weight': '500',
                                                              'font-family':selectedFont}">(Optional)</small></label>
                                                              <input type="url" class="form-control" id="website" name="website"
                                                              <input type="text" class="form-control" id="business" name="business"
                                                                  [placeholder]="item?.placeholder" [ngStyle]="{
                                                                  'font-size': bodying_Size+'px', 
                                                                  'width': '80%',
                                                                  'font-family':selectedFont }">
                                                          </div>
                                                        
                                                          </div> -->
                                                          <!-- <div *ngIf="item?.name==='Organization N.'" class="d-flex flex-column"
                                                              [ngStyle]="{
                                                              'align-items':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px'
                                                      }">
                                                              <label for="business" [ngStyle]="{
                                                              'font-size':bodying_Size+'px',
                                                              'font-weight':'500',
                                                              'font-family':selectedFont
                                                          }">{{item?.label}} <small *ngIf="!item?.required" [ngStyle]="{
                                                              'color': '#92A0A8',
                                                              'font-size': bodying_Size+'px',
                                                              'font-weight': '500',
                                                              'font-family':selectedFont}">(Optional)</small></label>
                                                              <input type="text" [value]="item?.tag" readonly class="form-control" id="business" name="business"
                                                                  [placeholder]="item?.placeholder" [ngStyle]="{
                                                                  'font-size': bodying_Size+'px', 
                                                                  'width': '80%',
                                                                  'font-family':selectedFont }">
                                                          </div> -->
                                       
                                                          <div *ngIf="item?.field_type==='Input'" class="flex flex-column textbox"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                          <label class="fw5" for="input"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic,
                                                              'text-align':item?.align,
                                                              'margin':' 0 !important'
                                                            }">{{item?.label}}
                                                              <small class="lbops" *ngIf="!item?.required"
                                                                  [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                              <small *ngIf="item?.required">*</small>
                                                          </label>
                                                          <input [type]="item?.required ? (item?.datatype || 'text') : 'text'"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                          
                                                          }" class="form-control w-1000" id="{{item?.id}}" name="input"
                                                              [placeholder]="item?.placeholder">
                                                          <p class="m-0 font-normal"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.help_font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                              'text-decoration': item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style': item?.font_italic
                                                            }">
                                                              {{item?.help_txt}}</p>
                                                      </div>
                                                      <div *ngIf="item?.field_type==='Date'"  class="flex flex-column textbox"
                                                      [ngStyle]="{
                                                          'justify-content': item?.align
                                                         
                                                        }">
                                                          <label class="fw5" for="date"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic,
                                                              'margin':' 0 !important',
                                                              'text-align':item?.align,
                                                                  'width':'100%'
                                                            }">{{item?.dateTitle == 'Custom'?item?.value:item?.label}}
                                                              <small class="lbops" *ngIf="!item?.required"
                                                                  [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                              <small *ngIf="item?.required">*</small>
                                                          </label>
                      
                                                          <div  class="d-flex gap-2 pe-2 py-1 pointer align-items-center ms-1" style="border: 1px solid #CBDBE4;border-radius: 5px;" (click)="openDatePicker()" (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)">
                                                              <input matInput (ngModelChange)="onDateChange($event,item?.name,item?.id,z,item?.dateTitle)" style="width: 0px; border: none;"  id="{{item?.id}}"  [ngModel]="dateValue"
                                                              />
                                                              <div style="min-width:70px;"  id="{{item?.id}}">{{item?.placeholder? item?.placeholder: ''}}</div>
                                                              <!-- <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                                                              <mat-datepicker #picker ></mat-datepicker> -->
                                                          </div>
                      
                                                          <!-- <input [type]="item?.required ? (item?.datatype || 'date') : 'date'"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                          
                                                          }" class="form-control w-80" id="{{item?.id}}" name="date"
                                                              [placeholder]="item?.placeholder"> -->
                                                          <p class="m-0 font-normal"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.help_font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                              'text-decoration': item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style': item?.font_italic
                                                            }">
                                                              {{item?.help_txt}}</p>
                                                      </div>
                                                      <!-- <div *ngIf="item?.field_type==='Color' || item?.name =='Color'" class="flex flex-column textbox"
                                                      [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic
                                                        }">
                                                          <label class="fw5" for="color"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style': item?.font_italic
                                                            }">{{item?.label}}
                                                              <small *ngIf="item?.required">*</small>
                                                          </label>
                                                          <input [type]="item?.required ? (item?.datatype || 'color') : 'color'"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                          
                                                          }"  class="form-control w-80" id="{{item?.id}}" name="color"
                                                              [placeholder]="item?.placeholder">
                                                              <p *ngIf="item?.name !=='Color'" class="m-0 font-normal"
                                                              [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.help_font_size + 'px',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'margin':' 0 !important',
                                                                  'font-style': item?.font_italic
                                                                }">
                                                                  {{item?.help_txt}}</p>
                                                      </div> -->
                                                      <div *ngIf="item?.field_type==='Dropdown'" class="flex flex-column"
                                                      [ngStyle]="{
                                                          'justify-content': item?.align,
                                                          'font-size': item?.font_size + 'px',
                                                          'color': '#333',
                                                          'font-family': item?.fontFamily,
                                                          'font-weight': item?.font_Weight,
                                                          'color': item?.color,
                                                          'text-decoration': item?.textDecoration,
                                                          'font-style': item?.font_italic
                                                        }">
                                                          <label class="fw5" for="dropdown"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style': item?.font_italic
                                                            }">{{item?.label}}
                                                              <small class="lbops" *ngIf="!item?.required"
                                                                  [ngStyle]="{'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                              <small *ngIf="item?.required">*</small>
                                                          </label>
                                                          <select class="form-select w-80" id="{{item?.id}}"
                                                          [ngStyle]="{
                                                              'border-color':item?.Bdr_Dvdr
                                                          
                                                          }" >
                                                              <option [value]="coun?.name"
                                                                  *ngFor="let coun of item?.custom_value?.type?item?.value:item?.custom_value?.value;"
                                                                  [selected]="coun?.selected">{{coun?.name}}</option>
                                                          </select>
                                                          <p class="m-0 font-normal"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.help_font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                              'text-decoration': item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style': item?.font_italic
                                                            }">
                                                              {{item?.help_txt}}</p>
                                                      </div>
                                                      <div *ngIf="item?.field_type==='Checkbox'"
                                                          class="flex flex-column checkboxradio"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px'
                                                            }">
                                                          <p class="m-0 fw5"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              {{item?.label}} <small class="lbops" *ngIf="!item?.required"
                                                                  [ngStyle]="{'font-size': bodying_Size+'px',
                                                                  'font-family':selectedFont
                                                                  
                      
                                                                  }">(Optional)</small>
                                                              <small *ngIf="item?.required">*</small>
                                                          </p>
                                                          <div class="flex flex-wrap checkboxradio-container">
                                                              <div class="checkboxradio-field"
                                                                  *ngFor="let check of item?.custom_value?.type?item?.value:item?.custom_value?.value;">
                                                                  <input type="checkbox" [checked]="check?.selected"
                                                                      id="{{check?.name}}" name="{{check?.name}}"
                                                                      value="{{check?.name}}"
                                                                      [ngStyle]="{
                                                                          'border-color':item?.Bdr_Dvdr
                                                                         
                                                                      
                                                                      
                                                                      }" >
                                                                  <label class="fw5" style="margin: 0px 7px 0px 6px;" for="{{check?.name}}"
                                                                  [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic
                                                                     
                                                                    }">
                                                                     {{check?.name}} </label>
                                                              </div>
                                                          </div>
                                                          <p class="m-0 font-normal"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'margin-bottom': item?.mrbottum + 'px',
                                                              'font-size': item?.help_font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                              'margin-top': item?.mrtop + 'px',
                                                              'margin-right': item?.mrright + 'px',
                                                              'margin-left': item?.mrleft + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                              
                                                            }">
                                                              {{item?.help_txt}}</p>
                                                      </div>
                                                      <div *ngIf="item?.field_type==='Radio'"
                                                          class="flex flex-column checkboxradio"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic,
                                                              'text-align':item?.align,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'width':'100%'
                                                            }">
                                                          <p class="m-0 fw5"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic,
                                                              'text-align':item?.align,
                                                              'width':'100%'
                      
                                                              
                                                            }">
                                                              {{item?.label}} <small class="lbops" *ngIf="!item?.required"
                                                                  [ngStyle]="{ 'font-size': bodying_Size+'px','font-family':selectedFont}">(Optional)</small>
                                                              <small *ngIf="item?.required">*</small>
                                                          </p>
                                                          <div class="flex flex-wrap checkboxradio-container">
                                                              <div class="checkboxradio-field"
                                                                  *ngFor="let radio of item?.custom_value?.type?item?.value:item?.custom_value?.value;">
                                                                  <input type="radio" [required]="item?.required"
                                                                      [checked]="radio?.selected" id="{{radio?.name}}"
                                                                      name="radio_button" value="{{radio?.name}}"
                                                                      [ngStyle]="{
                                                                          'border-color':item?.Bdr_Dvdr
                                                                      
                                                                      }" >
                                                                  <label style="margin: 0px 7px 0px 6px;" class="fw5" for="{{radio?.name}}"[ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-size': item?.font_size + 'px',
                                                                      'color': '#333',
                                                                      'font-family': item?.fontFamily,
                                                                      'font-weight': item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic
                                                                    }">
                                                                      {{radio?.name}} </label>
                                                              </div>
                                                          </div>
                                                          <p class="m-0 font-normal"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.help_font_size + 'px',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'margin':' 0 !important',
                                                              'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              {{item?.help_txt}}</p>
                                                      </div>
                      
                                                          <div *ngIf="item?.name==='Input Field'" class="d-flex flex-column" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                              'font-style': item?.font_italic
                                                              
                                                            }">
                                                              <label for="company" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align,
                                                                   'width':'100%'
                                                                  
                                                                 
                                                                }">{{item?.label}} </label>
                                                                <textarea rows="1" style="margin: 0 !important " class="form-control" id="company" name="company"
                                                                [placeholder]="item?.placeholder" [ngStyle]="{
                                                                    'justify-content': item?.align,
                                                                    'font-family': item?.fontFamily,
                                                                    'margin':' 0 !important',
                                                                    'color': item?.color,
                                                                    'text-decoration': item?.textDecoration,
                                                                    'font-style': item?.font_italic,
                                                                    'border-color':item?.Bdr_Dvdr,
                                                                    'text-align':item?.align
                                                                 
                                                                   
                                                                  }"></textarea> 
                                                              <!-- <input type="text" class="form-control" id="company" name="company"
                                                                  [placeholder]="item?.placeholder" [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'font-family': item?.fontFamily,
                                                                      'margin':' 0 !important',
                                                                      'color': item?.color,
                                                                      'text-decoration': item?.textDecoration,
                                                                      'font-style': item?.font_italic,
                                                                      'border-color':item?.Bdr_Dvdr,
                                                                      'text-align':item?.align
                                                                   
                                                                     
                                                                    }"> -->
                                                                  
                                                          </div>
                                                          <div *ngIf="item?.name==='Industry'" class="d-flex flex-column" [ngStyle]="{
                                                          'align-items':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px'
                                                      }">
                                                              <label class="lebel_res" for="industry" [ngStyle]="{
                                                              'font-size':bodying_Size+'px',
                                                              'font-weight':'500',
                                                              'font-family':selectedFont }">{{item?.label}} <small
                                                                      *ngIf="!item?.required" [ngStyle]="{
                                                              'color': '#92A0A8',
                                                              'font-size': bodying_Size+'px',
                                                              'font-weight': '500',
                                                              'font-family':selectedFont }">(Optional)</small></label>
                                                              <input  type="text" class="form-control lebel_res" id="industry" name="industry"
                                                                  [placeholder]="item?.placeholder" [ngStyle]="{
                                                                  'font-size': bodying_Size+'px', 
                                                                  'width': '80%',
                                                                  'font-family':selectedFont }">
                                                                  
                                                          </div>
                                                          <div *ngIf="item?.name==='Textbox'" class="d-flex flex-column" [ngStyle]="{
                                                          'align-items':item?.align
                                                         
                                                           }">
                                                              <label for="textbox" style="margin: 0 !important "  class="lebel_res" [ngStyle]="{
                                                                  'color': '#92A0A8',
                                                                  'font-size': bodying_Size+'px',
                                                                  'font-weight': '500',
                                                                  'width': '80%',
                                                                  'font-family':selectedFont || 'Arial, sans-serif',
                                                                  'justify-content':item?.align,
                                                                  'font-size':item?.font_size+'px',
                                                                  'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color':item?.color,
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic,
                                                                      'text-align':item?.align,
                                                                  'width':'100%'
                                                              
                                                              }">{{item?.label}} </label>
                                                              <input type="text" style="margin: 0 !important "  class="form-control txt-res" id="textbox"
                                                                  name="textbox" [placeholder]="item?.placeholder" [ngStyle]="{
                                                                      'border-color':item?.Bdr_Dvdr,
                                                                      'justify-content': item?.align,
                                                                      'text-align':item?.align,
                                                                      'width':'100%'
                                                                  
                                                                  }">
                                                                  
                                                              <p class="m-0 label_res2" style="margin: 0 !important "   [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.help_font_size + 'px',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'color': item?.H_Txt_Notes?item?.H_Txt_Notes:'#000000',
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'width':'100%'
                                                                }">{{item?.help_txt}}</p>
                                                          </div>
                                                          <div *ngIf="item?.name==='Button'" class="d-flex" [ngStyle]="{
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px'
                                                      }">
                                                              <button class="txt-res" type="submit" [ngStyle]="{
                                                          'width':item?.width+'%',
                                                          'background': item?.backgroundcolor,
                                                          'height':item?.height+'px',
                                                          'border': 'none',
                                                          'border-radius': '5px',
                                                          'font-weight': '600',
                                                          'font-size': bodying_Size+'px',
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                              'color': '#333',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'color':item?.color,
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'font-style':item?.font_italic,
                                                              'text-align':item?.align,
                                                                  'width':'100%'
                                                      }">{{item?.value}}</button>
                                                      
                                                          </div>
                                                          <div *ngIf="item?.name==='Selectbox'" class="d-flex flex-column" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'padding-bottom': item?.padding + 'px',
                                                              'padding-top': item?.paddingT + 'px',
                                                              'padding-right': item?.paddingR + 'px',
                                                              'padding-left': item?.paddingL + 'px',
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <label for="selectbox" class="lebel_res" [ngStyle]="{
                                                                'justify-content': item?.align,
                                                                'font-size': item?.font_size + 'px',
                                                                'color': '#333',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'margin':' 0 !important',
                                                                'color': item?.color,
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align':item?.align
                                                              }">{{labelName?labelName:item?.label}}</label>
                                                              <select class="form-select txt-res" name="selectbox" id="selectbox"
                                                                      [ngStyle]="{
                                                                        'border-color':item?.Bdr_Dvdr,
                                                                        'text-align':item?.align
                                                                      }">
                                                                <option value="" class="txt-res">Select an option</option> <!-- Empty option -->
                                                                <option [value]="coun?.name" class="txt-res"
                                                                        *ngFor="let coun of item?.custom_value?.value?.length ? item?.custom_value?.value : item?.value;"
                                                                        [selected]="coun?.selected">{{coun?.name}}</option>
                                                              </select>
                                                              
                                                            </div>
                      
                                                          <div *ngIf="item?.name==='Text Area'" class="d-flex flex-column" [ngStyle]="{
                                                          'align-items':item?.align
                                                          
                      
                                                        }">
                                                              <label  for="textarea" style="margin: 0 !important "  class="lebel_res" [ngStyle]="{
                                                                  'color': '#92A0A8',
                                                                  'font-size': bodying_Size+'px',
                                                                  'font-weight': '500',
                                                                  'width': '80%',
                                                                  'font-family':selectedFont || 'Arial, sans-serif',
                                                                  'justify-content':item?.align,
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'color':item?.color,
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic,
                                                                      'text-align':item?.align,
                                                                  'width':'100%'
                                                              
                                                              }">{{item?.label}} 
                                                      </label>
                      
                                                           <textarea rows="3" style="margin: 0 !important "  class="form-control txt-res" [ngStyle]="{
                                                             'width':'100%',
                                                              'justify-content':item?.align,
                                                              'font-family':item?.fontFamily,
                                                              'color':item?.color,
                                                              'font-style':item?.font_italic,
                                                              'font-weight': item?.font_Weight,
                                                              'text-align':item?.align,
                                                              'border-color': item?.Bdr_Dvdr,
                                                              'width':'100%',
                                                              'background-color':item?.backGroundColor
                                         
                                                         }" id="textbox" name="textbox"
                                                                  [placeholder]="item?.placeholder" [(ngModel)]="item.textareaValue"
                                                                  (change)="saveTextarea(item?.name, item?.id, item?.textareaValue)" ></textarea> 
                                                          </div>
                                                          <div
                                      *ngIf="item?.name == 'Clauses'"
                                      class="d-flex flex-column"
                                      [ngStyle]="{
                                        'justify-content': item?.align,
                                        'font-size': item?.font_size + 'px',
                                        color: '#333',
                                        'font-family': item?.fontFamily,
                                        'font-weight': item?.font_Weight,
          
                                        color: item?.color,
                                        'text-decoration': item?.textDecoration,
                                        'font-style': item?.font_italic
                                      }"
                                    >
                                      <p
                                        class="txt-res"
                                        style="margin: 0 !important"
                                        readonly
                                        [ngStyle]="{
                                          'font-size': item?.font_size + 'px',
                                          'font-family': item?.fontFamily,
                                          'font-weight': 'bolder',
                                          margin: ' 0 !important',
                                          'text-decoration': item?.textDecoration,
                                          'font-style': item?.font_italic,
                                          'text-align': item?.align,
                                          width: '100%',
                                          ' list-style-position': 'inside'
                                        }"
                                      >
                                        {{
                                          getTranslatedText(
                                            "QUOTE_TEMPLATE." + item.label
                                          ) || item.label
                                        }}
                                        <textarea
                                          rows="1"
                                          style="margin: 0 !important"
                                          class="form-control"
                                          id="company"
                                          name="company"
                                          [placeholder]="item?.placeholder"
                                          [ngStyle]="{
                                            'justify-content': item?.align,
                                            'font-family': item?.fontFamily,
                                            margin: ' 0 !important',
                                            color: item?.color,
                                            'text-decoration': item?.textDecoration,
                                            'font-style': item?.font_italic,
                                            'border-color': item?.Bdr_Dvdr,
                                            'text-align': item?.align,
                                            height: item?.autoHeight
                                              ? item?.autoHeight
                                              : 'auto'
                                          }"
                                          [(ngModel)]="item.textareaValue"
                                          (change)="
                                            saveClauses(
                                              item?.name,
                                              item?.id,
                                              item?.textareaValue
                                            )
                                          "
                                          (input)="
                                            autoResize(
                                              $event.target,
                                              item?.name,
                                              item?.id
                                            )
                                          "
                                        ></textarea>
                                      </p>
                                      <p
                                        class="m-0 label_res2"
                                        style="margin: 0 !important"
                                        [ngStyle]="{
                                          'justify-content': item?.align,
                                          'font-size': item?.help_font_size + 'px',
                                          'font-family': item?.fontFamily,
                                          'font-weight': item?.font_Weight,
                                          margin: ' 0 !important',
                                          color: item?.H_Txt_Notes
                                            ? item?.H_Txt_Notes
                                            : '#000000',
                                          'text-decoration': item?.textDecoration,
                                          'font-style': item?.font_italic,
                                          'text-align': item?.align,
                                          width: '100%'
                                        }"
                                      >
                                      {{
                                        getTranslatedText(
                                          "QUOTE_TEMPLATE." + item.help_txt
                                        ) || item.help_txt
                                      }}
                                     
                                      </p>
                                      <br />
                                      <!-- <div *ngIf="item?.custom_value?.value?.length">
                                                              <span  [value]="coun?.name"
                                                              *ngFor="let coun of item?.custom_value?.value"
                                                              [selected]="coun?.selected">{{coun?.name}}</span>
                                                          </div> -->
                                    </div>
                                                          <div *ngIf="item?.name==='Time'" class="d-flex flex-column " id="selectTime" [ngStyle]="{
                                                            'align-items': item?.align
                                                        }">
                                                            <label for="timepicker" style="margin: 0 !important" class="lebel_res" [ngStyle]="{
                                                                'color': '#92A0A8',
                                                                'font-size': bodying_Size+'px',
                                                                'font-weight': '500',
                                                                'width': '80%',
                                                                'font-family': selectedFont || 'Arial, sans-serif',
                                                                'justify-content': item?.align,
                                                                'font-size': item?.font_size+'px',
                                                                'color': '#333',
                                                                'font-family': item?.fontFamily,
                                                                'font-weight': item?.font_Weight,
                                                                'margin': '0 !important',
                                                                'color': item?.color,
                                                                'text-decoration': item?.textDecoration,
                                                                'font-style': item?.font_italic,
                                                                'text-align': item?.align,
                                                                'width': '100% !important'
                                                            }">{{ getTranslatedText("QUOTE_TEMPLATE." +item?.label)|| item?.label }}</label>
                                                            <mat-form-field appearance="outline" [ngStyle]="{
                                                              'border-color': item?.Bdr_Dvdr,
                                                              'border': '1px solid ' + item?.Bdr_Dvdr,
                                                              'border-radius': '5px'
                                                            }">
                                                                <input
                                                                  type="text"
                                                                  matInput
                                                                  [ngxMatTimepicker]="timepicker"
                                                                  [format]="24"
                                                                  [required]="required"
                                                                  readonly
                                                                  [(ngModel)]="item.value"
                                                                />
                                                              
                                                                <mat-icon
                                                                  matSuffix
                                                                  >schedule</mat-icon
                                                                >
                                                              </mat-form-field>
                                                              
                                                              <ngx-mat-timepicker
                                                              id="time-picker"
                                                                #timepicker
                                                                [enableKeyboardInput]="true"
                                                            ></ngx-mat-timepicker>
                                                            <span *ngIf="item?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                                                        </div> 
                                                          <div *ngIf="item?.name==='Checkbox'" class="d-flex flex-column" [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color': item?.color,
                                                              'text-decoration': item?.textDecoration,
                                                              'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="m-0 lebel_res" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'width':'100%'
                                                                }">{{item?.label}} </p>
                                                              <div class="d-flex flex-wrap" style="gap: 14px;">
                                                                  <div style="
                                                              display: flex;
                                                              align-items: center;
                                                              " *ngFor="let check of item?.custom_value?.value?.length?item?.custom_value?.value:item?.value;">
                                                                      <input type="checkbox" [checked]="check?.selected"
                                                                          id="{{check?.name}}" name="{{check?.name}}"
                                                                          value="{{check?.name}}" [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'font-size': item?.font_size + 'px',
                                                                              'font-family': item?.fontFamily,
                                                                            
                                                                              'color': item?.color,
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic,
                                                                              'border-color':item?.Bdr_Dvdr
                                                                            }">
                                                                      <label class="lebel_res" for="{{check?.name}}" style="margin: 0px 7px 0px 6px;" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'margin-left':'10px',
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'margin':' 0 !important',
                                                                          'color': item?.color,
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }"> {{check?.name}} </label>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div *ngIf="item?.name==='Radio button'" class="d-flex flex-column"
                                                          [ngStyle]="{
                                                              'justify-content': item?.align,
                                                              'font-size': item?.font_size + 'px',
                                                              'color': '#333',
                                                              'font-family': item?.fontFamily,
                                                              'font-weight': item?.font_Weight,
                                                              'color': item?.color,
                                                              'padding-bottom': item?.padding + 'px',
                                                                  'padding-top': item?.paddingT + 'px',
                                                                  'padding-right': item?.paddingR + 'px',
                                                                  'padding-left': item?.paddingL + 'px',
                                                              'text-decoration': item?.textDecoration,
                                                              'font-style': item?.font_italic
                                                            }">
                                                              <p class="m-0 lebel_res" [ngStyle]="{
                                                                  'justify-content': item?.align,
                                                                  'font-size': item?.font_size + 'px',
                                                                  'color': '#333',
                                                                  'font-family': item?.fontFamily,
                                                                  'font-weight': item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'color': item?.color,
                                                                  'text-decoration': item?.textDecoration,
                                                                  'font-style': item?.font_italic,
                                                                  'text-align':item?.align,
                                                                  'width':'100%'
                                                                }">{{item?.label}} </p>
                                                              <div class="d-flex flex-wrap" style="gap: 14px;">
                                                                  <div style="
                                                              display: flex;
                                                              align-items: center;
                                                              " *ngFor="let radio of item?.custom_value?.value?.length?item?.custom_value?.value:item?.value;">
                                                                      <input type="radio" [checked]="radio?.selected"
                                                                          id="{{radio?.name}}" name="radio_button"
                                                                          value="{{radio?.name}}" [ngStyle]="{
                                                                              'justify-content': item?.align,
                                                                              'font-size': item?.font_size + 'px',
                                                                              'color': '#333',
                                                                              'font-family': item?.fontFamily,
                                                                              'font-weight': item?.font_Weight,
                                                                             
                                                                              'color': item?.color,
                                                                              'text-decoration': item?.textDecoration,
                                                                              'font-style': item?.font_italic,
                                                                              'border-color':item?.Bdr_Dvdr
                                                                            }">
                                                                      <label class="lebel_res" for="{{radio?.name}}" style="margin: 0px 7px 0px 6px;" [ngStyle]="{
                                                                          'justify-content': item?.align,
                                                                          'font-size': item?.font_size + 'px',
                                                                          'color': '#333',
                                                                          'font-family': item?.fontFamily,
                                                                          'margin':' 0 !important',
                                                                          'color': item?.color,
                                                                          'text-decoration': item?.textDecoration,
                                                                          'font-style': item?.font_italic
                                                                        }"> {{radio?.name}} </label>
                                                                  </div>
                                                              </div>
                                                          </div>
                      
                      
                      
                                                          <div *ngIf="item?.quote_currency =='Quote Currency'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'margin-bottom':item?.mrbottum+'px',
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'color':item?.color,
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly  [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'margin-top':item?.mrtop+'px',
                                                                  'margin-right':item?.mrright+'px',
                                                                  'margin-left':item?.mrleft+'px',
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">{{item?.quote_currency}}
                                                              <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span>
                                                                                                            </p>
                                                              </div>
                      
                      
                      
                      
                      
                                                          <div *ngIf="item?.sub_total =='Subtotal' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                 
                                                                  'padding-top':item?.paddingT+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                 
                                                                  'color':item?.color,
                                                                 
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly  [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                               
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">{{item?.sub_total}}
                                                              <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.subTotalAmount?item?.subTotalAmount:item?.value }}</span>
                                                                                                            </p>
                                                              </div>
                      
                      
                      
                      
                                                           <div *ngIf="item?.discount =='Discount' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                 
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'color':item?.color,
                                                                  
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'margin':' 0 !important',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                
                                                                 
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">{{item?.discount}}
                                                              <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.discountAmount?item?.discountAmount: item?.value }}</span>
                                                                                                            </p>
                                                              </div>
                                                              <div *ngIf="item?.name =='Discount' && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'color':item?.color,
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                              }">
                                                                      <p readonly class="txt-res" [ngStyle]="{
                                                                          'font-size':item?.font_size+'px',
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'margin':' 0 !important',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                                  }">{{item?.discount}}
                                                                  <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
                                                                                                                </p>
                                                                  </div>
                      
                                                                  <div *ngIf="item?.additional_cost =='Additional Cost'" class="d-flex" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-size':item?.font_size+'px',
                                                                              'color': '#333',
                                                                          'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'color':item?.color,
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic,
                                                                          'margin-bottom':'20px',
                                                                          'margin':' 0 !important',
                                                                          'margin-top':'20px'
                                                                  }">
                                                                          <p readonly  [ngStyle]="{
                                                                              'color':'#1b8ef8',
                                                                              'justify-content':'center',
                                                                              'font-size':item?.font_size+'px',
                                                                              'margin-bottom':item?.mrbottum+'px',
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':'bolder',
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                      }">{{ item?.additional_cost}}
                                                                      <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                                          <div *ngFor="let coun of item?.custom_value?.value" style="text-align: center;">
                                                                            <span style="font-weight: normal;color: black;" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                                                          </span>
                                                                          <span style="color:#1b8ef8;font-weight: normal;" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
                      
                                                                          </div>
                                                                        </ng-container>
                                                                      
                                                           </p>
                                                           <br>
                                                                      </div>
                      
                      
                                                                      <div *ngIf="item?.additional_fee =='Additional Fee'" class="d-flex" [ngStyle]="{
                                                                          'justify-content':item?.align,
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-size':item?.font_size+'px',
                                                                                  'color': '#333',
                                                                              'font-family':item?.fontFamily,
                                                                              'font-weight':item?.font_Weight,
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'color':item?.color,
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'font-style':item?.font_italic,
                                                                              'margin-bottom':'20px',
                                                                              'margin-top':'20px'
                                                                      }">
                                                                              <p readonly  [ngStyle]="{
                                                                                  'justify-content':'center',
                                                                                  'font-size':item?.font_size+'px',
                                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                                  'font-family':item?.fontFamily,
                                                                              'font-weight':'bolder',
                                                                              'padding-bottom':item?.padding+'px',
                                                                              'padding-top':item?.paddingT+'px',
                                                                              'padding-right':item?.paddingR+'px',
                                                                              'padding-left':item?.paddingL+'px',
                                                                              'margin-top':item?.mrtop+'px',
                                                                              'margin-right':item?.mrright+'px',
                                                                              'margin-left':item?.mrleft+'px',
                                                                              'text-decoration':item?.textDecoration,
                                                                              'margin':' 0 !important',
                                                                              'font-style':item?.font_italic
                                                                          }">{{ item?.additional_fee}}
                                                                          <ng-container *ngIf="item?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                                                              <div *ngFor="let coun of item?.custom_value?.value" style="text-align: center;">
                                                                                <span style="font-weight: normal" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                                                              </span>
                                                                              <span style="color:#1b8ef8;font-weight: normal;" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ coun?.name?'0':'' }}</span>
                      
                                                                              </div>
                                                                            </ng-container>
                                                                          
                                                               </p>
                                                               <br>
                                                                          </div>
                      
                      
                                                           
                      
                      
                      
                                                           <div *ngIf="item?.taxes =='Taxes'  && item?.name !=='Item Table'" class="d-flex" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                            
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px',
                                                                  'color':item?.color,
                                                                  
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                          }">
                                                                  <p readonly class="txt-res" [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                     
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">{{item?.taxes}}
                                                              <span style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{item?.taxAmount?item?.taxAmount:item?.value }}</span>
                                                                                                            </p>
                                                              </div>
                      
                                                              <div *ngIf="item?.name =='Tax Exempt indicator' " class="d-flex" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'color':item?.color,
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                              }">
                                                                      <p readonly class="txt-res" [ngStyle]="{
                                                                          'font-size':item?.font_size+'px',
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'margin':' 0 !important',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                                  }">{{item?.taxes}}
                                                                  <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.value }}</span>
                                                                                                                </p>
                                                                  </div>
                      
                                                           <div *ngIf=" item?.name =='Total'" class="d-flex txt-res" [ngStyle]="{
                                                              'justify-content':item?.align,
                                                              'font-size':item?.font_size+'px',
                                                                      'color': '#333',
                                                                  'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'color':item?.color,
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic,
                                                                  'padding-bottom':item?.padding+'px',
                                                                  'padding-right':item?.paddingR+'px',
                                                                  'padding-left':item?.paddingL+'px'
                                                                  
                                                          }">
                                                                  <p readonly class="txt-res" style="margin: 0 !important " [ngStyle]="{
                                                                      'font-size':item?.font_size+'px',
                                                                      'font-family':item?.fontFamily,
                                                                  'font-weight':item?.font_Weight,
                                                                  'margin':' 0 !important',
                                                                  'text-decoration':item?.textDecoration,
                                                                  'font-style':item?.font_italic
                                                              }">
                                                              <span [ngStyle]="{
                                                                  'justify-content': item?.align
                                                                  
                                                                }" *ngIf="item?.total =='Total'">{{item?.total}}&nbsp;</span>
                                                              <!-- <span [ngStyle]="{
                                                                  'justify-content': item?.align
                                                                  
                                                                }" *ngIf="item.name == 'Total' && (item?.total !=='Total' && item?.discount !=='Discount' && item?.due_now !=='Due Now' && item?.taxes !=='Taxes' && item?.additional_cost !=='Add Additional Cost' && item?.sub_total !=='Subtotal' && item?.quote_currency !=='Quote Currency')">please select values into properties</span> -->
                                                              <span *ngIf="item.name == 'Total' && (item?.total =='Total')" [ngStyle]="{
                                                                  'justify-content': item?.align
                                                                 
                                                                }" style="color:#1b8ef8" >{{ item.user_currency ? item.currency_symbol : crSymbol }} {{ item?.totalAmount?item?.totalAmount:item?.value }}</span>
                                                                                                            </p>
                                                              </div>
                      
                      
                      
                                                                  
                      
                      
                      <!-- 
                                                              <div *ngIf="due_now =='Due Now'" class="d-flex" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'margin-bottom':item?.mrbottum+'px',
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'color':item?.color,
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                              }">
                                                                      <p readonly  [ngStyle]="{
                                                                          'font-size':item?.font_size+'px',
                                                                          'margin-bottom':item?.mrbottum+'px',
                                                                          'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'padding-bottom':item?.padding+'px',
                                                                      'padding-top':item?.paddingT+'px',
                                                                      'padding-right':item?.paddingR+'px',
                                                                      'padding-left':item?.paddingL+'px',
                                                                      'margin-top':item?.mrtop+'px',
                                                                      'margin-right':item?.mrright+'px',
                                                                      'margin-left':item?.mrleft+'px',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                                  }">{{due_now}}
                                                                  <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{item?.value}}</span>
                                                                   <span *ngIf="!item?.tag">{{ item?.tag }}</span> -->
                                                                <!-- </p>
                                                                  </div> -->
                      
                      
                      
                                                              <div *ngIf="item?.name =='Due Now'" class="d-flex" [ngStyle]="{
                                                                  'justify-content':item?.align,
                                                                  'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                      'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'color':item?.color,
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                              }">
                                                                      <p readonly  style="margin: 0 !important " [ngStyle]="{
                                                                          'font-size':item?.font_size+'px',
                                                                          'font-family':item?.fontFamily,
                                                                      'font-weight':item?.font_Weight,
                                                                      'margin':' 0 !important',
                                                                      'text-decoration':item?.textDecoration,
                                                                      'font-style':item?.font_italic
                                                                  }">{{item?.label}}
                                                                  <span *ngIf="!item?.user_currency" [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'padding-bottom': item?.padding + 'px',
                                                                      'padding-top': item?.paddingT + 'px',
                                                                      'padding-right': item?.paddingR + 'px',
                                                                      'padding-left': item?.paddingL + 'px'
                                                                    }" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + crSymbol}}</span>
                                                                  <span  *ngIf="item?.user_currency" [ngStyle]="{
                                                                      'justify-content': item?.align,
                                                                      'padding-bottom': item?.padding + 'px',
                                                                      'padding-top': item?.paddingT + 'px',
                                                                      'padding-right': item?.paddingR + 'px',
                                                                      'padding-left': item?.paddingL + 'px'
                                                                    }" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + item?.currency_symbol}}</span>
                      
                                                                                                                    </p>
                                                                  </div>
                      
                      
                                                                  <div *ngIf="item?.name =='Additional Cost'" class="d-flex" [ngStyle]="{
                                                                      'justify-content':item?.align,
                                                                      'margin-bottom':item?.mrbottum+'px',
                                                                      'font-size':item?.font_size+'px',
                                                                          'color': '#333',
                                                                          'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'color':item?.color,
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'font-style':item?.font_italic
                                                                  }">
                                                                          <p readonly  [ngStyle]="{
                                                                              'font-size':item?.font_size+'px',
                                                                              'margin-bottom':item?.mrbottum+'px',
                                                                              'font-family':item?.fontFamily,
                                                                          'font-weight':item?.font_Weight,
                                                                          'padding-bottom':item?.padding+'px',
                                                                          'padding-top':item?.paddingT+'px',
                                                                          'padding-right':item?.paddingR+'px',
                                                                          'padding-left':item?.paddingL+'px',
                                                                          'margin-top':item?.mrtop+'px',
                                                                          'margin-right':item?.mrright+'px',
                                                                          'margin-left':item?.mrleft+'px',
                                                                          'text-decoration':item?.textDecoration,
                                                                          'margin':' 0 !important',
                                                                          'font-style':item?.font_italic
                                                                      }">{{item?.label}}
                                                                      <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0}}</span>
                                                                                                                            </p>
                                                                      </div>
                      
                      
                      
                      
                      
                      
                      
                      
                      
                                                          <div *ngIf="item?.name==='Attachment'" class="d-flex flex-column" [ngStyle]="{
                                                          'align-items':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px'
                                                          }">
                                                              <p class="m-0 txt-res" [ngStyle]="{
                                                          'width': '80%',
                                                          'justify-content':item?.align,
                                                          'margin-bottom':item?.mrbottum+'px',
                                                          'font-size':item?.font_size+'px',
                                                              'font-family':item?.fontFamily,
                                                              'font-weight':item?.font_Weight,
                                                              'padding-bottom':item?.padding+'px',
                                                              'padding-top':item?.paddingT+'px',
                                                              'padding-right':item?.paddingR+'px',
                                                              'padding-left':item?.paddingL+'px',
                                                              'color':item?.color,
                                                              'margin-top':item?.mrtop+'px',
                                                              'margin-right':item?.mrright+'px',
                                                              'margin-left':item?.mrleft+'px',
                                                              'text-decoration':item?.textDecoration,
                                                              'margin':' 0 !important',
                                                              'font-style':item?.font_italic
                                                      }">{{item?.label}} 
                                                              </p>
                                                              <label for="attachment" class="attachment-label" [ngStyle]="{
                                                          'display': 'flex',
                                                          'border-radius': '5px',
                                                          'border': '1px dashed #C1C1C1',
                                                          'padding': bodying_Size+'px',
                                                          'background': '#F4F4F4',
                                                          'margin-top': '10px',
                                                          'justify-content': 'center',
                                                          'margin':' 0 !important',
                                                          'font-family':selectedFont }">
                                                                  <img src="assets/svg-icons/attachment_icon.svg"
                                                                      alt="attachment_icon">
                                                                  <div class="lebel_res" [ngStyle]="{
                                                              'display': 'flex',
                                                              'align-items': 'center',
                                                              'flex-direction': 'column',
                                                              'margin-left': '8px',
                                                              'font-family':selectedFont,
                                                              'font-size':bodying_Size+'px' }">
                                                                      <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                                                      <p class="m-0">
                                                                          <span *ngFor="let file of item?.value">
                                                                              <span *ngIf="file?.value">{{file?.name}},</span>
                                                                          </span>(MAX: 10 MB)
                                                                      </p>
                                                                  </div>
                                                              </label>
                                                              <input type="file" class="form-control" style="display: none;"
                                                                  id="attachment" name="attachment">
                                                                  
                                                          </div>
                      <!-- other element -->
                      <div *ngIf=" item?.name === 'Member Name'  || item?.name === 'Country'  || item?.name === 'Business Name' || item?.name ==='TAX ID' " class="d-flex" 
                      [ngStyle]="{
                      'justify-content': item?.align
                      
                      
                      }" 
                      >
                      <p readonly class="txt-res" style="margin: 0 !important" [ngStyle]="{
                        'font-size': item?.title_font_size + 'px',
                          'color': item?.title_color,
                          'font-family':item?.fontFamily,
                      'font-weight':item?.font_Weight,
                      'text-decoration':item?.textDecoration,
                      
                      'font-style':item?.font_italic,
                      'margin':' 0 !important',
                      'text-align':item?.align
                      
                      }">{{item?.value}}&nbsp;</p>
                      <span  [ngStyle]="{
                       'text-align':item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': item?.color
                      
                      }">{{item?.tag}}</span>
                      
                      </div>
                      
                      
                      
                      <div *ngIf="item?.name === 'Deal Description'" class="d-flex flex-column" [ngStyle]="{
                      'justify-content': item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': '#333',
                      'font-family': item?.fontFamily,
                      'font-weight': item?.font_Weight,
                      'padding-bottom': item?.padding + 'px',
                      'padding-top': item?.paddingT + 'px',
                      'padding-right': item?.paddingR + 'px',
                      'padding-left': item?.paddingL + 'px',
                      
                      'text-decoration': item?.textDecoration,
                      'font-style': item?.font_italic
                      }">
                      
                      <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
                       <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
                         <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                            'justify-content': item?.align,
                             'font-size': item?.title_font_size + 'px',
                             'font-family': item?.fontFamily,
                             'font-weight': item?.font_Weight,
                             'margin':' 0 !important',
                             'color': item?.title_color,
                             'text-decoration': item?.textDecoration,
                             'font-style': item?.font_italic,
                             'text-align':item?.align
                           }">
                           {{ item?.value }} </p>
                           <span [ngStyle]="{
                                 'justify-content': item?.align,
                                 'margin-top':'0',
                            
                            'text-align':item?.align
                           }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
                         
                       </ng-container>
                       <ng-template #noPhoneNumbers>
                          <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.title_font_size + 'px',
                              'color': item?.title_color,
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              'margin':' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align':item?.align
                            }"> 
                              {{ item?.value }}</p>
                              <span [ngStyle]="{
                                'justify-content': item?.align,
                              
                                'text-align':item?.align,
                                'color': item?.color
                      
                              }"  >{{ item?.tag }}</span>
                              
                          
                      </ng-template>
                      </ng-container>
                      
                      </div>
                      
                      <div *ngIf="item?.name === 'Deal Value'" class="d-flex flex-column" [ngStyle]="{
                      'justify-content': item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': '#333',
                      'font-family': item?.fontFamily,
                      'font-weight': item?.font_Weight,
                      'padding-bottom': item?.padding + 'px',
                      'padding-top': item?.paddingT + 'px',
                      'padding-right': item?.paddingR + 'px',
                      'padding-left': item?.paddingL + 'px',
                      
                      'text-decoration': item?.textDecoration,
                      'font-style': item?.font_italic
                      }">
                      
                      <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
                       <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
                         <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                            'justify-content': item?.align,
                             'font-size': item?.title_font_size + 'px',
                             'font-family': item?.fontFamily,
                             'font-weight': item?.font_Weight,
                             'margin':' 0 !important',
                             'color': item?.title_color,
                             'text-decoration': item?.textDecoration,
                             'font-style': item?.font_italic,
                             'text-align':item?.align
                           }">
                           {{ item?.value }} </p>
                           <span [ngStyle]="{
                                 'justify-content': item?.align,
                                 'margin-top':'0',
                            
                            'text-align':item?.align
                           }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
                         
                       </ng-container>
                       <ng-template #noPhoneNumbers>
                          <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.title_font_size + 'px',
                              'color': item?.title_color,
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              'margin':' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align':item?.align
                            }"> 
                              {{ item?.value }}</p>
                              <span [ngStyle]="{
                                'justify-content': item?.align,
                              
                                'text-align':item?.align,
                                'color': item?.color
                      
                              }"  >{{ item?.tag }}</span>
                              
                          
                      </ng-template>
                      </ng-container>
                      
                      </div>
                      
                      <div *ngIf="item?.name === 'Forecast Date'" class="d-flex flex-column" [ngStyle]="{
                      'justify-content': item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': '#333',
                      'font-family': item?.fontFamily,
                      'font-weight': item?.font_Weight,
                      'padding-bottom': item?.padding + 'px',
                      'padding-top': item?.paddingT + 'px',
                      'padding-right': item?.paddingR + 'px',
                      'padding-left': item?.paddingL + 'px',
                      
                      'text-decoration': item?.textDecoration,
                      'font-style': item?.font_italic
                      }">
                      
                      <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
                       <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
                         <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                            'justify-content': item?.align,
                             'font-size': item?.title_font_size + 'px',
                             'font-family': item?.fontFamily,
                             'font-weight': item?.font_Weight,
                             'margin':' 0 !important',
                             'color': item?.title_color,
                             'text-decoration': item?.textDecoration,
                             'font-style': item?.font_italic,
                             'text-align':item?.align
                           }">
                           {{ item?.value }} </p>
                           <span [ngStyle]="{
                                 'justify-content': item?.align,
                                 'margin-top':'0',
                            
                            'text-align':item?.align
                           }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
                         
                       </ng-container>
                       <ng-template #noPhoneNumbers>
                          <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.title_font_size + 'px',
                              'color': item?.title_color,
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              'margin':' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align':item?.align
                            }"> 
                              {{ item?.value }}</p>
                              <span [ngStyle]="{
                                'justify-content': item?.align,
                              
                                'text-align':item?.align,
                                'color': item?.color
                      
                              }"  >{{ item?.tag }}</span>
                              
                          
                      </ng-template>
                      </ng-container>
                      
                      </div>
                      
                      <div *ngIf="item?.name === 'Deal Title'" class="d-flex flex-column" [ngStyle]="{
                      'justify-content': item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': '#333',
                      'font-family': item?.fontFamily,
                      'font-weight': item?.font_Weight,
                      'padding-bottom': item?.padding + 'px',
                      'padding-top': item?.paddingT + 'px',
                      'padding-right': item?.paddingR + 'px',
                      'padding-left': item?.paddingL + 'px',
                      
                      'text-decoration': item?.textDecoration,
                      'font-style': item?.font_italic
                      }">
                      
                      <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
                       <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
                         <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                            'justify-content': item?.align,
                             'font-size': item?.title_font_size + 'px',
                             'font-family': item?.fontFamily,
                             'font-weight': item?.font_Weight,
                             'margin':' 0 !important',
                             'color': item?.title_color,
                             'text-decoration': item?.textDecoration,
                             'font-style': item?.font_italic,
                             'text-align':item?.align
                           }">
                           {{ item?.value }} </p>
                           <span [ngStyle]="{
                                 'justify-content': item?.align,
                                 'margin-top':'0',
                            
                            'text-align':item?.align
                           }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
                         
                       </ng-container>
                       <ng-template #noPhoneNumbers>
                          <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.title_font_size + 'px',
                              'color': item?.title_color,
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              'margin':' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align':item?.align
                            }"> 
                              {{ item?.value }}</p>
                              <span [ngStyle]="{
                                'justify-content': item?.align,
                              
                                'text-align':item?.align,
                                'color': item?.color
                      
                              }"  >{{ item?.tag }}</span>
                              
                          
                      </ng-template>
                      </ng-container>
                      
                      </div>
                      
                      <div *ngIf="item?.name === 'Deal Stage'" class="d-flex flex-column" [ngStyle]="{
                      'justify-content': item?.align,
                      'font-size': item?.font_size + 'px',
                      'color': '#333',
                      'font-family': item?.fontFamily,
                      'font-weight': item?.font_Weight,
                      'padding-bottom': item?.padding + 'px',
                      'padding-top': item?.paddingT + 'px',
                      'padding-right': item?.paddingR + 'px',
                      'padding-left': item?.paddingL + 'px',
                      
                      'text-decoration': item?.textDecoration,
                      'font-style': item?.font_italic
                      }">
                      
                      <ng-container *ngIf="item?.tag && item?.tag?.length; else noPhoneNumbers">
                       <ng-container #ngIf="" *ngFor="let phoneNumber of item.tag; let i = index">
                         <p *ngIf="item?.value !=='' " style="margin: 0 !important "  readonly class="txt-res" [ngStyle]="{
                            'justify-content': item?.align,
                             'font-size': item?.title_font_size + 'px',
                             'font-family': item?.fontFamily,
                             'font-weight': item?.font_Weight,
                             'margin':' 0 !important',
                             'color': item?.title_color,
                             'text-decoration': item?.textDecoration,
                             'font-style': item?.font_italic,
                             'text-align':item?.align
                           }">
                           {{ item?.value }} </p>
                           <span [ngStyle]="{
                                 'justify-content': item?.align,
                                 'margin-top':'0',
                            
                            'text-align':item?.align
                           }" style="color: #1b8ef8" >{{ phoneNumber}}</span>
                         
                       </ng-container>
                       <ng-template #noPhoneNumbers>
                          <p class="txt-res" *ngIf="item?.value == '' " readonly [ngStyle]="{
                              'justify-content': item?.align,
                              'font-size': item?.title_font_size + 'px',
                              'color': item?.title_color,
                              'font-family': item?.fontFamily,
                              'font-weight': item?.font_Weight,
                              'margin':' 0 !important',
                              'text-decoration': item?.textDecoration,
                              'font-style': item?.font_italic,
                              'text-align':item?.align
                            }"> 
                              {{ item?.value }}</p>
                              <span [ngStyle]="{
                                'justify-content': item?.align,
                              
                                'text-align':item?.align,
                                'color': item?.color
                      
                              }"  >{{ item?.tag }}</span>
                              
                          
                      </ng-template>
                      </ng-container>
                      
                      </div>
                      
                      <!-- other element -->
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                                                          <!-- <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                                          <div class="d-flex flex-column my-1">
                                                              <label for="name">Name</label>
                                                              <input type="text" id="name" name="name" placeholder="Your name"
                                                                  formControlName="name">
                                                          </div>
                                                      </form> -->
                                                      </div>
                      
                      
                      
                      
                      
                      
                      
                      
                      
                      
                                                  </div>
                      
                                                  <!-- <img src="assets/svg-icons/delete_icon.svg" (click)="removeField(i,j)"
                                                      class="delete-btn" type="image/svg+xml"> -->
                                              </div>
                                              <div *cdkDragPreview class="d-flex input-field-preview">
                                                  <span class="txt">{{item?.name}}</span>
                                              </div>
                                          </div>
                                      </ng-container>
                                      </div>
                                      <!-- <ng-container *ngIf="!dropPage?.items.length">
                                          <div class="not-eny-item">
                                              <object data="assets/svg-icons/drop_icon.svg" type="image/svg+xml"></object>
                                              <p class="title mb-0">Drag your first element here from the elements list at the left.
                                              </p>
                                          </div>
                                      </ng-container> -->
                                  </div>
                              </div>
                              <!-- <ng-container *ngIf="(active_Tabs==='submit_options')&&(droppedItem!==null)">
                                  <div class="success-submit">
                                      <ng-container *ngIf="false">
                                          <img src="assets/svg-icons/success_icon.svg" alt="success icon">
                                          <h1>Your message is sent successfully!</h1>
                                          <p>Thank you for reaching out. We will get <br> in touch within 24 hours.</p>
                                      </ng-container>
                                      <ng-container *ngIf="true">
                                          <h1>Form Submitted</h1>
                                          <p>Your client will not see this message and be redirected <br>
                                              to <b>https://www.osmoscloud.com</b></p>
                                      </ng-container>
                                  </div>
                              </ng-container> -->
                          </div>
                          </div>
                      <!-- <embedform2 [embedFromData]="getFormData(dailogData)"></embedform2> -->
                    </div>
                    
                        </div>
                    
                </div>
              </div>
            </ng-container>
           
          </div>
        </div>
        
                
    </div>
    </div>