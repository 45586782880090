import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent as AuthHeaderComponent } from './auth/header/header.component';
import { FooterComponent as AuthFooterComponent } from './auth/footer/footer.component';
import { HeaderComponent as Auth2HeaderComponent } from './auth2/header/header.component';
import { FooterComponent as Auth2FooterComponent } from './auth2/footer/footer.component';
import { HeaderComponent as DashHeaderComponent, HeaderComponent } from './dash/header/header.component';
import { FooterComponent as DashFooterComponent } from './dash/footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatMenuModule} from '@angular/material/menu';
import { HeaderModule } from './dash/header/header.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideNavService } from './side-nav-service';
import {MatDividerModule} from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatMenuModule,
    HeaderModule,
    MatSidenavModule,
    MatDividerModule,
    MatProgressBarModule,
    MatIconModule
  ],
  declarations: [
    AuthFooterComponent,
    AuthHeaderComponent,
    Auth2FooterComponent,
    Auth2HeaderComponent,
    DashFooterComponent,
    DashHeaderComponent,
    HeaderComponent


  ],
  exports: [
    AuthFooterComponent,
    AuthHeaderComponent,
    Auth2FooterComponent,
    Auth2HeaderComponent,
    DashFooterComponent,
    DashHeaderComponent,
    HeaderComponent

  ],
  providers: [
    SideNavService
  ]
})
export class ComponentsModule { }
