export class Contacts {
    id: number;
    contactId: number;
    firstname: String;
    lastname: String;
    merchant_customer_emails: any;
    merchant_customer_contacts: any;
    merchant_customer_main_addresses: any;
    merchant_customer_social_links: any;
    merchant_customers_relations: any;
    merchant_customer_social_infos: any;
    customer_custom_fields_datas: any;
    merchant_organizations:any;
    merchant_customer_tags: any;
    industries_id: Number;
    bus_industries_id: Number;
    languageId: any;
    contact_as_lead: any;
    send_mail_camp: any;
    formContactId: Number;
    clientTypeId: Number;
    roleId: any;
    price_id: any;
    company_name: String;
    tax_id: Text;
    birth_date: Date;
    anniversary_date: Date;
    title: Text;
    gender: Text;
    contact_title: Number;
    customField: any;
    is_organization: any;
    name: String;
    legal_name: String;
    business_number: String;
    anniversary: String;
    creation_date: String;
    about: String;
    audience: String;
}



