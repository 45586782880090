import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  @Input() successmessage:string="";
  @Input() closebuttonin:any;
  @Output() changeOutput: EventEmitter<any> = new EventEmitter<any>();
  page:number = 1;
  adduserpage = 1;
  modelWidth = 900;
  
  ngOnInit(): void {
    
  }
 
  closebuttonsuc(){
    this.changeOutput.emit(true);
  }
}
