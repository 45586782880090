import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoWhiteSpace]'
})
export class NoWhiteSpaceDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/\s+/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Tab') {
      event.preventDefault();
    }
  }
}
