import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export class AddressItems  {
    lineItems: any[]

/**
 * Constructor
 *
 * @param rec
 */
constructor(rec?) {
    this.lineItems = rec;

}

public validationRules(): any {
    return {
        lineItems: new FormArray([], []),
    };
}


public lineItemValidationRules(): any {

    return {
            formattedAddress: new FormControl(null),
            country: new FormControl(null),
            state: new FormControl(null),
            province: new FormControl(null),
            city: new FormControl(null),
            postal_code: new FormControl(null),
            street_name: new FormControl(null),
            house_number: new FormControl(null),
            interior_number: new FormControl(null),
    };

}

}
