import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket :any;

    constructor() {
        this.socket = io(environment.API_URL);
    }

    listen(Eventname : string){
        return new Observable((subscriber)=>{
            this.socket.on(Eventname,(data)=>{
                subscriber.next(data);
            })
        })
    }
}