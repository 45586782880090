import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationViewService {
  BASE_URL: string;
  orgUrl: string

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.orgUrl = environment.ORG_MODULE_URL;
  }


  organizationList(id) {
    return this.http.get(this.BASE_URL + '/merchantorganization/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  
  viewOrganization(id) {
    return this.http.get(this.orgUrl + '/viewOrgRecord/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


}
