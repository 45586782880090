import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-already-merchant-login',
  templateUrl: './already-merchant-login.component.html',
  styleUrls: ['./already-merchant-login.component.scss']
})
export class AlreadyMerchantLoginComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AlreadyMerchantLoginComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.afterClosed();
  }
}
