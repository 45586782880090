export class ItemEditModel {
    id: number;
    categoryId: number ;
    subCategoryId:number;
    top_flag: number = 0;
    status: boolean; 
    name:string ;
    code:string;
    description:Text;
    video_url:Text;
    website_url:Text; 
    item_bundles:any;  
    sub_items:any;
    item_warehouses:any;
    track_inventory:any;
    out_of_stock:any;
    item_coming_form:number;
    item_images:any;
    bundles:any;
    created_at:Date;
    childitems:any;
    images:any;
    unit_quantity: any;
    color:any;
    item_coming_from:any;
    
}
