import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');

    if (!token) {
      return next.handle(request);
    }
   let google_request:any = request.url
    if(google_request.includes('www.googleapis.com/calendar/') ){
      return next.handle(request);
    }
    if(request.url.indexOf('ipdata.co') >= 0) {
      const headers = request.clone();
      return next.handle(headers);
    } 
    else {
      const merchantId = localStorage.getItem('merchant_id');
      const userId = localStorage.getItem('userId');
      const email = localStorage.getItem('email');
      const deviceLoginId  = localStorage.getItem('deviceLoginId');
      const headers = request.clone({
        headers: request.headers.set('Authorization',`${token}`).set('merchantId', merchantId ? `${merchantId}` : '').
                  set('x-user-id', userId ? `${userId}` : '').set('email', email ? `${email}` : '').set('deviceLoginId', deviceLoginId ? `${deviceLoginId}` : '').set('clientdate',(new Date().toLocaleDateString('en-CA')).toString()),      
      });
      return next.handle(headers);
    }
  }


}
