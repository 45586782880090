<div class="notes-menu">
  <div class="m-2" (click)="$event.stopPropagation()">
    <form [formGroup]="addNote" id="update-textarea">
      <div class="box p-2 br-10">
        <div class="d-flex">
          <button class="menu-btn fs-2 text-right" [matMenuTriggerFor]="iconMenu">
            <img src="assets/img/icon/{{noteicon}}.png">
          </button>
          <mat-menu #iconMenu="matMenu" yPosition="below" class="notes-menu-panel">
            <div class="d-flex align-item-center gap-2 p-2 save-filter" (click)="$event.stopPropagation()">
              <ng-container *ngFor="let icon of icons">
                <a href="javascript:void(0)" class="border br-50 p-1" (click)="changeIcon(icon)">
                  <img src="assets/img/icon/{{icon}}.png">
                </a>
              </ng-container>
            </div>
          </mat-menu>

          <div class="w-100">
            <mat-form-field
              [ngClass]="noteSubmit && noteVal?.merchant_lead_activitie_id?.errors ? 'task-array-invalid':''">
              <mat-select formControlName="merchant_lead_activitie_id"
                placeholder="{{'LEADS.SELECTACTIVITIES'| translate}}" (selectionChange)="selectedActivity($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="activityFilterCtrl" placeholderLabel='Search'
                    noEntriesFoundLabel="'No match found'">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let activity of activityList; let i = index" [value]="activity?.id">
                  {{activity.name}}
                  <!-- {{ getTranslatedText ("LEADS." + activity.name) || activity.name}} -->
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="noteSubmit && noteVal?.merchant_lead_activitie_id?.errors">
              <div *ngIf="noteVal?.merchant_lead_activitie_id?.errors?.required"
                [ngClass]="noteVal?.merchant_lead_activitie_id?.errors?.required?'text-danger':''">
                {{"LEADS_ERR.SELECTANDACTIVITY" | translate}}
              </div>
            </div>
          </div>
        </div>
        <textarea matInput appearance="fill" class="form-control textarea-none w-100 textarea-sm border-0 br-0 mb-2"
          name="note" formControlName="note"
          [ngClass]="{ 'is-invalid': noteSubmit && noteVal?.note?.errors , 'is-valid': noteSubmit && noteVal?.note?.valid }"
          name="contect" style="height: 70px;resize:none" placeholder="{{'LEADS.NOTEHERE'| translate}}"></textarea>
        <div *ngIf="noteSubmit && noteVal?.note?.errors" class="invalid-feedback">
          <div *ngIf="noteVal?.note?.errors?.required"> {{"LEADS_ERR.ERRDEALNAMEREQUIRED" |
            translate}}
          </div>
        </div>

        <div>
          <ngx-dropzone (change)="onSelect($event)" class="ngx-dropzone">
            <ngx-dropzone-label>{{'LEADS.Upload_documents'| translate}}</ngx-dropzone-label>
            <ng-container *ngFor="let f of files">
              <ng-container *ngIf="f.type.startsWith('image/')">
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                </ngx-dropzone-image-preview>
              </ng-container>
              <ng-container *ngIf="!f.type.startsWith('image/')">
                <ngx-dropzone-preview  ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ng-container>
            </ng-container>
          </ngx-dropzone>
        </div>
        <p style="margin: 2px 0px 0px 0px;font-size: 14px; color: #8080808c;">{{'LEADS.allowed_extention'| translate}} jpg, png, pdf, doc, xls, ppt</p>
        <div class="update-Attachment mt-2" *ngIf="noteAttachment.length>0">
          <span *ngFor="let attach of noteAttachment;" class="image">
            <img *ngIf="attach?.name && (attach?.name?.split('.')?.pop() === 'jpg' 
            || attach?.name?.split('.')?.pop() === 'jpeg' 
            || attach?.name?.split('.')?.pop() === 'png')" [src]="IMAGE_URL+attach?.name">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'pdf'" src="assets/icons/file/pdf.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'doc'" src="assets/icons/file/doc.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'docx'" src="assets/icons/file/docx.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'xls'" src="assets/icons/file/xlsx.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'xlsx'" src="assets/icons/file/xls.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'ppt'" src="assets/icons/file/ppt.png">
            <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'pptx'" src="assets/icons/file/pptx.png">
            <span *ngIf="attach?.name" class="delete" (click)="deleteAttached(attach)"><svg>
                <line x1="0" y1="0" x2="10" y2="10"></line>
                <line x1="0" y1="10" x2="10" y2="0"></line>
            </svg>
            </span>
            <span *ngIf="attach?.name" class="name">{{imgname(attach?.name)}}</span>
          </span>
        </div>
      </div>
      <button type="button" class="btn btn-1 w-100 mt-2" (click)="addNoteForm()"
        *ngIf="!activityNoteId">{{"LEADS.ADD_NOTE" |
        translate}}</button>
      <button type="button" class="btn btn-1 w-100 mt-2" (click)="addNoteForm()"
        *ngIf="activityNoteId">{{"LEADS.UPDATE_NOTE" |
        translate}}</button>
    </form>
    <ng-container *ngFor="let activity of activityNotes">
      <div class="mt-4 mb-2">
        <div class="d-flex align-items-center gap-2">
          <img src="assets/img/icon/{{activity?.icon}}.png">
          <p class="color-{{activity?.icon}}">
            {{activity?.merchantDealActivity?.name}}</p>
        </div>
        <div class="border-start-3 mt-2 ms-3 ps-3 pb-3">
          <div class="d-flex align-items-center gap-2">
            <div>
              <p class="text-1">{{activity?.merchantUser?.fname}}
                {{activity?.merchantUser?.lname}}
                <span class="text-3">{{activity?.created_at | date:'d EEEE, MMM y'}}</span>
              </p>
            </div>
            <i type="button" class="fa fa-pen text-1 br-50 bg-6 p-2 ms-auto" (click)="editNote(activity)"
              *ngIf="activityNoteId != activity.id"></i>
            <i type="button" class="fa fa-times text-1 br-50 bg-6 p-2 ms-auto" (click)="editNoteCancel()"
              *ngIf="activityNoteId == activity.id"></i>
          </div>
          <div class="mt-2 p-2 shadow-sm border br-10">
            <div class="d-flex activityNoteAn align-items-center gap-2 border-start-3 ps-2">
              <p class="text-1 text-break" id="activityNote{{activity?.id}}"
                [ngClass]="activeNote_Id === activity?.id && addExpandClass ? 'max-charactor':'activity-note'">
                {{activity?.note}}</p>
              <button class="btn br-50 shadow-sm p-1 border" *ngIf="activity?.note?.length > 65"
                (click)="activityBlock(activity?.id)">
                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.2986 4.89918L11.152 4.89926C10.9403 4.89918 10.7408 4.98205 10.5903 5.13257C10.4397 5.2831 10.3568 5.48258 10.3569 5.69417L10.3568 6.36754C10.3571 6.80601 10.7137 7.16253 11.1519 7.16244L18.8326 7.16244L18.8326 14.8501C18.8327 15.0617 18.9154 15.2611 19.0659 15.4116C19.2165 15.5622 19.416 15.6449 19.6277 15.6451L20.3012 15.6449C20.5129 15.6449 20.7123 15.5621 20.8629 15.4116C21.0134 15.261 21.0963 15.0616 21.0962 14.85L21.0964 5.69684C21.0962 5.48467 21.0127 5.28469 20.8616 5.13433C20.7106 4.98271 20.5107 4.89926 20.2986 4.89918Z"
                    fill="#364F6B" />
                  <path
                    d="M5.70535 21.1008L14.8519 21.1007C15.0636 21.1008 15.2631 21.018 15.4136 20.8674C15.5642 20.7169 15.6471 20.5174 15.647 20.3058L15.6471 19.6325C15.6468 19.194 15.2902 18.8375 14.852 18.8376L7.17133 18.8376L7.17133 11.1499C7.17125 10.9383 7.08853 10.7389 6.93806 10.5884C6.78742 10.4378 6.5879 10.3551 6.37618 10.3549L5.70276 10.3551C5.49104 10.3551 5.2916 10.4379 5.14104 10.5884C4.99048 10.739 4.9076 10.9384 4.90768 11.15L4.90752 20.3032C4.90768 20.5153 4.99124 20.7153 5.1423 20.8657C5.29327 21.0173 5.49321 21.1007 5.70535 21.1008Z"
                    fill="#364F6B" />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-2 p-2 shadow-sm border br-10" *ngIf="activity?.dealNoteAttachment?.length>0">
            <div class="d-flex align-items-center flex-wrap activityNoteAn gap-2 border-start-3 ps-2">
              <span *ngFor="let attach of activity?.dealNoteAttachment;" class="img-box">
                <img *ngIf="attach?.name && (attach?.name?.split('.')?.pop() === 'jpg' 
                || attach?.name?.split('.')?.pop() === 'jpeg' 
                || attach?.name?.split('.')?.pop() === 'png')"
                [src]="IMAGE_URL+attach?.name" (click)="gotoSrc1(activity)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'pdf'" src="assets/icons/file/pdf.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'doc'" src="assets/icons/file/doc.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'docx'" src="assets/icons/file/docx.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'xls'" src="assets/icons/file/xlsx.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'xlsx'" src="assets/icons/file/xls.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'ppt'" src="assets/icons/file/ppt.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
                <img *ngIf="attach?.name && attach?.name?.split('.')?.pop() === 'pptx'" src="assets/icons/file/pptx.png" (click)="gotoSrc(IMAGE_URL+attach?.name)">
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>