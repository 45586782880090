import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-success-popup',
  templateUrl: './new-success-popup.component.html',
  styleUrls: ['./new-success-popup.component.scss']
})
export class NewSuccessPopupComponent implements OnInit {
  constructor(private router: Router) { }
  @Input() successmessage:string="";
  @Input() closebuttonin:any;
  @Output() changeOutput: EventEmitter<any> = new EventEmitter<any>();
  page:number = 1;
  adduserpage = 1;
  modelWidth = 900;
  
  ngOnInit(): void {
  }
 
  cancelModel(){
    this.changeOutput.emit(true);
    this.router.navigate(['/dash/system-users']);
  }

}
