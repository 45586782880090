import { Component, ElementRef, Input, OnInit, ViewChild, NgZone } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { imgViewComponent } from './image-view.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-notes',
  templateUrl: './activity-notes.component.html',
  styleUrls: ['./activity-notes.component.scss'],
})
export class ActivityNotesComponent implements OnInit {
  addNote: FormGroup;
  @Input() userId: string;
  @Input() leadId: string;
  @Input() leadData: any;
  @Input() dealStageArr: any;
  @Input() activityNotes: any;
  @Input() leadIdForNote: any;
  activityNoteId: any;
  activityList: any;
  noteSubmit: boolean = false;
  selectedactivity: any;
  noteicon = 'users';
  dealActivityFilterValue: any[] = [];
  subscriptions: Subscription[] = [];
  icons: string[] = ['like', 'message', 'call', 'info', 'moon', 'cloud', 'book', 'home', 'flight', 'car', 'question', 'query', 'edit', 'setting', 'laptop', 'doller', 'deslike', 'users', 'money'];
  activitynoteList: ReplaySubject<any> = new ReplaySubject<any>(1);
  public activityFilterCtrl: FormControl = new FormControl();
  files: File[] = [];
  addExpandClass: boolean;
  activeNote_Id: any;
  IMAGE_URL: string = `${environment.UPLOAD_NOTE_ATTACHMENT}`;
  noteAttachment: any[] = [];
  noteAttachArr: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private leadService: LeadService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.merchantAddNoteForm();
    this.getActivityData();
    const valueChangesSubs = this.activityFilterCtrl?.valueChanges?.subscribe(() => { this.activitynoteLists(); }, (error) => { });

  }

  get noteVal(): { [key: string]: AbstractControl } { return this.addNote.controls; }

  merchantAddNoteForm() {
    this.addNote = this.formBuilder.group({
      id: [''],
      merchant_lead_activitie_id: ['', Validators.required],
      note: ['', Validators.required],
      icon: [''],
      merchant_user_id: [''],
      merchant_lead_deal_id: [''],
    });
  }
  async getlisting() {
    await this.leadService.getNoteofLead({
      merchantUserId: localStorage.getItem('userId'),
      merchant_lead_deal_id: this.leadIdForNote,
    }).subscribe((res) => {
      this.files = [];
      this.noteAttachment = [];
      this.noteAttachArr = [];
      this.activityNotes = res?.data ? res?.data : [];
    }, (error) => { });
  }
  editNote(activity) {
    this.noteAttachment = [...activity?.dealNoteAttachment];
    this.activityNoteId = activity.id;
    this.addNote.controls['note'].setValue(activity.note);
    this.addNote.controls['id'].setValue(activity.id);
    this.addNote.controls['icon'].setValue(activity.icon);
    this.addNote.controls['merchant_lead_activitie_id'].setValue(activity.merchantDealActivity?.id);
    this.addNote.controls['merchant_user_id'].setValue(
      activity.merchant_user_id
    );
    this.addNote.controls['merchant_lead_deal_id'].setValue(
      activity.merchant_lead_deal_id
    );
    this.noteicon = activity.icon;
    document?.getElementById('update-textarea')?.scrollIntoView({ behavior: 'smooth' });
  }
  editNoteCancel() {
    this.noteAttachment = [];
    this.activityNoteId = '';
    this.addNote.reset();
    this.addNote.controls['icon'].setValue(this.leadData.icon);
    this.addNote.controls['merchant_lead_activitie_id'].setValue(this.leadData.merchant_lead_activitie_id);
    this.noteicon = this.leadData.icon;
  }
  protected activitynoteLists() {
    if (!this.activityList) {
      return;
    }
    let search = this.activityFilterCtrl.value;
    if (!search) {
      this.activitynoteList.next(this.activityList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.activitynoteList.next(
      this.activityList.filter(
        (activity) => activity?.name?.toLowerCase().indexOf(search) > -1
      )
    );
  }
  getActivityData() {
    this.leadService.lead_Activity_List$.subscribe((res) => {
      if (res) {
        this.activityList = res.data;
        this.activitynoteLists();
        this.dealActivityFilterValue = this.activityList;
      }
    });
    this.leadService.leadNoteActivity().subscribe((res) => {
      if (res) {
        this.activityList = res.data;
        this.activitynoteLists();
        this.dealActivityFilterValue = this.activityList;
      }
    },
      (error) => { }
    );
  }
  selectedActivity(event) {
    if (event.value) {
      this.selectedactivity = this.activityList.find((x) => x.id == event.value);
      const updateDealStageSubs = this.leadService.updateDealStage({
        defaultData: this.dealStageArr,
        merchant_lead_deal_id: this.leadIdForNote,
        merchant_lead_activitie_id: this.selectedactivity,
      }).subscribe((val: any) => { }, (error) => { });
      this.subscriptions.push(updateDealStageSubs);
    }
  }
  filterActivityType(event: any) {
    this.activityList = this.dealActivityFilterValue;
    this.activityList = this.activityList.filter((i) => i.name.toLowerCase().indexOf((event.target as HTMLInputElement)?.value?.toLocaleLowerCase()) !== -1);
  }

  async addNoteForm() {
    this.noteSubmit = true;
    if (this.addNote.invalid) {
      return;
    }
    this.addNote.controls['icon'].setValue(this.noteicon);
    if (this.activityNoteId) {
      const form_Data = new FormData();
      form_Data.append('merchant_lead_activitie_id', this.addNote?.value?.merchant_lead_activitie_id);
      form_Data.append('note', this.addNote?.value?.note);
      form_Data.append('merchant_user_id', localStorage.getItem('userId'));
      form_Data.append('merchant_lead_deal_id', this.leadIdForNote);
      form_Data.append('id', this.addNote?.value?.id);
      form_Data.append('icon', this.addNote?.value?.icon);
      if (this.files.length > 0) { this.files.forEach((file, index) => { form_Data.append(`images[${index}]`, file) }) };
      const updateLeadNoteSubs = this.leadService.updateLeadNote(form_Data).subscribe((res) => {
        this.addNote.reset();
        this.addNote.controls['merchant_lead_activitie_id'].setValue('');
        this.noteSubmit = false;
        this.activityNoteId = '';
        if (this.noteAttachArr?.length > 0) {
          this.updateAttachments();
        } else {
          this.getlisting();
        }
      }, (error) => { }
      );
      this.subscriptions.push(updateLeadNoteSubs);
    } else {
      const form_Data = new FormData();
      form_Data.append('merchant_lead_activitie_id', this.selectedactivity?.id);
      form_Data.append('note', this.addNote?.value?.note);
      form_Data.append('merchant_user_id', localStorage.getItem('userId'));
      form_Data.append('merchant_lead_deal_id', this.leadIdForNote);
      form_Data.append('icon', this.addNote?.value?.icon);
      if (this.files.length > 0) { this.files.forEach((file, index) => { form_Data.append(`images[${index}]`, file) }) };
      const creatLeadNoteSubs = this.leadService.creatLeadNote(form_Data).subscribe((val: any) => {
        this.noteSubmit = false;
        this.addNote.reset();
        this.addNote.controls['merchant_lead_activitie_id'].setValue('');
        this.getlisting();
        this.files = [];
        this.noteAttachment = [];
        this.noteAttachArr = [];
      }, (error) => { }
      );
      this.subscriptions.push(creatLeadNoteSubs);
    }
  }
  async changeIcon(icon) {
    this.noteicon = icon;
    const updateDealStageSubs = this.leadService.updateDealStage({
      merchant_lead_deal_id: this.leadIdForNote,
      icon: icon,
    }).subscribe((val: any) => { }, (error) => { });
    this.subscriptions.push(updateDealStageSubs);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onSelect(event) {
    Array.from(event.addedFiles).forEach((item: any) => {
      if (this.isValidFileType(item?.type)) {
        const size = item.size/(1024*1024);
        if(size<10){
          this.files.push(item);
        }else{
          this.ErrorSnackBar(this.translate.instant('LEADS.documents_size'), 'OK');
        }
      } else {
        this.ErrorSnackBar(this.translate.instant('LEADS.invalid_documents') + " " + `${item?.type}`, 'OK');
      }
    });
  }

  isValidFileType(type: string): boolean {
    const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    return acceptedFileTypes.includes(type);
  }

  onRemove(event) { this.files.splice(this.files.indexOf(event), 1); }

  activityBlock(id) {
    id ? this.addExpandClass = !this.addExpandClass : null;
    this.activeNote_Id = id;
  }

  // isSrc(src: string): boolean { const img = new Image(); img.src = src; return img?.complete && img?.naturalWidth !== 0; };
  imgname(name: string): string { return name?.replace(/^\d+_/g, '') }

  deleteAttached(data: any) {
    this.noteAttachment = this.noteAttachment.filter(item => item?.id !== data?.id);
    this.noteAttachArr.push(data);
  }

  updateAttachments() {
    const data: any[] = [...new Set(this.noteAttachArr)];
    const payload = {
      attachmentId: data.map((item: any) => item?.id),
      attachmentNames: data.map((item: any) => item?.name)
    };
    if (data?.length) {
      const updateLeadNoteSubs = this.leadService.updateLeadNoteAttach(payload).subscribe((res) => {
        this.getlisting();
        this.files = [];
        this.noteAttachment = [];
        this.noteAttachArr = [];
      }, (error) => { }
      );
      this.subscriptions.push(updateLeadNoteSubs);
    }
  }

  ErrorSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = ['notif-error'];
    this.snackbar.open(msg, tag, config);
  }

  gotoSrc(src: string) { if (src) { window.open(src, '_blank'); } }

  gotoSrc1(src: string) {
    if (src) {
      const dialogRef = this.dialog.open(imgViewComponent, {
        width:'65vw',
        data: { src: src }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog closed', result);
      });
    }
  }



}
