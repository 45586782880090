import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {
  BASE_URL: string;
  MAILIFIER_API: string;

  constructor( private http: HttpClient ) {
    this.BASE_URL = environment.API_URL;
    this.MAILIFIER_API = environment.MAILIFIER_KEY;
  }

  signup( body: any ): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  verifyEmailBusiness( email: any ): Observable<any> {
    return this.http.post(`${this.BASE_URL}/findmerchant`,{'filter':{'email': email}}, {
      observe: 'response'
    });
  }

  resendEmail( email: any ): Observable<any> {
    return this.http.post(`${this.BASE_URL}/resendemail`,{'email': email}, {
      observe: 'response'
    });
  }

  activateUser(data :any) {
    return this.http.post(`${this.BASE_URL}/merchantuser/reactivate`,{'email': data.email , 'user_id' : data.id, 'status': 1,'deactiveDate':null}, {
      observe: 'response'
    });
  }

  deleteInvitedUser( data ): Observable<any> {
    // console.log(data);
    return this.http.delete(`${this.BASE_URL}/merchantinviteduser/${data.id}/${data.merchantId}`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  verifyUrl(url: any ): Observable<any> {
    return this.http.post(`${this.BASE_URL}/findmerchant`, {'filter':{'url': url}}, {
      observe: 'response'
    });
  }

  verifyUsername( username: any ): Observable<any> {
    return this.http.get(`${this.BASE_URL}/sign-up/verify-username?username=${username}`, {
      observe: 'response'
    });
  }

  sendMailifer( email: any ): Observable<any> {
    return this.http.post(`https://mailifier.io/api/v1/apps/single?email=${email}`, {}, {
      observe: 'response',
      headers: {
        Authorization: this.MAILIFIER_API
      }
    });
  }

  verifyMailifer( code: any ): Observable<any> {
    return this.http.get(`https://mailifier.io/api/v1/apps/single/${code}`, {
      observe: 'response',
      headers: {
        Authorization: this.MAILIFIER_API
      }
    });
  }

}
