import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-model-layout',
  templateUrl: './model-layout.component.html',
  styleUrls: ['./model-layout.component.scss']
})
export class ModelLayoutComponent implements OnInit {
  // @Input() sigledata;
  // @Output() senddataDetails: EventEmitter<any> = new EventEmitter();
  item:any;
  constructor(public dialogRef: MatDialogRef<ModelLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(){
    this.dialogRef.updateSize('80%');
  }

  getdatadetails(data){
    if(data){
      this.onNoClick();
    }
  }


  closemodel(data){
    if(data){
      this.onNoClick();
    }
  }

}
