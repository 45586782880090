<div class="filter-container overflow-x-hidden" [ngClass]="{'filterHeight': dataSource.length <= 5}">
    <!-- <div class="search-filter"><input type="text" placeholder="{{'LEADS.Search' | translate}}"></div> -->
    <div class="saved-filter" *ngIf="savedFilter.length >0">

        <span *ngIf="savedFilter?.deal_type?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.deal_type; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_type')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.actions?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.actions; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'actions')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.action?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.action; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'action')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.organization?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.organization; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'organization')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.contact?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.contact; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)">
                    {{ getTranslatedText("QUOTE_TEMPLATE." + sfilter?.first_name + " " + sfilter?.last_name) || (sfilter?.first_name + " " + sfilter?.last_name) }}
                  </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'contact')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.member?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.member; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)">
                    {{ getTranslatedText("QUOTE_TEMPLATE." + sfilter?.first_name + " " + sfilter?.last_name) || (sfilter?.first_name + " " + sfilter?.last_name) }}
                  </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'member')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.status?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " *ngFor="let sfilter of savedFilter?.status; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'status')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.deal_stage?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center "
                *ngFor="let sfilter of savedFilter?.deal_stage; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_stage')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.customFields" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center "
                *ngFor="let sfilter of savedFilter?.customFields; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable" (click)="removeFilter(sfilter?.id, 'customFields')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.singale_value"
                [removable]="removable">
                <a href="javascript:void(0)">{{getTranslatedText ("QUOTE_TEMPLATE." + savedFilter?.singale_value ) || savedFilter?.singale_value}}</a>
                <mat-icon matChipRemove class="!h-[18px]" class="!h-[18px]" *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_value, 'singale_value')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.total_amount"
            [removable]="removable">
            <a href="javascript:void(0)">{{getTranslatedText ("QUOTE_TEMPLATE." + savedFilter?.total_amount ) || savedFilter?.total_amount}}</a>
            <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                (click)="removeFilter(savedFilter?.total_amount, 'total_amount')"> close </mat-icon>
        </mat-chip>
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable"
                *ngIf="savedFilter?.slider_value && savedFilter?.slider_value !='0-0'" [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.slider_value}}</a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                    (click)="removeFilter(savedFilter?.slider_value, 'slider_value')"> close </mat-icon>
            </mat-chip>
            
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.singale_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.singale_date | customDate}}</a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_date, 'singale_date')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.due_date"
            [removable]="removable">
            <a href="javascript:void(0)">{{savedFilter?.due_date| customDate}}</a>
            <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                (click)="removeFilter(savedFilter?.due_date, 'due_date')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.from_to_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{formatDateRange(savedFilter?.from_to_date)}}</a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                    (click)="removeFilter(savedFilter?.from_to_date, 'from_to_date')"> close </mat-icon>
            </mat-chip>
            
            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.deletedQuotes"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.deletedQuotes}}</a>
                <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                    (click)="removeFilter(savedFilter?.deletedQuotes,'deletedQuotes')"> close </mat-icon>
            </mat-chip>

            <mat-chip class="m-1 filter-input tag-input flex items-center " [selectable]="selectable" *ngIf="savedFilter?.invoices"
            [removable]="removable">
            <a href="javascript:void(0)">{{savedFilter?.invoices}}</a>
            <mat-icon matChipRemove class="!h-[18px]" *ngIf="removable"
                (click)="removeFilter(savedFilter?.invoices,'invoices')"> close </mat-icon>
        </mat-chip>
        </span>

    </div>
    <div class="acordion-filed">
        <mat-accordion>
            <mat-expansion-panel class="py-2" *ngIf="type !=='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Actions" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder='{{"QUOTE_TEMPLATE.Search Actions..." | translate}}' [(ngModel)]="actionSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of activityArr | search : actionSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'actions')">{{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="py-2" *ngIf="type =='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>Action</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder='{{"QUOTE_TEMPLATE.Search Actions..." | translate}}' [(ngModel)]="actionSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of actionListArr | search : actionSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'action')">{{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name | uppercase}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2" *ngIf="type !=='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Quote Status" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search Status ..." [(ngModel)]="statusSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of actionArr | search : statusSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'status')">  {{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="py-2" *ngIf="type =='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>Status</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search Status ..." [(ngModel)]="statusSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of paymentStatusArr | search : statusSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'status')">  {{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name | uppercase}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Organization" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search organization ..." [(ngModel)]="organizationSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of allOrgArr | search : organizationSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'organization')">{{item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2" *ngIf="type !=='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Members" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search members ..." [(ngModel)]="contactSearchTags">
                </div>
                <div class="acordion-input" >
                    <!-- (scroll)="onScroll($event)" -->
                    <span class="example-list-section" >
                        <span>
                            <p *ngFor="let item of merContactListArr | search : contactSearchTags">
                                <ng-container *ngIf="item?.fname !==null && item?.fname !==undefined && item?.fname !==''">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'contact')">{{ item?.fname || '' }} {{ item?.lname || '' }}
                                </mat-checkbox>
                            </ng-container>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="py-2" *ngIf="type =='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Members" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search members ..." [(ngModel)]="contactSearchTags">
                </div>
                <div class="acordion-input" >
                    <!-- (scroll)="onScroll($event)" -->
                    <span class="example-list-section" >
                        <span>
                            <p *ngFor="let item of merContactListArr | search : contactSearchTags">
                                <ng-container *ngIf="item?.fname !==null && item?.fname !==undefined && item?.fname !==''">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'member')">{{ item?.fname || '' }} {{ item?.lname || '' }}
                                </mat-checkbox>
                            </ng-container>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            
            <mat-expansion-panel class="py-2" *ngIf="type !=='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Deleted Quotes" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <mat-checkbox [(ngModel)]="deletedQuotes" (change)="wantDeletedQuotes()"
                                >Deleted Quotes
                            </mat-checkbox>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2" *ngIf="type !=='payment'">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Invoice" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <mat-checkbox [(ngModel)]="invoices" (change)="quoteInvoice()"
                                >Invoices
                            </mat-checkbox>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
        <div class="value-range-field" *ngIf="type =='payment'">
            <div class="value-field  ">
                <label for="singale_value">Enter Value</label>
                <input type="number" id="singale_value" class="value-input" [formControl]="singale_value"
                    (change)="getAccorValue($event, singale_value?.value, 'total_amount')">
            </div>
            <div class="range-field">
            <label for="range_value">{{"LEADS.Select_Range"|translate}}</label>
            <div class="range-input" id="range_value" class="range-input">
                <ngx-slider [(value)]="sliderValue" [(highValue)]="sliderMaxValue" [options]="sliderOptions"
                    (userChangeEnd)="getAccorValue($event, sliderMaxValue, 'slider_value')">
                </ngx-slider>
            </div>
        </div>
        </div>
        <div class="date-field" *ngIf="type =='payment'">
            <div class="single-date-field ">
                <label for="single_date">Enter Date</label>
                <app-custom-date id="single_date" (updateValue)="getAccorValue($event, 'singaledate', 'due_date')"
                    dataType='4' inputType="date" fvalue="{{savedFilter.singale_date}}" class="single-date-input">
                </app-custom-date>
            </div>
            <div class="fromto-date-field">
                <div class="from-date-field">
                    <label for="start_date">{{"LEADS.From"|translate}}</label>
                    <app-custom-date id="start_date" (updateValue)="getAccorValue($event,'fromtodate', 'start_date')"
                        dataType='4' maxDate="{{savedFilter.end_date}}" inputType="date" fvalue="{{savedFilter.start_date}}"
                        class="from-date-input">
                    </app-custom-date>
                </div>
                <div class="to-date-field">
                    <label for="end_date">{{"LEADS.To"|translate}}</label>
                    <app-custom-date id="end_date" (updateValue)="getAccorValue($event, 'fromtodate', 'end_date')"
                        dataType='4' minDate="{{savedFilter.start_date}}" inputType="date" fvalue="{{savedFilter.end_date}}"
                        class="to-date-input">
                    </app-custom-date>
                </div>
            </div>
        </div>    
    </div>
    <div class="apply-filter-field mt-4" >
        <div class="clear-filter-btn">
            <button type="button" (click)="resetFilter()">
                <app-custom-icon icon="round_close"></app-custom-icon> {{"LEADS.Clear_Filter"|translate}}
            </button>
        </div>
        <div class="apply-filter-btn">
            <button type="button" (click)="saveFilter()">
                <app-custom-icon icon="round_correct"></app-custom-icon> {{"LEADS.Apply_Filter"|translate}}
            </button>
        </div>
    </div>
</div>