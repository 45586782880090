<mat-dialog-content id="itemDiv" (mouseenter)="closingbulkdialog()">
  <div class="modal-content border-0" >
    <div class="modal-body bg-gris-1 p-3 px-4">
      <h2 class="text-1 fs-4 text-center mb-4" *ngIf="!itemsEditButton && inputData.type !== 'quote'">{{"COMMON.Add New Item"
        | translate}}
      </h2>
      <h2 class="text-1 fs-4 text-center mb-4" *ngIf="itemsEditButton && inputData.type !== 'quote'">{{"COMMON.Edit Item"
        | translate}}
      </h2>
      <h2 class="text-1 fs-4 text-center mb-4" *ngIf="inputData.type == 'quote'">Update Price
      </h2>
      <i type="button" class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        data-bs-dismiss="modal" (click)="onDismiss()" aria-label="Close"></i>
      <mat-tab-group [formGroup]="userBasicInfoForm" [selectedIndex]="currentTabIndex"
        (selectedIndexChange)="selectedTabIndex($event)" dynamicHeight>
        <mat-tab label="{{ 'COMMON.Basic Info' | translate }}">
          <div class="active" style="overflow-x: hidden;height: 45vh;position: relative;">
            <div class="pt-3 ">
              <div class="d-flex div_rsp align-items-center gap-3 my-4">
                <p class="text-3 col-2 txt_rsp">{{"COMMON.Category" | translate}}<span class="color-6">*</span></p>
                <mat-select class="text-1 field_rsp form-select d-inline-block border-bottom " style="width: 100%;"
                  aria-label="Default select example" [(ngModel)]="itemAddModel.categoryId"
                  formControlName="categoryId">
                  <!-- <div *ngIf="userBasicInfoForm.get('categoryId').errors" class="invalid-feedback">
                    <div *ngIf="userBasicInfoForm.get('categoryId').errors"> {{"SYSTEM_USER_ERR.ERRORITEMNAMEREQUIRED" | translate}}</div>
                  </div> -->
                  <mat-option class="text-1"><ngx-mat-select-search [formControl]="cateogrySearch"
                      [searching]="isUserSearching" [placeholderLabel]="'COMMON.Search'|translate"
                      [noEntriesFoundLabel]="'Result not found'|translate">
                    </ngx-mat-select-search></mat-option>
                  <mat-option class="text-1" [value]="null"
                    *ngIf="itemAddModel.categoryId !== null && itemAddModel.categoryId !== undefined">
                    <span class="flex items-center"> <mat-icon class="vertical-align-baseline">
                        close
                      </mat-icon> Clear</span>
                  </mat-option>

                  <mat-option id="option_num" class="text-1 " *ngFor="let obj of filteredCategories | async; index as i"
                    [value]="obj?.name" (click)="getCatId(obj.id)">
                    <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                      <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown"
                        style="background: none; border:none;" aria-expanded="false"
                        (click)="openBulkActionPopup($event,i)">

                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                        </svg>
                      </button>
                      <div (click)="openBulkAction = false">{{getTranslatedText ("COMMON."+obj.name |translate)  || obj.name}}</div>
                      <div class="shadow-sm dropdown-menu bg-white dropdown-1 flex-column gap-3 show"
                        aria-labelledby="sideMenuDropDown" style="top: 58%; width: 10%;
                        left: 14%;" *ngIf="openBulkAction && currentIndex === i">
                        <div class="px-2 flex flex-col gap-1">
                          <div class="flex items-center gap-3" (click)="removeCategory(i,obj?.id,'category')">
                            <mat-icon class="vertical-align-baseline m-0">
                              <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                src="assets/img/home/delete.svg">
                            </mat-icon> {{"COMMON.Delete" | translate}}

                          </div>
                          <div class="flex items-center gap-3 px-1" (click)="editCustomCategory(obj)">
                            <i class="fa fa-edit edit-icon" style="color: #03C0FC;"></i>
                            {{"COMMON.Edit" | translate}}
                          </div>

                        </div>

                      </div>
                    </div>
                  </mat-option>
                  <!-- <mat-option *ngFor="let obj of filteredCategories | async; index as i" [value]="obj?.name"  (click)="getCatId(obj.id)">
                  <span class="d-flex align-items-center">
                    <mat-icon class="vertical-align-baseline">
                      <img role="button" class="vertical-align-baseline"
                        src="assets/img/home/delete.svg"
                     (click)="removeCategory(i,obj?.id,'category')">
                   </mat-icon>  
                   <i class="fa fa-edit edit-icon mr-2" style="color: #03C0FC;" (click)="editCustomCategory(obj)"></i>

                   {{obj.name}}

                  </span>
                 
                
                  </mat-option> -->

                  <mat-option class="text-1" (click)="addDropdownValue('addFormOfContact','categoryId')">
                    <span class="d-flex items-center">
                      <mat-icon>
                        <svg width="30" height="25" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                          <path
                            d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                            fill="white" />
                        </svg>

                      </mat-icon>
                      <span>
                        {{"COMMON.Add" | translate}}
                      </span>
                    </span>
                  </mat-option>



                  <!-- <mat-option class="text-1" *ngFor="let obj of filteredCateogry |async; index as i "
                    selected="itemAddModel.categoryId == obj.id" [value]= obj.id selected="selected">
                    <span class="d-flex align-items-center">
                      <mat-icon class="vertical-align-baseline">
                        <img role="button" class="vertical-align-baseline"
                          src="assets/img/home/delete.svg"
                       (click)="removeCategory(i,obj?.id,'category')">
                     </mat-icon>  {{obj.name}}
                    </span>
                  </mat-option> -->
                </mat-select>
              </div>

              <div class=" d-flex div_rsp align-items-center gap-3 my-3">
                <p class="text-3 txt_rsp col-2">{{"COMMON.Sub Category" | translate}}:</p>
                <mat-select class="text-1 form-select field_rsp d-inline-block " style="width: 100%;"
                  aria-label="Default select example" [(ngModel)]="itemAddModel.subCategoryId"
                  formControlName="subCategoryId">
                  <mat-option class="text-1"><ngx-mat-select-search [formControl]="subCateogrySearch"
                      [searching]="isUserSearching" [placeholderLabel]="'COMMON.Search'|translate"
                      [noEntriesFoundLabel]="'QUOTE_TEMPLATE.Result not found'|translate">
                    </ngx-mat-select-search></mat-option>
                  <mat-option class="text-1" [value]="null"
                    *ngIf="itemAddModel.subCategoryId !== null && itemAddModel.subCategoryId !== undefined">
                    <mat-icon class="vertical-align-baseline">
                      close
                    </mat-icon> Clear
                  </mat-option>


                  <mat-option id="option_num" class="text-1 "
                    *ngFor="let obj of filteredSubCategories | async; index as i" [value]="obj?.name"
                    (click)="subCatId(obj.id)">
                    <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                      <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown"
                        style="background: none; border:none;" aria-expanded="false"
                        (click)="openBulkActionPopup($event,i)">

                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                        </svg>
                      </button>
                      <div (click)="openBulkAction = false">{{obj.name}}</div>
                      <div class="shadow-sm dropdown-menu bg-white dropdown-1 flex-column gap-3 show"
                        aria-labelledby="sideMenuDropDown" style="top: 58%; width: 10%;
                      left: 14%;" *ngIf="openBulkAction && currentIndex === i">
                        <div class="px-2 flex flex-col gap-1">
                          <div class="flex items-center gap-3" (click)="removeSubCategory(i,obj?.id,'Subcategory')">
                            <mat-icon class="vertical-align-baseline m-0">
                              <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                src="assets/img/home/delete.svg">
                            </mat-icon> {{"COMMON.Delete" | translate}}

                          </div>
                          <div class="flex items-center gap-3 px-1" (click)="updateSubcategories(obj)">
                            <i class="fa fa-edit edit-icon" style="color: #03C0FC;"></i>
                            {{"COMMON.Edit" | translate}}
                          </div>

                        </div>

                      </div>
                    </div>
                  </mat-option>



                  <mat-option class="text-1" (click)="addSubcategories('addFormOfContact','categoryId')">
                    <span class="d-flex align-items-baseline">
                      <mat-icon>
                        <svg width="30" height="25" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                          <path
                            d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                            fill="white" />
                        </svg>
                      </mat-icon>
                      <span>
                        {{"COMMON.Add" | translate}}
                      </span>

                    </span>
                  </mat-option>
              
                </mat-select>
              </div>
              <div class=" d-flex div_rsp align-items-center gap-3 my-3">
                <p class="text-3 txt_rsp col-2">{{"COMMON.Item Name" | translate}}<span class="color-6">*</span>
                </p>
                <input matInput [(ngModel)]="itemAddModel.name" formControlName="name"
                  class="form-control field_rsp p-2 text-1 p-2" type="text" [placeholder]="'COMMON.Enter Name' | translate"
                  [ngClass]="{ 'is-invalid': userBasicInfoSubmit && f.name.errors , 'is-valid': userBasicInfoSubmit && f.name.valid }">
                <div *ngIf="userBasicInfoSubmit && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required"> {{"SYSTEM_USER_ERR.ERRORITEMNAMEREQUIRED" | translate}}</div>
                </div>
              </div>
              <div class=" d-flex div_rsp align-items-center gap-3 mb-4 my-3">
                <p class="text-3 txt_rsp col-2"> {{"COMMON.Item_Code" | translate}}
                </p>
                <input matInput formControlName="code" class="form-control field_rsp p-2 text-1"
                  [(ngModel)]="itemAddModel.code" type="text" type="text" [placeholder]="'QUOTE_TEMPLATE.Enter Code' | translate">

              </div>
              <!-- <div class="p-3 border-gris position-relative" style="border-radius: 7px;">
                <nav class="position-absolute top-0 start-0">
                  <div class="nav nav-tabs border-0" id="nav-tab" role="tablist" style="border-radius: 7px 0 7px 0;">
                    <button class="border-0 text-3 bg-gris-2 p-2 btn-modal-item active"
                      style="border-radius: 7px 0 0 0;" id="nav-subitems-2-tab" data-bs-toggle="tab"
                      data-bs-target="#nav-subitems-2" type="button" role="tab" aria-controls="nav-subitems-2"
                      aria-selected="true"> {{"ITEMS.Subitems" |
                      translate}}</button>
                    <button class="border-0 text-3 bg-gris-2 p-2 btn-modal-item" style="border-radius: 0 0 7px 0;"
                      id="nav-bundles-2-tab" data-bs-toggle="tab" data-bs-target="#nav-bundles-2" type="button"
                      role="tab" aria-controls="nav-bundles-2" aria-selected="false">{{"ITEMS.Bundles" |
                      translate}}</button>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-subitems-2" role="tabpanel"
                    aria-labelledby="nav-subitems-2-tab">
                    <form [formGroup]="editItemCodeForm">
                      <div class=" d-flex align-items-center gap-3 mt-5">
                        <p class="text-3 col-2" >{{"ITEMS.Item code" | translate}}</p>
                        <mat-select class="text-1 form-select d-inline-block"
                        [(ngModel)]="itemAddModel.item_code" formControlName="item_code">
                        <mat-option class="text-1"><ngx-mat-select-search [formControl] ="searchItemCode" [searching] ="isUserSearching"
                          [placeholderLabel] ="'search'|translate"
                          [noEntriesFoundLabel] ="'Result not found'|translate">
                      </ngx-mat-select-search></mat-option>
                            <mat-option *ngFor="let obj of filteredItems | async" [value]="obj.name" (onSelectionChange)="onSelectedOption(obj.id);">
                     {{obj?.code}} {{obj.name}}
                            </mat-option>
                        </mat-select>
        
                        <div *ngIf=" itemCodeSubmit && itemCode.item_code.errors" class="invalid-feedback">
                          <div *ngIf="itemCode.item_code.errors.min"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                            translate}}</div>
                        </div>
                        <button class="btn btn-p-2 text-white" (click)="addEditItemcode()">
                          {{"ITEMS.ADD" |
                          translate}}</button>
                      </div>
                    </form>
                    <div class="col-10 p-4 mt-4 bg-celeste" *ngIf="addEditItemCodeArr?.length > 0">
                      <p class="text-1 mb-2">{{"ITEMS.Subitem" | translate}}</p>
                      <div class="d-flex flex-wrap gap-2">
                        <div class="color-1 d-flex align-items-center gap-2"
                          *ngFor="let childitemsList of addEditItemCodeArr;let i = index">
                          {{childitemsList.code}}<b class=" text-2 fs-5 no-drag" role="button"
                            (click)="removeItem(i,'subitem')">✕</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-bundles-2" role="tabpanel" aria-labelledby="nav-bundles-2-tab">
                    <form [formGroup]="editItemBundleForm">
                      <div class=" d-flex align-items-center gap-3 mt-5">
                        <p class="text-3 col-2" >{{"ITEMS.Bundles" | translate}}:</p>
                        <mat-select class="form-select text-1" type="text"
                          placeholder="Select Bundles Name" formControlName="item_bundles"
                          [(ngModel)]="itemAddModel.item_code"
                          [ngClass]="{ 'is-invalid': itemBundleSubmit && itemBundle.item_bundles.errors ,'is-valid': itemBundleSubmit && itemBundle.item_bundles.valid }">
                          <mat-option class="text-1" *ngFor="let bundleList of bundleListArr"
                            [ngValue]="{bundleId:bundleList.id,title:bundleList.title,hide_price:'0'}">
                            {{bundleList.title}}</mat-option>
                        </mat-select>
                        <div *ngIf=" itemBundleSubmit && itemBundle.item_bundles.errors" class="invalid-feedback">
                          <div *ngIf="itemBundle.item_bundles.errors.min"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                            translate}}</div>
                        </div>
                        <button class="btn btn-p-2 text-white" type="submit" (click)="addBundle()">
                          {{"ITEMS.ADD" | translate}}</button>
                        <button class="btn-plus color-1 btn-round" title='Add Bundle' data-bs-toggle="modal"
                          data-bs-target="#modal-add-new-Bundle" aria-expanded="false">+</button>
                      </div>
                    </form>
                    <div class="modal fade" id="modal-add-new-Bundle" data-bs-backdrop="false" tabindex="-1"
                      aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered w-500">
                        <div class="modal-content cnt-dashboard border shadow-sm border-r2">
                          <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
                            <form [formGroup]="addNewItemBundle">
                              <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Bundle" | translate}}</h2>
                              <i type="button" #closeAddBundle data-bs-dismiss="modal" aria-label="Close"
                                class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                              <div class="d-flex align-items-center gap-3">
                                <p class="text-1 text-nowrap"> {{"ITEMS.Name" | translate}}</p>
                                <input matInput type="text" placeholder="Bundle name" formControlName="title"
                                  class="form-control p-2" [ngClass]="{ ' is-invalid': itemAddBundleSubmit &&
                                  newBundle.title.errors , 'is-valid' : itemAddBundleSubmit &&
                                  newBundle.title.valid }">
                                <div *ngIf="itemAddBundleSubmit && newBundle.title.errors" class="invalid-feedback">
                                  <div *ngIf="newBundle.title.errors.required">
                                    {{"TASK.ERRONAMEREQUIRED"
                                    |
                                    translate}}
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center mt-5 gap-2">
                                <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-2">{{"ITEMS.Cancel" |
                                  translate}}</button>
                                <button aria-label="Close" class="btn btn-1ms-2 btn-2 "
                                  (click)="addNewBundle()">{{"ITEMS.Add"
                                  |
                                  translate}}</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-10 p-4 mt-4 bg-celeste" *ngIf="selectedBundle.length > 0">
                      <p class="text-1 mbe-2">{{"ITEMS.Bundles" | translate}}</p>
                      <div class="d-flex align-items-center"
                        *ngFor="let selectedBundleList of selectedBundle;let i = index">
                        <p class="color-1 ">{{selectedBundleList.item_bundles.title}}</p>
                        <div class="form-check ms-auto">
                          <input matInput class="form-check-input" type="checkbox"
                            [checked]="selectedBundleList.item_bundles.hide_price == '1'"
                            (change)="changeValueHide($event, i)" />
                          <label class="form-check-label text-3" for="flexCheckDefault">
                            {{"ITEMS.Hide Price" | translate}} <b class=" text-2 fs-5 no-drag" role="button"
                              (click)="removeItem(i,'bundleRemove')">✕</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class=" d-flex div_rsp align-items-center gap-3 my-3">
                <div class="col-3 txt_rsp">
                  <div class="d-flex align-items-center gap-2">
                    <span class="text-3 col-9" style="word-wrap: break-word;" id="AddressTitle1">
                      {{ firstUrlTitle? firstUrlTitle: 'COMMON.Video URL' | translate}}
                    </span>
                    <input matInput style="width: 75px;" type="text" class="form-control field_rsp p-2 d-none col-lg-2 "
                      (change)="changeAddField($event, 'addTitle')" value="{{firstUrlTitle}}" id="AddressTitleInput1" />
                    <button class="nav-link add-btn btn-1 color-1 me-4" (click)="editAddField(1)" id="editButton1">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="nav-link add-btn btn-1 color-1 me-4 d-none" (click)="saveAddField(1)"
                      id="checkButton1">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
                <!-- <p class="text-3 col-2" >{{"ITEMS.Video URL" | translate}}</p> -->
                <input matInput formControlName="video_url" class="form-control field_rsp p-2 text-1"
                  [(ngModel)]="itemAddModel.video_url" type="text" [placeholder]="'QUOTE_TEMPLATE.Enter Video URL' | translate" >
              </div>
              <div class=" d-flex div_rsp align-items-center gap-3 my-3">
                <div class="col-3 txt_rsp">
                  <div class="d-flex align-items-center gap-2">
                    <span class="text-3 col-9" style="word-wrap: break-word;" id="AddressTitle2">
                      {{secondUrlTitle? secondUrlTitle: 'LEADFRMODULE.Website URL'| translate}}
                    </span>
                    <input matInput style="width: 100px;" type="text"
                      class="form-control field_rsp p-2 d-none col-lg-2 "
                      (change)="changeSecondUrlField($event, 'addTitle')" value="{{secondUrlTitle}}"
                      id="AddressTitleInput2" />
                    <button class="nav-link add-btn btn-1 color-1 me-4" (click)="editAddField(2)" id="editButton2">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="nav-link add-btn btn-1 color-1 me-4 d-none" (click)="saveAddField(2)"
                      id="checkButton2">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
                <!-- <p class="text-3 col-2">{{"ITEMS.Website URL" | translate}}</p> -->
                <input matInput formControlName="website_url" class="form-control field_rsp p-2 text-1"
                  [(ngModel)]="itemAddModel.website_url" type="text" [placeholder]="'QUOTE_TEMPLATE.Enter Website URL' | translate" >
              </div>
              <div class="d-flex flex-column align-center gap-3">
                <p class="text-3">{{ "ITEMS.Images" | translate }}</p>
                <div class="d-flex align-items-center">
                  <div class="item-image-box gap-2 cursor-pointer" style="width: 250px;" *ngIf="image">
                    <img class="edit-item-images" src="{{ imageUrl + image.image }}">
                    <div class="overlay"></div>
                    <div class="image-remove-btn">
                      <a (click)="removeItem(image,'imgRemove')">
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div class="bg-gris-2 p-2 ms-2 d-flex justify-content-center rounded-circle rounded-2"
                    style="width: 45px; height: 45px; background-color: rgb(3, 192, 252, 10%);">
                    <input type="file" name="file-6" id="file-6" class="inputfile" accept=".jpeg,.jpg,.png"
                      data-multiple-caption="{count} archivos seleccionados" (change)="imageFilesAddedEdit($event)"
                      multiple />
                    <label for="file-6">
                      <img class="" width="33px" height="29.22px" src="assets/svg-icons/Group (2).svg">
                    </label>
                  </div>
                  <div class="flex flex-col gap-1 pl-2">
                    <span>{{ "ITEMS.Supported file formats: JPEG, PNG" |translate}}</span>
                    <span>{{ "ITEMS.Maximum file size: 2GB per file" |translate}}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex gap-2" style="margin-top:20px">
                <div class="item-image-box cursor-pointer gap-2" *ngFor="let image of editItemsImages;let i = index"
                  (click)="changeImage(i,image)" style="width:80px; height: 70px;">
                  <img class="edit-item-image-zoom" src="{{ imageUrl + image.image }}">
                  <img class="edit-item-images" src="{{imageUrl +image.image}}">
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'ITEMS.itemsDetail' | translate }}"
          [disabled]="!userBasicInfoForm.get('name').value || !userBasicInfoForm.get('categoryId').value">
          <div class=" pt-3" style="overflow-x: hidden;height: 43vh;">

            <!-- adding custom firld starts -->
            <div class="row">
              <div class="col-lg-12 add-custom-field-btn mt-3">
                <button type="button" class="add-btn  add-btn-plain-link" data-bs-toggle="modal"
                  data-bs-target="#add_custom_field">
                  <i class="fas fa-plus-circle"></i> {{"COMMON.Add Custom Field" |
                  translate}}</button>

                <div class="modal " id="add_custom_field" data-bs-backdrop="false" tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
                    <div class="modal-content cnt-dashboard border-r2 border shadow">
                      <div class="modal-header pt-5">
                        <h2 class="text-1 fs-4 text-center">
                          {{(editCustomFieldId ? "COMMON.Edit_Custom_Field" :
                          "COMMON.Add Custom Field") |
                          translate}}</h2>
                        <i type="button" data-bs-dismiss="modal" aria-label="Close" #customFieldForm
                          (click)="closeCustomFieldDialog()"
                          class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                      </div>
                      <form [formGroup]="addCustomFieldForm" (ngSubmit)="addCustomFieldList()">
                        <div class="modal-body bg-gris-1 px-5">
                          <label class=" mb-2">
                            {{"COMMON.Field Name" | translate}}</label>
                          <input matInput type="text" class="form-control p-2" formControlName="name">
                        </div>
                        <div class="modal-body bg-gris-1 px-5" *ngIf="!editCustomFieldId">
                          <label class=" mb-2">{{"COMMON.Field_Type" |
                            translate}}<span class="color-6">
                              *</span></label>
                          <mat-select class="form-select-mat text-1 border-gris " [placeholder]="'COMMON.FIELDTYPEREQUIRED' | translate"
                            formControlName="field_type_id" (selectionChange)="chnageType($event)"
                            [ngClass]="{ 'is-invalid': cusFieldFormSubmit && customField.field_type_id.errors , 'is-valid': cusFieldFormSubmit && customField.field_type_id.valid }">
                            <mat-option class="text-1" *ngFor=" let customFieldList of customFieldListArr"
                              [value]="customFieldList.id">
                              {{"COMMON." + customFieldList.name | translate}}
                            </mat-option>
                          </mat-select>
                          <div *ngIf="cusFieldFormSubmit && customField.field_type_id.errors" class="invalid-feedback">
                            <div *ngIf="customField.field_type_id.errors.required">
                              {{"COMMON.FIELDTYPEREQUIRED" |
                              translate}}
                            </div>
                          </div>
                        </div>
                        <div class="modal-body bg-gris-1 px-5"
                          *ngIf="valueFieldShow == '2' || valueFieldShow == '4' || valueFieldShow == '5'">
                          <label class=" mb-2"> {{"COMMON.Field_Value" |
                            translate}}<span class="color-6">
                              *</span></label>
                          <div class="d-flex align-items-center gap-2 w-100">
                            <div class="custom-contact-val w-100" id="tags">
                              <mat-chip class=" m-1 tag-input" *ngFor="let fieldValue of fieldValueArr"
                                [selectable]="selectable" [removable]="removable" (removed)="remove1(fieldValue)">
                                {{fieldValue.value}}
                                <mat-icon matChipRemove *ngIf="removable">
                                  cancel
                                </mat-icon>
                              </mat-chip>
                              <mat-form-field required class="w-100 p-0 m-0">
                                <mat-chip-list #chipList>
                                  <input [placeholder]="'QUOTE_TEMPLATE.Enter value to add' | translate" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add1($event)" />
                                </mat-chip-list>
                              </mat-form-field>
                            </div>

                          </div>
                        </div>

                        <div class="modal-footer j-c-center mt-4 pb-4">
                          <button class="btn-skyblue-s-1 standard-btn btn mx-2" id="" data-bs-toggle="modal"
                            (click)="closeCustomFieldDialog()" data-bs-dismiss="modal" data-bs-target="#modal-new"
                            #closebutton>{{"COMMON.Cancel" | translate}}
                          </button>
                          <button type="submit" class="btn  btn-p-2 standard-btn mx-2">{{"COMMON.Add"
                            | translate}} </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- adding custom field endds here -->
            <!-- Showing Custom Form Fields -->
            <div class="row">
              <div class="col-12 mt-3 custom-fields">
                <app-custom-form-fields #custom class="d-flex" [customerCustomFieldArr]="customerCustomFieldArr"
                  (editCustomField)="editCustomField($event)" (removeCustomField)="deleteCustomField($event)"
                  [customDataFieldViewData]="customDataFieldViewData">
                </app-custom-form-fields>
              </div>
            </div>
            <!-- Showing Custom Form Fields -->





            <mat-tab-group [selectedIndex]="getSelectedTabIndex()">
              <!-- English Description -->
              <mat-tab label="{{ 'COMMON.Description_en' | translate }}">
                <div class="p-3">
                  <ckeditor formControlName="description" style="width: 100%;" [config]="config" [editor]="Editor" [(ngModel)]="itemAddModel.description">
                  </ckeditor>
                </div>
              </mat-tab>
            
              <!-- Spanish Description -->
              <mat-tab label="{{ 'COMMON.Description_es' | translate }}">
                <div class="p-3">
                  <ckeditor formControlName="description_es" style="width: 100%;" [config]="config" [editor]="Editor" [(ngModel)]="itemAddModel.description_es">
                  </ckeditor>
                </div>
              </mat-tab>
            
              <!-- French Description -->
              <mat-tab label="{{ 'COMMON.Description_fr' | translate }}">
                <div class="p-3">
                  <ckeditor formControlName="description_fr" style="width: 100%;" [config]="config" [editor]="Editor" [(ngModel)]="itemAddModel.description_fr">
                  </ckeditor>
                </div>
              </mat-tab>
            </mat-tab-group>
            
            
            <div class="mt-4">
              <div class="flex gap-2">
              <p class="text-3 d-flex align-items-center">{{"COMMON.Item Comments" | translate}}</p>
              <span matTooltipClass="helpText-tooltip" matTooltip="{{'ITEMS.Comments are different from item descriptions. Item descriptions are general, while comments are specific and used on a case-by-case basis. Comments allow you to make special annotations for the item based on customer requirements. Each comment is saved for future use, if needed.' | translate}}" matTooltipPosition="right">
                <app-custom-icon  icon="view"></app-custom-icon>
              </span>
              </div>
              <div class="col-10 " id="comment">
                <mat-form-field class="example-chip-list " appearance="outline">
                  <mat-chip-list #chipList aria-label="Fruit selection">
                    <mat-chip *ngFor="let comment of comments;let i = index" (removed)="removeComment(comment)">
                      {{comment.title}}
                      <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                    </mat-chip>
                    <input [placeholder]="'QUOTE_TEMPLATE.add_comments' | translate" style="min-width: 555px;" [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                      (matChipInputTokenEnd)="addComment($event)">
                  </mat-chip-list>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-between flex-row items-center resp-quantity">
              <div class="col-8 d-flex gap-3 align-items-center mt-4 " style="margin-bottom: 20px;">
                <div class="col-4 gap-3 align-items-center resp-product" style="width: 50%;">
                  <p class="text-3 text-nowrap me-1 mb-2">{{"ITEMS.Item is a" | translate}}</p>
                  <mat-select class="form-select" style="width: 90%;" [formControl]="itemType" value="" selected>
                    <mat-option value="Product">{{"ITEMS.Product" | translate}}</mat-option>
                    <mat-option value="Service">{{"COMMON.Service" | translate}}</mat-option>
                    <!-- <mat-option>Service</mat-option> -->

                  </mat-select>
                </div>
                <div class="col-4 gap-3 align-items-center resp-items" style="width: 50%;">
                  <div class="flex gap-2">
                  <p class="text-3 text-nowrap mb-2">{{"COMMON.Unit" | translate}}</p>
                  <span matTooltipClass="helpText-tooltip" matTooltip="{{'ITEMS.Set the unit of measurement for this item, if needed.' | translate}}" matTooltipPosition="right">
                    <app-custom-icon  icon="view"></app-custom-icon>
                  </span>
                </div>
                  <mat-select class="form-select-mat form-select text-1" [formControl]="unitSelect" style="width: 90%;">
                    <mat-option class="text-1" value="" selected>{{"HOME.Select Unit" | translate}}</mat-option>
                   
                   
                    <mat-option id="option_num" class="text-1 "
                    *ngFor="let unitList of unitListArr;let i = index"
                    [value]="unitList.name">
                    <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                      <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown"
                        style="background: none; border:none;" aria-expanded="false"
                        (click)="openBulkActionPopup($event,i)">

                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                        </svg>
                      </button>
                      <div (click)="openBulkAction = false">{{unitList.name}}</div>
                      <div class="shadow-sm dropdown-menu bg-white dropdown-1 flex-column gap-3 show"
                        aria-labelledby="sideMenuDropDown" style="top: 58%; width: 10%;
                      left: 14%;" *ngIf="openBulkAction && currentIndex === i">
                        <div class="px-2 flex flex-col gap-1">
                          <div class="flex items-center gap-3"  (click)="removeDropdownValues(i,unitList?.id,'unit')">
                            <mat-icon class="vertical-align-baseline m-0">
                              <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                src="assets/img/home/delete.svg">
                            </mat-icon> {{"COMMON.Delete" | translate}}

                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-option>
                   
                   
                   
                   
      
        <!--        <mat-option class="text-1" *ngFor="let unitList of unitListArr;let i = index"
                      [value]="unitList.name">
                      <span class="d-flex align-items-baseline">
                        <mat-icon class="vertical-align-baseline">
                          <img style="width: 25px;height: 20;" role="button" src="assets/img/home/delete.svg"
                            (click)="removeDropdownValues(i,unitList?.id,'unit')">
                        </mat-icon>
                        <span>
                          {{unitList.name}}
                        </span>
                      </span>
                    </mat-option> -->

                    <mat-option class="text-1" (click)="addDropdownValues('addFormOfContact','items','unit')">
                      <span class="d-flex align-items-baseline">
                        <mat-icon>
                          <svg width="30" height="25" viewBox="0 0 55 55" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                            <path
                              d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                              fill="white" />
                          </svg>
                        </mat-icon>
                        <span>
                          {{"COMMON.Add" | translate}}
                        </span>
                      </span>
                    </mat-option>
                    <mat-error *ngIf="itemPriceTaxForm.invalid"></mat-error>
                  </mat-select>
                </div>
              </div>
              <div class="col-4 resp-quantity-1">
                <div class="flex gap-2">
                <p class="text-3 text-nowrap mb-2 ">{{"ITEMS.Quantity per click" | translate}}</p>
                <span matTooltipClass="helpText-tooltip" matTooltip="{{'ITEMS.Define the value for each click of the + sign. For example, if you want to add 12 items at a time, set the value to 12.' | translate}}" matTooltipPosition="left">
                  <app-custom-icon  icon="view"></app-custom-icon>
                </span>
              </div>
                <form [formGroup]="numberField" class="resp-quantity-items">
                  <div class="d-flex function-cont border-bottom pb-1 justify-content-between ">
                    <button id="btn_menos" (click)="decrements()" class="btn btn-cont cont-1">─</button>
                    <input id="cont" type="number" min="0" formControlName="numberValue"
                      class="input-num text-1 border-0 " style="width: 50px;text-align: center;">
                    <button id="btn_mas" (click)="increments()" class="btn btn-cont cont-2">+</button>
                  </div>
                </form>
              </div>
              <!-- <span style=" margin-right:-6px;margin-left:-17px;">{{"ITEMS.Quantity per click" | translate}}</span> -->


            </div>

            <!-- <div class="form-check mt-4 form-switch d-flex gap-3 p-0 align-items-center">
              <label class="text-1" for="aa">{{"ITEMS.Advance unit calculation" | translate}}</label>
              <input class="form-check-input input-switch m-0" type="checkbox" (click)="show()">
            </div> -->
            <!-- <div *ngIf="hideMe">
              <form [formGroup]="unitSelection" class="col-4 mt-3">
                <div class="d-flex gap-3 align-items-center">
                  <select class="form-select " formControlName="unitList1">
                    <option *ngFor="let units of unitList" [value]="units.name" >{{units.name}}</option>
                  </select>
                </div>
              </form>  

              <div class="d-flex gap-3 p-3 mt-2 align-items-center bg-celeste justify-content-center"  *ngIf="unitSelection.controls.unitList1.value" >
                <span class="text-3">{{unitSelection.controls.unitList1.value}}:</span>
                <input type="number" (change)="calculate()" style="border: none; border-radius:5px;width: 61px;padding: 7px;font-size: 14px;" name="" id="" [(ngModel)]="calculations.height" [ngModelOptions]="{standalone: true}">
                <span class="text-1">{{"HOME.Length" | translate}}</span>
                <select class="form-select border-bottom border-0" (change)="calculate()" [(ngModel)]="calculations.sign" [ngModelOptions]="{standalone: true}" style="width: 61px;">
                  <option *ngFor="let sign of signsArray" [value]="sign">{{sign}}</option>
               </select>
                <input type="number" (change)="calculate()" style="border: none; border-radius:5px;width: 61px;padding: 7px;font-size: 14px;" name="" id="" [(ngModel)]="calculations.width" [ngModelOptions]="{standalone: true}">
                <span class="text-1">{{"HOME.Width" | translate}}</span>
                <span class="fs-3 mx-1 color-1"> =</span>
                <span class="text-1">{{calculations.result }}{{unitSelect.value}}</span>
              </div>
            </div> -->

            <!-- Show More: <mat-slide-toggle class="mt-4" (click)="toggle()"></mat-slide-toggle> -->
            <!-- <div *ngIf="showMe" >
             <div class="d-flex gap-4 mt-4" >
               <div class="form-check form-switch d-flex gap-3 p-0 align-items-center">
                 <label for="aa">{{"ITEMS.Track inventory" | translate}}</label>
                 <input class="form-check-input input-switch m-0" (change)="trackInventory($event)"
                   [checked]="itemAddModel.track_inventory == '1'" type="checkbox">
               </div>
               <div class="form-check form-switch d-flex gap-3 p-0 align-items-center">
                 <label for="flexSwitchCheckDefault">{{"ITEMS.Out of stock selling" | translate}}</label>
                 <input class="form-check-input input-switch m-0" (change)="outOfStock($event)"
                  [checked]="itemAddModel.out_of_stock == '1'" type="checkbox">
               </div>
             </div>
             <div class="p-3 mt-4 bg-celeste px-4" style="background-color: rgb(3, 192, 252, 9%);">
               <span style="color: #113D55;font-weight: normal;"> {{"ITEMS.Item available in" | translate}}:</span>
               <div class="d-flex gap-3 mt-3">
                  <span class="text-1" style="font-weight: normal;"
                 *ngFor="let warehouseNameList of addWarehouseArr;let i = index">{{warehouseNameList.name}}:
                  <span class="color-1" style="font-weight: normal;">{{warehouseNameList.items}} items</span><a class="fs-6 ms-3 color-1 cursor-pointer"
                  (click)="removeItem(i,'warehouseRemove')">✕</a></span>
               </div>
               <div class="d-flex gap-3 mt-3">
                 <div class="btn-mas color-1 border-1">+</div><a class="color-1" role="button" data-bs-toggle="modal"
                   data-bs-target="#modal-warehouse" style="font-weight: normal;"> {{"Add additional warehouse" |
                   translate}}</a>
               </div>
               <div class="d-flex gap-3 mt-3">
                 <span class="text-3 col-4 pt-3"> {{"ITEMS.Item will be coming from" | translate}}</span>
                 <mat-form-field class="pr-4" >
                  <mat-select  class="pb-2 text-1 d-inline-block " [(ngModel)]="itemAddModel.item_coming_form"
                   name="currency_id"  formControlName="item_coming_form">
                      <mat-option>
                          <ngx-mat-select-search [formControl] ="wareHouseSearch" [searching] ="isUserSearching"
                              [placeholderLabel] ="'search'|translate"
                              [noEntriesFoundLabel] ="'Result not found'|translate">
                          </ngx-mat-select-search>
                      </mat-option>
                      <mat-option class="text-1" *ngFor="let warehouseNameList of filteredWareHouses  | async ;let i = index"
                      [value]="warehouseNameList.id">
                      {{warehouseNameList.name}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>

               </div>
             </div>
             <div class="d-flex gap-4 my-4">
                <!-- <form [formGroup]="unitSelection" class="col-4">
                  <div class="d-flex gap-3 align-items-center ">
                    <select class="form-select " formControlName="unitList1">
                      <option *ngFor="let units of unitList" [value]="units.name" >{{units.name}}</option>
                    </select>
                  </div>
                </form>   -->
            <!-- <div class="form-check form-switch d-flex gap-3 p-0 align-items-center">
                 <label class="text-1" for="aa">{{"ITEMS.Advance unit calculation" | translate}}</label>
                 <input class="form-check-input input-switch m-0" type="checkbox" (click)="show()">
               </div> -->
            <!-- </div> -->

            <!-- </div>  -->
          </div>
        </mat-tab>
        <mat-tab label="{{ 'ITEMS.ADetail' | translate }}"
          [disabled]="!userBasicInfoForm.get('name').value || !userBasicInfoForm.get('categoryId').value">


          <div class="pt-3 ps-3" style="overflow-x: hidden;height: 45vh;">
            <div class="d-flex flex-column align-items-center mt-3 gap-3">
              <form [formGroup]="itemPriceForm" class="col-12">

                <div class="row d-flex gap-2 resp-itemPrice">
                  <div class="flex col-3 gap-2">
                    <span class="text-3 text-nowrap resp-priceLabel"> {{"ITEMS.Item price" | translate}}</span>
                    <span matTooltipClass="helpText-tooltip" matTooltip="{{'ITEMS.You can set up to four different prices for an item. Use Price A as the regular price and Price D as the lowest.' | translate}}" matTooltipPosition="right">
                      <app-custom-icon  icon="view"></app-custom-icon>
                    </span>
                  </div>
                  <!-- Assuming this code is within an Angular component's template -->
                  <div class="d-block col-4 p-0 resp-item">
                    <input matInput class="form-control p-2 col-12" formControlName="pricesA" type="text"
                      (blur)="onBlur($event.target.value, 'priceA')" placeholder="Item Price A" style="max-width: 94%;"
                      [ngClass]="{ 'is-invalid': itemPriceSubmit && itemPrice.pricesA.errors, 'is-valid': itemPriceSubmit && itemPrice.pricesA.valid }"
                      required>
                    <div *ngIf="itemPrice.pricesA.errors" class="invalid-feedback">
                      <div *ngIf="itemPrice.pricesA.errors?.required">{{ "ITEMS.Item Price A is required" | translate }}
                      </div>
                      <div *ngIf="itemPrice.pricesA.errors?.min">{{"ITEMS.Item Price should be grater than minimun price" | translate }}</div>
                    </div>
                  </div>
                  <div class="d-block col-4 ms-3 p-0 resp-item">
                    <input matInput class="form-control p-2 col-12" formControlName="pricesB"
                      (blur)="onBlur($event.target.value,'priceB')" type="text" placeholder="Item Price B"
                      style="max-width: 94%;">
                    <div *ngIf=" itemPriceSubmit && itemPrice.pricesB.errors" class="invalid-feedback">
                      <div *ngIf="itemPrice.pricesB.errors.min">{{"ITEMS.Item Price should be grater than minimun price"
                        | translate }}</div>
                    </div>
                  </div>


                </div>
                <div class=" row d-flex gap-2 mt-3 resp-itemPrice resp">
                  <span class="text-3 text-nowrap col-3"></span>
                  <div class="d-block col-4 p-0 resp-item">
                    <input matInput class="form-control p-2 col-12" formControlName="pricesC"
                      (blur)="onBlur($event.target.value,'priceC')" type="text" placeholder="Item Price C"
                      style="max-width: 94%;">
                    <div *ngIf=" itemPriceSubmit && itemPrice.pricesC.errors" class="invalid-feedback">
                      <div *ngIf="itemPrice.pricesC.errors.min">{{"ITEMS.Item Price should be grater than minimun price"
                        | translate }}</div>
                    </div>
                  </div>

                  <div class="d-block col-4 ms-3 p-0 resp-item">
                    <input matInput class="form-control p-2 col-12" formControlName="pricesD"
                      (blur)="onBlur($event.target.value,'priceD')" type="text" placeholder="Item Price D"
                      style="max-width: 94%;">
                    <div *ngIf=" itemPriceSubmit && itemPrice.pricesD.errors" class="invalid-feedback">
                      <div *ngIf="itemPrice.pricesD.errors.min">{{"ITEMS.Item Price should be grater than minimun price"
                        | translate }}</div>
                    </div>
                  </div>

                  <!-- <button class="btn text-white" (click)="addPrice()" style="background: #364f6b;">
                    {{"COMMON.Add" | translate}}</button> -->
                </div>

              </form>

            </div>
            <div class="col-12 d-flex align-items-center mt-3 gap-1 resp-minimumPrice">
              <div  class="flex gap-2 col-3 col-2">
                <div> 
                   <span class="text-3 text-nowrap "> {{"COMMON.Minimum Price" | translate}}<span class="color-6">*</span></span>
                  </div>
                  <span matTooltipClass="helpText-tooltip" matTooltip="{{'ITEMS.The minimum price sets a cap on the lowest price an agent can assign to an item when creating a quote or manually modifying the price, if they have been granted that permission.' | translate}}" matTooltipPosition="right">
                    <app-custom-icon  icon="view"></app-custom-icon>
                  </span>
                </div>
              <div class="col-10 resp-minimun d-flex items-center">
                <div class="col-5 d-block col-4 p-0 resp-minimun-input">
                  <input matInput formControlName="min_price" placeholder="Enter Item Min Price"
                    class="form-control p-2 text-1" (change)="addminPrice($event)"
                    (blur)="onBlur($event.target.value,'Minimum')" [(ngModel)]="itemAddModel.min_price" type="text"
                    style="max-width: 96%;"
                    [ngClass]="{ 'is-invalid': f.min_price.errors ,'is-valid': userBasicInfoSubmit && f.min_price.valid }">
                  <div *ngIf="userBasicInfoForm.get('min_price').errors" class="invalid-feedback">
                    <div *ngIf="userBasicInfoForm.get('min_price').errors?.min"> {{"Minimum Price Cannot be Greater than
                      PriceA" | translate}}</div>
                  </div>
                </div>
                <!-- <div class="col-5 form-check d-flex gap-3 pe-1 align-items-center resp-tax">
                  <label class="text-3" for="ww"> {{"ITEMS.TAX_Exempt" | translate}}</label>
                  <input class="form-check-input m-0" [checked]="itemAddModel.advance_unit_cal == '1'"
                    (change)="advUnitCal($event)" type="checkbox">
                </div> -->
              </div>
            </div>
            <!-- <div class="d-flex align-items-center mt-3 gap-1 resp-currency">
              <span class="text-3 text-nowrap col-3"> {{"COMMON.Currency" | translate}}</span>
              <mat-form-field class="form-select" appearance="outline" class="pe-3" fxFlex="100" fxFlex.gt-xs="100">
                <mat-select name="currency_id" formControlName="currency_id">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="userSearch" (keyup)="_autoCompleteSearchControlForCurrency()"
                      [searching]="isUserSearching" [placeholderLabel]="'search'|translate"
                      [noEntriesFoundLabel]="'Result not found'|translate">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let currency of filteredCurrency" [value]="currency.id"
                    (onSelectionChange)="onSelectCurrency(currency.id,currency.currency_symbol);">
                    {{currency.currency_code}} - {{currency.currency_symbol}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          


            </div> -->
            <div class="d-flex flex-column align-items-center mt-3">
              <form [formGroup]="itemDiscountForm" class="col-12">
                <div class="d-flex align-items-center gap-1 resp-discount">
                  <span class="text-3 text-wrap col-3"> {{"ITEMS.Available_Discounts" |
                    translate}}</span>

                  <div class="d-flex gap-3 col-9 pe-3">

                    <input [placeholder]="'QUOTE_TEMPLATE.Enter Discounts' | translate"  min="0" max="100" formControlName="discounts" class="form-control p-2 "
                      type="text" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur"
                      [ngClass]="{ 'is-invalid': itemDiscountSubmit && itemDiscount.discounts.errors ,'is-valid': itemDiscountSubmit && itemDiscount.discounts.valid }">

                    <!-- <input matInput formControlName="discounts"  numeric decimals="2" [percentageCheck]="true"  class="form-control p-2" type="text"
                       [placeholder]="'QUOTE_TEMPLATE.Enter Discounts' | translate"
                        [ngClass]="{ 'is-invalid': itemDiscountSubmit && itemDiscount.discounts.errors ,'is-valid': itemDiscountSubmit && itemDiscount.discounts.valid }"> -->
                    <!-- <div *ngIf=" itemDiscountSubmit && itemDiscount.discounts.errors" class="invalid-feedback">
                        <div *ngIf="itemDiscount.discounts.errors"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                          translate}}</div>
                      </div> -->

                    <button class="btn btn-p-2 text-white col-4" style="margin: 4px 0px;" (click)="addDiscounts()">
                      {{"COMMON.Add" |
                      translate}}</button>
                  </div>
                </div>
              </form>
              <!-- <div class="d-flex gap-2 flex-wrap col-12 align-items-center ms-5">
                  <p class="text-1 p-1 bg-celeste-2" *ngFor="let addDiscountsList of addDiscountsArr;let i = index">
                    {{addDiscountsList.discounts}}%<span class="fs-4 ms-1 align-bottom"
                      (click)="removeItem(i,'discountRemove')">✕</span></p>
                </div>      -->
            </div>
            <div class="col-12 d-flex gap-3 pe-3">
              <div class="col-3"></div>
              <div class="col-9 pe-3">
                <mat-chip-list>
                  <ng-container *ngFor="let addDiscountsList of addDiscountsArr; let i = index">
                    <ng-container *ngIf="addDiscountsList.discounts !== undefined && addDiscountsList.discounts !== ''">
                      <mat-chip (removed)="removeItem(i,'discountRemove')">
                        {{ addDiscountsList.discounts }}%
                        <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                      </mat-chip>
                    </ng-container>
                  </ng-container>
                </mat-chip-list>
              </div>


            </div>



            <div class="d-flex flex-column align-items-center mt-3">
              <form [formGroup]="itemPriceTaxForm" class="col-12">
                <div class="d-flex gap-1 align-items-center resp-applicable">
                  <span class="text-3 text-nowrap col-3 resp-applicable-1"> {{"COMMON.Applicable taxes" |
                    translate}}</span>
                  <div class="col-8 me-1 resp-applicable-2">



                    <mat-select id="salesTax" class="form-select-mat form-select text-1 " formControlName="taxData"
                      (selectionChange)="onOptionSelected($event)">
                      <mat-option class="text-1" value="" selected>{{ "QUOTE_TEMPLATE.Select tax" |translate}}</mat-option>

                      <mat-option id="option_num" class="text-1 "
                      *ngFor="let taxList of taxListArr;let i = index"
                         [value]="{customValueId:taxList.id,name:taxList.name,complete:taxList}">
                    <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                      <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown"
                        style="background: none; border:none;" aria-expanded="false"
                        (click)="openBulkActionPopup($event,i)">

                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                          <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                        </svg>
                      </button>
                      {{ taxList.name }} {{ taxList?.taxe_value ? ('(' + taxList?.taxe_value + '%)') : '' }}
                      <div class="shadow-sm dropdown-menu bg-white dropdown-1 flex-column gap-3 show"
                        aria-labelledby="sideMenuDropDown" style="top: 58%; width: 10%;
                      left: 14%;" *ngIf="openBulkAction && currentIndex === i">
                        <div class="px-2 flex flex-col gap-1">
                          <div class="flex items-center gap-3" (click)="removeDropdownValues(i,taxList?.id,'tax')">
                            <mat-icon class="vertical-align-baseline m-0">
                              <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                src="assets/img/home/delete.svg">
                            </mat-icon> {{"COMMON.Delete" | translate}}

                          </div>
                          <div class="flex items-center gap-3 px-1" (click)="editDropdownValues(taxList)">
                            <i class="fa fa-edit edit-icon" style="color: #03C0FC;"></i>
                            {{"COMMON.Edit" | translate}}
                          </div>

                        </div>

                      </div>
                    </div>
                  </mat-option>



                      <!-- <mat-option class="text-1 d-flex align-items-center"
                         *ngFor="let taxList of taxListArr;let i = index"
                         [value]="{customValueId:taxList.id,name:taxList.name,complete:taxList}">

                         <mat-icon class="vertical-align-baseline">
                          <img style="width: 25px;height: 20;" role="button" src="assets/img/home/delete.svg"
                            (click)="removeDropdownValues(i,taxList?.id,'tax')">
                         </mat-icon>
                         <i class="fa fa-edit edit-icon mr-2" style="color: #03C0FC;"
                           (click)="editDropdownValues(taxList)"></i>
                         {{ taxList.name }} {{ taxList?.taxe_value ? ('(' + taxList?.taxe_value + '%)') : '' }}

                       </mat-option> -->



                      <mat-option class="text-1" (click)="addDropdownValues('addFormOfContact','items','taxes')">
                        <span class="d-flex  align-items-baseline">
                          <mat-icon>
                            <svg width="30" height="25" viewBox="0 0 55 55" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                              <path
                                d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                                fill="white" />
                            </svg>
                          </mat-icon>
                          <span>
                            {{"COMMON.Add" | translate}}
                          </span>
                        </span>
                      </mat-option>
                      <mat-error *ngIf="itemPriceTaxForm.invalid"></mat-error>
                    </mat-select>
                    <!-- <mat-error *ngIf=" itemPriceTaxForm && itemPriceTax.taxe_value.errors" class="invalid-feedback">{{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                        translate}}</mat-error> -->
                  </div>
                  <div *ngIf=" saleTax" class="invalid-feedback">
                    <div *ngIf="saleTax"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                      translate}}</div>
                  </div>
                  <div class=" ml-3">
                    <!-- <mat-form-field  appearance="outline"  id="discount">
                      <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let addItemPriceTaxList of addItemPriceTaxArr;let i = index" (removed)="removeItem(i,'itemPriceRemove')">
                          {{addItemPriceTaxList.taxData.name || 'Sales tax'}} {{addItemPriceTaxList.taxe_value}}%
                            <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                        </mat-chip>
                        <input numeric decimals="2" placeholder="Enter Tax Value" formControlName="taxe_value" type="text"
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                      [ngClass]="{ 'is-invalid': itemPriceTaxSubmit && itemPriceTax.taxe_value.errors ,'is-valid': itemPriceTaxSubmit && itemPriceTax.taxe_value.valid }">
                      </mat-chip-list>
                      <mat-error *ngIf=" itemPriceTaxSubmit && itemPriceTax.taxe_value.errors" class="invalid-feedback">{{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                        translate}}</mat-error>
                    </mat-form-field> -->


                    <!-- <input matInput class="form-control p-2 text-1" [disabled]="!itemPriceTaxForm && !itemPriceTaxForm.get('taxData').value || !enableInput" numeric decimals="2" placeholder='{{"ITEMS.Enter Tax Percentage" | translate}}'
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                      formControlName="taxe_value" type="text"
                      [ngClass]="{ 'is-invalid': itemPriceTaxSubmit && itemPriceTax.taxe_value.errors ,'is-valid': itemPriceTaxSubmit && itemPriceTax.taxe_value.valid }"> -->
                  </div>

                  <!-- <div *ngIf=" itemPriceTaxSubmit && itemPriceTax.taxe_value.errors" class="invalid-feedback">
                    <div *ngIf="itemPriceTax.taxe_value.errors"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                      translate}}</div>
                  </div> -->
                  <!-- <div class="ml-3">
                      <button class="btn btn-p-2 text-white" [ngClass]="languageName == 'Spanish'?'languageClass':'btn btn-p-2 text-white'" (click)="addItemPriceTax()">{{"COMMON.Add"
                        |
                      translate}}</button>
                   </div>   -->
                </div>
              </form>
              <!-- started commented on the base of https://app.meetvolley.com/products/7265/notes/125763 -->

              <!-- <button class="btn text-white" data-bs-toggle="modal" data-bs-target="#modal-add-new-tax"
                style="background: #364f6b;">{{"ITEMS.ADDTAX" |
                translate}}</button> -->

              <!-- ended commented on the base of https://app.meetvolley.com/products/7265/notes/125763 -->

              <div class="d-flex col-12 align-items-center">
                <!-- <mat-chip-listbox aria-label="Fish selection">
                  <mat-chip  *ngFor="let addItemPriceTaxList of addItemPriceTaxArr;let i = index" (removed)="removeItem(i,'itemPriceRemove')">
                    {{addItemPriceTaxList.taxData.name || 'Sales tax'}} {{addItemPriceTaxList.taxe_value}}%
                      <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                  </mat-chip>
               </mat-chip-listbox> -->
                <div class="col-3"></div>
                <div class="col-9 mt-3" style="padding-left: 9px;">
                  <mat-chip-list>
                    <mat-chip *ngFor="let addItemPriceTaxList of addItemPriceTaxArr;let i = index"
                      (removed)="removeItem(i,'itemPriceRemove')">
                      {{addItemPriceTaxList?.taxData?.name?addItemPriceTaxList?.taxData?.name:addItemPriceTaxList?.name
                      || ''}} {{addItemPriceTaxList?.taxe_value}}%
                      <mat-icon matChipRemove class="cursor-pointer">cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
                <!-- <span class="text-1 p-1 bg-celeste-2"
                  *ngFor="let addItemPriceTaxList of addItemPriceTaxArr;let i = index">{{addItemPriceTaxList.taxData.name}}
                  {{addItemPriceTaxList.taxe_value}}%<span class="fs-4 ms-1 align-bottom" role="button"
                    (click)="removeItem(i,'itemPriceRemove')">✕</span></span> -->
              </div>
            </div>
            <div class="col-12 d-flex align-items-center resp-sale-commission mt-3 gap-1 col-12">
              <span class="col-2 text-3 text-nowrap col-3">{{"COMMON.Sales Commission" | translate}}</span>
              <div class="col-10 resp-sales-commission d-flex items-center flex-row">
                <div class="col-5 resp-sales" style="margin-right: 1.25rem !important;width: 50%;">
                  <input matInput formControlName="sales_commission" numeric
                    [decimals]="itemAddModel.sales_comm_type == 2? 2:10" [percentageCheck]="true"
                    [placeholder]="'COMMON.Sales Commission' | translate" class="form-control p-2 text-1"
                    [(ngModel)]="itemAddModel.sales_commission" type="text">
                </div>
                <div class=" col-5 btn-group border-gris ml-4 sales-percent" style="width: 30%">
                  <input type="radio" class="btn-check text-1" [checked]="itemAddModel.sales_comm_type == '1'"
                    name="options" id="option1" autocomplete="off">
                  <label class="btn btn-comision text-1" (click)="salesCommType('1')" for="option1">$</label>
                  <input type="radio" class="btn-check text-1" [checked]="itemAddModel.sales_comm_type == '2'"
                    name="options" id="option2" autocomplete="off">
                  <label class="btn btn-comision text-1" (click)="salesCommType('2')" for="option2">%</label>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center mt-3 gap-1 col-12 resp-fix">
              <span class="col-2 text-3 text-nowrap col-3">{{"COMMON.Fix Cost Per Unit" | translate}}<span
                  class="color-6">
                  *</span></span>
              <div class="col-10 resp-fixCost d-flex items-center">
                <div class="col-4 resp-fixC-1">
                  <input matInput formControlName="fix_cost_per_unit" numeric decimals="0"
                    class="form-control p-2 text-1" style="max-width: 89%;" placeholder="Enter Cost Unit"
                    [(ngModel)]="itemAddModel.fix_cost_per_unit" type="text"
                    (blur)="onBlur($event.target.value,'fix_cost_per_unit')"
                    [ngClass]="{ 'is-invalid': userBasicInfoSubmit && f.fix_cost_per_unit.errors ,'is-valid': userBasicInfoSubmit && f.fix_cost_per_unit.valid }">
                </div>

                <div class="col-6 resp-fixC-2 d-flex gap-3 ms-3 ">

                  <span class="text-3 " [ngClass]="languageName == 'Spanish'?'languageClass':'text-3'">{{"COMMON.Profit margin" | translate}}: <span class="text-2">{{profitMargn | number}} %</span></span>


                  <span class="text-3">{{"ITEMS.Profit" | translate}}: <span class="text-2">{{profit | number}} 
                    {{ (selectedCurrency?.currency_symbol || selectedCurrency || userCurrency?.currency_symbol) }}</span></span>

                </div>

              </div>
            </div>
            <div *ngIf="f.fix_cost_per_unit.errors && currentTabIndex == 2"
              class="invalid-feedbacknew d-flex justify-content-center" style="width: 78%;">
              <div *ngIf="f.fix_cost_per_unit.errors.required  && currentTabIndex == 2">
                {{"SYSTEM_USER_ERR.ERRORFIXCOSTPERUNITNOTNULL" |
                translate}}</div>
            </div>



            <div class="d-flex align-items-center mt-3 gap-1 col-12" *ngIf="isDeactivated">
              <span class="text-3 text-nowrap col-3">{{ "QUOTE_TEMPLATE.Deactivate Item"  |translate}}</span>
              <div class="col-3">
                <mat-slide-toggle [(ngModel)]="isChecked" formControlName="deactivate_item"></mat-slide-toggle>
              </div>
            </div>



          </div>


        </mat-tab>
      </mat-tab-group>



      <div>

        <div class="modal fade" id="modal-warehouse" data-bs-backdrop="false" tabindex="-1"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 600px; ">
            <div class="modal-content " style="padding: 20px;">
              <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
                <form [formGroup]="itemwarehouseListForm">
                  <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Warehouse" | translate}}</h2>
                  <i type="button" #closeWarehouse data-bs-dismiss="modal" aria-label="Close"
                    class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                  <div class="d-flex flex-column gap-3">
                    <div class="d-flex flex-row align-items-center  gap-3">
                      <p class="text-1 text-nowrap" style="font-weight: 500;">{{"ITEMS.Warehouse name" | translate}}</p>
                      <div class="d-flex flex-column align-items-center">
                        <input matInput type="text" class="form-control p-2 text-1" style="width: 230px;"
                          placeholder="Type warehouse name" formControlName="item_warehouses"
                          [ngClass]="{ 'is-invalid': itemWarehouseListSubmit && itemWarehouses.item_warehouses.errors ,'is-valid': itemWarehouseListSubmit && itemWarehouses.item_warehouses.valid }">
                        <mat-error *ngIf=" itemWarehouseListSubmit && itemWarehouses.item_warehouses.errors">
                          {{"SYSTEM_USER_ERR.ERRORWAREHOUSEITEMCENOTNULL" |
                          translate}}</mat-error>

                      </div>



                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-between gap-3">
                      <p class="text-1 text-nowrap" style="font-weight: 500;">{{"COMMON.Items" | translate}}:</p>
                      <div class="d-flex flex-row align-items-center">
                        <div class="d-flex function-cont border-bottom pb-1" style="width: 230px;">
                          <button id="btn_menos" (click)="decrement()" class="btn btn-cont cont-1">─</button>
                          <input matInput id="cont" type="number" min="0" formControlName="items"
                            class="input-num text-1 border-0" style="text-align: center;width: 160px;">
                          <button id="btn_mas" (click)="increment()" class="btn btn-cont cont-2">+</button>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="d-flex justify-content-center mt-4 gap-3">
                    <!-- <button data-bs-toggle="modal" data-bs-dismiss="modal" class="btn btn-danger">Delete item</button> -->
                    <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-2">{{"COMMON.Cancel"
                      | translate}}</button>
                    <button aria-label="Close" (click)="addWarehouseList()"
                      class="btn btn-p-2 standard-btn">{{"ITEMS.Save
                      Warehouse" | translate}}</button>
                  </div>
                </form>



              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal fade" id="modal-warehouse-Add" data-bs-backdrop="false" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 600px; ">
              <div class="modal-content cnt-dashboard border shadow-sm border-r2">
                <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
                  <form [formGroup]="newWarehouseForm">
                    <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Warehouse" | translate}}</h2>
                    <i type="button" #closeAddWarehouse data-bs-dismiss="modal" aria-label="Close"
                      class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3" style="font-size: 34px;"></i>
                    <div class="d-flex align-items-center gap-3">
                      <p class="text-1 text-nowrap"> {{"COMMON.NAME" | translate}}<span class="color-6">*</span></p>
                      <input type="text" formControlName="name" placeholder="Type warehouse name "
                        class="form-control border-bottom border-0"
                        [ngClass]="{ 'is-invalid': itemWarehouseSubmit && newWarehouses.name.errors ,'is-valid': itemWarehouseSubmit && newWarehouses.name.valid }">
                      <div *ngIf=" itemWarehouseSubmit && newWarehouses.name.errors" class="invalid-feedback">
                        <div *ngIf="newWarehouses.name.errors.required">
                          {{"SYSTEM_USER_ERR.ERRORWAREHOUSENAMECENOTNULL"
                          |
                          translate}}</div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mt-4 gap-2">
                      <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-2">Cancel</button>
                      <button aria-label=" Close" class="btn btn-1ms-2 btn-2 " style="background: #1cadff; color: #ffffff;" (click)="addNewWarehouse()">{{"COMMON.Add"
                        | translate}}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> -->

        <div class="modal fade" id="modal-add-new-tax" data-bs-backdrop="false" tabindex="-1"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style="max-width: 600px; ">
            <div class="modal-content cnt-dashboard border shadow-sm border-r2">
              <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
                <form [formGroup]="addNewTexListForm">
                  <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.TAX" | translate}}</h2>
                  <i type="button" data-bs-dismiss="modal" aria-label="Close"
                    class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                  <div class="d-flex align-items-center gap-3">
                    <p class="text-1 text-nowrap"> {{"COMMON.NAME" | translate}}</p>
                    <input type="text" placeholder="Type Tax name" formControlName="name"
                      class="form-control border-bottom border-0">
                  </div>
                  <div class="d-flex justify-content-center mt-5 gap-2">
                    <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-2">Cancel</button>
                    <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-1ms-2 btn-2 "
                      (click)="addNewTex()">{{"COMMON.Add" | translate}}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- started commented on the base of voley issue # https://app.meetvolley.com/products/7265/notes/125770 -->

        <!-- <div class="tab-pane fade pt-3" id="nav-Additional-details-edit" role="tabpanel"
            aria-labelledby="nav-Additional-details-tab">
            <div class="d-flex align-items-center mt-5 gap-3">
              <span class="text-3 text-nowrap">{{"ITEMS.Variant label" | translate}}</span>
              <div class="d-flex gap-1">
                <input formControlName="variantName" class="form-control border-0 border-bottom text-1"
                  [(ngModel)]="itemAddModel.variantName" type="text" placeholder="Enter Variant label"
                  style="width: 200px;">
              </div>
            </div>
            <div class="d-flex align-items-center gap-3 my-3">
              <form [formGroup]="itemValueForm">
                <div class="d-flex gap-2">
                  <span class=" text-3">{{"ITEMS.Value" | translate}}</span>
                  <input class="form-control border-0 border-bottom text-1" placeholder="Enter Value"
                    formControlName="value" style="width: 150px;"
                    [ngClass]="{ 'is-invalid': itemValueSubmit && itemValue.value.errors ,'is-valid': itemValueSubmit && itemValue.value.valid }">
                  <div *ngIf=" itemValueSubmit && itemValue.value.errors" class="invalid-feedback">
                    <div *ngIf="itemValue.value.errors"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                      translate}}</div>
                  </div>
                  <span class="text-3">{{"ITEMS.Units" | translate}}</span>
                  <select class="form-select text-1 border-0 border-bottom" style="width: 150px;"
                    formControlName="unit_id"
                    [ngClass]="{ 'is-invalid': itemValueSubmit && itemValue.unit_id.errors ,'is-valid': itemValueSubmit && itemValue.unit_id.valid }">
                    <option class="text-1" *ngFor="let unitList of unitListArr"
                      [ngValue]={unit_id:unitList.id,unit_name:unitList.name}>
                      {{unitList.name}}</option>
                  </select>
                  <div *ngIf=" itemValueSubmit && itemValue.unit_id.errors" class="invalid-feedback">
                    <div *ngIf="itemValue.unit_id.errors"> {{"SYSTEM_USER_ERR.ERRORMINPRICENOTNULL" |
                      translate}}</div>
                  </div>
                  <button class="btn btn-p-2 text-white" (click)="addItemAdditionaldetails()"
                    >{{"COMMON.Add" |
                    translate}}</button>
                  <button class="btn text-white" data-bs-toggle="modal" data-bs-target="#modal-add-new-unit"
                    style="background: #364f6b;">{{"ITEMS.ADD NEW UNIT" |
                    translate}}</button>
                </div>
              </form>
            </div>
            <div class="d-flex gap-2 align-items-center mt-3 mb-4 color-1 ">
              <label
                *ngFor="let addItemAdditionalDetailsList of addItemAdditionalDetailsArr;let i = index">{{addItemAdditionalDetailsList.value}}
                {{addItemAdditionalDetailsList.unit_name?addItemAdditionalDetailsList.unit_name:''}}<span
                  class="fs-4 ms-1 align-bottom" role="button" (click)="removeItem(i,'removeDetails')">✕</span>
              </label>
            </div>
            <div class="d-flex">
              <button class="btn text-white" style="background: #364f6b;"
                (click)="itemAdditionaldetailsAdd()">{{"ITEMS.SAVE" |
                translate}}</button>
            </div>
            <span *ngIf="itemAddModel.item_additional_details" class="text-3">{{"ITEMS.Available values" |
              translate}}</span>
            <div class="mt-2 gap-2" *ngFor="let itemAdditionalDetailsList of itemAdditionalDetailsArr;let i = index">
              <label class="color-1  p-1 bg-celeste-2" (click)="selecItemAdditionalDetailsList(i)"
                role="button">{{itemAdditionalDetailsList.variantName}}</label>
            </div>
          </div> -->
        <!-- <div class="modal fade" id="modal-add-new-unit" data-bs-backdrop="false" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 600px; ">
              <div class="modal-content cnt-dashboard border shadow-sm border-r2">
                <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
                  <form [formGroup]="addNewUnitForm">
                    <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.UNIT" | translate}}</h2>
                    <i type="button" #closeAddUnithouse data-bs-dismiss="modal" aria-label="Close"
                      class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                    <div class="d-flex align-items-center gap-3">
                      <p class="text-1 text-nowrap"> {{"COMMON.NAME" | translate}}</p>
                      <input type="text" placeholder="Type Unit name" class="form-control border-bottom border-0"
                        formControlName="name"
                        [ngClass]="{ 'is-invalid': itemUnitSubmit && newUnit.name.errors ,'is-valid': itemUnitSubmit && newUnit.name.valid }">
                      <div *ngIf=" itemUnitSubmit && newUnit.name.errors" class="invalid-feedback">
                        <div *ngIf="newUnit.name.errors.required">
                          {{"SYSTEM_USER_ERR.ERRORUNITNAMECENOTNULL" |
                          translate}}</div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mt-5 gap-2">
                      <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-2">Cancel</button>
                      <button aria-label="Close" class="btn btn-1ms-2 btn-2 " (click)="addNewUnit()">{{"COMMON.Add" |
                        translate}}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> -->

        <!-- ended commented on the base of voley issue # https://app.meetvolley.com/products/7265/notes/125770 -->

        <!-- started commented on the base of voley issue # https://app.meetvolley.com/products/7265/notes/125771 -->


        <!-- <div class="tab-pane fade pt-3" id="nav-CFDI-edit" role="tabpanel" aria-labelledby="nav-CFDI-tab">
            <div class="d-flex gap-3 mt-3">
              <span class="text-3 mt-2 mb-auto">{{"ITEMS.SAT Product" | translate}}</span>
              <div class="d-flex flex-column gap-3">
                <select class="form-select border-0 border-bottom" style="width: 200px;">
                  <option>Select</option>
                  <option>Select</option>
                  <option>Select</option>
                </select>
                <select class="form-select border-0 border-bottom" style="width: 200px;">
                  <option>Select</option>
                  <option>Select</option>
                  <option>Select</option>
                </select>
                <select class="form-select border-0 border-bottom" style="width: 200px;">
                  <option>Select</option>
                  <option>Select</option>
                  <option>Select</option>
                </select>
              </div>
              <i class="fas fa-filter text-1 mt-2 mb-auto" role="button"></i>
            </div>
            <div class="d-flex gap-3 align-items-center my-4">
              <span class="text-3">{{"ITEMS.SAT Unit" | translate}}</span>
              <select class="form-select border-0 border-bottom" style="width: 200px;">
                <option>Select</option>
                <option>Select</option>
                <option>Select</option>
              </select>
            </div>
            <div class="d-flex align-items-center mb-4 gap-3">
              <span class="text-3 text-nowrap">{{"ITEMS.Tax Type" | translate}}</span>
              <div class="d-flex gap-1">
                <select class="form-select border-0 border-bottom" style="width: 200px;">
                  <option>Select</option>
                  <option>Select</option>
                  <option>Select</option>
                </select>
                <button class="btn btn-p-2 text-white" >{{"COMMON.Add" |
                  translate}}</button>
              </div>
            </div>
            <div class="d-flex gap-2 align-items-center">
              <span class="color-1 p-1 bg-celeste-2">IVA 5%<span class="fs-4 ms-1 align-bottom"
                  role="button">✕</span></span>
            </div>
          </div> -->

        <!-- ended commented on the base of voley issue # https://app.meetvolley.com/products/7265/notes/125771 -->

        <div class="d-flex justify-content-center mt-4 gap-3">
          <button (click)="onDismiss()" class="btn btn-skyblue-s-1 standard-btn">{{"COMMON.Cancel" |
            translate}}</button>
          <button class="btn btn-p-2 standard-btn" data-bs-toggle="modal" data-bs-target="#exampleModal4_3"
            data-bs-dismiss="modal" (click)="addItem(custom?.customFielsDataFrom)" *ngIf="!itemsEditButton"
            [disabled]="itemPrice.pricesA.errors?.min || userBasicInfoForm.get('min_price').errors?.min || userBasicInfoForm.get('name').errors || userBasicInfoForm.get('categoryId').errors">
            {{ (currentTabIndex == 2 ? 'COMMON.Save changes' : 'COMMON.Next') | translate }}</button>
          <button class="btn btn-p-2 standard-btn" data-bs-toggle="modal" data-bs-target="#exampleModal4_3"
            data-bs-dismiss="modal" *ngIf="itemsEditButton && inputData.type !== 'quote'"
            (click)="updateItem(custom?.customFielsDataFrom)"
            [disabled]="itemPrice.pricesA.errors?.min || userBasicInfoForm.get('min_price').errors?.min || userBasicInfoForm.get('name').errors || userBasicInfoForm.get('categoryId').errors">{{"COMMON.Update changes" | translate}}</button>

          <button class="btn btn-p-2 standard-btn" data-bs-toggle="modal" data-bs-target="#exampleModal4_3"
            data-bs-dismiss="modal" *ngIf="inputData.type == 'quote'" (click)="updateItem('')"
            [disabled]="itemPrice.pricesA.errors?.min">{{"COMMON.UPDATE" | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modal-Move" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 700px; ">
      <div class="modal-content cnt-dashboard" style="border-radius: 20px;">
        <div class="modal-body bg-gris-1 p-4 py-5 d-flex align-items-center flex-column">
          <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Move Item" | translate}}</h2>
          <i type="button" class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
            data-bs-dismiss="modal" aria-label="Close"></i>
          <div class="w-75 d-flex align-items-center gap-3">
            <p class="text-1">{{"COMMON.Category" | translate}}</p>
            <input matInput class="form-control p-2 text-center" type="text"
              placeholder="Enter or Select Category or Subcategory">
          </div>
          <p class="text-3 text-start w-100 mb-3 mt-5">{{"ITEMS.Categories" | translate}}:</p>
          <div class="row row-cols-md-4 row-cols-2 g-3">
            <div class="col justify-content-center d-flex">
              <div class="text-center" role="button" data-bs-toggle="modal" data-bs-dismiss="modal"
                data-bs-target="#modal-item-2">
                <div class="position-relative rounded-3 border-gris p-2 d-flex  h-75">
                  <img class="border-gris position-absolute ms-5 rounded-3" style="width: 80px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute ms-4 rounded-3" style="width: 90px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute rounded-3" style="width: 100px;"
                    src="assets/img/home/car.png">
                </div>
                <p class="mt-3">Jeep</p>
              </div>
            </div>
            <div class="col justify-content-center d-flex">
              <div class="text-center">
                <div class="position-relative rounded-3 border-gris p-2 d-flex  h-75">
                  <img class="border-gris position-absolute ms-5 rounded-3" style="width: 80px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute ms-4 rounded-3" style="width: 90px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute rounded-3" style="width: 100px;"
                    src="assets/img/home/car.png">
                </div>
                <p class="mt-3">Jeep</p>
              </div>
            </div>
            <div class="col justify-content-center d-flex">
              <div class="text-center">
                <div class="position-relative rounded-3 border-gris p-2 d-flex  h-75">
                  <img class="border-gris position-absolute ms-5 rounded-3" style="width: 80px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute ms-4 rounded-3" style="width: 90px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute rounded-3" style="width: 100px;"
                    src="assets/img/home/car.png">
                </div>
                <p class="mt-3">Cars</p>
              </div>
            </div>
            <div class="col justify-content-center d-flex">
              <div class="text-center">
                <div class="position-relative rounded-3 border-gris p-2 d-flex  h-75">
                  <img class="border-gris position-absolute ms-5 rounded-3" style="width: 80px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute ms-4 rounded-3" style="width: 90px;"
                    src="assets/img/home/car.png">
                  <img class="border-gris position-absolute rounded-3" style="width: 100px;"
                    src="assets/img/home/car.png">
                </div>
                <p class="mt-3">Cars</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5 gap-3">
            <button (click)="onDismiss()" class="btn btn-2">{{"COMMON.Cancel" |
              translate}}</button>
            <button class="btn btn-1" data-bs-toggle="modal" data-bs-dismiss="modal">{{"ITEMS.Select Category"
              | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modal-Delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px; ">
      <div class="modal-content cnt-dashboard" style="border-radius: 20px;">
        <div class="modal-body bg-gris-1 py-5 d-flex align-items-center flex-column">
          <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Delete Item" | translate}}</h2>
          <i type="button" class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
            data-bs-dismiss="modal" aria-label="Close"></i>
          <div class="w-75 d-flex align-items-center gap-3">
            <p class="text-1 col-4">{{"ITEMS.Type item name" | translate}}</p>
            <input matInput class="form-control p-2" value="iteamName" type="text" placeholder="Type item name">
          </div>
          <div class="d-flex justify-content-center mt-5 gap-3">
            <button (click)="onDismiss()" class="btn btn-2">{{"COMMON.Cancel" |
              translate}}</button>
            <button class="btn border-gris text-white btn-danger" data-bs-toggle="modal"
              data-bs-dismiss="modal">{{"ITEMS.Delete Item" | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>