import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { AutoLogoutDialogComponent } from './Dialogs/auto-logout-dialog/auto-logout-dialog.component';
import { CrudService } from './services/crud.service';
import { Subscription } from 'rxjs';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  title = 'front-osmos';

  defaultMerchantCountry: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  viewData = false;
  url: any
  addEditDialogRef: any;
  subscriptions: Subscription[] = [];
  constructor(private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private idle: Idle,
    private _gService: CrudService,
    private keepalive: Keepalive,
    public dialog: MatDialog) {
    // const userData = localStorage.getItem('loginUserValue') && JSON.parse(localStorage.getItem('loginUserValue'));
    // translateService.use(userData?.languages?.locale || 'en');
  }
  ngOnInit() {
    this.spinner.show();
    let strings = window.location.href.split(window.location.host);
    this.url = strings[strings.length - 1];
    if (this.url === '/dash/mail-box/inbox/1' && this.spinner.show()) {
      this.title = 'Loading Your emails...'
      this.viewData = true
    } else {
      !this.viewData
    }

    if(localStorage.getItem('loginUserValue')) {
        this.idleApp()
    }
  }

  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Dark-Theme //
  isDarkTheme = false;
  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
  }

  idleApp() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([new EventTargetInterruptSource(document.body, 'click')]);

    this.idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    this.idle.onTimeout.subscribe(() => {
      this.dialog.closeAll();
      this.logout();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.openDialog();
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => console.log('You will time out in ' + countdown + ' seconds!'));

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  openDialog(): void {
    this.idle.clearInterrupts();
    this.addEditDialogRef = this.dialog.open(
      AutoLogoutDialogComponent,
      { disableClose: true, width: '550px', height: '250px',panelClass: 'my-dialog-panel' }
    );
    this.addEditDialogRef.afterClosed().subscribe((action: any) => {
      if (action) {
        if (action === 'connected') {
          this.idle.setInterrupts([new EventTargetInterruptSource(document.body, 'click')]);
          this.reset();
        }
        if (action === 'logout') {
          this.idle.setInterrupts([new EventTargetInterruptSource(document.body, 'click')]);
          this.logout();
        }
      }
    });
  }

  reset() {
    this.idle.watch();
  }

  logout() {
    const payload = {
      id: localStorage.getItem('userId'),
      email: localStorage.getItem('email'),
      merchant_id: localStorage.getItem('merchant_id'),
      device_id: localStorage.getItem('deviceLoginId')
    }
    this._removeSession(payload)
    localStorage.removeItem('reminderList');
    localStorage.removeItem('merchant_id');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('fname');
    localStorage.removeItem('lname');
    localStorage.removeItem('email');
    localStorage.removeItem('profile_pic');
    localStorage.removeItem('create-session');
    localStorage.removeItem('redirect-merchant');
    localStorage.removeItem('user_merchants');
    localStorage.removeItem('deviceLoginId');
    localStorage.removeItem('loginUserValue');
    localStorage.removeItem('osmos_access_level');
    localStorage.removeItem('permission');
    localStorage.removeItem('role');
    localStorage.removeItem('accessId');
    localStorage.removeItem('country_list');
    this.translateService.use('en');
    window.location.reload();
  }

  private _removeSession(body) {
    const removeSessionSubscription = this._gService.postUserManagement(`merchantuser/removeusersession`, body).subscribe(result => {
    }, error => {
    });
    // this.subscriptions.push(removeSessionSubscription);
  }
}
