import { createAction, props } from '@ngrx/store';

export const ShowAllAction = createAction('[TASK] Show All');
export const ShowAllSuccessAction = createAction('[TASK] Show All Success', props<{ payload: any}>());
export const CreateAction = createAction('[TASK] Create', props<{ payload: any}>());
export const CreateSuccessAction = createAction('[TASK] Create Success', props<{ payload: any}>());
export const CreateFailureAction = createAction('[TASK] Create Failure', props<{ payload: any}>());
export const GetByIdAction = createAction('[TASK] Get by Id', props<{ payload: string}>());
export const GetByIdSuccessAction = createAction('[TASK] Get by Id Success', props<{ payload: any}>());
export const ResetAction = createAction('[TASK] Reset'); 

export const ShowMerchantProfileAction = createAction('[PROFILE] Show All');
export const ShowMerchantProfileSuccessAction = createAction('[PROFILE] Show All', props<{ payload: any}>());
