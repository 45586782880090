<ng-container *ngIf="index === 1 && Inputdata.checkingItems !== 'newImages'">
    <div class="modal-body bg-gris-1 py-3 w-100" style="height: 340px; overflow-x:hidden">
        <h2 class="text-1 fs-4 text-center mb-4">{{"ITEMS.Upload_Documents" | translate}}</h2>
        <div class="d-flex justify-content-center gap-5">
            <div class="text-center d-flex flex-column">
                <div class="variants">
                    <div class='file'>
                        <label for='input-file'>
                            <img src="assets/img/home/laptop 1.png">
                        </label>
                        <input id='input-file' #csvReader name="Upload CSV" type='file'
                            (change)="handleFileInput($event)" accept=".csv" />
                    </div>
                </div>
                <label for="select-upload-3" role="button" class="text-3 mt-3">{{"ITEMS.My Computer" |
                    translate}}</label>
                <a  *ngIf="Inputdata.checkingItems !== 'value'" [href]="downloadcsv" class="mt-4 sampletxt" target="_blank">{{"ITEMS.DownloadCSV" | translate }}</a>
                <a  *ngIf="Inputdata.checkingItems == 'value'" [href]="downloadItemcsv" class="mt-4 sampletxt" target="_blank">{{"ITEMS.DownloadCSV" | translate }}</a>
                <p *ngIf="remaining && remaining.limit != false">{{"ITEMS.You are limited to adding" |
                    translate}} {{remaining.limit}} &nbsp;{{"ITEMS.contacts for this month. If you need to add more contacts, you can purchase an add-on" |
                    translate}} <a href="/dash/profile">here</a></p>
                <p *ngIf="remaining == false" class="text-danger">{{"ITEMS.CONTACTLIMITEXCEEED" |
                    translate}}</p>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="index === 3">
    <div class="modal-body bg-gris-1 py-3 w-100" style=" overflow-x:hidden;overflow-y: scroll;">
        <h2 class="text-1 fs-4 text-center mb-2">{{"ITEMS.Upload CSV File" | translate}}</h2>
        <h4>{{ "ITEMS.Upload CSV File Sub Text" | translate}}</h4>
        <div class="table-design" style="overflow: scroll;max-height: 400px;">
            <table class="table table-bordered">
                <div>
                    <thead>
                        <tr>
                            <!-- Only render the column header if the heading name is not "Images" -->
                            <ng-container *ngFor="let heading of headingdata">
                                <ng-container *ngIf="heading.name !== 'Images'">
                                    <th>{{getTranslatedText ("ITEMS." + heading.name ) || heading.name}}</th>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- Only render the data cell if the heading name is not "Images" -->
                            <ng-container *ngFor="let heading of headingdata " >
                                <ng-container *ngIf="heading.name !== 'Images'" >
                                    <td>
                                        <div class="customtable-changed" *ngFor="let data of heading.valueData">
                                            {{data?.toString()}}
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </tbody>
                </div>
            </table>
        </div>
        <div class="text-3 d-flex gap-3 mt-1">
                        <!-- <p>Records: <b class="fw-normal text-1">{{dataarryCsc.length && 2 >= dataarryCsc.length?dataarryCsc.length:2}}</b></p> -->
                        <p>Records: <b class="fw-normal text-1">{{records}}</b></p>
                        <p>Columns: <b class="fw-normal text-1">{{headingdata.length}}</b></p>
                        <p>File Size: <b class="fw-normal text-1">{{filesize}}</b></p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="index === 2">  
    <div class="modal-body bg-gris-1 py-3 w-100" style="height: 400px; overflow-x:hidden;overflow-y: scroll;">
        <h2 class="text-1 fs-4 text-center mb-3">{{"ITEMS.Upload CSV File" | translate}}</h2>
        <div class="d-flex align-items-center text-break align-items-center gap-3 my-3 px-2"
            *ngFor="let item of headingdata; let i = index;">
            <p class="text-3 col-4">{{getTranslatedText ("ITEMS." + item.name ) || item.name}}
                <span class="color-6" *ngIf="item.star">*</span> :</p>
            <mat-form-field>
                <mat-select [disabled]="item.name =='Id'" [(ngModel)]="item.valueData" placeholder="Select" name="mat-select-{{i}}"
                    (selectionChange)="onOptionsSelected($event, item.options)" [required]="item.star"
                    [multiple]="item.multiple.indexOf(item.name) != -1" placeholder="Select" (click)="onClickChangeOptions($event, item.options)">
                    <mat-option>
                        <ngx-mat-select-search [(ngModel)]="item.searchTxt" placeholderLabel="Search">
                        </ngx-mat-select-search>

                    </mat-option>
                    <mat-option *ngIf="item.multiple.indexOf(item.name) == -1" (click)="noOptionSelection($event, item.options)">Select</mat-option>
                    <mat-option *ngFor="let item2 of allcsvlisting | search:item.searchTxt" [value]="item2.id.trim()"
                        (click)="optionSelection($event, item2.id.trim(), item.multiple.indexOf(item.name) != -1 ? [item.valueData] : item.valueData, item2.name, item.name)" [disabled]="isOptionDisabled(item2.id.trim())">
                        {{item2.name}}  
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
        </div>
    </div>
</ng-container>
<ng-container *ngIf="index === 4">
    <div class="modal-body bg-gris-1 py-3 w-100" style="height: 300px; overflow-x:hidden;overflow-y: scroll;">
        <h2 class="text-1 fs-4 text-center mb-3">{{"ITEMS.Upload CSV File" | translate}}</h2>
        <form [formGroup]="csvform">
            <div class="text-3 pb-4" [ngClass]="{'border-bottom mb-4':emaildata.length != 0}">
                <div class="d-flex gap-3 align-items-center upload_tags">
                    <p style="margin: 0px;">{{"ITEMS.Tags" | translate}}:</p>
                    <div class="w-100">
                        <input class="form-control border-gris"
                            style="text-align: left !important; padding: 4px 6px !important;" formControlName="tag"
                            type="text" placeholder="Enter Tag Name">
                    </div>
                </div>
                <div *ngIf="(sub || f.tag.touched) && f.tag.errors" class="invalid-feedback" style="padding-left: 9%;">
                    <div *ngIf="f.tag.errors.required">Tag is Required</div>
                </div>
            </div>
            <!-- <div class="color-2 d-flex align-items-center gap-3" *ngIf="emaildata.length != 0">
                <p style="margin: 0px;">{{emaildata.length}} emails already exist</p>
                <i class="fas fa-check-circle fs-4"></i>
            </div> -->
            <div class="d-flex align-items-center gap-2 my-3 create_update_select" *ngIf="showOption != false">
                <!-- <p class="text-3" style="width: 200%;">{{"ITEMS.Based on status above select your option:" |
                    translate}}</p> -->
                <select class="form-select border-gris w-100" formControlName="status" type="text">
                    <option value="create">{{"ITEMS.Create the new contact if found the duplicate" |
                        translate}}</option>
                    <option value="update">{{"ITEMS.Update the contact if found the duplicate" |
                        translate}}</option>
                    <option value="update_replace">{{"ITEMS.Update and Replace" |
                        translate}}</option>    
                </select>
                <div *ngIf="(sub || f.status.touched) && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">Status is Required</div>
                </div>
            </div>
            <div class="d-flex gap-2">
                <input class="form-check-input col-1" formControlName="Confirm" type="checkbox" [checked]="checkbox1"
                    value="" id="flexCheckDefault">
                <p class="text-3">
                    {{"ITEMS.CSVTerms" | translate}}
                </p>
                <div *ngIf="(sub || f.Confirm.touched) && f.Confirm.errors" class="invalid-feedback">
                    <div *ngIf="f.Confirm.errors.required">Status is Required</div>
                </div>
            </div>
        </form>
    </div>
</ng-container>
<ng-container *ngIf="index === 5">
    <div class="modal-body bg-gris-1 p-5 d-flex flex-column align-items-center">
        <img src="assets/img/home/cloud.png">
        <h2 class="text-1 fs-4 text-center my-4">{{"ITEMS.Upload CSV File" | translate}}</h2>
        <p class="text-3 mb-5">{{"ITEMS.ContactProcessDone" | translate}}
        </p>
        <button class="btn btn-1" (click)="csvUpload()">{{"ITEMS.Done" |
            translate}}</button>
    </div>
</ng-container>

<ng-container *ngIf="index !== 1 && index !==5 ">
    <div class="d-flex justify-content-center mt-4 gap-3">
        <button (click)="closebutton()" class="btn btn-2">{{"PROFILE.cancel" |
            translate}}</button>

        <button class="btn btn-1" type="submit" [disabled]="index == 4 ? csvform.controls['Confirm'].value === false : false"
            (click)="continue()">
            {{"ITEMS.Continue" | translate}}</button>
    </div>
</ng-container>




<!-- <ng-container *ngIf="Inputdata.checkingItems == 'newImages'">
    <div class="modal-body bg-gris-1 p-5">
        <h2 class="text-1 fs-4 text-center mb-4">{{"Upload Images" | translate}}</h2>
        <div class="d-flex justify-content-center gap-5">
            
            <div class="text-center d-flex flex-column col-8 justify-content-center">
            
                <div class="variants col-12">
                    <div class='file col-12'>
                        <mat-select [formControl]="csvFile" multiple class="form-select mt-3 border-gris">
                            <mat-option *ngFor="let item of itemsCsvFile" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="d-flex flex-column align-center mt-3 gap-3">
                    <p class="text-3"></p>
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="item-image-box gap-2 cursor-pointer" style="width: 250px;" *ngIf="image">
                            <img class="edit-item-images" src="{{ imageUrl + image.image }}">
                            <div class="overlay"></div>
                            <div class="image-remove-btn">
                                <a (click)="removeItem(image,'imgRemove')">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="bg-gris-2 p-2 ms-2 d-flex justify-content-center rounded-circle rounded-2"
                            style="width: 45px; height: 45px; background-color: rgb(3, 192, 252, 10%);">
                            <input type="file" name="file-6" id="file-6" class="inputfile"
                                data-multiple-caption="{count} archivos seleccionados"
                                (change)="imageFilesAddedEdit($event)" multiple />
                            <label for="file-6">
                                <img class="" width="33px" height="29.22px" src="assets/svg-icons/Group (2).svg">
                            </label>
                        </div>
                    </div>
                    <div class="d-flex gap-2" style="margin-top:20px">
                        <div class="item-image-box cursor-pointer gap-2"
                            *ngFor="let image of editItemsImages;let i = index" (click)="changeImage(i,image)"
                            style="width:80px; height: 70px;">
                            <img class="edit-item-image-zoom" src="{{ imageUrl + image.image }}">
                            <img class="edit-item-images" src="{{imageUrl +image.image}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center mt-4 gap-3">
        <button (click)="closebutton()" class="btn btn-2 mx-2">{{"PROFILE.cancel" |
            translate}}</button>

        <button class="btn btn-1 mx-2" [disabled]="!csvFile" (click)="contiineItems()">
            {{"ITEMS.Continue" | translate}}</button>
    </div>
</ng-container> -->

<ng-container *ngIf="Inputdata.checkingItems == 'newImages'">
    
    <div class="modal-body bg-gris-1" style="overflow: hidden;">
        <div class="d-flex justify-content-end me-2"><mat-icon class="material-symbols-outlined icon_close color-1" role="button" (click)="closeDialog()">close</mat-icon></div>
      <h2 class="text-1 fs-4 text-center mb-2">{{"ITEMS.Upload_images" | translate}}</h2>
      <div class="table-design" style="overflow-y: scroll;height: 400px;">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{"ITEMS.Name" | translate}}</th>
              <th>{{"ITEMS.Item_Code" | translate}}</th>
              <th>{{"ITEMS.Upload_images" | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of newItemsData?.data;let i =index">
              <td>{{ item?.name }}</td>
              <td> 
                <div>
                  {{Inputdata?.value=='allItems'? item?.code:item?.item_code }}
                </div>
              </td>
              <td>
                <div>
                    <button  class="btn btn-1 mx-2" [ngClass]="item.disabled?'upload_images_check':''" [matTooltip]="item.disabled?'Images uploaded':''" (click)="uploadItemImages(Inputdata?.value=='allItems'?{ id:item.id,name:item.name}:{ id:item.item_id,name:item.name},i)">
                        {{"ITEMS.Upload" | translate}}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Additional information if needed -->
      <!-- <div class="text-3 d-flex gap-3 mt-1">
        <p>Records: <b class="fw-normal text-1">{{ dataarryCsc.length }}</b></p>
        <p>Columns: <b class="fw-normal text-1">{{ headingdata.length }}</b></p>
        <p>File Size: <b class="fw-normal text-1">{{ filesize }}</b></p>
      </div> -->
    </div>

    <div class="d-flex flex-col gap-3 justify-content-around align-items-baseline table-pagination mt-4 pl-3 pr-3" style="color: rgba(0,0,0,0.54);font-size: 12px;">
        <div class="w-full flex justify-center items-center">
        <div class="flex gap-2" style="font-size: 12px;">
            <label for="cars">{{ "LEADS.Item_per_page" | translate }}</label>
            <select (change)="paginationPageSize($any($event.target).value)" style="height: 22px;width: 45px;">
                <option *ngFor="let size of defaultsPage.pageSizes" value="{{ size.value }}">{{ size.value }}</option>
            </select>
        </div>
        <div class="pl-3" style="font-size:12px;">
            {{ pageDetail }}
        </div>
        </div>
        <nav class="w-full flex justify-center" aria-label="Page navigation example">
            <ul class="pagination mb-0">
                <li class="page-item" [ngClass]="{ 'disabled': 1 === newItemsData?.currentPage }">
                    <a class="page-link text-3" href="javascript:void(0)" aria-label="Previous"
                        (click)="paginationPage(newItemsData?.currentPage-1)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li class="page-item" *ngFor="let page of itemActivePagination;let i = index"
                    [ngClass]="{ 'active': i+1 === newItemsData?.currentPage }">
                    <a class="page-link text-3" href="javascript:void(0)" (click)="paginationPage(i+1)">{{ i + 1 }}</a>
                </li>
                <li class="page-item" [ngClass]="{ 'disabled': newItemsData?.totalPages === newItemsData?.currentPage }">
                    <a class="page-link text-3" href="javascript:void(0)" aria-label="Next"
                        (click)="paginationPage(newItemsData?.currentPage+1)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    
  </ng-container>