<div class="modal-content border-0 cnt-dashboard border-r2"  data-backdrop="static">
  <form (ngSubmit)="cateogryForm.form.valid" #cateogryForm="ngForm" novalidate>
    <div class="modal-body bg-gris-1 p-5 d-flex align-items-center flex-column">
      <h2 class="text-1 fs-4 text-center mb-4">
        {{ inputData?.label === 'Address' ? 'Update Address Name' : (inputData?.label === 'phone_name' ? 'Update Phone Name' : (inputData?.name ? 'ITEMS.Update Category' : 'ITEMS.ADDNEWCATEGORY')) | translate }}
      </h2>
            <i type="button" #closebutton class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"
        data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"></i>
      <input matInput class="form-control p-2 text-center w-75" type="text" placeholder="Enter Category"
        [(ngModel)]="categoryAddModel.name" name="name" #name="ngModel" required>
      <div *ngIf="name.invalid && throwError" class="alert error_message mb-0">
        <div *ngIf="throwError">
          {{"ITEMS.ERRORNAMEREQUIRED" | translate}}
        </div>
      </div>
      <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId == 'categoryId'" (click)="addCateogry(cateogryForm)"><i
          class="fa fa-plus"></i>&nbsp;&nbsp;{{"ITEMS.ADD" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId !== 'categoryId' && !inputData.label" (click)="updateCateogry(cateogryForm)">&nbsp;&nbsp;{{"EMAILS.UPDATE" | translate }}</button>
          <button class="btn btn-1 mt-4 py-2" *ngIf="inputData.itemsEditButtonId !== 'categoryId' && inputData.label" (click)="updateAddress(cateogryForm)">&nbsp;&nbsp;{{"EMAILS.UPDATE" | translate }}</button>

  
    </div>
  </form>
</div>