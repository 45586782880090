import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CrudService {
    private endPoint: string = environment.API_URL;
    private user_management: string = environment.USER_MANAGEMENT_URL;
    private mailbox_management: string = environment.MAILBOX_MANAGEMENT_URL;
    private agenda_management:string=environment.AGENDA_MANAGEMENT_URL;
    updateList: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private calendarId = 'zaidalviza786@gmail.com';
    private apiKey = 'AIzaSyAmdjBfBPzTJc49HXNaHUTkhUTbdPUBuGI';
  
    private url = `https://www.googleapis.com/calendar/v3/calendars/${this.calendarId}/events?key=${this.apiKey}`;
    public updateProfileImage = new Subject<string>();
    constructor(
        private _http: HttpClient,
        private _translateService: TranslateService,
    ) {
    }

    buildParams(obj): string {
        let str = '';
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (str !== '') {
                    str += '&';
                }
                str += key + '=' + encodeURIComponent(obj[key]);
            }
        }
        return str;
    }

    getCalendarEvents() {
        return this._http.get(this.url);
      }

    index(modelUrl: string, form: any, params = {}): Observable<any[]> {
        return this._http.post<any[]>(`${this.endPoint}${modelUrl}?${this.buildParams(params)}`, form);
    }

    read(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.endPoint}/${modelUrl}`);
    }

    bounce(modelUrl: string,form:any): Observable<any> {
        return this._http.post<any>(`${this.endPoint}/${modelUrl}`,form);
    }

    get(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.endPoint}${modelUrl}`);
    }
    getMerchantTeams(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.agenda_management}/${modelUrl}`);
    }
    getMerchantUsers(modelUrl: string,form:any): Observable<any> {
        return this._http.get<any>(`${this.agenda_management}/${modelUrl}`,form);
    }
    createTaskType(modelUrl: string,form:any): Observable<any> {
        return this._http.post<any>(`${this.agenda_management}/${modelUrl}`,form);
    }
    getTasksTypes(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.agenda_management}/${modelUrl}`);
    }

    getUserManagement(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.user_management}/${modelUrl}`);
    }

    postUserManagement(modelUrl: string, form: any): Observable<any> {
        return this._http.post<any>(`${this.user_management}/${modelUrl}`, form);
    }

    deleteUserManagement(modelUrl: string): Observable<any> {
        return this._http.delete<any>(`${this.user_management}/${modelUrl}`);
    }

    postAgendaManagement(modelUrl: string, form: any): Observable<any> {
        return this._http.post<any>(`${this.agenda_management}/${modelUrl}`, form);
    }
    getFilterData(modelUrl:string,resource: any): Observable<any> {
        return this._http.post(`${this.agenda_management}/${modelUrl}`, resource);
    }
    deleteAgendaManagement(modelUrl: string): Observable<any> {
        return this._http.delete<any>(`${this.agenda_management}/${modelUrl}`);
    }

    getAgendaManagement(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.agenda_management}/${modelUrl}`);
    }

    putAgendaManagement(modelUrl: string, form: any): Observable<any> {
        return this._http.put<any>(`${this.agenda_management}/${modelUrl}`, form);
    }

    putUserManagement(modelUrl: string, form: any): Observable<any> {
        return this._http.put<any>(`${this.user_management}/${modelUrl}`, form);
    }


    getDatafromMailbox(modelUrl: string): Observable<any> {
        return this._http.get<any>(`${this.mailbox_management}/${modelUrl}`);
    }
    create(modelUrl: string, form: any): Observable<any> {
        return this._http.post<any>(`${this.endPoint}${modelUrl}`, form);
    }

    post(modelUrl: string, form: any): Observable<any> {
        return this._http.post<any>(`${this.endPoint}${modelUrl}`, form);
    }
    postMailManagement(modelUrl: string, form: any): Observable<any> {
        return this._http.post<any>(`${this.mailbox_management}/${modelUrl}`, form);
    }

    update(modelUrl: string, form: any): Observable<any> {
        return this._http.put<any>(`${this.endPoint}${modelUrl}`, form);
    }

    put(modelUrl: string, form: any): Observable<any> {
        return this._http.put<any>(`${this.endPoint}${modelUrl}`, form);
    }

    delete(modelUrl: string): Observable<any> {
        return this._http.delete<any>(`${this.endPoint}${modelUrl}`);
    }

    getMenuStructure(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http
                .get(`${environment.API_URL}system-menus/load-after-login`, {})
                .pipe(map((res: any) => {
                    return res;
                })).subscribe((data) => resolve(data));
        });
    }
    saveModelAttachments(madelId: any, modelName: any, file: File, status: any, isFolder: boolean): Observable<any> {
        const formData: any = new FormData();
        if (file.type.includes('application')) {
            formData.append('document', true);
        }
        formData.append('file', file);
        // importnat in any multi file upload add this else it will overwrite exsisting
        formData.append('multi', true);
        formData.append('isFolder', isFolder);
        formData.append('modelId', madelId);
        formData.append('refModel', modelName);
        formData.append('internal_attachment', status);
        return this._http.post(environment.API_URL + 'attachments/upload', formData);
    }


    public download(url): any {

        // let headers = new HttpHeaders();

        // headers = headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

        // return this._http.get<any>(`${this.endPoint}${url}`);
        // , responseType: "blob"
        return this._http.get(`${this.endPoint}${url}`, { responseType: "blob" });

        // return this.http.post(`${environment.backendApiUrl}/${url}`, data, { headers: headers, responseType: "blob" });
    }



    uploadDocument(file: File, data: any): Observable<any> {

        const formData: any = new FormData();

        if (file.type.includes('application')) {
            formData.append('document', true);
        }

        formData.append('file', file);
        formData.append('multi', true);

        Object.keys(data).forEach((key) => { formData.append(key, data[key]); });

        return this._http.post(environment.API_URL + 'documents/upload', formData);
    }
}
