import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AutoLogoutDialogComponent } from './auto-logout-dialog.component';



@NgModule({
  declarations: [AutoLogoutDialogComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule
  ],
  entryComponents: [AutoLogoutDialogComponent]
})
export class AutoLogoutDialogModule { }
