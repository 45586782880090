import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent, ModalModel } from '../modal/modal.component';

@Component({
  selector: 'app-custom-form-fields',
  templateUrl: './custom-form-fields.component.html',
  styleUrls: ['./custom-form-fields.component.scss']
})
export class CustomFormFieldsComponent implements OnInit,OnChanges {
  public customFielsDataFrom: FormGroup;
  @Input() customerCustomFieldArr: any;
  @Input() customDataFieldViewData: any;
  @Output() removeCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomeOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Input() editdeletshow: any;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder ,
    private cdr: ChangeDetectorRef
    ) { 
        
    this.customFielsDataFrom = this.formBuilder.group(
      {

      },
    );
 
  }
  ngOnChanges(changes: SimpleChanges): void {    
    if(changes.customerCustomFieldArr?.currentValue){
      for(let i = 0;i< this.customerCustomFieldArr?.length;i++){
        if (this.customerCustomFieldArr[i].field_type_id == 2) {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_' + element.value, new FormControl());            
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 4) {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_radio', new FormControl());
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 5) {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_dropdown', new FormControl(''));
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 3) {
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_date', new FormControl(''));          
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 6) {
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_color', new FormControl(''));          
        }
        else {
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id, new FormControl(''));
        }
      }
    }
    if(changes.customDataFieldViewData?.currentValue ){
      this.customDataFieldViewData = changes.customDataFieldViewData?.currentValue
      this.patchFormValues()
    }

  }

  ngOnInit(): void {
   
  }

patchFormValues(){
 
  if(this.customDataFieldViewData?.length){
    this.customDataFieldViewData.forEach((data, index) => {
      if (data.field_type_id == 2) {
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_' + data.value]?.setValue(true)
      }
      else if (data.field_type_id == 4) {
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_radio']?.setValue(data.value)
      }
      else if (data.field_type_id == 5) {
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_dropdown']?.setValue(data.value)
      }
      else if (data.field_type_id == 3) {

        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_date']?.setValue(data.value)
        this.cdr.detectChanges()
      }
      else if (data.field_type_id == 6) {

        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_color']?.setValue(data.value)
        this.cdr.detectChanges()
      }
      else if (data.field_type_id == 1) {
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id)]?.setValue(data.value)
      }
      this.customerCustomFieldArr.forEach((customFieldData, index) => {            
        if((data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) == customFieldData.id){
          this.customerCustomFieldArr[index].value = data.value;
        }
      });
    })  
  }

}
  async removeItem(customField, index, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "450px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {

     
        if (type == 'removeCustomField') {
          this.removeCustomField.emit({customField, index});
        }
      }
    });
  }
  updateSelectChange(event,controlName) {
    if (event.value === null) {
      this.customFielsDataFrom[controlName] = null;
  } else {
      this.customFielsDataFrom[controlName] = event.value;
  }
    this.customFielsDataFrom.controls[controlName].setValue(event.value);
  }
  onColorChange(event: Event,controlName) {
    const colorInput = event.target as HTMLInputElement;
    const selectedColor = colorInput.value;
    this.customFielsDataFrom.controls[controlName].setValue(selectedColor);
  }

  editField(customField, index) {    
      this.editCustomField.emit({isEdit: true, index, customField});
  }


  drop(event: CdkDragDrop<any>) {
    this.customerCustomFieldArr[event.previousContainer.data.index] = event.container.data.item;
    this.customerCustomFieldArr[event.container.data.index] = event.previousContainer.data.item;
    this.updateCustomeOrder.emit(this.customerCustomFieldArr);

  }
}
