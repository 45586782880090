<div class="modal-content cnt-dashboard border-0">
    <div class="w-100 text-right">
        <i type="button" class="color-4 fs-2 far fa-times-circle" (click)="onDismiss()"></i>
    </div>
    <div class="modal-body py-4 px-3 text-center">
        <div class="d-flex justify-content-center"> <img class=" mb-4" src="assets/img/orgdelete.svg"> </div>
        <h2 class="conformation-message"> {{"ORGANIZATION.Are you sure you want to close" | translate}} "<span>{{org_name}}</span>" organization?</h2>
        <div class="d-flex gap-3 justify-content-center mt-5">
            <div class="btn btn-cancel me-2" (click)="onDismiss()">{{"ORGANIZATION.Cancel" | translate}}</div>
            <div class="conform-btn" (click)="onConfirm()">{{"ORGANIZATION.Close the organization" | translate}}</div>
        </div>
    </div>
</div>