<form [formGroup]="customFielsDataFrom" class="col-12">
    <div #contenedor class="categories" cdkDropListGroup>
        <ng-container class="mt-4" *ngFor="let customField of customerCustomFieldArr;let i = index">
            <div class="categories-item col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6" cdkDropList  cdkDropListOrientation="horizontal"
                [cdkDropListData]="{item:customField,index:i}" (cdkDropListDropped)="drop($event)">
                <div  cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <div class="d-flex gap-2 align-items-baseline justify-content-between ">
                            <div class="d-inline-block text-1 fw-500 align-top inner f-15">
                                {{customField?.name}}
                            </div>
                            <div id="some-icon" *ngIf = 'editdeletshow != false'>
                                <i class="fa fa-edit edit-icon mr-2"  (click)="editField(customField, i)"></i>

                                <i class="far fa-trash-alt del-icon" (click)="removeItem(customField, i,'removeCustomField')"></i>
                            </div>
                        </div>
                    <ng-container *ngIf="customField?.field_type_id == 1 || customField?.field_type_id == '1'">
                        <div class="mt-2">
                            <mat-form-field 
                                appearance="outline" 
                                floatLabel="always">
                                <input matInput type="text" class="fs-2"
                                formControlName="value_{{customField.id}}">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="customField?.field_type_id == 2 || customField?.field_type_id == '2'">
                        <div class="text-3 d-flex gap-2 flex-wrap mt-2" >
                            <div *ngFor="let customerCusVal of customField?.customer_custom_field_values;let j = index">                                
                                <mat-checkbox 
                                        [value]="customerCusVal.value"
                                        color="primary"
                                        formControlName="value_{{customerCusVal.customerCustomFieldId}}_{{customerCusVal.value}}">
                                    {{customerCusVal.value}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="customField?.field_type_id == 6 || customField?.field_type_id == '6'">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <input matInput type="text"
                            formControlName="value_{{customField.id}}_color"
                                   [value]="customField.value">
                            <input matInput class="p-0 position-absolute end-0 top-0 m-2 text-1"
                                   [style.background]="customField.value"
                                   formControlName="value_{{customField.id}}_color"
                                   [value]="customField.value"
                                   style="width: 15px; height: 15px; margin-top: 3px!important" type="color"
                                   (change)="onColorChange($event,'value_' + customField.id + '_color')" />
                        </mat-form-field>
                    </ng-container>
                    


                    <ng-container  *ngIf="customField?.field_type_id == 3 || customField?.field_type_id == '3'">
                        <div class="text-3 d-flex gap-2 flex-wrap mt-2 custom-date">
                            <app-custom-date formControlName="value_{{customField.id}}_date" dataType='4'
                                    inputType="date" fvalue="{{customField.value}}" class="w-100">
                            </app-custom-date>
                        </div>
                     </ng-container>
                     

                     <ng-container *ngIf="customField?.field_type_id == 5 || customField?.field_type_id == '5'">  
                        <div class="text-3 mt-2" >
                            <mat-form-field 
                                appearance="outline" 
                                floatLabel="always">
                                <mat-select class="text-1" (selectionChange)="updateSelectChange($event,'value_'+ customField.id + '_dropdown')" value="{{customField.value}}">  
                                    <mat-option class="text-1" [value]="null">
                                        <mat-icon class="vertical-align-baseline">close</mat-icon> Clear
                                    </mat-option>                                             
                                    <mat-option class="text-1"  *ngFor="let customerCusVal of customField?.customer_custom_field_values;let j = index"
                                        value="{{customerCusVal.value}}">
                                        {{customerCusVal.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                        </div>
                    </ng-container>
                    <ng-container *ngIf="customField?.field_type_id == 4 || customField?.field_type_id == '4'">
                        <div class="text-3 d-flex gap-2 flex-wrap mt-2" >
                            <mat-radio-group
                                aria-labelledby="custom-radio-group-label"
                                formControlName="value_{{customField.id}}_radio"
                                color="primary"
                                class="custom-radio-group">
                                <mat-radio-button 
                                    class="custom-radio-button" 
                                    *ngFor="let customerCusVal of customField?.customer_custom_field_values;let j = index" 
                                    [value]="customerCusVal.value">
                                    {{customerCusVal.value}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div> 
</form>
