import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class LeadFormService {
  BASE_URL: string;
  cloneTemplateSuffix = '';
  copyTemplateSuffix = '';
  public embedTemplate$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.LEAD_MANAGEMENT_URL;
  }

  setCloneTemplateSuffix(suffix: string) {
    this.cloneTemplateSuffix = suffix;
  }

  getCloneTemplateSuffix() {
    return this.cloneTemplateSuffix;
  }

  setCopyTemplateSuffix(suffix: string) {
    this.copyTemplateSuffix = suffix;
  }

  getCopyTemplateSuffix() {
    return this.copyTemplateSuffix;
  }

  getForms_Data(): Observable<any> {
    return this.http.get(`assets/json/form_data.json`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  getQuoteForms_Data(): Observable<any> {
    return this.http.get(`assets/json/quote_form_data.json`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }


  create_Lead_Form(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-template/createTemplate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_Lead_Form_list(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-template/templateList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_Lead_Form(data: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}/lead-template/viewTemplate/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  update_Lead_Form(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-template/updateTemplate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  delete_Lead_Form(id: string): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-template/deleteTemplate/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  set_default_Lead_Form(id: string): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-template/setTemplateDefault/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  uploadLogoAttachment(data:any): Observable<any>{
      return this.http.post(`${this.BASE_URL}/lead-template/imageUpload`, data).pipe(
        map((response: any) => {
          return response;
        }),
       catchError((err: any) => {
          return err;
       })
       );
    }


    getUpperFormHtml(): Observable<string> {
      return this.http.get('assets/html/lead-upper-form.html', { responseType: 'text' });
    }

    getLowerFormHtml(): Observable<string> {
      return this.http.get('assets/html/lead-lower-form.html', { responseType: 'text' });
    }

    getCustomFields(data: any = ''): Observable<any> {
      return this.http.post(environment.API_URL + '/customfield/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }

    getSavedCustomFields(data: any = ''): Observable<any> {
      return this.http.post(environment.API_URL + '/customercustomfield/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }

    getFormContacts(data: any): Observable<any> {
      return this.http.post(environment.API_URL + '/formcontacts/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }


    getCurrentCountry(): Observable<any> {
      return new Observable(observer => {
        const xhr = new XMLHttpRequest();
  
        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const responseData = JSON.parse(xhr.responseText);
              observer.next(responseData);
              observer.complete();
            } catch (parseError) {
              observer.error('Error parsing JSON response');
            }
          } else {
            observer.error(`HTTP error! Status: ${xhr.status}`);
          }
        });
  
        xhr.addEventListener('error', () => {
          observer.error('Network error');
        });
  
        xhr.open('GET', 'https://ipapi.co/json/');
        xhr.send();
      }).pipe(
        catchError((error: any) => {
          console.error('Error:', error);
          return null 
        })
      );
    }

    countryList(data: any = ''): Observable<any> {
      return this.http.post(environment.API_URL + '/country/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }
}