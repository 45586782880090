import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  BASE_URL: string;
  ITEM_MODULE_URL: string;
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.ITEM_MODULE_URL = environment.ITEM_MODULE_URL
  }


  createPassword(user: any, password): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }
  categoryAdd(resource) {
    return this.http.post(this.BASE_URL + '/category', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateAddress(resource) {
    return this.http.put(this.BASE_URL + '/formcontacts', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  categoryUpdate(resource) {
    return this.http.post(this.ITEM_MODULE_URL + '/category/categoryUpdate', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  customerCustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.ITEM_MODULE_URL + '/customFieldList', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  updateCustomeOrder(data: any = ''): Observable<any> {
    return this.http.put(this.ITEM_MODULE_URL + '/updateitemsCustomOrder', { data })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  createCategorySearchHistory(resource) {
    return this.http.post(this.ITEM_MODULE_URL + '/category/create/search', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }



  itemSearch(resource = '') {
    return this.http.post(this.ITEM_MODULE_URL + '/item/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  eQuoteItemSearch(resource:any = '') {
    return this.http.post(this.ITEM_MODULE_URL + '/item/eQuote/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  itemSearchUploaded(resource = '') {
    return this.http.post(this.ITEM_MODULE_URL + '/uploaded/', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getItemsBySubcategory( resource): Observable<any> {
    return this.http.post<any>(this.ITEM_MODULE_URL  + '/item/itemsBySubcategory', resource)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
}
updateItemBulkPrice(resource) {
  return this.http.put(this.ITEM_MODULE_URL + '/item/bulkprice', resource)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
}
  // getItems(resource = ''): Observable<any> {
  //   return this.http.post(this.BASE_URL + '/item/list', resource).pipe(
  //     // tap(
  //     //   data => data,
  //     //   error => error
  //     // ),
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError((err: any) => {
  //       return null;
  //     })
  //   );
  // }


  currenciesList(resource = '') {
    return this.http.post(this.BASE_URL + '/currency/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  cateogryList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/category/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  eQuoteCateogryList(resource:any = '') {
    return this.http.post(this.ITEM_MODULE_URL + '/category/eQuoteCategory', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  updateComments(resource:any = '') {
    return this.http.post(this.ITEM_MODULE_URL + '/item/updateComments', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  getSearchList() {
    return this.http.get(this.ITEM_MODULE_URL + '/category/getSearchHistory')
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteItem(id) {
    return this.http.delete(this.BASE_URL + '/item/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteBulkItem(id) {
    return this.http.post(this.ITEM_MODULE_URL + '/item/bulkdelete' , id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  cloneBulkItem(data) {
    return this.http.post(this.BASE_URL + '/item/bulkclone' , data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  addCloneItem(resource): Observable<any>{
    return this.http.post(this.ITEM_MODULE_URL + '/item/cloneitems', resource)
    .pipe(
      tap(
        data => data,
        error => error
      )
    );
  }




  subCateogryList(resource = '') {
    return this.http.post(this.BASE_URL + '/subcategory/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateItem(resource) {
    return this.http.put(this.ITEM_MODULE_URL + '/item', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateItemPrice(resource) {
    return this.http.put(this.ITEM_MODULE_URL + '/item/price', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
//Download lead csv catalog 
getDownloadcvsCatalog(): Observable<any> {
  return this.http.post(`${this.ITEM_MODULE_URL}/item/download/catalog`, { responseType: 'text' })
}

  bundleList(resource = '') {
    return this.http.post(this.BASE_URL + '/bundle/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  iteamDataList(id) {
    return this.http.get(this.BASE_URL + '/item/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }



  warehouseList(resource = '') {
    return this.http.post(this.BASE_URL + '/warehouse/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  taxList(resource = '') {
    return this.http.post(this.BASE_URL + '/taxes/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  unitList(resource = '') {
    return this.http.post(this.BASE_URL + '/units/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  subCategorySearch(resource = '') {
    return this.http.post(this.BASE_URL + '/subcategory/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addSubCateogry(resource) {
    return this.http.post(this.BASE_URL + '/subcategory', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateItemsSubCateogry(resource) {
    return this.http.post(this.ITEM_MODULE_URL + '/subcategory/subcategoryUpdate', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addNewWarehouse(resource) {
    return this.http.post(this.BASE_URL + '/warehouse', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  addNewTex(resource) {
    return this.http.post(this.BASE_URL + '/taxes', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  addNewBundle(resource) {
    return this.http.post(this.BASE_URL + '/bundle', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  addNewUnit(resource) {
    return this.http.post(this.BASE_URL + '/units', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addItem(resource): Observable<any>{
    return this.http.post(this.ITEM_MODULE_URL + '/item', resource)
    .pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateTexValues(resource): Observable<any>{
    return this.http.post(this.ITEM_MODULE_URL + '/item/texValues', resource)
    .pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  itemTexValues(resource): Observable<any>{
    return this.http.post(this.ITEM_MODULE_URL + '/item/itemTexValues', resource)
    .pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  itemTexCustomValuess(resource:any=''): Observable<any>{
    return this.http.post(this.ITEM_MODULE_URL + '/item/itemTexCustomValues',resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
 

  uploadItemPhoto(resource) {
    return this.http.patch(this.BASE_URL + '/item/item_image', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  uploadItemImages(resource) {
    return this.http.patch(this.BASE_URL + '/item', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  uploadItemOrder(resource) {
    return this.http.post(this.ITEM_MODULE_URL + '/item/item_image_order', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addCustomValue(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customvalues/add', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  userCustomUrl(data: any = ''): Observable<any> {
    return this.http.post(this.ITEM_MODULE_URL + '/item/customUrl', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  getTaxesList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customvalues/getCustomValues', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }


  removeCustomValue(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/customvalues/removeCustomValues/` + data.id, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }
  removeCategoryValue(data: any = ''): Observable<any> {
    return this.http.delete(`${this.ITEM_MODULE_URL}/category/${data.id}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  removeSubCategoryValue(data: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/subcategory/${data.id}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }


  createCustomField(data: any = ''): Observable<any> {
    return this.http.post(this.ITEM_MODULE_URL + '/create/custom/field', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  updateItemCustomfield(id, data: any = ''): Observable<any> {
    return this.http.put(this.ITEM_MODULE_URL + '/updateItemsCustomfield', {...data, id })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  itemcustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.ITEM_MODULE_URL + '/customFieldList', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  deleteCustomField(resource:any): Observable<any> {
    return this.http.post(`${this.ITEM_MODULE_URL}/deleteCustomField`, resource).pipe(
      tap(data => data, error => error));
  }
}
