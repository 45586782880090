import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-Select-Contact',
  templateUrl: './select_contact.component.html',
  styleUrls: ['./select_contact.component.scss']
})
export class SelectContactComponent implements OnInit {
  public fg: FormGroup;
  selectedContact: any = '';

  constructor(public dialogRef: MatDialogRef<SelectContactComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
    console.log(this.dialogData,'dialogDatadialogData')
    this.fg = new FormGroup({
      name: new FormControl(''),
    });
   
  }
  closeDialogThankYou(){
    this.dialogRef.close(true); 
  }
  closeDialog(){
    this.dialogRef.close(false);
  }

  onSelectedOption(selectedObj: any): void {
    if (selectedObj && selectedObj.mobile_number && selectedObj.country?.phone_code) {
      const formattedNumber = this.convertToInternational(selectedObj.mobile_number, selectedObj.country.phone_code);
      this.selectedContact=formattedNumber
      console.log("International Format:", formattedNumber);
    }
  }
  
  convertToInternational(mobileNumber: string, phoneCode: string): string {
    // Remove all non-digit characters
    let cleanedNumber = mobileNumber.replace(/\D/g, '');
  
    // If the number starts with a leading zero, remove it
    if (cleanedNumber.startsWith('0')) {
      cleanedNumber = cleanedNumber.substring(1);
    }
  
    // Return the formatted international number
    return `+${phoneCode}${cleanedNumber}`;
  }
  

  submit(){
    this.dialogRef.close(this.selectedContact);

  }

}
