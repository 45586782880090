import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QuoteTemplateService {
  BASE_URL: string;
  QUOTE_TEMPLATE_URL:string;
  lead:string;
  cloneTemplateSuffix = '';
  copyTemplateSuffix = '';
  mailBox_url=''
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.QUOTE_TEMPLATE_URL= environment.QUOTE_MANAGEMENT_URL;
    this.lead = environment.LEAD_MANAGEMENT_URL;
    this.mailBox_url=environment.MAILBOX_MANAGEMENT_URL
  }

  createPassword( user: any, password ): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }

  customerCustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.QUOTE_TEMPLATE_URL + '/customFieldList', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  contactCustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.QUOTE_TEMPLATE_URL + '/eQuote-template/contactCustoms', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  get_Lead_Form(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/lead-template/viewTemplate/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_eQuote_Form(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/viewTemplate/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_Payment(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/viewPayment/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_all_Payment(): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/allPayment`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_contact_Payment(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/contactPayment/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

 
  get_uploads_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/find/uploadAttachments`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  viewContact(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/view/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  

  merchantcustomerInfo(id) {
    return this.http.get(this.BASE_URL + '/merchantcustomer/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  get_Quote_List_id(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/quotesListing/viewListing/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_Quote_maximum(data: any): Observable<any> {
    return this.http.post(this.QUOTE_TEMPLATE_URL +'/quotesListing/getMaximumNumber', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  getMaximumNumberInvoices(data: any): Observable<any> {
    return this.http.post(this.QUOTE_TEMPLATE_URL +'/quotesListing/getMaximumNumberInvoices', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  

  get_Quote_all_maximum(): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/quotesListing/getMaximumNumberAll`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  // get_Quote_id(type:any): Observable<any> {
  //   return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quoteId`,type).pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError((err: any) => {
  //       return err;
  //     })
  //   );
  // }

  get_Quote_id(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quoteId`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_quote_listing(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/updateListing`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  
  update_payment_data(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/updatePayment`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_payment_status(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/updatePaymentStatus`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  // updateListingStatus

  update_quote_listing_status(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/updateListingStatus`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_contact_view_date(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/updateContactViewdate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  sendNotification(resource) {
    return this.http.patch(this.BASE_URL + '/item/item_image', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  update_Lead_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/updateTemplate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_eQuote_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/updateTemplate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  quote_listing_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelisting`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  payment_listing_add(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/paymentListing`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  eQuote_listing(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelisting/eQuote`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  quoteWithNewVersion(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quoteWithNewVersion`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  savePdf(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPdf/savePdf`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  createQuoteFilter(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesFilters/savefilters`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  saveQuoteFilterList(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesFilters/saveFilterList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  deleteQuoteFilter(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesFilters/deleteSaveFilter`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  sendMail(data: any): Observable<any> {
    return this.http.post(`${this.mailBox_url}/email/quote`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  
  quote_pdf_list(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPdf/quotepdfList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_status(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPdf/updateStatus`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  quote_pdf_notification(data: any): Observable<any> {
    return this.http.post(`http://localhost:5005/quotes_management/quotesPdf/quotepdfListNotification`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  delete_quote_listing_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/deletelisting`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  quoteInvoiceStatus(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/invoiceStatus`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_quote_listing_list(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelistingList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_quote_listing_list_filters(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelistingListFilters`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_quote_listing_list_invoice(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelistingListInvoices`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  senEmail(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesListing/senEmail`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  
  get_deleted_quote_listing(): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/quotesListing/getDeletedQuotes`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  uploadLogoAttachment(data:any): Observable<any>{
    return this.http.post(`${this.lead}/lead-template/imageUpload`, data).pipe(
      map((response: any) => {
        return response;
      }),
     catchError((err: any) => {
        return err;
     })
     );
  }
  get_Lead_Form_list(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/templateList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  get_Lead_Form_list_All(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/templateListAll`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  // customerCustomFieldList(data: any = ''): Observable<any> {
  //   return this.http.post(this.BASE_URL + '/customercustomfield/list', data).pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError((err: any) => {
  //       return null;
  //     })
  //   );
  // }

  get_eQuote_Form_list(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/templateList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  getQuoteForms_Data(): Observable<any> {
    return this.http.get(`assets/json/quote_form_data.json`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  getCustomFields(data: any = ''): Observable<any> {
    return this.http.post(environment.API_URL + '/customfield/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }
  create_Lead_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/createTemplate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  set_default_Lead_Form(id: string): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/setTemplateDefault/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  set_default_eQuote_Form(id: string): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/setTemplateDefault/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  orgcustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.QUOTE_TEMPLATE_URL + '/organization/customFieldList', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }
  delete_Lead_Form(id: string): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/deleteTemplate/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  delete_eQuote_Form(id: string): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/deleteTemplate/${id}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  setCopyTemplateSuffix(suffix: string) {
    this.copyTemplateSuffix = suffix;
  }
  getCopyTemplateSuffix() {
    return this.copyTemplateSuffix;
  }
  getCloneTemplateSuffix() {
    return this.cloneTemplateSuffix;
  }

  get_Quote_Stages(data: any=''): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/quoteStagesList`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  delete_Quote_Stage(data: any=''): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/deleteStages`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  create_Quote_Stage(data: any=''): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/createStages`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_Quote_Stage(data: any=''): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/updateStages`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  update_Quote_Stage_orders(data: any=''): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/updateStagesOrder`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }


  get_Quote_history(data: any): Observable<any> {
    return this.http.get(`${this.QUOTE_TEMPLATE_URL}/quotesListing/quotelistingList/${data}`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

   create_eQuote_Form(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/eQuoteCreate`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  validateContactEmail(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/verifyEmail`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  contactInfo(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/viewContactInfo`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  customerCustomFields(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/customerCustomFields`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  orgCustomFields(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/orgCustomFields`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  createEQuoteContact(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/eQuoteContact`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  updateEQuoteContact(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/eQuoteContactUpdate`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  getOrganization(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/orgFind`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }


  createEQuoteOrg(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/eQuoteOrg`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }


  updateEQuoteOrg(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/eQuote-template/eQuoteOrgUpdate`,data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  updateLeadNote(data: any = ''): Observable<any> {
    return this.http.post(`${this.lead}/lead-note/updateEQuoteNote`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  merchantUserList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/merchantuser/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  merchantCustomerList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/merchantcustomer/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  defaultTemplate(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/lead-template/defaultList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }


  formContactList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/formcontacts/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  getCountryDataFromIp() {
    return this.http.get(`https://api.ipdata.co/?api-key=7310519aa7afd9ee1f093ae321e4af48e31c102c6945051e7cd71a24`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  get_payment_listing_list(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/paymentListingList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }
  get_payment_listing_list_filters(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/paymentsListing/paymentListingListFilters`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  createPaymentFilter(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPaymentFilters/savefilters`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  savePaymentFilterList(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPaymentFilters/saveFilterList`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

  deletePaymentFilter(data: any): Observable<any> {
    return this.http.post(`${this.QUOTE_TEMPLATE_URL}/quotesPaymentFilters/deleteSaveFilter`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return err;
      })
    );
  }

}
