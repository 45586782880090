import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalStorage } from '@ng-idle/core';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  BASE_URL: string;
  BASE_URL1:string;
  public totalSelectedDeals$: BehaviorSubject<any> = new BehaviorSubject([]);
  public sortData$: BehaviorSubject<any> = new BehaviorSubject({});
  public activeTabs$: Subject<any> = new Subject();
  public tableDataSource$: Subject<any> = new Subject();
  public cardLayoutViewData$: Subject<any> = new Subject();
  public leadDealCountData$: Subject<any> = new Subject();
  public resetFilterValue$: Subject<any> = new Subject();
  public resetFilterleadValue$: Subject<any> = new Subject();
  public filterTap$: Subject<any> = new Subject();
  public selectMat$: Subject<boolean> = new Subject();
  public varticalviewArr$: Subject<any> = new Subject();
  public viewFilter$: BehaviorSubject<any> = new BehaviorSubject('list_view');
  public deactivatedStatus$: Subject<any> = new Subject();
  public rotted_days$: Subject<any> = new Subject();
  public removelead$: Subject<any> = new Subject();
  public totalLeadCount$: Subject<any> = new Subject();
  public filtermodule$: Subject<any> = new Subject();
  public col_Togl$: Subject<any> = new Subject();
  public columns_Togl$: Subject<any> = new Subject();
  public menu_closed$: Subject<any> = new Subject();
  public removeleads$: Subject<any> = new Subject();
  public newdeal$: Subject<any> = new Subject();
  public dealtype$: Subject<any> = new Subject();
  public updateLeadTabs$: Subject<any> = new Subject();
  public closeFilter$: Subject<any> = new Subject();
  public lead_Activity_List$: Subject<any> = new Subject();
  public currencySymbal$: BehaviorSubject<any> = new BehaviorSubject('$');
  public currencyCode$: BehaviorSubject<any> = new BehaviorSubject('USD');
  

  public tagsArr$: Subject<any> = new Subject();
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.LEAD_MANAGEMENT_URL;
    this.BASE_URL1 = environment.API_URL;

    this.defaultCurrency();
  }

  defaultCurrency(){
    let merchantId=localStorage.getItem("merchant_id");
    let allMerchants =JSON.parse(localStorage.getItem("user_merchants"));
    for (let i = 0; i < allMerchants?.length; i++) {
      const ele:any = allMerchants[i];
      if(merchantId==ele.id && ele.merchant_default_currencies.length){
        this.currencySymbal$.next(ele.merchant_default_currencies[0].currencies.currency_symbol);
        this.currencyCode$.next(ele.merchant_default_currencies[0].currencies.currency_code);

      }
    }
    
  }

  leadlisting(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantdealtype/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addNewLead(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantcustomerlead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getDealDetails(id): Observable<any> {
    return this.http.get(this.BASE_URL + '/merchantcustomerlead/' + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }



  reasonTypelisting(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadreasontype/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  reasonlisting(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadreason/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  customerLeadListing(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantcustomerlead/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  customerLeadData(id): Observable<any> {
    return this.http.get(`${this.BASE_URL}/merchantcustomerlead/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  leadStagesList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantdealstage/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLeadStage(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantcustomerlead/updatestage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createPassword(user: any, password): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }

  addReasonType(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadreasontype`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addReason(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadreason`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateReason(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantleadreason`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  leadStageUpdate(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantcustomerlead/stageUpdate`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }



  deleteReason(id): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchantleadreason/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateCustomerLeadTags(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantcustomerlead/updatetags`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  merchantCustomerList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantcustomer/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  leadNote(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadnote/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  addLeadNote(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantleadnote`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLeadNote1(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantleadnote`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  ////deal api//
  dealStageList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/dealStageList`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  dealTagsList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/deal/allDeals/tag`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  dealStageListId(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/dealStageListById`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createDealStage(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/createDealStage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateDealStage(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/editDealStage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getDealTypeList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/deal/getDealTypeList`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteDealStage(id): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/deal/deleteDealStage/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createDealType(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/createDealType`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateDealType(data: any = '', id: any = ''): Observable<any> {
    if(id) {
      data.id = id;
    }
    return this.http.post(`${this.BASE_URL}/deal/editDealType`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteDealType(id): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/deal/deleteDealType/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteStageorDealType(data): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason-detail/remove/example/data`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  SaveDealDetail(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/editDealStage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ////Get Lead List with deal count//

  getLeadDealCount(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead/withDealCount`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  ////customer list api///
  getAllContactList(): Observable<any> {
    return this.http.get(this.BASE_URL + '/allContact/list').pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getAllContactListPage(data=''): Observable<any> {
    return this.http.post(this.BASE_URL + '/allContact/listPage',data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  convertCustomerToLead(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/convert-customer-tolead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ///get customer lead list//
  getCustomerLeadList(): Observable<any> {
    return this.http.get(this.BASE_URL + '/lead/getLeadList').pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  ///api deal info//
  createDeallInfo(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/create/lead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateDealInfo(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/update-lead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  relatedList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/relatedto/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLeadTags(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/update/tag/` + data?.id, data?.payLoadData).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  AllLeadTags(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/all-lead/tag/`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  removeDealInfo(id): Observable<any> {
    return this.http.delete(this.BASE_URL + '/lead-delete/' + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ///contact list api//
  getContactList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/contact/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }



  getLeadList(resource: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-list`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ///activity api//
  leadNoteActivity(data: any = ''): Observable<any> {
    return this.http.get(`${this.BASE_URL}/lead-note/activity-list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  creatLeadNote(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/addNote`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLeadNote(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/updateNote`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLeadNoteAttach(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/deleteNote/attachment`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  getNoteofLead(resource: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/getAllNote`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  markLeadAsImportant(id: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/isImportant', id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  ///managerReason///
  getReasonTypeListing(data: any = ''): Observable<any> {
    return this.http.get(`${this.BASE_URL}/reason/getReasonTypeList`, data).pipe(
      tap(
        data => data,
        error => error
      ));
  }

  createReasonType(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason/createReasonType`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteReasonType(id): Observable<any> {
    return this.http.delete(this.BASE_URL + '/reason/deleteReasonType/' + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  getOrganizationList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL1}/merchantorganization/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getReasonDetailList(id): Observable<any> {
    return this.http.get(this.BASE_URL + '/reason-detail/get/reason/' + id).pipe(
      tap(
        data => data,
        error => error
      ));
  }


  createReasonList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason-detail/create/reasonDetail`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  updateReasonList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason-detail/update/reason`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  deleteReasonList(id): Observable<any> {
    return this.http.delete(this.BASE_URL + '/reason-detail/delete/reason/' + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateDealStageOrder(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal/reorder/stage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateReasonOrder(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason-detail/reasonOrder`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ownerList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/lead/owners/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  assignOwner(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/assign/owner`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  leadcountStausBasis(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/leadcount-staus-basis`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  //reason-detail/list
  getReasonList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reason-detail/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  canceldeal(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/cancel-deal`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  ///Update Deal Status  (won, cancel, lost, abandoned)//
  updateDealStatus(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal-update-status`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  /*Deal list acc to type  ( Card layout)*/
  DealListToType(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/deal-list-acc-stage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  dealsSageUpdate(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/update-deal-stage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  stepsDataUpdate(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-step/steps-create`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getStepsData(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-step/steps-list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  //Download lead csv catalog 
  getDownloadcvsCatalog(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/convert/lead/csv`, { responseType: 'text' })
  }


  //remove lead 
  removeLeadDetail(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/remove-lead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  //reactive lead 
  reactiveLeadDetail(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reactive-lead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  reActivateLostDeal(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/reactivate-deal`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  // activity api
  creatActivity(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/activity-create`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  updateActivity(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/updateActivity`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  removeActivity(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/lead-note/removeActivity`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  // Deal Detail api
  dealDetail(id): Observable<any> {
    return this.http.get(`${this.BASE_URL}/lead-detail/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  getleadData(leadId) {
    return this.http.get(this.BASE_URL + '/get/leadData/' + leadId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  createContact(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/lead/createContact', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getTagsList(data: any = ''): Observable<any> {
    return this.http.get(`${this.BASE_URL}/get/allTag-List`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  activityValues(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/create-deafult/activity-values`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  reasonValues(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/create-deafult/reason-values`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }
  dealtypeValues(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/create-deafult/dealtype-values`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  customerCustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(environment.API_URL + '/customercustomfield/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

    customFieldList(data: any = ''): Observable<any> {
      return this.http.post(environment.API_URL + '/customfield/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }


    dealCustomFieldList(data: any = ''): Observable<any> {
      return this.http.post(this.BASE_URL + '/deal-custom/dealcustomfield/list', data).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
    }

    
  addDealCustomField(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/deal-custom/dealcustomfield', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

    deleteCustomField(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/deal-custom/dealcustomfield/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateCustomField(id, data: any = ''): Observable<any> {
    return this.http.put(this.BASE_URL + '/deal-custom/dealcustomfield/', { ...data, id })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  
}
