<div class="py-2" id="password" *ngIf="inputType != 'checkbox'">
    <label class="{{lableClass}}">{{labelText}}</label>
    <div class="input-group {{inputWrapperClass}}" *ngIf="inputType == 'password'">
        <input #input type="{{passwordFieldType}}" id="{{inputId ? inputName: inputName}}" name="{{inputName}}"
            class="{{inputClass}}" [ngClass]="{'border-danger' : touched && error}" [value]="fcontrol?.value"
            (keyup)="onChange($event, input.value)" placeholder="{{inputPlaceHolder ? inputPlaceHolder : labelText}}" />
        <a href="javascript:void(0)" (click)="passwordShowHide()" class="{{iconWrapperClass}}" role="button">
            <i class="{{passwordEyeIcon}}"></i>
        </a>
        <span class="error-message" *ngIf="touched && error && error.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </span>
        <span class="error-message" *ngIf="touched && error && error.pattern">
            {{errorMessage_pattern}}
        </span>
    </div>
    <div class="input-group {{inputWrapperClass}}"
        *ngIf="inputType =='text' || inputType =='email' || inputType =='number'">
        <input #input type="{{inputType}}" id="{{inputId ? inputName: inputName}}" name="{{inputName}}"
            class="{{inputClass}}" [ngClass]="{'border-danger' : touched && error }"
            (keyup)="onChange($event, input.value)" [value]="fcontrol?.value"
            placeholder='{{inputPlaceHolder  ? inputPlaceHolder : labelText}}' />
        <a *ngIf="icon" href="javascript:void(0)" class="{{iconWrapperClass}}" role="button">
            <i class="{{icon}}"></i>
        </a>
        <span class="error-message" *ngIf="touched && error && error.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </span>
        <span class="error-message" *ngIf="touched && error && error.pattern">
            {{errorMessage_pattern}}
        </span>
    </div>
</div>
<div class="py-2" id="password" *ngIf="inputType == 'checkbox'">
    <div *ngIf="inputType =='checkbox'" class="form-check form-switch d-flex gap-2 align-items-center ms-auto">
        <label class="text-muted fs-6">{{labelText}}</label>
        <input #input type="{{inputType}}" (change)="onChangecheckbox($event, input.value)" class="form-check-input input-switch m-0 ms-auto" value="{{currentValue}}" [checked]="checkbox" [value]="fcontrol?.value"> 
    </div>
</div>