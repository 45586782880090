import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-deleteorg',
  templateUrl: './deleteorg.component.html',
  styleUrls: ['./deleteorg.component.scss']
})
export class DeleteorgComponent implements OnInit {
  org_name: string;
  constructor(public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalModel) {
      dialogRef.disableClose = true;
    this.org_name = data.title;
  }

  ngOnInit(): void { }
  onConfirm(): void { this.dialogRef.close(true) }
  onDismiss(): void { this.dialogRef.close(false) }

}

export class ModalModel {
  constructor(public title: string) { }
}