import { DownloadFileComponent } from './pages/downloadfile/downloadfile.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './components/dash/header/header.component';
import { LoggedGuard } from './core/guards/logged.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { Auth2LayoutComponent } from './layouts/auth2-layout/auth2-layout.component';
import { Auth3LayoutComponent } from './layouts/auth3-layout/auth3-layout.component';
import { DashLayoutComponent } from "./layouts/dash-layout/dash-layout.component";
import { EmbedformComponent } from './pages/lead-form/embedform/embedform.component';
import { FormBuilderResolver } from './pages/lead-form/services/form-builder.resolver';
import { quoteEmbedformComponent } from './quote-template-new/embedform/embedform.component';
import { InvoicePaymentComponent } from './quote/invoice-payment/invoice-payment.component';
import { QuotePdfViewComponent } from './quote/quote-pdf-view/quote-pdf-view.component';
//Cambio a deechar
const routes: Routes = [
  {
    path: 'equote-ui',
    loadChildren: () => import('./pages/eQuote-templates/equote-ui/equote-ui.module').then(m => m.EquoteUiModule)
  },
  {
    path: '',
    redirectTo: 'auth/sign-in',
    pathMatch: 'full',
  }, {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren : () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  },{
    path: 'auth2',
    component: Auth2LayoutComponent,
    children: [
      {
        path: '',
        loadChildren : () => import('./layouts/auth2-layout/auth2-layout.module').then(m => m.Auth2LayoutModule),
        canLoad: [LoggedGuard],
        canActivate: [LoggedGuard]
      }
    ]
  },{
    path: 'dash',
    component: DashLayoutComponent,
    children: [
      {
        path: '',
        loadChildren : () => import('./layouts/dash-layout/dash-layout.module').then(m => m.DashLayoutModule),
        canLoad: [LoggedGuard],
        canActivate: [LoggedGuard]
      }
    ]
  },{
    path: 'auth3',
    component: Auth3LayoutComponent,
    children: [
      {
        path: '',
        loadChildren : () => import('./layouts/auth3-layout/auth3-layout.module').then(m => m.Auth3LayoutModule)
      }
    ]
  },{ path: 'lead-form-embed/:id', component:EmbedformComponent, resolve: {viewTemplateData: FormBuilderResolver} },
  { path: 'quote-form-embed/:id', component:quoteEmbedformComponent },
  { path: 'payment/:id', loadChildren:() => import('./quote/invoice-payment/invoice-payment.module').then(m=>m.InvoicePaymentModule) },
  { path: 'invoice/:id', loadChildren:() => import('./quote/quote-pdf-view/quote-pdf-view.module').then(m=>m.QuotePdfViewModule) },
  { path: 'downloadfile', loadChildren:() => import('./pages/downloadfile/downloadfile.module').then(m=>m.DownloadFileModule) },
  { path: 'e-quote-view/:id', loadChildren: () => import('./pages/eQuote-templates/view-equote-dialog/view-equote-dialog.module').then(m =>m.ViewEquoteDialogModule)},
  { path: 'maintenance', loadChildren:() => import('./pages/maintenance/maintenance.module').then(m=>m.MaintenanceModule) },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth/sign-in',
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: PreloadAllModules

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
