import { Component, OnInit, ElementRef, HostBinding, Inject, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from 'src/environments/environment';
import { BusinessProfileService } from '../../../pages/business-profile/services/business-profile.service';
import { addressElements } from '../../../shared/enum/address-elements';
import { TasksService } from '../../../pages/tasks/services/tasks.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import * as fromReducer from '../../../pages/tasks/reducers/task.reducer';
import { TaskAddModel } from './../../../pages/tasks/model/task-model';
import { TaskState } from './../../../pages/tasks/task.state';
import { CrudService } from 'src/app/services/crud.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SwitchToAnotherWorkspaceComponent } from 'src/app/Dialogs/switch-to-another-workspace/switch-to-another-workspace.component';
import { SignInService } from 'src/app/pages/sign-in/services/sign-in.service';
import { AlreadyMerchantLoginComponent } from 'src/app/Dialogs/already-merchant-login/already-merchant-login.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PermissionService } from 'src/app/services/permission.service';
import * as CryptoJS from 'crypto-js';
import { SocketService } from 'src/app/shared/socket/socketio.service';
import { io } from 'socket.io-client';
import { ItemsService } from 'src/app/pages/items/services/items.service';
import { UploadCsvComponent } from 'src/app/shared/model-component/upload-csv/upload-csv.component';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';
import { DataService } from 'src/app/quote/quote-listing/contact-quote.service';
import { OrganizationViewService } from 'src/app/pages/contact-profile/view-organization/services/view-organization.service';
import { ProfileService } from 'src/app/pages/contact-profile/services/contact-profile.service';
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/h/inicio', title: 'Inicio', icon: 'ni-tv-2 text-primary', class: '' },
];
declare var $: any;
declare var LOU: any;

@Component({
  selector: 'app-dash-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationArr: any = []
  public focus;
  isOpen: boolean;
  public userName;
  public userProfilePic;
  public userMerchants;
  public langs = ['en', 'es'];
  public imageUrl = environment.IMAGE_BACKET_URL;
  public langSelect = 'en';
  public listTitles: any[] = [];
  public location: Location;
  public merchantId;
  public currentMerchant;
  public notificationInterval;
  viewOrganizationArr:any;
  taskListDateArr: any;
  templatePayload:any;
  notifictionCount: number = 0;
  notifictionCountAll: number = 0;
  selectedContact:any;
  template_id:any;
  listingNotification: any = [];
  listingNotificationAll: any = [];
  reminderListingNotification: any = [];
  reminderListingNotificationAll: any = [];
  subscriptions: Subscription[] = [];
  // LOU = LOU
  serchArr: any = {}; // hanldes the pagination and filters
  public permission;
  private socket: any;
  private loadingNotificaitons = false;
  private loadingNotificaitonsAll = false;
  private itemCount = 10; // items per page 
  imagesNotification:any;
  public openDrawer = false;
  customerLeadDetailsArr:any;
  public allNotifications;
  quote_data:any;
  allNotificationsCount;
  @Output() dataEvent = new EventEmitter<boolean>();
  progressData: { jobId: string; progress: number,name:string }[] = [];
  constructor(
    location: Location,
    private datePipe: DatePipe,
    private element: ElementRef,
    private router: Router,
    private route: Router,
    private translate: TranslateService,
    public overlayContainer: OverlayContainer,
    private businessProfileService: BusinessProfileService,
    private TasksService: TasksService,
    private store: Store<TaskState>,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private _gService: CrudService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private permissionService: PermissionService,
    private SignInService: SignInService,
    private srv :SocketService,
    private itemsService: ItemsService,
    public QuoteTemplateServices: QuoteTemplateService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    public quoteTemplateService: QuoteTemplateService,
    private organizationservice: OrganizationViewService,
    private profileService: ProfileService,
    private translateService: TranslateService,

  ) {
    this.socket = io(environment.API_URL);
    this.socket.on('connection', (socket) => {
      console.log('a user connected');
      socket.on('disconnect', () => {
        console.log('user disconnected');
      });
    });
   this.socket.on('jobProgress', (data) => {
    this.quote_data=data
      // Find the index of the progress data with the matching job ID
      const index = this.progressData.findIndex((res) => res?.jobId === data?.jobId?.id);
      this.imagesNotification=this.progressData?.length

      if (index !== -1) {
        // Update the existing progress data
        this.progressData[index].progress = data?.progress;
        this.imagesNotification=this.progressData?.length

      }
      else if(data?.invoice_id){
        console.log(data,'datadatadatadata')
       this.notificationListing()
       this.template_id=data?.template_id

      }
      else {
        // Add a new progress data entry
        this.progressData.push({jobId:  data?.jobId?.id, progress: data?.progress,name: data?.jobId?.data.selected_column});
      }
    });
    this.location = location;
    const userData = localStorage.getItem('loginUserValue') && JSON.parse(localStorage.getItem('loginUserValue'));
    translate.setDefaultLang('en');
    translate.use(userData?.languages?.locale || 'en');
    this.merchantId = localStorage.getItem('merchant_id');
    this.store.select(fromReducer.getTasks).subscribe(async res => {
      if (res.data) {
        this.setNotificationData(res);
      }
    });
  }
  
  @HostBinding('class') componentCssClass: any;
  theme = "";
  async ngOnInit() {
    this.dataEvent.emit(false);
    this.permission = this.permissionService.retrivePermission();
    this.notificationListing();
    this.srv.listen(localStorage.getItem('userId')+localStorage.getItem('merchant_id')).subscribe((res: any) => {
      this.loadingNotificaitons = false;
      this.notificationListing();
    });

    this.userName = localStorage.getItem('fname');
    this.userProfilePic = this.imageUrl + localStorage.getItem('profile_pic');
    this.userMerchants = JSON.parse(localStorage.getItem('user_merchants'));

    const getUserMerchantSubs = this.SignInService.getUserMerchant({ id: localStorage.getItem('userId'), accessId: localStorage.getItem('accessId') }).subscribe(result => {
      this.userMerchants = result.data.merchants
      if (this.merchantId) {
        this.userMerchants.forEach(merchant => {
          if (merchant.id == this.merchantId) {
            if(this.userMerchants?.profile_pic){
              merchant.profile_photo=this.userMerchants?.profile_pic
            }
            this.currentMerchant = merchant;
          }
        });
      }
    });

    this.subscriptions.push(getUserMerchantSubs);

    const taskListSubs = this.TasksService.taskList().subscribe(async res => {
      this.setNotificationData(res);
    });

    this.subscriptions.push(taskListSubs)

    if (!localStorage.getItem('country_list')) {
      const countryListSubs = this.businessProfileService.countryList().subscribe((val: any) => {
        localStorage.setItem('country_list', JSON.stringify(val.data));
      });
      this.subscriptions.push(countryListSubs);
    }

    if (this.merchantId) {
      this.userMerchants.forEach(merchant => {
        if (merchant.id == this.merchantId) {
          if(this.userMerchants?.profile_pic){
            merchant.profile_photo=this.userMerchants?.profile_pic
          }
          this.currentMerchant = merchant;
        }
      });
    } else {
      this.currentMerchant = this.userMerchants[0];
    }

    this.listTitles = ROUTES.filter(listTitle => listTitle);

    let theme = window.localStorage.getItem("theme")
    if (theme) {
      this.onSettheme(theme)
    } else {
      this.onSettheme("ligth-theme")
    }
  }

  openAllNotification() {
    this.openDrawer = !this.openDrawer;
    this.viewAllNotificationListing();
  }

  toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  getSafeHtml(htmlString: string): SafeHtml {
    let translation =  ''
    if(htmlString.includes("<a")) {
      let splitText = htmlString.split('<a');
      let linkString = '<a' + splitText[1];
      translation = this.translateService.instant(splitText[0].trim());
      translation = translation.replace('{link}',linkString.split('here')[0]);
      translation = translation.replace('{/link}','</a>')
     } else {
      translation = this.translateService.instant(htmlString);
     }
    const decodedHtml = decodeURIComponent(translation);
    const trustedHtml = this.sanitizer.bypassSecurityTrustHtml(`${decodedHtml}`);
    const safeHTML = trustedHtml["changingThisBreaksApplicationSecurity"];
    return safeHTML;
  }

  openSideBar(){
    console.log('Before toggle:', this.dataEvent);
    this.dataEvent.emit(true);
    console.log('After toggle:', this.dataEvent);
  
  }
  
  notificationListing() {    
    if(!this.loadingNotificaitons && localStorage.getItem('token')) {
      this.loadingNotificaitons = true;
      const notificationListingSubs = this.TasksService.notificationListing({viewAll: false, hideSpinner: true}).subscribe((res: any) => {
        this.listingNotification = res.body?.data?.rows;
        let notificationCountInt = res.body?.data?.count;
        this.notifictionCount = notificationCountInt;

        // const notificationListingSubs1 = this.TasksService.taskReminderList({reminders: 1, hideSpinner: true}).subscribe((res: any) => {  
        //   this.reminderListingNotification = res?.reminders;          
        //   this.reminderListingNotification = this.reminderListingNotification[this.toJSONLocal(new Date())];
        //   if(this.reminderListingNotification) {
        //     notificationCountInt = notificationCountInt + this.reminderListingNotification?.length;
        //   }
        //   this.notifictionCount = notificationCountInt;
        //   this.loadingNotificaitons = false;
        // });

        // this.subscriptions.push(notificationListingSubs1);
      });
      
      this.subscriptions.push(notificationListingSubs);
    }    
  }

  viewAllNotificationListing() {
    if(!this.loadingNotificaitonsAll) {
      this.loadingNotificaitonsAll = true;
      const notificationListingSubs22 = this.TasksService.notificationListing({viewAll: true}).subscribe((res: any) => {
        this.listingNotificationAll = res.body?.data?.rows;
        let notificationCountIntAll = res.body?.data?.count;
        this.notifictionCountAll = notificationCountIntAll;
  
        // const notificationListingSubs21 = this.TasksService.taskReminderList({reminders: 1, viewAll: true}).subscribe((res: any) => {        
        //   this.reminderListingNotificationAll = res?.reminders;
        //   this.reminderListingNotificationAll = this.reminderListingNotificationAll[this.toJSONLocal(new Date())];
        //   if(this.reminderListingNotificationAll) {
        //     notificationCountIntAll = notificationCountIntAll + this.reminderListingNotificationAll?.length;
        //   }
        //   this.notifictionCountAll = notificationCountIntAll;
        //   this.loadingNotificaitonsAll = false;
        // });
        // this.subscriptions.push(notificationListingSubs21);
      });
      
      this.subscriptions.push(notificationListingSubs22);
    }    
  }

  notificationReadAll(view: any) {
    const notificationAllReadSubs = this.TasksService.notificationRead({
      "type": "update",
      "id": "all"
    }).subscribe(async (res: any) => {
      this.notifictionCount = 0;
      this.listingNotification = null;
      console.log(view);
      if(view) {
        this.loadingNotificaitonsAll = false;
        this.viewAllNotificationListing();
      }
    });
    this.subscriptions.push(notificationAllReadSubs);
  }

  notificationRead(data: any) {
      const notificationReadSubs = this.TasksService.notificationRead({
        "type": "update",
        "id": data.id
      }).subscribe(async (res: any) => {
        if (!data.is_read) {
          if (this.notifictionCount != 0) {
            this.notifictionCount = --this.notifictionCount;
          }
        }
        data.is_read = true;
        if(data.moduleId && (data.module == 'automation' || data.module == 'task')) {
          this.openDrawer = false;
          if(!data.notifications_data) {
            this.viewTask(data.moduleId);
          } else {
            this.viewTask(data.moduleId);
          }
          
        } else {
          if (data.module == "csv_upload" || data.module == "item_csv_upload" || data.module == "quote_module") {
            if(data.module == "item_csv_upload"){
              await this.getUploadedItems()
              }else if(data.module == "quote_module"){
    
                this.addCateogry(data?.moduleId)
                // this.route.navigate(['/dash/quote-listing']);
              }
              
              else{
                const locatedPath = this.location.path();
                if (locatedPath == '/dash/contacts') {
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate(['dash/contacts']);
                  });
                } else {
                  this.route.navigate(['/dash/contacts']);
                }
            }
          }
        }
      });
      this.subscriptions.push(notificationReadSubs);    
  }
  getImage(image) {
    if (image) {
      return this.imageUrl + image;
    }
    else {
      return 'assets/img/home/profile-logo.gif'
    }
  }
  getUploadedItems(){
    this.serchArr = {
      pagination: true,
      limit: this.itemCount,
      page: 1,
    }
    const itemSearchSubs = this.itemsService.itemSearchUploaded(this.serchArr).subscribe(res => {
      if(res?.data?.length){
        this.uploadItemsImages(res)
      }else{
        this.SuccessSnackBar(this.translate.instant('Your Items is not Uploaded Please Wait..'), '');
      }
    })
  }
  setNotificationData(res) {
    this.notificationArr = [];
    this.taskListDateArr = res.data['Tasks'];
    const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    let calenderData = res.reminders ? res.reminders[today] : [];
    if (calenderData) {
      this.notificationArr = [...this.notificationArr, ...calenderData]
    }
  }
  viewTask(id) {
    // this.openAllNotification()
    localStorage.setItem('viewTaskId', id);
    this.router.navigate(['/dash/tasks/' + id + '/' + _.random(10000, 99999)]);
  }

  public onSettheme(e: string) {
    this.document.body.classList.remove(e !== 'ligth-theme' ? 'ligth-theme' : 'dark-theme');
    this.document.body.classList.add(e);
    this.componentCssClass = e;
    this.theme = this.componentCssClass
    window.localStorage.setItem("theme", e);
  }

  updateMerchant(merchant) {
    if (merchant?.id != localStorage.getItem("merchant_id")) {
        this.selectMerchant(merchant, );
    }
  }

  private selectMerchant(merchant) {
    let alreadyLoginDialogRef: any;
    const getSessionSubs =  this.SignInService.getSession({ id: localStorage.getItem('userId'),email: localStorage.getItem('email'),merchant_id: merchant.id}).subscribe((result)=>{
      if(result){
        if(result.status == 'expired') { 
          localStorage.setItem('merchant_id', merchant.id);
          localStorage.setItem('osmos_access_level', merchant?.osmos_access_level);
          localStorage.setItem('role', merchant?.role);
          localStorage.setItem('permission', this.permissionService.encryptData(JSON.stringify(merchant.permission)));
          this.router.navigate(['/auth2/free-trial-over']);
      } else {
        alreadyLoginDialogRef = this.dialog.open(
            AlreadyMerchantLoginComponent,
            { disableClose: true, width: '500px',height: '320px'}
        );
        alreadyLoginDialogRef.afterClosed().subscribe((action: any) => {
          if (action) {
              this.logout(false);
              this.currentMerchant = merchant;
              localStorage.setItem('merchant_id', merchant.id);
              localStorage.setItem('osmos_access_level', merchant.osmos_access_level);
              localStorage.setItem('permission', this.permissionService.encryptData(JSON.stringify(merchant.permission)));
              const email = localStorage.getItem('email')
              const merchant_id = merchant.id;
              const id = localStorage.getItem('userId');
              const device_login_id = CryptoJS.AES.encrypt(localStorage.getItem('email'), environment.ENCRYPTPASSWORD).toString();
          const updateMerchantSubs =  this.SignInService.updateMerchant({id, device_login_id, email, merchant_id }).subscribe((result) => {
                localStorage.setItem('deviceLoginId', device_login_id);
                // this.router.navigate(['/dash/home']);
                window.location.href = '/dash/home';
              },(error) => {
                this.logout();
                this.router.navigate(['/auth/sign-in']);     
              });
              this.subscriptions.push(updateMerchantSubs);
          }
        });
        this.subscriptions.push(getSessionSubs);
      }
    } else {
      this.logout(false);
      const deviceLoginId = CryptoJS.AES.encrypt(localStorage.getItem('email'), environment.ENCRYPTPASSWORD).toString();
      const createSessionSubs = this.SignInService.createSession({ ignore: 0, id: localStorage.getItem('userId'),email: localStorage.getItem('email'),merchant_id:merchant.id, device_login_id: deviceLoginId}).subscribe((result) => {
        if(result.status == 'expired') {
          this.currentMerchant = merchant;
          localStorage.setItem('merchant_id', merchant.id);
          localStorage.setItem('osmos_access_level', merchant.osmos_access_level);
          localStorage.setItem('permission', this.permissionService.encryptData(JSON.stringify(merchant.permission)));
          this.router.navigate(['/auth2/free-trial-over']);
        } else if(result.status == 'blocked') {
          this.SuccessSnackBar(this.translate.instant('SignIn.AccountSuspended'), '');
        } else {
          this.currentMerchant = merchant;
          localStorage.setItem('deviceLoginId', deviceLoginId);
          localStorage.setItem('merchant_id', merchant.id);
          localStorage.setItem('osmos_access_level', merchant.osmos_access_level);
          localStorage.setItem('permission', this.permissionService.encryptData(JSON.stringify(merchant.permission)));
          // this.router.navigate(['/dash/home']);
          window.location.href = '/dash/home';
        }
      },(error) => {
        this.logout();
      });
      this.subscriptions.push(createSessionSubs);
    }
  })  
  }

  

  private _removeSession(body) {
   const postUserManagementSubs = this._gService.postUserManagement(`merchantuser/removeusersession`, body).subscribe(result => {
    console.log(result,"result")
    }, error => {
      console.log(error,"error")
    });
    // this.subscriptions.push(postUserManagementSubs);
  }

  logout(isLogout = true) {
    const payload = {
      id: localStorage.getItem('userId'),
      email: localStorage.getItem('email'),
      merchant_id: localStorage.getItem('merchant_id'),
      device_id: localStorage.getItem('deviceLoginId')
    }
    this._removeSession(payload)
    localStorage.removeItem('merchant_id');
    localStorage.removeItem('deviceLoginId');
    if (isLogout) {
      localStorage.removeItem('reminderList');
      localStorage.removeItem('profile_pic');
      localStorage.removeItem('user_merchants');
      localStorage.removeItem('osmos_access_level');
      localStorage.removeItem('loginUserValue');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('fname');
      localStorage.removeItem('lname');
      localStorage.removeItem('create-session');
      localStorage.removeItem('redirect-merchant');
      localStorage.removeItem('email');
      localStorage.removeItem('permission');
      localStorage.removeItem('role');
      localStorage.removeItem('accessId');
      localStorage.removeItem('country_list');
      clearInterval(this.notificationInterval);      
      this.router.navigate(['/auth/sign-in']);
    }

  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    return 'Inicio';
  }

  changeLang(lang) {
    this.translate.use(lang);
    this.langSelect = lang;
  }

  newAccount() {
    // const dialogRef = this.dialog.open(
    //     SwitchToAnotherWorkspaceComponent,
    //     { disableClose: true, width: '500px',height: '320px'}
    // );
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.logout(false);
    //     this.router.navigate(['/auth2/onboarding']);
    //   }
    // })
    this.router.navigate(['/auth2/onboarding']);
  }

  search() {
    $('.ico-header').css('display', 'none');
    $('.navbar-toggler').css('opacity', '0').css('position', 'absolute').css('z-index', '-1');
    $('.input-search-h').css('display', 'flex');
  }
  searchExit() {
    $('.ico-header').css('display', 'flex');
    $('.navbar-toggler').css('opacity', '1').css('position', 'relative').css('z-index', '0');
    $('.input-search-h').css('display', 'none');
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  otherWorkspace() {
    console.log('otherWorkspace')
    const dialogRef = this.dialog.open(
      SwitchToAnotherWorkspaceComponent,
      { disableClose: true, width: '500px', height: '320px' }
    );
  }

  SuccessSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = 'notif-error';
    this.snackbar.open(msg, tag, config);
  }

  uploadItemsImages(data){
    const dialogRef = this.dialog.open(UploadCsvComponent, {
      width: '600px',
      maxHeight:'620px',
      data: { checkingItems: 'newImages',itemsData:data }
    });
  
    dialogRef.afterClosed().subscribe(async (result) => {
      // if (result.status) {
      //  await this.getItemList();
      // }
    });
  }

  async addCateogry(cateogryForm:any='') {
    // if (cateogryForm.form.status === 'INVALID') {
    //   this.throwError = true;
    //   return;
    // }
  
    try {
      if(cateogryForm !==''){
        await this.getData(cateogryForm)
      }
      // Wait for businessPatchdata to complete
      if(this.selectedContact && this.selectedContact?.id){
        await this.contactDeal(this.selectedContact?.id)
  
      }
  
      if (this.selectedContact && this.selectedContact?.merchant_organization_members && this.selectedContact?.merchant_organization_members?.merchant_organization?.id) {
        // Wait for organizationPatchdata to complete
        await this.organizationPatchdata(this.selectedContact?.merchant_organization_members?.merchant_organization?.id);
      }
      // business: this.workSpaceData
        const data={ contact: this.selectedContact, quote:this.templatePayload?.quote_template_id?{template_id:this.templatePayload?.quote_template_id,template_style:this.templatePayload?.field_styles,quote_name:cateogryForm?.quote_name}: cateogryForm?.form?.value?.title, org: this.viewOrganizationArr ,deals:this.customerLeadDetailsArr,business:this.templatePayload?.merchantDetails};
        this.dataService.updateData(data);
        this.router.navigate([`dash/create-quote-template/${this.templatePayload?.quote_listing_id}`]);

      
    } catch (error) {
      console.error('Error in addCateogry', error);
      // Handle error if needed
    }
  }

  contactDeal(data: any = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const merchantleadDetailSubs = this.profileService.getleadData(data).subscribe(val => {
        this.customerLeadDetailsArr = val.data;
        resolve(this.customerLeadDetailsArr);
      }, error => {
        reject(error);
      });
    });
  }
  
  organizationPatchdata(data: any = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const organizationListSubs = this.organizationservice.viewOrganization(data).subscribe(res => {
        this.viewOrganizationArr = res.data;
        resolve(this.viewOrganizationArr);
      }, error => {
        reject(error);
      });
    });
  }

  getData(id: any = ''): Promise<any> {
    console.log(id,'idididid')
    return new Promise((resolve, reject) => {
      const organizationListSubs = this.QuoteTemplateServices.quote_pdf_list({invoice_id:id}).subscribe((res: any) => {
        const decodedPayloads = JSON.parse(decodeURIComponent(escape(atob(res.data[0]?.payloads))));
        console.log(decodedPayloads,'decodedPayloadsdecodedPayloads')
        this.templatePayload=decodedPayloads[0]
        this.selectedContact=decodedPayloads[0]?.contactDetails
        resolve(this.selectedContact);
      }, error => {
        reject(error);
      });
    });
  
}
}