import { LOCALE_ID, Inject, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  language:any;
  termsConditionsLink : string = 'https://www.osmoscloud.com/termsOfService';
  privacyPolicy: string = 'https://www.osmoscloud.com/privacyPolicy';

  constructor(
    @Inject(LOCALE_ID) public locale: string,
        private activatedRoute: ActivatedRoute,
    
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.language=params?.language;
      const lang = localStorage.getItem('language');
      if(this.language == 'es' || lang == 'es'){
        this.termsConditionsLink = 'https://www.osmoscloud.com/es/termsOfService';
        this.privacyPolicy = 'https://www.osmoscloud.com/es/privacyPolicy';
      } else if(this.language == 'fr' || lang == 'fr'){
        this.termsConditionsLink = 'https://www.osmoscloud.com/fr/termsOfService';
        this.privacyPolicy = 'https://www.osmoscloud.com/fr/privacyPolicy';
      } else {
        this.termsConditionsLink = 'https://www.osmoscloud.com/termsOfService';
        this.privacyPolicy = 'https://www.osmoscloud.com/privacyPolicy';
      }
   })
  }

}
