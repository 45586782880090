import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { FormControl } from '@angular/forms';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { WidgetService } from 'src/app/widgets/services/widget.service';
import { TranslateService } from '@ngx-translate/core';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';


@Component({
   selector: 'advfilterPayment',
  templateUrl: './advancefilterPayment.component.html',
  styleUrls: ['./advancefilterPayment.component.scss']
})
export class AdvancefilterPaymentComponent implements OnInit,OnChanges {
  @Output() getFilterList: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('advfMenu') advfMenu: MatMenuTrigger;
  @Input() type:string= ''

  itemCount = 10;
  globalSearchText: any = '';
  sortField: any = 'created_at';
  sortOrder: any = 'DESC';
  where: any;
  savefiltercolor: any;
  userMerchants: any;
  sliderValue: number = 0;
  sliderMaxValue: number = 0;
  statusSearchTags=''
  contactSearchTags=''
  selectable = true;
  removable = true;
  allOrgArr:any;
  searchTags: string = '';
  paymentStatusArr:any=[{id:1,name:'paid'},{id:2,name:'due'},{id:3,name:'past due'},{id:4,name:'refunded'},{id:5,name:'due today'}]
  sliderOptions: Options = {
    floor: 0,
    ceil: 100000,
    step:300,
    translate: (value: number, label: LabelType): string => {
      //this.savedFilter.slider_value = this.sliderValue + '-' + this.sliderMaxValue;
      switch (label) {
        case LabelType.Low:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
        case LabelType.High:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
        default:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
      }
    }
  };
  savedFilter: any = {};
  singale_value = new FormControl();
  deal_typeArr: any[] = [];
  deal_stageArr: any[] = [];
  activityArr: any[] = [];
  @Input() tagsArr: any[] = [];
  @Input() allTagsArr: any[] = [];
  @Input() customerCustomFieldArr: any[] = [];
  stageDummyArr: any[] = [];
  activityDummyArr: any[] = [];
  orgDummyArr: any[] = [];
  tagsDummyArr: any[] = [];
  customFieldDummyArr: any[] = [];
  viewData: any;
  totalCount: number;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  deactivatedleads: any;
  rotted_days: any;
  deal_status: any;
  merContactListArr:any;
  statusDummyArr: any[] = [];
  conDummyArr: any[] = [];
  page: any = {
    currentPage: 1,
    pageSize: 10,
  }
  serchArr: any = {};

  currentPage:any=0

  constructor(
    public leadService: LeadService,
    public WidgetService: WidgetService,
    private translateService: TranslateService,
    public QuoteTemplateServices: QuoteTemplateService,

  ) { }

  ngOnInit(): void {
   
    if(sessionStorage.getItem('appliedFilters')) {
      this.savedFilter = JSON.parse(sessionStorage.getItem('appliedFilters'));
      console.log(this.savedFilter,'this.savedFilter')
      this.splitValue()
      this.patchValues()
     
    }    
    // this.getViews();
    this.getOrgList()
    this.getMerchantCustomer()
    this.userMerchants = JSON.parse(localStorage.getItem('user_merchants'));
    this.leadService.activeTabs$.subscribe((res) => { this.deal_status = res; });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes){
    //   this.ngOnInit()
    // }
  }
  checkIfNameMatchesContact(firstName: string, lastName: string): boolean {
    
    return this.savedFilter?.contact?.some(contact => 
      contact?.first_name === firstName && contact?.last_name === lastName
    );
  }

  getCurrency() { return this.userMerchants[0]?.merchant_details?.currencies?.currency_symbol || '' }
  getMerchantCustomer(data: any = '') {
    this.serchArr = {
      pagination: true,
      limit: this.page?.pageSize,
      page: this.page?.currentPage || 1,
      whereFilterData: this.where,
      search: this.globalSearchText ? this.globalSearchText : '',
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    const merchantUserListSubs = this.QuoteTemplateServices.merchantUserList({list: 1}).subscribe(res => {
      if (res.data) {
        this.currentPage = res.currentPage;
        this.merContactListArr = res.data.map((item: any) => {
          return {
            ...item,
            name:item?.fname,
            checked: this.checkIfNameMatchesContact(item?.fname, item?.lname)
          };
        });
      }
    });
  
    // const getCustomerSubs = this.contactService.getCustomer(this.serchArr).subscribe(result => {  
    //   if (result) {
    //     this.currentPage = result.currentPage;
    //     this.merContactListArr = result.data.map((item: any) => {
    //       return {
    //         ...item,
    //         name:item?.firstname,
    //         checked: this.checkIfNameMatchesContact(item?.firstname, item?.lastname)
    //       };
    //     });
    //   }
    // });
  }
  getAccorValue(event: any, value: any, from: any) {
    if (from === 'organization') {
      if (event?.checked) {
        this.orgDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['organization'] = this.orgDummyArr;
      } else {
        this.orgDummyArr?.splice(this.orgDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['organization'] = this.orgDummyArr;
      }   
     }



    

   

    if (from === 'customFields') {      
      if (event?.checked) {
        this.customFieldDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['customFields'] = this.customFieldDummyArr;
      } else {        
        this.customFieldDummyArr?.splice(this.customFieldDummyArr?.findIndex((obj) => obj?.id+'--'+obj?.name === value?.id+'--'+value?.name), 1);
        console.log(this.customFieldDummyArr)
        this.savedFilter['customFields'] = this.customFieldDummyArr;
      }
    }

    if (from === 'singale_value') {
      this.savedFilter['singale_value'] = value;
      this.sliderValue = 0;
      this.sliderMaxValue = 0;
      this.savedFilter.slider_value = null;
    }

  if (from === 'slider_value') {
      this.savedFilter['slider_value'] = `${this.sliderValue}-${this.sliderMaxValue}`;
      if (event || value) {
        this.singale_value.setValue(null);  // Ensure this is the correct form control
        this.savedFilter.singale_value = ''; // Reset savedFilter singale_value
      }
    }

    if (from === 'status') {
      if (event?.checked) {
        this.statusDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['status'] = this.statusDummyArr;
        console.log(this.savedFilter,'this.savedFilter')
      } else {
        this.statusDummyArr?.splice(this.statusDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['status'] = this.statusDummyArr;
      }
    }

    if (from === 'member') {
      if (event?.checked) {
        this.conDummyArr.push({ first_name: value?.fname, last_name: value?.lname });
        this.savedFilter['member'] = this.conDummyArr;
        console.log(this.savedFilter,'this.savedFilter')
      } else {
        this.conDummyArr?.splice(this.conDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['member'] = this.conDummyArr;
      }   
      
     }

    if (from === 'singale_date') {
      this.savedFilter['singale_date'] = event;
      this.savedFilter.start_date = null;
      this.savedFilter.end_date = null;
      this.savedFilter.from_to_date = null;

    }

    if (value === 'fromtodate') {
      if (from === 'start_date') {
        this.savedFilter['start_date'] = event;
        this.savedFilter.singale_date = null;
      }
      if (from === 'end_date') {
        this.savedFilter['end_date'] = event;
        this.savedFilter.singale_date = null;
      }
      this.savedFilter['from_to_date'] = (this.savedFilter?.end_date != null && this.savedFilter?.start_date != null) ? (this.savedFilter?.start_date + ' to ' + this.savedFilter?.end_date) : null;
    }

   
    
  }

  removeFilter(id, type) {
    if (type === 'deal_type') {
      this.savedFilter?.deal_type?.splice(this.savedFilter?.deal_type?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'deal_stage') {
      this.savedFilter?.deal_stage?.splice(this.savedFilter?.deal_stage?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'status') {
      this.savedFilter?.status?.splice(this.savedFilter?.status?.findIndex((obj) => obj?.id === id), 1);
      this.tagsArr = this.tagsArr?.map(e => (e.id === id ? { ...e, checked: false } : e));
    }
    if (type === 'member') {
      this.savedFilter?.member?.splice(this.savedFilter?.member?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'activity') {
      this.savedFilter?.activity?.splice(this.savedFilter?.activity?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'organization') {
      this.savedFilter?.organization?.splice(this.savedFilter?.organization?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'tags') {
      this.savedFilter?.tags?.splice(this.savedFilter?.tags?.findIndex((obj) => obj?.id === id), 1);
      this.tagsArr = this.tagsArr?.map(e => (e.id === id ? { ...e, checked: false } : e));
    }
    if (type === 'customFields') {
      this.savedFilter?.customFields?.splice(this.savedFilter?.customFields?.findIndex((obj) => obj?.id === id), 1);
      this.customerCustomFieldArr = this.customerCustomFieldArr.map(e => (e.id === id ? { ...e, checked: false } : e));
    }
    if (type === 'singale_value') {
      this.savedFilter.singale_value = '';
      this.singale_value?.setValue(null);
    }
    if (type === 'slider_value') {
      this.sliderValue = 0;
      this.sliderMaxValue = 0;
      this.savedFilter.slider_value = null;
    }
    if (type === 'singale_date') {
      this.savedFilter.singale_date = null;
    }
    if (type === 'from_to_date') {
      this.savedFilter.from_to_date = null;
      this.savedFilter.end_date = null;
      this.savedFilter.start_date = null;
    }
    this.deactivatedleads = 0;
    this.rotted_days = 0;
  }

  toggle(event: any, type: string) {
    if (type === 'rotten_days') {
      this.rotted_days = event?.checked ? 1 : 0;
      this.leadService.rotted_days$.next(this.rotted_days);
      this.getAccorValue('', '', 'rotten_days');
    }

    if (type === 'in_active_deals') {
      this.deactivatedleads = event?.checked ? 1 : 0;
      this.leadService.deactivatedStatus$.next(this.deactivatedleads);
      this.getAccorValue('', '', 'in_active_deals');
    }

  }

  saveFilter() {
    let payload = {
      pagination: true,
      limit: this.itemCount,
      page: 1,
      search: this.globalSearchText ? this.globalSearchText : '',
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      whereFilterData: [{
        deal_status: "",
        activity_id: [],
        deal_type_id: [],
        deal_stage_id: [],
        organization_id:[],
        contactName:[],
       
      }]
    }

    if (this.savedFilter) {
console.log(this.savedFilter,'this.savedFilter')
      if (this.savedFilter?.actions) {
        this.savedFilter?.actions?.forEach((ac, i) => {
          payload.whereFilterData[0]['activity_id']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.organization) {
        this.savedFilter?.organization?.forEach((ac, i) => {
          payload.whereFilterData[0]['organization_id']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.contact) {
        this.savedFilter?.contact?.forEach((ac, i) => {
          payload.whereFilterData[0]['contactName']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.status) {
      
          this.savedFilter?.status?.forEach((ac, i) => {
            payload.whereFilterData[0]['status_id']?.push(ac?.id)
          })
        
      
      };
      if (this.savedFilter?.customFields) {
        this.savedFilter.customFields.forEach((ac, i) => {
          const id = ac?.id;
          if (!payload.whereFilterData[0]['customField'].includes(id)) {
            payload.whereFilterData[0]['customField'].push(id);
          }
        });
      };
      if (this.savedFilter?.deal_type) {
        this.savedFilter?.deal_type?.forEach((ac, i) => {
          payload.whereFilterData[0]['deal_type_id']?.push(ac?.id)
        })
      };
      if (this.savedFilter?.deal_stage) {
        this.savedFilter?.deal_stage?.forEach((ac, i) => {
          payload.whereFilterData[0]['deal_stage_id'].push(ac?.id)
        })
      };
      if (this.savedFilter?.singale_value) {
        payload.whereFilterData[0]['single_value'] = this.savedFilter?.singale_value;
      };

      if (this.savedFilter?.total_amount ) {
        payload.whereFilterData[0]['total_amount'] = this.savedFilter?.total_amount;
      };
      if (this.savedFilter?.due_date ) {
        payload.whereFilterData[0]['due_date'] = this.savedFilter?.due_date;
      };
      if (this.sliderValue) {
        payload.whereFilterData[0]['min_value'] = this.sliderValue;
      };
      if (this.sliderMaxValue) {
        payload.whereFilterData[0]['max_value'] = this.sliderMaxValue;
      };
      if (this.savedFilter?.singale_date) {
        payload.whereFilterData[0]['singale_date'] = this.savedFilter?.singale_date;
      };

      if (this.savedFilter?.start_date) {
        payload.whereFilterData[0]['start_date'] = this.savedFilter?.start_date;
      };
      if (this.savedFilter?.end_date) {
        payload.whereFilterData[0]['end_date'] = this.savedFilter?.end_date;
      };
    }
    payload.whereFilterData[0]['show_deactivated_leads'] = this.deactivatedleads ? this.deactivatedleads : 0;
    payload.whereFilterData[0]['show_rotten_deals'] = this.rotted_days ? this.rotted_days : 0;
    payload.whereFilterData[0]['deal_status'] = this.deal_status;
    payload.whereFilterData = [this.clean(payload?.whereFilterData[0])];
    // localStorage.setItem('leadAppliedFilter', JSON.stringify(payload.whereFilterData))

    console.log("filters list:",this.savedFilter);
    sessionStorage.setItem("appliedFilters",JSON.stringify(this.savedFilter))
    if (this.viewData === 'list_view') {
      this.getFilterList.emit(this.savedFilter)
      if (this.advfMenu) {
        this.advfMenu.closeMenu();
      }

    }

    if (this.viewData === 'grid_view') {
      console.log(this.savedFilter,'this.savedFilter')
      this.getFilterList.emit(this.savedFilter)
      if (this.advfMenu) {
        this.advfMenu.closeMenu();
      }

      // this.leadService.resetFilterleadValue$.next({ filterWhere: this.savedFilter, view:'grid_view' });
      // this.leadService.cardLayoutViewData$.next(payload?.whereFilterData);
      // this.savedFilter = {};
      // this.singale_value.setValue(null);
      // this.deactivatedleads = 0;
      // this.rotted_days = 0;
      // this.sliderValue = 0;
      // this.sliderMaxValue = 0;
      // this.savedFilter.slider_value = null;
    }
    this.getFilterList.emit(this.savedFilter)
    if (this.advfMenu) {
      this.advfMenu.closeMenu();
    }
  }
  
  clean(obj) {
    for (let propName in obj) {
      if (obj[propName]?.length === 0) {
        delete obj[propName];

      }
    }
    return obj
  }

  resetFilter() {
    if (this.viewData === 'list_view') {
      this.savedFilter = {};
      sessionStorage.removeItem("appliedFilters")

      this.getFilterList.emit(this.savedFilter)
    }

    if (this.viewData === 'grid_view') {
      this.savedFilter = {};
      sessionStorage.removeItem("appliedFilters")
      this.getFilterList.emit(this.savedFilter)

    }
    this.savedFilter = {};
    sessionStorage.removeItem("appliedFilters")
    this.getFilterList.emit(this.savedFilter)

  }

  getActivityList() {
    this.leadService.leadNoteActivity().subscribe((res: any) => {
      this.activityArr = res ? res?.data : null;
      this.leadService.lead_Activity_List$.next(res);
    }, (error) => { });
  }

  getTags() {
    this.leadService.getTagsList().subscribe((res: any) => {
      this.tagsArr = res ? this.getUniqueTags(res.data, 'name') : null;
      this.allTagsArr =  res?.data;
    }, (error) => { });

  }

  getOrgList(){
    this.leadService.getOrganizationList().subscribe((res: any) => {
      // this.tagsArr = res ? this.getUniqueTags(res.data, 'name') : null;
      this.allOrgArr =  res?.data;
    }, (error) => { });

  }

  getUniqueTags(tags: any[], property: string): any[] {
    return Array.from(new Set(tags.map(tag => tag[property]))).map(name => {
      return tags.find(tag => tag[property] === name);
    });
  }

  getDealType() {
    this.leadService.getDealTypeList().subscribe((res: any) => {
      this.WidgetService.dealType_List$.next(res);
      this.deal_typeArr = res ? res?.data : null;
      this.getActivityList();
      this.getTags();
    }, (error) => { });
  }

  getDealStage(id: any) {
    this.leadService.dealStageList({ deal_type_id: id }).subscribe((res: any) => {
      this.deal_stageArr = res?.data ? res?.data : null;
    })
  }

  getOrgDetail(id: any) {
    console.log(id,'kkkkkkkkkkkkkkkk')
    // this.leadService.dealStageList({ deal_type_id: id }).subscribe((res: any) => {
    //   this.deal_stageArr = res?.data ? res?.data : null;
    // })
  }

  getViews() {
    this.leadService.viewFilter$.subscribe((res: any) => {
      if (res) {
        this.viewData = res;
        if (res === 'list_view') { this.getDealType(); }
        if (res === 'grid_view') { this.getActivityList(); this.getTags(); }
      }
    })
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }


  checkValueExist(item_arr, item) {
    let findIndex = item_arr?.findIndex((obj) => obj?.id === item);    
    return findIndex >= 0;
  }
  checkValueExistMember(item_arr, item) {
    if (!Array.isArray(item_arr) || !item) {
      return false; // Return false if inputs are invalid
    }
  
    const findIndex = item_arr.findIndex(
      (obj) => obj?.first_name === item?.fname && obj?.last_name === item?.lname
    );
  
    return findIndex >= 0;
  }

  checkValueExistStatus(item_arr, item) {
    console.log(item,'jjj')
    if (!Array.isArray(item_arr) || !item) {
      return false; // Return false if inputs are invalid
    }
  
    const findIndex = item_arr.findIndex(
      (obj) => obj?.first_name === item?.fname && obj?.last_name === item?.lname
    );
  
    return findIndex >= 0;
  }
  
  checkValueExistWithId(item_arr, item) {
    let findIndex = item_arr?.findIndex((obj) => obj?.id + '--' + obj.name === item);    
    return findIndex >= 0;
  }

  splitValue(){
    console.log(this.savedFilter,'this.savedFilter22')
    if(this.savedFilter?.slider_value){
      if(this.savedFilter?.slider_value?.includes("-")){
        const [firstValue, secondValue] = this.savedFilter.slider_value.split("-");
        console.log(firstValue,secondValue,"secondValue secondValue secondValue")
        this.sliderMaxValue=secondValue
        this.sliderValue=firstValue
      }
    }
    
  }

  patchValues() {
    if (this.savedFilter.slider_value) {
      const [minValue, maxValue] = this.savedFilter.slider_value.split('-').map(Number);
      this.sliderValue = minValue;
      this.sliderMaxValue = maxValue;
    }
  }
  

  
}
