
      <div class="mb-3 mt-3" style="border-bottom: 1px solid #E3ECF1;">
         <div class="d-flex justify-content-between pb-4 px-3">
            <span class="h-1">Follow-ups</span>
            <img src="assets/svg-icons/Vector (5).svg">
         </div>
         <ul class="px-3" style="list-style-type:none;padding-left: 0px;">
            <li>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Creating Account</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Creating First Quote</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Setup Dashboard</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Adding First client</span></mat-checkbox>
            </li>
         </ul>
      </div>
      <div class="mb-3" style="border-bottom: 1px solid #E3ECF1;">
        <div class="d-flex justify-content-between pb-4 px-3">
            <span class="h-1">Reminders</span>
            <img src="assets/svg-icons/Vector (5).svg">
        </div>
        <ul class="px-3" style="list-style-type:none;padding-left: 0px;">
            <li>
                <div class="d-flex justify-content-between"><mat-checkbox class="custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox>
                <span class="text-2 px-2 d-flex align-items-center justify-content-center" style="background-color: #F85050;">Today
                </span></div><br>
                <div class="d-flex justify-content-between"><mat-checkbox class="custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox>
                <span class="text-2 px-1 d-flex align-items-center justify-content-center" style="background-color: #FFB800;width: 53px;height: 25px;">2 Days
                </span></div><br>
                <div class="d-flex justify-content-between"><mat-checkbox class="custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox>
                <span class="text-2 px-1 d-flex align-items-center justify-content-center" style="background-color: #03C0FC;width: 53px;height: 25px;">5 Days
                </span></div><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox>
            </li>
        </ul>
      </div>
      <div style="border-bottom: 1px solid #E3ECF1;">
         <div class="d-flex justify-content-between pb-4 px-3">
            <span class="h-1">To-do</span>
            <img src="assets/svg-icons/Vector (5).svg">
         </div>
         <ul class="px-3" style="list-style-type:none;padding-left: 0px;">
            <li>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox><br>
                <mat-checkbox class="pb-3 custom-frame"><span class="text">Lorem ipsum dolor sit amet</span></mat-checkbox>
            </li>
         </ul>
      </div>
     
       