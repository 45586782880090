import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { IconsModule } from './icons/icons.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IconsModule,
    RouterModule,
    SharedModule,
    ScrollingModule,

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ], // Must add this one.

  exports:  [ScrollingModule],
})
export class CoreModule { 
  /**
     * Constructor
     */
   constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
)
{
    // Do not allow multiple injections
    if ( parentModule )
    {
        throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
}
}
