import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-deal-detail',
  templateUrl: './view-deal-detail.component.html',
  styleUrls: ['./view-deal-detail.component.scss']
})
export class ViewDealDetailComponent implements OnInit {
  deal_Title: string;
  related_To: string;
  deal_Type: string;
  lead_Stage: string;
  deal_Des: string;
  tags: string;
  forecast_Date: string;
  deal_Value: number;
  subscriptions: Subscription[] = [];
  constructor(
    public leadService: LeadService,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
  ) { }

  ngOnInit(): void {
    const lead_Id = this.modalData?.deal_id?.tableData?.id;
    this.getdealDetailInfo(lead_Id);
  }

  getdealDetailInfo(leadId: any) {
    const dealDetailSubs = this.leadService.dealDetail(leadId).subscribe((res: any) => {
      if (res?.data) {
        const related_To_List = this.leadService.relatedList().subscribe((val: any) => {
          const related_to =  val?.data?.find((el:any)=>el?.id === res?.data?.related_to);
          this.related_To = related_to?.name;
        }, (error) => { });
        this.subscriptions.push(related_To_List);
        this.deal_Title = res?.data?.name;
        this.deal_Type = res?.data?.merchantDealType?.name;
        this.lead_Stage = res?.data?.dealStage?.name;
        this.deal_Des = res?.data?.description;
        this.tags = res?.data?.merchantLeadDealTags;
        this.forecast_Date = res?.data?.forecast_date;
        this.deal_Value = res?.data?.value;
      }
    }, (error) => { });
    this.subscriptions.push(dealDetailSubs);
  }

}
