import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-final-confirmation',
  templateUrl: './final-confirmation.component.html',
  styleUrls: ['./final-confirmation.component.scss']
})
export class FinalConfirmationComponent implements OnInit {
  public fg: FormGroup;

  constructor(public dialogRef: MatDialogRef<FinalConfirmationComponent>,
    ) { }

  ngOnInit(): void {
    this.fg = new FormGroup({
      name: new FormControl(''),
    });
  }

  closeDialog() {
    const res:boolean = true;
    this.dialogRef.close(res);
  }

}
