import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {
  @Input() color;
  @Input() total;
  @Input() progress;

  progressColor;
  progressPercentage;
  remainingProgressColor;
  constructor() { }

  ngOnInit(): void {
    this.progressColor = this.color;
    if(this.progress > this.total) {
      this.progress = this.total;
    }
    if(this.total) {
      this.progressPercentage = Math.round((this.progress/this.total) * 100);
    } else {
      this.progressPercentage = 0;
    }
    this.remainingProgressColor = '#E3ECF1';
  }

}
