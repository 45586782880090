import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private spinner: NgxSpinnerService,
     private _snackBar: MatSnackBar, private _http: HttpClient) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 440 || err.status === 401) {
              this.unAuthenticatedUserRemove(); 
          }
          if (err?.error?.error && typeof err?.error?.error === 'string') {
              this._snackBar.open( err?.error?.message || err?.error?.error, "OK" , {
                panelClass: ['mat-custom-snackbar-class'],
                duration: 2000
              });
          } else if (err?.error && typeof err?.error?.message === 'string') {
            this.spinner.hide();
            this._snackBar.open( err?.error?.message || err?.error?.error, "OK" , {
              panelClass: ['mat-custom-snackbar-class'],
              duration: 6000
            });
          }  
        }
         return throwError(err);
      }));
  }

  private unAuthenticatedUserRemove() {
    if (localStorage.getItem('merchant_id')) {
      this._http.post<any>(`${environment.USER_MANAGEMENT_URL}/merchantuser/removeusersession`, {
       id:localStorage.getItem('userId'),
       email:localStorage.getItem('email'),
       merchant_id:localStorage.getItem('merchant_id'),
       device_id:localStorage.getItem('deviceLoginId')
       }).subscribe(() => {});

   }
   localStorage.removeItem('reminderList');
   localStorage.removeItem('merchant_id');
   localStorage.removeItem('token');
   localStorage.removeItem('userId');
   localStorage.removeItem('fname');
   localStorage.removeItem('lname');
   localStorage.removeItem('create-session');
   localStorage.removeItem('redirect-merchant');
   localStorage.removeItem('profile_pic');
   localStorage.removeItem('email');
   localStorage.removeItem('user_merchants');
   localStorage.removeItem('deviceLoginId');
   localStorage.removeItem('loginUserValue');
   localStorage.removeItem('osmos_access_level');
   localStorage.removeItem('permission');
  localStorage.removeItem('role');
  localStorage.removeItem('country_list');
  localStorage.removeItem('accessId');
  //  this.translate.use('en');
   localStorage.removeItem('osmos_access_level');
   this.router.navigate(['/auth/sign-in']);
   this.spinner.hide();
   window.location.reload();
  }
}
