import { Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import SignaturesPad from 'signature_pad';

@Component({
  selector: 'app-signature-pad-create',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponentCreate implements OnInit {
  signatureNeeded!: boolean;
  signaturePad!: SignaturesPad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  @Output() signatureSaved: EventEmitter<any> = new EventEmitter<string>();
  @Input() Border_Color: any;
  Border_Color1: string = 'blue'; // Replace 'blue' with your desired default color
  @ViewChild('signaturePad') signaturePadElement!: ElementRef;
  signaturesPad!: SignaturesPad | undefined;
  @Input() type
  constructor() { }


  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.canvasEl && this.canvasEl.nativeElement) {
        this.signaturePad = new SignaturesPad(this.canvasEl.nativeElement);
      }
    });
  
    if (this.signaturePadElement && this.signaturePadElement.nativeElement) {
      this.signaturesPad = new SignaturesPad(this.signaturePadElement.nativeElement);
    }
  }
  
  
  startDrawing(event: TouchEvent) {
    // You may need to add implementation here
  }
  
  moved(event: TouchEvent) {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureSaved.emit(base64Data);  // Use 'emit' to send the value through the event
  }
  getBorderStyle(): string {
    // Define your condition here
    if (this.Border_Color) {
      return '1px solid ' + this.Border_Color; // Return the Border_Color if condition is met
    } else {
      return '1px solid #CBDBE4'; // Fallback color if condition is not met
    }
  }
  clearSignature(): void {
    this.signaturesPad?.clear();
    this.signatureSaved.emit(null); 
  }

  saveSignature(): void {
    // Implement save logic here, e.g., save image data to a file or send it to a server
    const imageData = this.signaturesPad?.toDataURL(); // Get the signature image data
    this.signatureSaved.emit(imageData);
  }
  

  // clearPad() {
  //   this.signaturePad.clear();
  //   this.signatureSaved.emit('clear'); 

  // }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureSaved.emit(base64Data);  // Use 'emit' to send the value through the event
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

  stopDrawing() {
    console.log(this.signaturePad,'this.signaturePad')
    // Capture signature data when drawing is stopped
    if (this.signaturePad) {
      const signatureDataUrl = this.signaturePad.toDataURL();
      // You can emit this data or use it as needed
      console.log('Signature data:', signatureDataUrl);
    }
  }
}
