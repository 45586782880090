<canvas  *ngIf="type !== 'invoice'"
        [ngStyle]="{
          'border': getBorderStyle(),
          'border-radius': '5px!important',
          'background': '#FFF!important',
          'width': '100% !important'
        }"
        style="border-radius: 5px!important;
              border: '1px solid #CBDBE4  !important';
              background: #FFF!important;
              width: 100% !important;"
        
        >
</canvas>
<canvas  *ngIf="type == 'invoice'"
    style="border-radius: 5px!important;
    border: '1px solid #CBDBE4  !important';
    background: #FFF!important;
    width: 100% !important;"
    #signaturePad width="530" height="300" 
    (click)="startDrawing($event)"
    (click)="saveSignature()">
</canvas>


<!-- <button (click)="saveSignature()">Save Signature</button> -->


<!-- <div style="display: flex; justify-content: space-between;margin-top: 10px;">
  <button style="color:white; background-color: #03C0FC; border:none; border-radius: 8px; padding: 10px 10px 10px 10px;" (click)="clearPad()">
      Clear Signature</button>
      <button style="color:white; background-color: #03C0FC; border:none; border-radius: 8px; padding: 10px 10px 10px 10px;" (click)="savePad()">Save Signature</button>
 </div> -->

<!-- <div *ngIf="signatureNeeded === false">
  <p>Signature in Base64Encoded Format</p>
  <img src="{{ signatureImg }}" alt="" srcset="" />
</div> -->
<!-- #canvas -->