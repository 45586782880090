import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavService } from '../components/side-nav-service';


@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit , OnChanges {

  @ViewChild('sidenav') public sidenav: MatSidenav;

  @Input()
  openNav: boolean;


  constructor(private sidenavService: SideNavService) { }

  ngOnChanges(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnInit(): void {
  }


}
