import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-value-in-dropdown',
  templateUrl: './add-value-in-dropdown.component.html',
  styleUrls: ['./add-value-in-dropdown.component.scss'],
})
export class AddValueInDropdownComponent implements OnInit {
  public fg: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddValueInDropdownComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    ) {
      this.fg = new FormGroup({
        name: new FormControl(''),
        percent: new FormControl('',[Validators.required]),
      });
    }

  ngOnInit(): void {
    if(this.data?.editValues){
      this.fg.patchValue({
        name:this.data?.editValues.name,
        percent:this.data?.editValues.taxe_value
      })
    }
    
  }

  addFormOfContactList() {
    if(this.data?.name =='Category'){
      this.dialogRef.close(this.fg.controls['name'].value);

    }else{
      if(this.fg.controls['percent'].value<100 && this.fg.controls['percent'].value>-1){
        this.dialogRef.close({name:this.fg.controls['name'].value,percent:this.fg.controls['percent'].value});
      }
    }
    
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }

  preventNegative(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (Number(inputElement.value) < 0) {
      inputElement.value = '0';
      this.fg.get('percent')?.setValue(0); // update FormControl value if using Reactive Forms
    }
  }
}
