import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddressMapComponent } from './address-map.component';
import { AgmCoreModule } from '@agm/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppDirectivesModule } from '../../directives';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
    declarations: [
        AddressMapComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB9C0iVNLS5_igud0vkq-_7wQTtMbmRgeI'
        }),
        TranslateModule,
        MatToolbarModule,
        AppDirectivesModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FormsModule,
        MatProgressSpinnerModule
    ],
    exports: [AddressMapComponent]
})
export class AddressMapModule { }
