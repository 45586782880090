import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
    BASE_URL: string;
//   private reminders = [
//     { message: 'Meeting at 3 PM', time: new Date(Date.now() + 5000) }, // 5 seconds from now
//     { message: 'Doctor Appointment at 5 PM', time: new Date(Date.now() + 10000) } // 10 seconds from now
//   ];

 constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
  }

  taskList(resource:any = '') {    
    return this.http.post(this.BASE_URL + '/task/list/reminders', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
}
