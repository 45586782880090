import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(searchListing: any[], searchTxt:string): any[] {
    if(!searchListing || !searchListing.length) return searchListing;
    if(!searchTxt || !searchTxt.length) return searchListing;
    return searchListing.filter(item => {
      return item.name?.toString()?.toLowerCase().indexOf(searchTxt?.toLowerCase()) > -1
    });
  }

}