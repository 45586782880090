<div class="modal-content">
  <div class="modal-header pt-5">
    <h2 class="text-1 fs-4 text-center" style="color:#03c0fc;">{{ data.name }}</h2>
  </div>
  <div class="modal-body bg-gris-1 px-5 text-left">
    <p>{{ data.description }}</p>    
    <p>{{"TASK.ReminderDate" | translate}} : {{data.calender_show_date | customDate}}</p>
    <p>{{"TASK.ReminderTime" | translate}} : {{data.display_time}}</p>    
    <p>{{"TASK.CUSTOMER" | translate}} : {{data.customer}}</p>
  </div>
  <div class="modal-footer j-c-center mt-4 pb-4">
    <button class="btn btn-2 mx-2" (click)="closeDialog('c')">{{"LEADS.Close" | translate}}</button>    
    <button class="btn btn-2 mx-2 btn-2" (click)="closeDialog('h')">{{"TASK.MARKCOMPLETE" | translate}}</button>    
  </div>
</div>