import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ModalComponent, ModalModel } from 'src/app/shared/components/modal/modal.component';
import { addressElements } from '../../enum/address-elements';
import { emptyAddress } from '../../enum/empty-address';
import { AddressItems } from './models/address-Items.model';
import { EditContactService } from 'src/app/pages/contact-profile/edit-contact/services/edit-contact.service';
import { AddSubCategoryComponent } from 'src/app/pages/items/add-sub-category/add-sub-category.component';
import { AddCategoryComponent } from 'src/app/pages/items/add-category/add-category.component';
declare var $: any;
@Component({
  selector: 'app-address-fields',
  templateUrl: './address-fields.component.html',
  styleUrls: ['./address-fields.component.scss'],
})
export class AddressFieldsComponent implements OnInit {

  @Input() customerAddressArr: any;
  @Output() outputInputFromChild : EventEmitter<string> = new EventEmitter();
  @Output() updateAddress: EventEmitter<any> = new EventEmitter<any>();
  @Input() paddingLeft: boolean;
  @Input() moduleName: any;
  @Input() labelName: any;
  @ViewChild('content', { static: false }) modal;
  @ViewChild('closeformOfConForm2') closeformOfConForm2;
  @ViewChild('clientTypesForm') clientTypesForm;
  @ViewChild('roleForm') roleForm;
  @ViewChild('customFieldForm') customFieldForm;
  @ViewChild('socialLinkForm') socialLinkForm;
  @ViewChild('closesocialbutton') closesocialbutton;
  @ViewChild('addFormOfContactButton2') addFormOfContactButton2;
  @ViewChild('addFormClientTypeButton') addFormClientTypeButton;
  @ViewChild('addRoleContactButton') addRoleContactButton;
  @ViewChild('fnameRef') fnameRef: ElementRef;
  openBulkAction:boolean = false;
  currentIndex:any
  touched = false;
  dropdown:boolean=false
  formOfContactFormSubmit = false;
  citiesListMainArr: any = [];
  formContactListArr:any;
  addressFormate: any;
  formContactListArrData:any;
  addressFormateArr: any = {}
  addressFormateArrView = [];
  addressformArr = Array();
  color:any;

  public fg: FormGroup;
  public addressItems: AddressItems;
  public lineItems: FormArray
  addContactForm: FormGroup;
  formOfContactForm2: FormGroup;



  constructor(
      private _fb: FormBuilder,
      private translateService: TranslateService,
      public dialog: MatDialog,
      private editContactService: EditContactService,
      private formBuilder: FormBuilder,


  ) {

}

  ngOnInit(): void {
    this.getAllListing()
    this.contactBasicInfoFormVal()
    this.addressItems = new AddressItems(this.customerAddressArr)
      this.fg = this._fb.group(this.addressItems.validationRules());
        // generate line items
        if(this.customerAddressArr?.length){
            _.each(this.customerAddressArr, (o) => { this.addLineItem(this.customerAddressArr.indexOf(o)); });

        }else{
            this.addLineItem(0);
        }

      this.getAddressOrder();
      setTimeout(() => {
          this.fg.patchValue(this.addressItems)
          if (this.customerAddressArr.length < 1) {
              this.customerAddressArr.push(new emptyAddress());
          }
      }, 2000);
  }

  contactBasicInfoFormVal() {
    this.addContactForm = this.formBuilder.group(
      {
        formContactId: [''],
      },
    );
    this.formOfContactForm2 = this.formBuilder.group(
      {
        name: ['', Validators.required],
        color: ['#000000', Validators.required],
        color2: ['#000000']
      },
    );
  
}

get formOfContact(): { [key: string]: AbstractControl } {
    return this.formOfContactForm2?.controls;
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }

  async getAllListing() {
    let merchantId = localStorage.getItem('merchant_id');
    const formContactListSubs = this.editContactService.formContactList({label:this.labelName}).subscribe((val: any) => {
      this.formContactListArr = val.data;
      this.formContactListArrData = val.data
    });
  }

  addFormOfContactList2() {
    this.formOfContactFormSubmit = true;
    if (this.formOfContactForm2.invalid) {
      return
    } else {
      const addformOfContactTypesSubs = this.editContactService.addformOfContactTypes({...this.formOfContactForm2.value,label:this.labelName,title:this.formOfContactForm2.value.name,module_name:this.moduleName}).subscribe((res) => {
        this.closeformOfConForm2.nativeElement.click();
        this.formOfContactForm2.reset();
        this.formOfContactFormSubmit = false;
        this.getAllListing();
      })
      // this.subscriptions.push(addformOfContactTypesSubs);
    }
  }


  async getAddressOrder() {
      let addressFormatSequance = localStorage.getItem('address_format');
      if (addressFormatSequance) {
          this.addressFormate = addressFormatSequance.split(',');
          this.addressFormate.forEach((addressFormate, index) => {
              this.addressFormateArr[addressFormate] = '';
              this.addressFormateArrView.push(addressFormate)
          })
          this.addressformArr.push(this.addressFormateArr)
      }
      if (this.addressFormateArrView.length <= 0) {
          this.addressFormateArrView = addressElements;
      }
  }

  changeAddField(event, type, ix) {
      if (type == 'addTitle') {
          this.customerAddressArr[ix].title = event
      }

      this.touched = true;
  }

  addFormOfContact(event: any) {
    if (event.value == 'addFormOfContact') {
      this.addFormOfContactButton2.nativeElement.click();
    }
  }


  defaultAddress(ix) {
      this.customerAddressArr.forEach((customerAddressArr, index) => {
          if (index == ix) {
              this.customerAddressArr[index].default_address = '1';
          } else {
              this.customerAddressArr[index].default_address = '0';
          }
      })
  }
  editAddField(ix) {
    this.dropdown=true
      $("#editButton" + ix).addClass("d-none");
      $("#AddressTitle" + ix).addClass("d-none");
      $("#AddressTitleInput" + ix).removeClass("d-none");
      $("#checkButton" + ix).removeClass("d-none");
  }

  saveAddField(ix) {
    this.dropdown=false

      $("#editButton" + ix).removeClass("d-none");
      $("#AddressTitle" + ix).removeClass("d-none");
      $("#AddressTitleInput" + ix).addClass("d-none");
      $("#checkButton" + ix).addClass("d-none");
  }

  updateSubcategories(obj) {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: "800px",
      data: {...obj,label:this.labelName,module_name:this.moduleName}
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if(dialogResult){
        this.getAllListing()
      }
   
    });
  }

  async removeAddress(id: any) {
      const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
      const dialogRef = this.dialog.open(ModalComponent, {
          width: "450px",
          data: dialogData
      });
      dialogRef.afterClosed().subscribe(async (dialogResult) => {
          if (dialogResult) {
              this.customerAddressArr.splice(id, 1);
              this.lineItems.removeAt(id)

          }
      });
  }

  addAdditionalAddress(event) {
   
      this.customerAddressArr.forEach((customerAddressArr, index) => {
          this.customerAddressArr[index].additional_add_show = false;
          
      })
      this.customerAddressArr.push(new emptyAddress());
      this.citiesListMainArr.push([]);
      this.addLineItem(this.customerAddressArr.length-1);
      let index = this.customerAddressArr.length-1;
      this.sendBlurAction(index);
      
  }


public addLineItem(index): void {
    this.lineItems = this.fg.get('lineItems') as FormArray;
  
    const lineItem = this._fb.group(this.addressItems.lineItemValidationRules());
    lineItem.get('formattedAddress').valueChanges
    .subscribe((value) => {
            this.customerAddressArr[index].formattedAddress = value
    });
    lineItem.get('city').valueChanges
    .subscribe((value) => {
            this.customerAddressArr[index].city = value
    });
    lineItem.get('country').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].country = value
    });

    lineItem.get('state').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].state = value
    });
    lineItem.get('postal_code').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].postal_code = value
    });
    lineItem.get('street_name').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].street_name = value
    });
    lineItem.get('interior_number').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].interior_number = value
    });
    lineItem.get('house_number').valueChanges
    .subscribe((value) => {
        this.customerAddressArr[index].house_number = value
    });
    this.lineItems.push(lineItem);
    

}
sendBlurAction(index) {
    this.outputInputFromChild.emit(index);
}

async removeItem(i, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "450px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {

        
        if (type == 'deleteformContact') {
          this.deleteformContact(i)
        }
        
        if (type == 'Delete_Address') {
          this.customerAddressArr.splice(i, 1);
        }
      }
    });
  }

  deleteformContact(id) {
    const deleteformOfContactTypesSubs = this.editContactService.deleteformOfContactTypes(id).subscribe((res) => {
      this.getAllListing();
    })
  }

  changeColor(event) {
    this.color = event?.target?.value;
    this.formOfContactForm2?.controls['color'].setValue(event?.target?.value);
  }

  changeColor1(event) {
    this.color = event?.target?.value ? ('#' + event?.target?.value.replace(/#/g, "")) : null;
    this.formOfContactForm2?.controls['color'].setValue(this.color);
  }
  openBulkActionPopup(event,i){
    this.currentIndex = i
      event.stopPropagation();
      this.formContactListArr.map((x,index)=>{
        if(i === index){
          this.openBulkAction = !this.openBulkAction
        } 
      })
      
    }

}
