import { Injectable } from '@angular/core';
import { CanActivate,
         CanLoad,
         Route,
         UrlSegment,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         UrlTree,
         Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoggedService } from '../services/logged.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedGuard implements CanActivate, CanLoad {

  constructor(
    private route: Router,
    private loggedService: LoggedService
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.checkLogged();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.checkLogged();
  }
  async checkLogged(): Promise<boolean> {
    const token: string | null = localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      localStorage.removeItem('merchant_id');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('fname');
      localStorage.removeItem('lname');
      localStorage.removeItem('profile_pic');
      localStorage.removeItem('create-session');
      localStorage.removeItem('redirect-merchant');
      localStorage.removeItem('user_merchants');
      localStorage.removeItem('deviceLoginId');
      localStorage.removeItem('loginUserValue');
      localStorage.removeItem('osmos_access_level');
      this.route.navigate(['/auth/sign-in']);
      return false;
    }
  }
}
