import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]',
  host: {
    '(input)': '$event'
  }
})
export class NumericNumbersDirective {

  constructor(
    private el: ElementRef
  ) { }

  @Input('decimals') decimals: any = 0;
  @Input('percentageCheck') percentageCheck: boolean = false;

  private check(value: string, decimals: any)
  {
    if (decimals <= 0) {
      return String(value).match(new RegExp(/^\d*\.?\d*$/));
    } else {
        var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
        return String(value).match(new RegExp(regExpString));
    }
  }

  private specialKeys = [ 
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
  ];

 

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event) {
      if (this.specialKeys.indexOf(event.key) !== -1) {
          return;
      }
      // current.replace(/\,/g,'')
      // Do not use event.keycode this is deprecated.
      // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      let current: string = this.el.nativeElement.value;

      current = current.replace(/\,/g,'')
      let next: string = current.concat(event.key);
      
      if(this.decimals == 2 && Number(next) > 100 && this.percentageCheck){
        event.preventDefault();

      }
      if ( next && !this.check(next, this.decimals) ) {
         event.preventDefault();
      }
      // this.el.nativeElement.value = this._decimalPipe.transform(current.replace(/\,/g,''));

  }

}
