<div id="page-container">
    <div id="content-wrap">
        <!-- <div id="background">
            <img src="assets/img/auth/circle.png" />
        </div> -->
        <section>
            <app-auth-header></app-auth-header>
            <router-outlet></router-outlet>
        </section>
    </div>
    <div class="mt-5 page-footer">
        <app-auth-footer></app-auth-footer>
    </div>
</div>