import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, mergeMap, catchError, debounceTime, mapTo } from 'rxjs/operators';
import * as fromActions from '../actions/task.actions';
import { TasksService } from '../services/tasks.service';

@Injectable()
export class TaskEffects {

  constructor(
    private actions$: Actions,
    private TasksService: TasksService
  ) { }

  listTasks$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ShowAllAction),
    switchMap(() =>
      this.TasksService.taskList().pipe(
        map(data => fromActions.ShowAllSuccessAction({payload: data}))
      )
    )
  ));

  profile$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ShowMerchantProfileAction),
    mapTo(fromActions.ShowMerchantProfileSuccessAction({payload: {}}))
    // switchMap(() =>
    //   this.TasksService.taskList().pipe(
    //     map(data => fromActions.ShowMerchantProfileSuccessAction({payload: data}))
    //   )
    // )
  ));
} 