import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NewTmpComponent } from 'src/app/quote-template-new/new-tmp/new-tmp.component';

@Component({
  selector: 'app-template-not-save',
  templateUrl: './template-not-save.component.html',
  styleUrls: ['./template-not-save.component.scss']
})
export class templateNotSavingComponent implements OnInit {
  titleMsg:any;
  ItemsDeleteModule:any;
  constructor( public dialogRef: MatDialogRef<templateNotSavingComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    if(this.data){
      this.titleMsg = this.data?.title;
      this.ItemsDeleteModule=this.data?.message
    }
  }

  closeDialog() {
    this.dialogRef.afterClosed();
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }
  viewQuote(){
    const diylogdata: any = { title: "Clone Quote Form", type: 'preview', data: this.data?.html };
    this.openDialog(diylogdata)
  }

  openDialog(data: any) {
    const dialogData: any = data;
    const dialogRef = this.dialog.open(NewTmpComponent, {
      panelClass: 'my-dialog-panel',
      width: data.type === 'preview' ? "870px" : "450px",
      data: dialogData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
   
    });
  }
}
