import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatMenuModule} from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BookmarksComponent } from 'src/app/bookmarks/bookmarks.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SideNavService } from '../../side-nav-service';
import { SwitchToAnotherWorkspaceModule } from 'src/app/Dialogs/switch-to-another-workspace/switch-to-another-workspace.module';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    MatSidenavModule,
    MatCheckboxModule,
    SwitchToAnotherWorkspaceModule
  ],
  declarations: [
    BookmarksComponent,

  ],
  exports: [
   BookmarksComponent
  ],
  providers: [
    SideNavService
  ]
})
export class HeaderModule { }
