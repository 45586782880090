import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var google: any;

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AddressMapComponent implements OnInit {

  longitude: any;
  latitude: any;
  entityObj: any;
  lat = 40.416394;
  lng = -3.703930;
  zoom = 10;
  markerLat = null;
  markerLng = null;
  private geoCoder: any;
  address: any;
  compAddress: any;
  coordinates = [] as any;
  mapHeight: any;
  mqAlias: string;
  loadingResults = false;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<AddressMapComponent>,
    private mapsAPILoader: MapsAPILoader,
    private mediaObserver: MediaObserver,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // this.entityObj = ''
    // if (this.entityObj && this.entityObj.lat && this.entityObj.lng) {
    //   this.lat = parseFloat(this.entityObj.lat);
    //   this.lng = parseFloat(this.entityObj.lng);
    //   this.zoom = this.entityObj.zoom;
    // }
    this.mediaObserver.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((change: MediaChange) => {
        this.mqAlias = change.mqAlias;
        let adjustScreen = 265;
        if (this.mqAlias === 'xl' || this.mqAlias === 'gt-lg') {
          adjustScreen = 500;
        }
        this.mapHeight = (window.innerHeight - adjustScreen) + 'px';
      });

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      if (this._data.address) {
        this.loadingResults = true;
        this.loadAddress(this._data.address);
      } else if (this._data.longitude && this._data.latitude && !this._data.address) {
        this.markerLat = this._data.latitude.value;
        this.markerLng = this._data.longitude.value;
        this.getAddress(this.markerLat, this.markerLng);
      } 
      // else if (!this._data.longitude && !this._data.latitude && !this._data.address) {
      //   this.markerLat = this.lat;
      //   this.markerLng = this.lng;
      //   this.getAddress(this.markerLat, this.markerLng);
      // }
    });
  }

  mapClicked($event: any): void {
    this.markerLat = $event.coords.lat;
    this.markerLng = $event.coords.lng;
    this.getAddress(this.markerLat, this.markerLng);
  }

  getAddress(latitude: any, longitude: any): void {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].address_components;
          this.compAddress = results[0].formatted_address;
        } else {
          return;
        }
      } else {
        return;
      }
      this.coordinates['lat'] = latitude;
      this.coordinates['lng'] = longitude;
    });
  }

  loadAddress(address: string): void {
    this.geoCoder.geocode({ address: address }, (results: any, status: any) => {
      if (status === 'OK') {
        this.zoom = 13;
        this.address = results[0].address_components;
        this.compAddress = results[0].formatted_address;
        this.coordinates['lat'] = results[0].geometry.location.lat();
        this.coordinates['lng'] = results[0].geometry.location.lng();
        this.lat = results[0].geometry.location.lat();
        this.lng = results[0].geometry.location.lng();
        this.markerLat = results[0].geometry.location.lat();
        this.markerLng = results[0].geometry.location.lng();
        this.loadingResults = false;
      } else {
        this.loadingResults = false;
        return;
      }
    });
  }



}

