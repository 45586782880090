import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent, ModalModel } from '../modal/modal.component';

@Component({
  selector: 'app-custom-form-fields',
  templateUrl: './custom-form-fields.component.html',
  styleUrls: ['./custom-form-fields.component.scss']
})
export class CustomFormFieldsComponent implements OnInit,OnChanges {
  public customFielsDataFrom: FormGroup;
  @Input() customerCustomFieldArr: any;
  @Input() customDataFieldViewData: any;
  @Output() removeCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomeOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCustomField: EventEmitter<any> = new EventEmitter<any>();
  @Input() editdeletshow: any;

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder ,
    private cdr: ChangeDetectorRef
    ) { 
        
    this.customFielsDataFrom = this.formBuilder.group(
      {

      },
    );
 
  }
  ngOnChanges(changes: SimpleChanges): void {    
    if(changes.customerCustomFieldArr?.currentValue){
      for(let i = 0;i< this.customerCustomFieldArr?.length;i++){
        if (this.customerCustomFieldArr[i].field_type_id == 2 || this.customerCustomFieldArr[i].field_type_id == '2') {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_' + element.value, new FormControl());            
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 4 || this.customerCustomFieldArr[i].field_type_id == '4') {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_radio', new FormControl());
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 5 || this.customerCustomFieldArr[i].field_type_id == '5') {
          let k = 0;
          this.customerCustomFieldArr[i].customer_custom_field_values.forEach(element => {
            this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_dropdown', new FormControl(''));
          });
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 3 || this.customerCustomFieldArr[i].field_type_id == '3') {
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_date', new FormControl(''));          
        }
        else if (this.customerCustomFieldArr[i].field_type_id == 6 || this.customerCustomFieldArr[i].field_type_id == '6') {
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id + '_color', new FormControl(''));          
        }
        else {
          console.log(this.customerCustomFieldArr,'this.customerCustomFieldArr999')
          this.customFielsDataFrom.addControl('value_' + this.customerCustomFieldArr[i].id, new FormControl(''));
        }
      }
    }
    if(changes.customDataFieldViewData?.currentValue ){
      this.customDataFieldViewData = changes.customDataFieldViewData?.currentValue
      this.patchFormValues()
    }

  }

  ngOnInit(): void {
   
  }

patchFormValues(){
 if (this.customerCustomFieldArr.length > 0) {
  this.customerCustomFieldArr.forEach((field) => {
    if (field.field_type_id === '5') {
      field.customer_custom_field_values.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();

        // Sort by created_at first (oldest first)
        if (dateA !== dateB) {
          return dateA - dateB;
        }

        // Function to extract meaningful sorting value
        const extractSortValue = (val: string): number | string => {
          if (!val) return val; // Return empty values as they are

          // If value is numeric (including "+12", "-3"), return as a number
          if (/^[-+]?\d+(\.\d+)?$/.test(val.trim())) {
            return parseFloat(val);
          }

          // If value is a range (e.g., "1-4", "8-12"), extract the starting number
          if (/^\d+-\d+$/.test(val.trim())) {
            return parseInt(val.split('-')[0], 10);
          }

          // Otherwise, return as a string (for alphabetical sorting)
          return val.toLowerCase();
        };

        const valA = extractSortValue(a.value);
        const valB = extractSortValue(b.value);

        // If both are numbers, sort numerically
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        }

        // If both are strings, sort alphabetically
        if (typeof valA === 'string' && typeof valB === 'string') {
          return valA.localeCompare(valB);
        }

        // If one is a number and one is a string, prioritize numbers first
        return typeof valA === 'number' ? -1 : 1;
      });
    }
  });
}

console.log(this.customerCustomFieldArr, 'Sorted customerCustomFieldArr');


  if(this.customDataFieldViewData?.length){
    this.customDataFieldViewData.forEach((data, index) => {
      if (data.field_type_id == 2 || data.field_type_id == '2') {
        console.log(data,'datadatadatadata')
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_' + data.value]?.setValue(true)
      }
      else if (data.field_type_id == 4 || data.field_type_id == '4') {
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_radio']?.setValue(data.value)
      }
      else if (data.field_type_id == 5 || data.field_type_id == '5') {        
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_dropdown']?.setValue(data.value)
      }
      else if (data.field_type_id == 3 || data.field_type_id == '3') {

        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_date']?.setValue(data.value)
        this.cdr.detectChanges()
      }
      else if (data.field_type_id == 6 || data.field_type_id == '6') {

        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) + '_color']?.setValue(data.value)
        this.cdr.detectChanges()
      }
      else if (data.field_type_id == 1 || data.field_type_id == '1') {
        console.log(data,'datadatadataxxxx')
        this.customFielsDataFrom.controls['value_' + (data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id)]?.setValue(data.value)
      }
      console.log(this.customerCustomFieldArr,'this.customerCustomFieldArr000')
      this.customerCustomFieldArr.forEach((customFieldData, index) => {            
        if((data.customerCustomFieldId ? data.customerCustomFieldId :  data.custom_field_id) == customFieldData.id){
          this.customerCustomFieldArr[index].value = data.value;
        }
      });
    })  
  }

}
  async removeItem(customField, index, type) {
    const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "450px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {

     
        if (type == 'removeCustomField') {
          this.removeCustomField.emit({customField, index});
        }
      }
    });
  }
  updateSelectChange(event,controlName) {
    if (event.value === null) {
      this.customFielsDataFrom[controlName] = null;
  } else {
      this.customFielsDataFrom[controlName] = event.value;
  }
    this.customFielsDataFrom.controls[controlName].setValue(event.value);
  }
  onColorChange(event: Event,controlName) {
    const colorInput = event.target as HTMLInputElement;
    const selectedColor = colorInput.value;
    this.customFielsDataFrom.controls[controlName].setValue(selectedColor);
  }

  editField(customField, index) {    
      this.editCustomField.emit({isEdit: true, index, customField});
  }


  drop(event: CdkDragDrop<any>) {
    this.customerCustomFieldArr[event.previousContainer.data.index] = event.container.data.item;
    this.customerCustomFieldArr[event.container.data.index] = event.previousContainer.data.item;
    this.updateCustomeOrder.emit(this.customerCustomFieldArr);

  }
}
