import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'customTime'
})
export class CustomTimeFormatPipe extends DatePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return super.transform(value, 'hh:mm a');
	}
}