export class ItemAddModel {
    id: number;
    categoryId: any;
    subCategoryId: number;
    subcategory_id:number;
    top_flag: number = 0;
    status: boolean;
    name: string;
    code: string;
    description: any;
    description_es: any;
    description_fr: any;
    video_url: Text;
    website_url: Text;
    item_bundles: any = '';
    sub_items: any;
    item_warehouses: any;
    track_inventory: any;
    out_of_stock: any;
    item_coming_form: number;
    item_images: any;
    bundles: any;
    comments: any;
    created_at: Date;
    ItemsDeactivated: boolean=false
    childitems: any;
    images: any;
    warehouses: any;
    price: any;
    unit_quantity:any;
    color:any
    min_price: number = 0;
    currency_id: number;
    discount: any;
    advance_unit_cal: any;
    sales_commission: number;
    sales_comm_type: any;
    fix_cost_per_unit: number = 0;
    item_prices: any;
    item_prices_taxs: any;
    variantName: any;
    item_additional_details: any;
    item_code: any = '';
    prices: any = '';
    taxData: any = '';
    items: any = ' ';
    unit:any;
    item_coming_from:any;
    item_service_type:any;
    user_notify_task: any;
    first_url_title: string = 'Video Url';
    second_url_title: string = 'Website Url';
    items_custom_field_datas?:any=[];
    isDeactivated:boolean=false;

}
