<!-- <h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content>
  <p>{{message}}</p>
</div> -->
<!-- <div mat-dialog-actions align="center">
  <button mat-button class="btn btn-sm btn-success" (click)="onDismiss()">No</button>
  <button class="btn btn-sm btn-danger" (click)="onConfirm()">Yes</button>
</div> -->


<div class="modal-content cnt-dashboard border-0">
  <div class="modal-body bg-gris-1 p-5 text-center">
    <div class="d-flex justify-content-center">
      <img class=" mb-4" src="assets/img/home/dangera.png">
    </div>  
    <h2 class="text-1 fs-4 "> {{title}}</h2>
    <!-- <div class="text-3 fw-500">{{message}}</div> -->
    <div class="d-flex gap-3 justify-content-center mt-5">
      <div class=" btn   btn-skyblue-s-1 standard-btn m-2" (click)="onDismiss()" >
        {{"EMAILS.cancel" | translate}}
      </div>
      <div class=" btn btn-p-2 standard-btn  m-2" (click)="onConfirm()" >
        {{"EMAILS.confirm" | translate}}</div>
    </div>
  </div>
</div>