import { Component, Output, EventEmitter } from "@angular/core";

@Component({
selector: 'app-model-layout-header',
template:`
<div class="w-100 text-right modal-header">
<i type="button"  (click)="closemodel()" class="close fs-2 far fa-times-circle"></i>
</div>
`
})

export class ModelLayoutHeaderComponent {
    @Output() closemodellayout: EventEmitter<any> = new EventEmitter();

    closemodel(){
        this.closemodellayout.emit(true);
    }
}