<!-- <div class="row">
    <div class="col py-3 px-5">
      <div class="d-flex flex-wrap my-4">
        <h1 class="col align-self-center m-0 text-1">{{ content?.title | translate}}</h1>
        <div class="d-flex align-items-center">
          <div class="dropdown navbar" style="background: transparent">
            <button class="btn-circle btn fs-2 bg-1" style="width: 40px; height: 40px;" id="dropdownMenuButton5"
              data-bs-toggle="dropdown" aria-expanded="false">+</button>
            <div class="shadow dropdown-menu flex-column p-3 gap-3 menu-option" aria-labelledby="dropdownMenuButton5"
              style="width: 250px; top: 45px !important; right: -7px !important;">
              <i class="fas fa-caret-up flecha-up"></i>
              <div class="d-flex align-items-center gap-3" *ngFor="let menuItem of content.menu">
                <i class="{{menuItem.icon}} fs-5 text-1"></i><a class="text-1"
                  href="{{menuItem.url}}">{{menuItem.title |
                  translate}}</a>
              </div>
             <div class="d-flex align-items-center gap-2">
                <i class="fas fa-user-plus fs-5 text-1 me-1"></i><a class="text-1"
                  href="/dash/contacts/add">{{"CUSTOMER.Add_New_Client" |
                  translate}}</a>
              </div> 
               <div class="d-flex align-items-center gap-3">
                <i class="far fa-window-maximize fs-5 text-1"></i><a class="text-1"
                  href="javascript:void(0)">{{"CUSTOMER.Create_Template" | translate}}</a>
              </div> 
            </div>
          </div>
        </div>
      </div>
      </div>
  </div> -->

  <div class="row">
    <div class="col py-3 px-5">
      <div class="d-flex flex-wrap my-4">
        <h1 class="col align-self-center m-0 text-1">{{"Quote.New_Quote" | translate}}</h1>
        <div class="d-flex align-items-center">
          <div class="dropdown navbar" style="background: transparent">
            <button class="btn-circle btn fs-2 bg-1" style="width: 40px; height: 40px;" id="dropdownMenuButton5"
              data-bs-toggle="dropdown" aria-expanded="false">+</button>
            <div class="shadow dropdown-menu flex-column p-3 gap-3 menu-option" aria-labelledby="dropdownMenuButton5"
              style="width: 250px; top: 45px !important; right: -7px !important;">
              <i class="fas fa-caret-up flecha-up"></i>
              <div class="d-flex align-items-center gap-3">
                <i class="far fa-list-alt fs-5 text-1"></i><a class="text-1" 
                  routerLink="/dash/quote-listing/create">{{"CUSTOMER.Create_Quote" |
                  translate}}</a>
              </div>
              <div class="d-flex align-items-center gap-2">
                <i class="fas fa-user-plus fs-5 text-1 me-1"></i><a class="text-1" 
                  routerLink="/dash/contacts/add">{{"CUSTOMER.Add_New_Client" |
                  translate}}</a>
              </div>
              <div class="d-flex align-items-center gap-3">
                <i class="far fa-window-maximize fs-5 text-1"></i><a class="text-1"
                  href="javascript:void(0)">{{"CUSTOMER.Create_Template" | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>