import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AddressItems } from 'src/app/shared/components/address-fields/models/address-Items.model';
import { emptyAddress } from 'src/app/shared/enum/empty-address';
import { addressElements } from 'src/app/shared/enum/address-elements';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalComponent, ModalModel } from 'src/app/shared/components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'address-comp',
  templateUrl: './address-comp.component.html',
  styleUrls: ['./address-comp.component.scss']
})

export class AddressCompComponent implements OnInit {
  recieved : any;
  data : [];
  @Input('customerAddressArr') customerAddressArr: any;
  @Input('tempIndex') tempIndex: any;
  
  set dataSet(customerAddressArr : any) {
    // this.recieved = [...this.recieved, 'Setter got a value'];
    this.customerAddressArr = customerAddressArr;
  }
  @Input() customerAddressStyle: any;
  @Input() isDisabled: boolean = false;
  @Input() needOtherFields: boolean = false;
  @Input() field_address: any;
  @Input() addressRequired: boolean = false;
  
  addressFormate: any;
  addressFormateArrView = [];
  public fg: FormGroup;
  public addressItems: AddressItems;
  public lineItems: FormArray
  constructor(private _fb: FormBuilder, public dialog: MatDialog,private snackBar: MatSnackBar,private translateService: TranslateService,) { }



  ngOnInit(): void {
    if (this.tempIndex == undefined) {
      this.tempIndex = 0;
    } else {
      this.tempIndex = Number(this.tempIndex) + 1
    }
    this.addressItems = new AddressItems(this.customerAddressArr);
    console.log(this.addressItems,'this.addressItemsthis.addressItems')
    this.fg = this._fb.group(this.addressItems.validationRules());
    if (this.customerAddressArr?.length) {
      _.each(this.customerAddressArr, (o) => { this.addLineItem(this.customerAddressArr.indexOf(o)); });
    } else { this.addLineItem(0); }

    this.getAddressOrder();
    setTimeout(() => {
      this.fg.patchValue(this.addressItems);
      if (this.customerAddressArr.length < 1) {
        this.customerAddressArr.push(new emptyAddress());
      }
    }, 2000);
  }

  async getAddressOrder() {
    let addressFormatSequance = localStorage.getItem('address_format');
    if (addressFormatSequance) {
      this.addressFormate = addressFormatSequance.split(',');
      this.addressFormate.forEach((format, index) => {
        this.addressFormateArrView.push(format);
      })
    }
    if (this.addressFormateArrView.length <= 0) {
      this.addressFormateArrView = addressElements;
    }
  }
  hideDiv: boolean = false;  // Set it to true to hide the div

  // Function or logic to determine when to hide the div
  toggleDivVisibility() {
    this.hideDiv = !this.hideDiv;
  }

  public addLineItem(index): void {
    this.lineItems = this.fg.get('lineItems') as FormArray;
    const lineItem = this._fb.group(this.addressItems.lineItemValidationRules());
    lineItem.get('formattedAddress').valueChanges.subscribe((value) => { this.customerAddressArr[index].formattedAddress = value });
    lineItem.get('city').valueChanges.subscribe((value) => { this.customerAddressArr[index].city = value });
    lineItem.get('country').valueChanges.subscribe((value) => { this.customerAddressArr[index].country = value });
    lineItem.get('state').valueChanges.subscribe((value) => { this.customerAddressArr[index].state = value });
    lineItem.get('postal_code').valueChanges.subscribe((value) => { this.customerAddressArr[index].postal_code = value });
    lineItem.get('street_name').valueChanges.subscribe((value) => { this.customerAddressArr[index].street_name = value });
    lineItem.get('interior_number').valueChanges.subscribe((value) => { this.customerAddressArr[index].interior_number = value });
    lineItem.get('house_number').valueChanges.subscribe((value) => { this.customerAddressArr[index].house_number = value });
    this.lineItems.push(lineItem);
  }

  changeAddField(event, type, ix) {
    if (type == 'addTitle') {
        this.customerAddressArr[ix].title = event
    }

}

addAdditionalAddress(event) {
  // let fieldLength = this.field_address?.field_length || 0;
  // const currentLength = this.customerAddressArr?.length ==0?1: this.customerAddressArr?.length|| 0;
  // if (currentLength >= fieldLength) {
  //   this.snackBar.open(`You cannot add more than ${fieldLength} Address`, 'Close', {
  //     duration: 2000,
  //     panelClass: ['custom-snackbar']
  //   });
  //   return;
  // }
  this.customerAddressArr.forEach((address, index) => {
    this.customerAddressArr[index].additional_add_show = false;
  });
  this.customerAddressArr.push(new emptyAddress());
  this.addLineItem(this.customerAddressArr.length - 1);

}

async removeAddress(id: any) {
  console.log(id,'kkkkkkk')
  const dialogData = new ModalModel(this.translateService.instant('PROFILE.CONFIRMDELETE'));
  const dialogRef = this.dialog.open(ModalComponent, {
      width: "450px",
      data: dialogData,
      panelClass: 'my-dialog-panel',
  });
  dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
          this.customerAddressArr.splice(id, 1);
          this.lineItems.removeAt(id)

      }
  });
}



}

