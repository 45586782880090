import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject, Subscription } from 'rxjs';
import { WidgetService, widgetFilterArr } from "../../../widgets/services/widget.service";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


export interface filterPayLoad {
  filterType: string,
  filterRecordType: string,
  startDate?: string;
  endDate?: string;
  deal_type_id?: string;
  dealByStageTypeId?: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MMM/YYYY', 
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'wgts-filter',
  templateUrl: './wgtsfilter.component.html',
  styleUrls: ['./wgtsfilter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class WgtsfilterComponent implements OnInit {

  selectedOption: any;
  @ViewChild('startDate', { static: false }) startDate: ElementRef<any>;
  @ViewChild('endDate', { static: false }) endDate: ElementRef<any>;
  value: string;
  @ViewChild(MatSelect) matSelect: MatSelect;
  subscriptions: Subscription[] = [];
  @Input() widgetsType: string;
  filterType: string;
  deal_id: string;
  showSaveFilterFlag: boolean = false;
  saveWidgetFilterPayload: widgetFilterArr[] = [];
  private _unsubscribeAll: Subject<any> = new Subject();
  oneTimeCall: boolean = true;
  dealTypeId: string;
  message: string;
  savedWidgetName: string;
  constructor(
    public WidgetService: WidgetService,
    private datePipe: DatePipe,
    public snackbar: MatSnackBar,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.callOninit();
    this.showSaveFilterFlag = false;
  }

  filterNameSwitch(filterName: string) {
    switch (filterName) {
      case 'current_month': {
        this.message = this.translateService.instant('HOME.Current_month') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'last_month': {
        this.message = this.translateService.instant('HOME.Last_month') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'last_3_month': {
        this.message = this.translateService.instant('HOME.Last_3_months') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'last_12_month': {
        this.message = this.translateService.instant('HOME.Last_12_months') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'Next_3_months': {
        this.message = this.translateService.instant('HOME.Next_3_months') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'Next_6_months': {
        this.message = this.translateService.instant('HOME.Next_6_months') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'Next_12_months': {
        this.message = this.translateService.instant('HOME.Next_12_months') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      case 'custom_range': {
        this.message = this.translateService.instant('HOME.Custom_range') + " " + this.translateService.instant('HOME.filter_is_saved_for') + " " + this.savedWidgetName + " " + this.translateService.instant('HOME.widget');
        break;
      }
      default:
        break;
    }
  }

  saveFilter(filter: string) {
    localStorage.setItem('savedStage', this.deal_id);
    this.showSaveFilterFlag = false;
    this.filterNameSwitch(filter);
    this.SuccessSnackBar(this.message, 'OK');
    if (this.widgetsType === "Sales_Forecast") {
      this.WidgetService.showSaveFilterBtn$.next(false);
    }
    this.saveWidgetFilterPayload = [];
    let payload: widgetFilterArr;
    if (this.dealTypeId) {
      if (filter === 'custom_range') {
        payload = {
          widgetName: this.filterType,
          widgetValue: filter,
          startDate: this.startDate?.nativeElement?.value,
          endDate: this.endDate?.nativeElement?.value,
          ...(this.filterType === 'time_per_stage' && { deal_type_id: this.deal_id }),
          ...(this.filterType === 'deals-by-type-and-stages-typeId' && { dealByStageTypeId: this.dealTypeId })
        }
      } else {
        payload = {
          widgetName: this.filterType,
          widgetValue: filter,
          ...(this.filterType === 'time_per_stage' && { deal_type_id: this.deal_id }),
          ...(this.filterType === 'deals-by-type-and-stages-typeId' && { dealByStageTypeId: this.dealTypeId })
        }
      }
    }
    else {
      if (filter === 'custom_range') {
        payload = {
          widgetName: this.filterType,
          widgetValue: filter,
          startDate: this.startDate?.nativeElement?.value,
          endDate: this.endDate?.nativeElement?.value
        }
      } else {
        payload = {
          widgetName: this.filterType,
          widgetValue: filter,
        }
      }
    }
    this.saveWidgetFilterPayload.push(payload);
    this.WidgetService.storeWidgetFilter(this.saveWidgetFilterPayload).subscribe((res) => {

    }, (error) => error);
  }

  filterbtn(event: any) {
    localStorage.setItem('filterType', event?.value);
    this.selectedOption = undefined;
    this.showSaveFilterFlag = true;
    if (event?.value === 'custom_range') {
      this.customRngFilter();
    } else {
      this.selectedOption = undefined;
      const payload: filterPayLoad = {
        filterType: this.filterType,
        filterRecordType: event?.value,
        ...(this.filterType === 'time_per_stage' && { deal_type_id: this.deal_id }),
        ...(this.filterType === 'deals-by-type-and-stages-typeId' && { dealByStageTypeId: this.deal_id })
      };
      this.getWidget(payload);
    }
    if (this.widgetsType === "Sales_Forecast") {
      this.WidgetService.showSaveFilterBtn$.next(true);
    }
  }

  customRngFilter() {
    let payload: filterPayLoad;
    this.showSaveFilterFlag = true;
    this.selectedOption = this.startDate?.nativeElement?.value + ' - ' + this.endDate?.nativeElement?.value;
    payload = {
      filterType: this.filterType,
      filterRecordType: 'custom_range',
      startDate: this.datePipe.transform(this.startDate?.nativeElement?.value, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.endDate?.nativeElement?.value, 'yyyy-MM-dd'),
      ...(this.filterType === 'time_per_stage' && { deal_type_id: this.deal_id }),
      ...(this.filterType === 'deals-by-type-and-stages-typeId' && { dealByStageTypeId: this.deal_id })
    };
    if (payload?.startDate && payload?.endDate) { this.getWidget(payload); }
  };
  endDateChange() {
    this.customRngFilter();
    this.value = 'custom_range';
    this.matSelect.close();
    if (this.widgetsType === "Sales_Forecast") {
      this.WidgetService.showSaveFilterBtn$.next(true);
    }
  }
  filterbtnonclick(start: any, end: any) { this.selectedOption = (start?.value && end?.value) ? start?.value + ' - ' + end?.value : 'Custom range'; }
  callOninit(): void {
    this.WidgetService.deal_type_id$.subscribe(data => {
      this.deal_id = data?.id;
      if (data?.type === 'time_per_stage') {
        const filterType = localStorage.getItem("filterType");
        if (filterType === 'custom_range') {
          const payload = {
            filterType: 'time_per_stage',
            filterRecordType: filterType,
            deal_type_id: this.deal_id,
            startDate: this.datePipe.transform(this.startDate?.nativeElement?.value, 'yyyy-MM-dd'),
            endDate: this.datePipe.transform(this.endDate?.nativeElement?.value, 'yyyy-MM-dd'),
          };
          if (payload?.startDate && payload?.endDate) { this.getWidget(payload); }
        }
      }
    });
    this.WidgetService.dealdata$.subscribe((res) => {
      if (this.oneTimeCall) {
        res?.dbSaveFilterVal?.forEach((obj: widgetFilterArr) => {
          if (obj.widgetName === this.filterType) {
            this.value = obj.widgetValue;
            this.selectedOption = (obj?.startDate && obj?.endDate) ? obj?.startDate + ' - ' + obj?.endDate : obj.widgetValue === "custom_range" ? 'Custom range' : undefined;
          }
        });
        this.oneTimeCall = false;
      }
    });
    switch (this.widgetsType) {
      case 'Abandoned_Reasons':
        this.filterType = 'abandoned_deal_reason';
        this.savedWidgetName = this.translateService.instant('HOME.Abandoned_Reasons');
        break;
      case 'Lost_Reasons':
        this.filterType = 'lost_deal_reasons';
        this.savedWidgetName = this.translateService.instant('HOME.Lost_Reasons');
        break;
      case 'Activity_Report':
        this.filterType = 'user_activities';
        this.savedWidgetName = this.translateService.instant('HOME.Activity_Report');
        break;
      case 'Deals_Won_By_User_Graph':
        this.filterType = 'won_deal_user';
        this.savedWidgetName = this.translateService.instant('HOME.Deals_Won_By_User_Graph');
        break;
      case 'Deals_Per_User':
        this.filterType = 'deal_created_by_users';
        this.savedWidgetName = this.translateService.instant('HOME.Deals_Per_User');
        break;
      case 'Create_VS_Won_Deals_Graph':
        this.filterType = 'created_vs_won_deals';
        this.savedWidgetName = this.translateService.instant('HOME.Create_VS_Won_Deals_Graph');
        break;
      case 'Lost_Deal':
        this.filterType = 'lost_deal_of_12_month';
        this.savedWidgetName = this.translateService.instant('HOME.Lost_Deal');
        break;
      case 'Won_Deal':
        this.filterType = 'won_deal_of_12_month';
        this.savedWidgetName = this.translateService.instant('HOME.Won_Deal');
        break;
      case 'Time_Per_Stage':
        this.filterType = 'time_per_stage';
        this.savedWidgetName = this.translateService.instant('HOME.Time_Per_Stage');
        break;
      case 'Source_of_Contact':
        this.filterType = 'source_of_contact';
        this.savedWidgetName = this.translateService.instant('HOME.Source_of_Contact');
        break;
      case 'Sales_Forecast':
        this.filterType = 'sale_forecast';
        this.savedWidgetName = this.translateService.instant('HOME.Forecast sales');
        break;
      case 'Top_Deals':
        this.filterType = 'top_deals';
        this.savedWidgetName = this.translateService.instant('HOME.Top_5_Deals');
        break;
      case 'Deal_By_Type_And_Stages':
        this.filterType = 'deals-by-type-and-stages';
        this.savedWidgetName = this.translateService.instant('HOME.Deal_By_Type_And_Stages');
        break;
    }

    this.WidgetService.deal_type_id$.subscribe((data) => {
      this.dealTypeId = this.widgetsType === "Time_Per_Stage" || this.widgetsType === "Deal_By_Type_And_Stages" ? data?.id : undefined;
      if (data?.type === 'time_per_stage' && this.widgetsType === "Time_Per_Stage") {
        const filterType = localStorage.getItem("filterType");
        if (filterType !== 'custom_range') {
          let filterRecordType = filterType != 'undefined' ? filterType : 'current_month';
          const payload = {
            filterType: 'time_per_stage',
            filterRecordType: filterRecordType,
            deal_type_id: data?.id,
          };
          this.getWidget(payload);
        }
      }

    });

    if (this.widgetsType === "Deal_By_Type_And_Stages") {
      this.WidgetService.showFunnel$.subscribe((el: boolean) => {
        if (el) {
          this.filterType = 'deals-by-type-and-stages-typeId';
        } else {
          this.filterType = 'deals-by-type-and-stages';
        }
      });
    }

  }

  getWidget(data: any) {
    const getSubs = this.WidgetService.getWidgetData(data).subscribe((res: any) => {
      if (!res?.status) {
        return;
      }
      switch (this.widgetsType) {
        case 'Abandoned_Reasons':
          this.WidgetService.Abandoned_Reasons$.next(res);
          break;
        case 'Lost_Reasons':
          this.WidgetService.Lost_Reasons$.next(res);
          break;
        case 'Activity_Report':
          this.WidgetService.Activity_Report$.next(res);
          break;
        case 'Lost_Deal':
          this.WidgetService.Lost_Deal$.next(res);
          break;
        case 'Won_Deal':
          this.WidgetService.Won_Deal$.next(res);
          break;
        case 'Deals_Won_By_User_Graph':
          this.WidgetService.Deals_Won_By_User_Graph$.next(res);
          break;
        case 'Deals_Per_User':
          this.WidgetService.Deals_Per_User$.next(res);
          break;
        case 'Create_VS_Won_Deals_Graph':
          this.WidgetService.Create_VS_Won_Deals_Graph$.next(res);
          break;
        case 'Time_Per_Stage':
          this.WidgetService.Time_Per_Stage$.next(res);
          break;
        case 'Source_of_Contact':
          this.WidgetService.Source_of_Contact$.next(res);
          break;
        case 'Sales_Forecast':
          this.WidgetService.Sales_Forecast$.next(res);
          break;
        case 'Top_Deals':
          this.WidgetService.Top_Deals$.next(res);
          break;
        case 'Deal_By_Type_And_Stages':
          this.WidgetService.Deal_By_Type_And_Stages$.next(res);
          break;
      }
      this.WidgetService.dbSaveFilterVal$.next(res?.dbSaveFilterVal);

      if (this.oneTimeCall) {
        res?.dbSaveFilterVal?.forEach((obj: widgetFilterArr) => {
          if (obj.widgetName === this.filterType) {
            this.value = obj.widgetValue;
            this.selectedOption = (obj?.startDate && obj?.endDate) ? obj?.startDate + ' - ' + obj?.endDate : obj.widgetValue === "custom_range" ? 'Custom range' : undefined;
          }
        });
        this.oneTimeCall = false;
      }
    }, (error) => { });
    this.subscriptions.push(getSubs);
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  SuccessSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = 3000;
    config.panelClass = ['notif-success', 'message-position'];
    this.snackbar.open(msg, tag, config);
  }
}
