<div class="modal-content">
  <!-- <button type="button" class="bg-trans" (click)="closePopup()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button> -->
  <div class="reminder-popup" *ngFor="let item of data;let i = index;"  >
    <div class="modal-header">
      <h4 class="text-1 fs-4 text-left" style="color:#03c0fc;">{{ item.name }}</h4>
    </div>
    <div class="modal-body bg-gris-1 text-left">
      <p>{{ item.description }}</p>    
      <p> <i class="fa fa-calendar"></i> {{item.calender_show_date | customDate}}</p>
      <p *ngIf="item.display_time"><i class="fa fa-clock"></i> {{item.display_time}}</p>    
      <p *ngIf="item.customer"><i class="fa fa-user"></i> {{item.customer}}</p>
    </div>
    <div class="modal-footer j-c-center mt-4">
      <button class="btn btn-2 mx-2" (click)="closeDialog('c',item)">{{"LEADS.Snooze" | translate}}</button>    
      <button class="btn btn-2 mx-2 btn-2" (click)="closeDialog('h',item)">{{"TASK.MARKCOMPLETE" | translate}}</button>    
    </div>
    <div *ngIf="data.length != (i+1)" class="my-sm-4 border-bottom"></div>
  </div>
</div>