import { createFeatureSelector, createSelector, createReducer, on, Action } from '@ngrx/store';
import * as fromActions from '../actions/task.actions';
import { TaskState } from '../task.state';

export const initialState: TaskState = {tasks: [], message: ''};

// Creating reducer
const _taskReducer = createReducer(
  initialState,
  on(fromActions.ShowAllSuccessAction, (state, {payload}) => ({tasks: payload, message: 'Success'})),
  on(fromActions.CreateSuccessAction, (state, {payload}) => ({tasks: [payload], message: 'Article Created.'})),
  on(fromActions.CreateFailureAction, (state, {payload}) => ({tasks: [], message: payload})),
  on(fromActions.GetByIdSuccessAction, (state, {payload}) => ({tasks: payload, message: 'Success'})),    
  on(fromActions.ResetAction, (state) => ({ tasks: [], message: ''}))
);

export function taskReducer(state: any, action: Action) {
  return _taskReducer(state, action);
}

// Creating selectors
export const getTaskState = createFeatureSelector<TaskState>('taskState');

export const getTasks = createSelector(
    getTaskState, 
    (state: TaskState) => state.tasks 
);

export const getMessage = createSelector(
    getTaskState, 
  (state: TaskState) => state.message
); 