import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TasksService } from 'src/app/pages/tasks/services/tasks.service';

@Component({
  selector: 'app-reminder-dialog',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class ReminderDialogComponent implements OnInit {

  subscriptions: Subscription[] = [];
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ReminderDialogComponent>,private TasksService: TasksService,) { 
    console.log(data);
  }

  ngOnInit(): void {
    let reminderResult =  JSON.parse(localStorage.getItem('reminderList')); 
    const indexInInput = reminderResult.findIndex(item => item.uniqueId === this.data?.uniqueId);
    reminderResult.splice(indexInInput, 1);
    localStorage.setItem('reminderList', JSON.stringify(reminderResult));
  }

  closeDialog(a) {
    if(a == 'c') {
      const updateTaskSubs =  this.TasksService.updateTask({id: this.data.id, create: this.data}).subscribe(res => {        
        this.dialogRef.close();
      });
      this.subscriptions.push(updateTaskSubs);  
      // const notificationReadSubs = this.TasksService.viewTaskList(this.data.id).subscribe(async (res: any) => {
      //   this.dialogRef.close();
      // });
      // this.subscriptions.push(notificationReadSubs);
    } 
    if(a == 'h') {      
      const updateTaskSubs =  this.TasksService.updateTask({id: this.data.id, mark_as_completed_user: 1, update: this.data}).subscribe(res => {        
        this.dialogRef.close();
      });
      this.subscriptions.push(updateTaskSubs);
    }
  }

  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
