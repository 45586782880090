<div class="modal-header pt-5" style="text-align:center;">
    <h1 matDialogTitle fxLayoutAlign="space-between center" class="m-0">
        <span>
            {{message}} {{timeLeft}} {{unit}}
        </span>
    </h1>
</div>
    <div class="modal-footer j-c-center mt-4 pb-4">
        <button mat-raised-button class="btn btn-1 mx-2"  (click)="dialogRef.close('logout')" style="background-color:#43c1fc;color:white">
            {{"MERCHANT_LOGIN.Logout" | translate}}</button>
        <button mat-raised-button class="btn btn-2 mx-2" (click)="dialogRef.close('connected')" >
            {{"CONFIRM.STAYIN" | translate}}
        </button>
    </div>

