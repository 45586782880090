import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataSubject = new BehaviorSubject<any>(this.loadData());
  public data$ = this.dataSubject.asObservable();

  updateData(data: any) {
    localStorage.setItem('appData', JSON.stringify(data));
    this.dataSubject.next(data);
  }

  private loadData(): any {
    const storedData = localStorage.getItem('appData');
    return storedData ? JSON.parse(storedData) : null;
  }
}
