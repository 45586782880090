import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { emptyAddress } from 'src/app/shared/enum/empty-address';
import { AddressCompComponent } from 'src/app/pages/lead-form/address-comp/address-comp.component';
import { LeadFormService } from 'src/app/pages/lead-form/services/lead.form.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BusinessProfileService } from 'src/app/pages/business-profile/services/business-profile.service';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { QuoteTemplateService } from '../services/quote-template.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'embedform2',
  templateUrl: './embedform.component.html',
  styleUrls: ['./embedform.component.scss']
})
export class quoteEmbedformComponent implements OnInit {
  @ViewChild('previewContent') previewContent: ElementRef;
  @Input() embedFromData: any;
  embedFromData1: any;
  currencyArr: any;
  myLeadForm: FormGroup;
  checkedValues: string[] = [];
  selectedFiles: File[] = [];
  tableData: string[][] = [];  rowsArray: any[];
  crSymbol:any;
  selectedRowCount: number = 3;
  selectedColumnCount: number=3;
  headerData: string[] = [];
  isDragOver = false;
  subscriptions: Subscription[] = [];
  countryListArr: any[] = [];
  phonenumber:any[] = [{ 'countryId': '', 'mobile_number': '', 'name': '', 'order': 0 }];
  staticLogo: string =
    'https://osmos-bucket-prod.s3.us-east-1.amazonaws.com/upload_note_attachment/1699445669424_placeholder_logo.png';
  protected onDestroy = new Subject<void>();
  customerAddressArr: any = [new emptyAddress()];
  labelName1='address';
  moduleName='Lead Form Address';
  selectedCurrency: any
  dropListData = [
    {
      page: 'page00',
      items: [],
    },
    // Add more pages as needed
  ];
    tableStyleClass = 'table-style-default'
    tables: { name: any, data: any[] }[] = [];
    columnsToDisplayWithExpand :any ;
    field_styles: any[] = []; 
    rateValue:any;
    actpageIndx: number = 0;
    formattedSelectedDate:any;
    creationDate:boolean=false;
    addressArr: any;
    columnsToDisplay = [
      'item',
      'quantity',
      'weight',
      'price',
      'discount',
      'discPrice',
      'totalPrice',
    ];

  @ViewChild(AddressCompComponent, { static: false }) AddressCompComponent: AddressCompComponent;
  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private businessProfileService: BusinessProfileService,
    public leadService: LeadService,
    private QuoteTemplateService: QuoteTemplateService,
    private elementRef: ElementRef, private renderer: Renderer2,
    public router: Router,
    private translateService: TranslateService,

  ) {
    this.myLeadForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.getTemplate();
    this.leadService.currencySymbal$.subscribe((res: any) => { this.crSymbol = res; });
    // this.leadForm_service?.countryList().subscribe((val: any) => {
    //   this.countryListArr = val?.data;
    // })
    const id = this.route.snapshot.params['id'];
if(id){
  const get_Lead_Form_Subs = this.QuoteTemplateService.get_Lead_Form(id).subscribe((res: any) => {
    if (res?.data) {
      let data: any;
      this.embedFromData1=res?.data?.html
      res?.data?.quotes_templates_new_fields.forEach((x) => {
        try {
            data = JSON.parse(x.field_styles);
            console.log(data,'datadatadata');
            
            this.field_styles.push(data);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    });
   this.field_styles.sort((a, b) => {
        if (a.page < b.page) {
            return -1;
        }
        if (a.page > b.page) {
            return 1;
        }
        return 0;
    });

    this.dropListData = this.field_styles;
      
    this.dropListData.forEach((item) =>{
      
      item.items.forEach(async (item2)=>{          
        if(item2.name =='Item Table'){
        
          if(item2.class_name){
            this.tableStyleClass = item2.class_name
          }
          this.tables=item2.table
          this.columnsToDisplayWithExpand =[...item2?.displayedColumns11]
          this.columnsToDisplayWithExpand = [...this.columnsToDisplayWithExpand];

        }
        
        
        // this.displayedColumnsKeys = this.columnsToDisplayWithExpand.map(column => column.name)
        // console.log(this.displayedColumnsKeys,'wwwwwwwwwwww');
       
  // this.columnsToDisplay = this.columnsToDisplay
      })
    })
      this.setReadonlyForInputAndTextarea();

    }
    this.subscriptions.push(get_Lead_Form_Subs);

  }, (error) => {
  });

}
    // Call your data service to fetch data based on the 'id'
    // this.getCurrency()

    
  }

  get getColor1() { return this.embedFromData.data?.color; }
  get getFmly1() { return this.embedFromData?.data.font_family; }
  get getFsize1() { return this.embedFromData?.data.font_size; }
  get getLogo1() { return this.embedFromData?.style?.logo; }
  get getCheckbox1() {const value = this.embedFromData?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }

  get getData() { return this.embedFromData?.data; }
  get getColor() { return this.embedFromData?.data.color; }
  get getFmly() { return this.embedFromData?.style?.font_family; }
  get getFsize() { return this.embedFromData?.data?.font_size; }
  get getLogo() { return this.embedFromData?.style?.logo; }
  get getCheckbox() {const value = this.embedFromData?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }
  get getAttechValue() { return this.embedFromData?.data?.find((el: any) => el?.form_name === 'Attachment')?.value }
  get acceptFile(): string {
    const acceptedTypes = this.getAttechValue?.filter((fileType: any) => fileType?.value)?.map((fileType: any) => `.${fileType?.name?.toLowerCase()}`)?.join(',');
    return acceptedTypes;
  }
  onSubmit() {
    const address = this.AddressCompComponent?.addressItems?.lineItems[0];
    this.myLeadForm?.controls['Address']?.setValue(address);
    if (this.myLeadForm.invalid) {
      console.log('myLeadForm.invalid', this.myLeadForm.value);
      return
    }
    console.log('myLeadForm.valid', this.myLeadForm.value);
  }

  getSafeHtml(): SafeHtml {
      const decodedHtml = decodeURIComponent(this.embedFromData1);
      const trustedHtml = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
      return trustedHtml;
  }

  

  createForm(embed: any): FormGroup {
    if (!this.myLeadForm) {
      this.myLeadForm = this.fb.group({});
    }
    if (embed?.type !== 'content') {
      if (embed?.form_name !== 'Submit') {
        if (embed?.required) {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null, Validators.required));
        } else {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null));
        }
      }
    }
    return this.myLeadForm;
  }

  onCheckboxChange(event: any, data: any) {
    const valueAt = this.getCheckbox?.findIndex((el: any) => el?.name == data?.name);
    const checked = event?.target?.checked;
    if (valueAt !== -1) {
      this.getCheckbox[valueAt].selected = checked;
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  ngAfterViewInit(): void {
    this.setReadonlyForInputAndTextarea();
    if (this.myLeadForm?.controls['Checkbox'] !== undefined) {
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
    if (this.isInDropZone(event)) {
      event.dataTransfer.dropEffect = 'copy';
    } else {
      event.dataTransfer.dropEffect = 'none';
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.handleFiles(event.dataTransfer.files);
  }

  private isInDropZone(event: DragEvent): boolean {
    const targetElement = event.target as HTMLElement;
    return targetElement.matches('#attachment_icon');
  }

  onFileSelected(event: any) {
    this.handleFiles(event.target.files);
  }

  handleFiles(files: FileList | File[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.isValidFile(file)) {
        this.selectedFiles.push(file);
        this.myLeadForm?.controls['Attachment']?.setValue(this.selectedFiles);
      }
    }
  }
  initializeHeaderData() {
    for (let j = 0; j < this.selectedColumnCount; j++) {
      this.headerData.push(`Column ${j + 1}`);
    }}

  initializeTableData() {
    for (let i = 0; i < this.selectedRowCount; i++) {
      const row: string[] = [];
      for (let j = 0; j < this.selectedColumnCount; j++) {
        row.push('');
      }
      this.tableData.push(row);
    }}


  async getCurrency() {
    const currencySubs = await this.businessProfileService.currency().subscribe((val: any) => {
      // Assuming that val.data is an array of currencies
      this.currencyArr = val.data;
      const matchingCurrency = val.data.find(
        currency =>
          currency.currency_symbol === this.crSymbol 
      );
      this.selectedCurrency = { currency_code: matchingCurrency.currency_code, currency_symbol:matchingCurrency.currency_symbol };    
      // Check if currencyArr is empty or not
      if (this.currencyArr.length > 0) {
        // If currencyArr is not empty, set the default currency as the first item in the array
        this.selectedCurrency = this.currencyArr[1];
      } else {
        // Handle the case where no currencies were fetched
        // You can set a default currency or handle this scenario as per your requirements
      }
  
    });

    // const matchingCurrency = this.currencyArr.find(
    //   currency =>
    //     currency.currency_symbol === this.crSymbol
    // );  tu
    // this.selectedCurrency = matchingCurrency;

  }

  isValidFile(file: File): boolean {
    let accepted: string[] = [];
    const fileTypeMap = new Map<string, string[]>([['DOX', ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']], ['PDF', ['application/pdf']], ['PNG', ['image/png']], ['JPG', ['image/jpeg']]]);
    this.getAttechValue.forEach(fileType => { if (fileType?.value && fileTypeMap?.has(fileType?.name)) { accepted.push(...fileTypeMap.get(fileType?.name)!); } });
    const maxSize = 10 * 1024 * 1024;
    if (!accepted.includes(file.type)) {
      this.errorSnackBar('Invalid file type.', 'OK');
      return false;
    }
    if (file.size > maxSize) {
      this.errorSnackBar('File size exceeds the limit.', 'OK');
      return false;
    }
    return true;
  }

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  errorSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = ['notif-error'];
    this.snackbar.open(msg, tag, config);
  }

  ngOnDestroy(): void {
    this.onDestroy.next(null);
    this.onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTemplate() {
    if (this.embedFromData1) {
      this.embedFromData1 = null;
    }
    this.route.data.subscribe((data) => {
      if (data?.viewTemplateData) {
        const embedFrom = data.viewTemplateData?.data?.quotes_templates_fields;
        if (embedFrom) {
          this.embedFromData1 = {
            data: embedFrom?.map((el: any) => el?.field_styles),
            style: {
              color: embedFrom[0]?.field_styles?.colors,
              font_family: embedFrom[0]?.field_styles?.fontFamily,
              font_size: {
                head: embedFrom[0]?.field_styles?.headingSize,
                body: embedFrom[0]?.field_styles?.bodySize,
                helptxt: embedFrom[0]?.field_styles?.helpTextSize
              },
              logo: embedFrom[0]?.field_styles?.imageURL
            }
          }
        }
      }
    });
  }
  makeInputFieldsReadonly() {
    if (this.embedFromData1) {
      const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
      inputElements.forEach((input: HTMLInputElement) => {
        this.renderer.setAttribute(input, 'readonly', 'true');
      });
    }
  }

  private setReadonlyForInputAndTextarea() {
    if (this.embedFromData1 && this.previewContent) {
      const inputElements = this.previewContent.nativeElement.querySelectorAll('input, textarea');
      inputElements.forEach((element: HTMLInputElement | HTMLTextAreaElement) => {
        this.renderer.setAttribute(element, 'readonly', 'true');
      });
    }
  }

  closeDialog() {
    this.router.navigate([`dash/quote-template`]);
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }
  
  
  getSubtotal(data: any[]): number {
    let subtotal = 0;
    for (const item of data) {
      let price = item?.perItemPrice;
      if (item?.perItemPrice) {
        if (typeof item?.perItemPrice == 'string') {
          price = parseFloat(item['Price'].replace(/,/g, ''));
        }
      }
      let quantity = item['Quantity']
        ? item['Quantity']
        : item['QTY']
        ? item['QTY']
        : item['QUANTITY']?item['QUANTITY']:item['quantity'];
      if (typeof quantity === 'string') {
        quantity = parseFloat(quantity.replace(/,/g, ''));
      }
      if(quantity ==0){
        quantity =1
      }
      subtotal += price * quantity;
    }
    if (
      this.rateValue !== undefined &&
      this.rateValue &&
      (this.rateValue !== 0 || this.rateValue !== '0')
    ) {
      subtotal = subtotal * this.rateValue;
    }
    // this.grandTotal2=subtotal
    return subtotal;
  }
  
  getSubtotalAfter(data) {
    let subtotal = 0;
  
    for (const item of data) {
      let quantity = item['Quantity']
      ? item['Quantity']
      : item['QTY']
      ? item['QTY']
      : item['QUANTITY']?item['QUANTITY']:item['quantity'];
      if (typeof quantity === 'string') {
        quantity = parseFloat(quantity.replace(/,/g, ''));
      }
      subtotal += item?.priceAfterDiscount;
    }
    if (
      this.rateValue !== undefined &&
      this.rateValue &&
      (this.rateValue !== 0 || this.rateValue !== '0')
    ) {
      subtotal = subtotal * this.rateValue;
    }
  
    return subtotal;
  }
  
  getDiscount(data: any[]): number {
    let discount = 0;
    let perItemDiscount = 0;
  
    for (const item of data) {
      let perItemDiscount = item?.perItemDisc;
      if(item?.perItemDisc == item?.perItemPrice){
        perItemDiscount=0
      }
      if (typeof item?.perItemDisc === 'string') {
        perItemDiscount = parseFloat(item.perItemDisc.replace(/,/g, ''));
      }
      let quantity = item['Quantity']
        ? item['Quantity']
        : item['QTY']
        ? item['QTY']
        : item['QUANTITY']?item['QUANTITY']:item['quantity'];
      if (typeof quantity === 'string') {
        quantity = parseFloat(quantity.replace(/,/g, ''));
      }
      if(quantity ==0){
        quantity =1
      }
      discount += perItemDiscount * quantity;
      // this.totalDiscount2 = discount;
    }
    // if (
    //   this.rateValue !== undefined &&
    //   this.rateValue &&
    //   (this.rateValue !== 0 || this.rateValue !== '0')
    // ) {
    //   discount = discount * this.rateValue;
    // }
    return discount;
  }

  getCurrentFormattedDate(name,id,kk) {
    this.actpageIndx=kk
    const index = this.dropListData[this.actpageIndx].items.findIndex(
      (item: any) => item?.name === name && item?.id === id
    );
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    if (index !== -1) {
      this.dropListData[this.actpageIndx].items[index].quoteCreationDate =
      formattedDate;
      this.formattedSelectedDate = formattedDate
      this.creationDate=true
      return formattedDate;
    }else return "";
  }

  commonFormat(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const currentDate = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    
    return formattedDate;


}

getAddress(address: any): string {
  let addressVal = localStorage.getItem('address_format');
  this.addressArr = addressVal?.split(',');
  let addressData = '';
  if (this.addressArr && this.addressArr?.length > 0) {
    for (let i = 0; i < this.addressArr?.length; i++) {
      if (this.addressArr[i] === 'City Name') {
        addressData += (address?.city && address?.city + ', ') || '';
      }
      if (this.addressArr[i] === 'Street Name') {
        addressData +=
          (address?.street_name && address?.street_name + ', ') || '';
      }
      if (this.addressArr[i] === 'Country Name') {
        addressData += (address?.country && address?.country + ', ') || '';
      }
      if (this.addressArr[i] === 'Postal Code') {
        addressData +=
          (address?.postal_code && address?.postal_code + ', ') || '';
      }
      if (this.addressArr[i] === 'House Number') {
        addressData +=
          (address?.house_number && address?.house_number + ', ') || '';
      }
      if (this.addressArr[i] === 'Interior Number') {
        addressData +=
          (address?.interior_number && address?.interior_number + ', ') || '';
      }
      if (this.addressArr[i] === 'State Name') {
        addressData += (address?.state && address?.state + ', ') || '';
      }
    }
    return addressData;
  } else {
    return (
      (
        ((address?.house_number && address?.house_number + ', ') || '') +
        ((address?.interior_number && address?.interior_number + ', ') || '') +
          ((address?.street_name && address?.street_name + ', ') || '') +
          ((address?.country && address?.country + ', ') || '') +
          ((address?.city && address?.city + ', ') || '') +
        ((address?.state && address?.state + ', ') || '') +
        ((address?.postal_code && address?.postal_code + ', ') || '') 
      )?.trim() || ''
    );
  }
}

getFilteredColumns(columns: any[], orientation: string): any[] {
  const filteredColumns = columns.filter(
    (column) => column.orientation === orientation
  );
  this.columnsToDisplay = filteredColumns.map((c) => c.name);
  return filteredColumns;
}

getTableDataSource(tableIndex: number): MatTableDataSource<any> {
  return new MatTableDataSource<any>(this.tables[tableIndex].data);
}

getTotalTax(data: any[], rateValue: number): number {
  let totalTaxAmount = 0;

  data.forEach((item) => {
    if (item && item.taxesArray) {
      const perItemPrice = parseFloat(item.priceAfterDiscount!==0?item?.priceAfterDiscount:item?.perItemPrice);
      let quantity = item['Quantity']
      ? item['Quantity']
      : item['QTY']
      ? item['QTY']
      : item['QUANTITY']?item['QUANTITY']:item['quantity'];
      if (typeof quantity === 'string') {
        quantity = parseFloat(quantity.replace(/,/g, ''));
      }
      if(quantity ==0){
        quantity=1
      }
      const totalPrice = perItemPrice; // Ensure to multiply by quantity
      item.taxesArray.forEach((tax) => {
        if (tax.name && tax.value) {
          const taxPercentage = parseFloat(tax.value) / 100;
          const taxAmount = totalPrice * taxPercentage * (rateValue || 1);
          totalTaxAmount += taxAmount;
        }
      });
    }
  });

  return totalTaxAmount;
}
aggregateTaxes(
  data: any[],
  rateValue
): { [key: string]: { amount: number; percentage: number; value: any } } {
  const aggregatedTaxes: {
    [key: string]: { amount: number; percentage: number; value: any };
  } = {};
  data.forEach((item) => {
    if (item && item.taxesArray) {
      item.taxesArray.forEach((tax) => {
        if (tax.name && tax.value) {
          const taxName = tax.name;
          if (aggregatedTaxes.hasOwnProperty(taxName)) {
            aggregatedTaxes[taxName].value = parseFloat(tax.value);
          } else {
            aggregatedTaxes[taxName] = {
              amount: 0,
              percentage: 0,
              value: parseFloat(tax.value),
            };
          }
        }
      });
    }
  });
  data.forEach((item) => {
    if (item && item.taxesArray) {
      const perItemPrice = parseFloat(item.priceAfterDiscount!==0?item?.priceAfterDiscount:item?.perItemPrice);
      let quantity = item['Quantity']
      ? item['Quantity']
      : item['QTY']
      ? item['QTY']
      : item['QUANTITY']?item['QUANTITY']:item['quantity'];
      if (typeof quantity === 'string') {
        quantity = parseFloat(quantity.replace(/,/g, ''));
      }
      if(quantity ==0){
        quantity =1
      }
      const totalPrice = perItemPrice;
      item.taxesArray.forEach((tax) => {
        if (tax.name && tax.value) {
          const taxName = tax.name;
          const taxPercentage = parseFloat(tax.value) / 100;
          const taxAmount = totalPrice * taxPercentage * (rateValue || 1);
          if (aggregatedTaxes.hasOwnProperty(taxName)) {
            aggregatedTaxes[taxName].percentage = parseFloat(tax.value);
            aggregatedTaxes[taxName].amount += taxAmount;
          }
        }
      });
    }
  });
  return aggregatedTaxes;
} 

  conorglead(el: string): boolean {  return el === 'First Name' || el === 'Last Name' || el === 'Email' || el === 'Address' || el === 'Organization' || el === 'Business N.' || el === 'Company' || el === 'Industry'; }

}

