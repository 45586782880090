import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModelLayoutComponent } from '../components/model-layout/model-layout.component';

@Injectable({
  providedIn: 'root'
})
export class ComSerService {
  private isOpenedDrawer = new BehaviorSubject<boolean>(false);
  isOpenedDrawer$ = this.isOpenedDrawer.asObservable();

  private isPinCLick = new BehaviorSubject<boolean>(false);
  isPinCLick$ = this.isPinCLick.asObservable();

  public dataModelClose$:Subject<any> = new Subject()
  constructor(public dialog: MatDialog,
    private http:HttpClient
    ) { }

  openModel(modelName:any){
    if(this.dialog.openDialogs && this.dialog.openDialogs.length > 0){
      return;
    }
    let dialogRef = this.dialog.open(ModelLayoutComponent, modelName);
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status){
        this.newfunction(result);
      }
    });
  }
  newfunction(res:any){
    this.dataModelClose$.next(res);
  }

  readcsv(csv:string): Observable<any>{
   return this.http.get(csv , {responseType: 'text'})
  }

 
 
  setScreenWidth (isOpened: boolean): void {
    this.isOpenedDrawer.next(isOpened);
  }

   
  pinSetScreenWidth (isClicked: boolean): void {
    this.isPinCLick.next(isClicked);
  }
  
  }




  // createDialog: Function = (element: HTMLElement, model: DialogModel): Dialog => {
  //   if (!element.classList.contains('e-dialog')) {
  //     model.showCloseIcon = true;
  //     this.dialogObj = new Dialog(model, element);
  //   }
  //   return this.dialogObj
  // };

  // showDialog: Function = (elemnet: HTMLElement, model: DialogModel) => {
  //   this.dialogInstance = this.createDialog(elemnet, model);
  //   this.dialogInstance.show();
  // }


