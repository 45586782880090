import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public retrivePermission(): any {
        return JSON.parse(this.decryptData(localStorage.getItem('permission')));
    }   

    /**
	* Encrypt Algorithm
	*/
    encryptData(data) {  
        return CryptoJS.AES.encrypt(data, environment.ENCRYPTPASSWORD).toString();
    }  

    decryptData(data) {  
        let bytes  = CryptoJS.AES.decrypt(data, environment.ENCRYPTPASSWORD);
        return bytes.toString(CryptoJS.enc.Utf8);
    }  
}
