import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  BASE_URL: string;
  ITEM_MODULE_URL:string;
  public organizationList$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.ITEM_MODULE_URL=environment.ITEM_MODULE_URL
  }

  getCustomer(resource = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantcustomer/list`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  downloadCustomer(data = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantcustomer/downloadCsv`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getMerchantuser(resource: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantuserdata/list`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getLanguage(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/language/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getPrice(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/prices/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateLanguage(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/updateLanguage`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updatePrice(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/updatePrice`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateRating(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/updaterating`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  convertlead(data: any = ''): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/merchantcustomer/convertlead`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteContact(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchantcustomer/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteSaveFilter(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/customerfilter/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateCustomerTags(data: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantcustomer/updatetags`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createUpdateFieldListing(resource: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/merchantfieldlist/filter`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createUpdateFilterListing(resource: any = ''): Observable<any> {
    return this.http.put(`${this.BASE_URL}/customerfilter/filter`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  getFieldListing(resource: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantfieldlist/filterlist`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getFilterListing(resource: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/customerfilter/filterlistdata`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getOrganizationList(resource: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantorganization/listdata`, resource).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getFilterList(id: any = ''): Observable<any> {
    return this.http.get(`${this.BASE_URL}/customerfilter/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  actionContact(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchantcustomer', data)
      .pipe(
        tap(
          data => data,
          error => error
        )
      );
  }

  headercsvfile(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/merchant/contact/csv/header/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  headerItemscsvfile(): Observable<any> {
    return this.http.get(`${this.ITEM_MODULE_URL}/item/headers/list`).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  emailexits(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/contact/email/exist`,data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadCsv(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/upload/file`,data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  uploadItemsCsv(data:any): Observable<any> {
    return this.http.post(`${this.ITEM_MODULE_URL}/item/uploadItemsCsv`,data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


}
