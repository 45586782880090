import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAlphasOnly]'
})
export class AlphasOnlyDirective {

  constructor(private _el: ElementRef) { }

  // @HostListener('input', ['$event']) onInputChange(event) {
  //   const initalValue = this._el.nativeElement.value;
  //   this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z]*/g, '');
  //   if (initalValue !== this._el.nativeElement.value) {
  //     event.stopPropagation();
  //   }
  // }

  @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const current = this._el.nativeElement.value;
    const regex: RegExp =  new RegExp("^[a-zA-Z][a-zA-Z\\s]*$");
    const position = this._el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');
    
    if (regex.test(next)) {
      return true;
    }

  event.preventDefault();
  return false;
}

}
