export class emptyAddress {
    public title: string = 'Billing Address'
    public citiesListArr: any = [];
    public default_address: boolean| string = false;
    public city: string;
    public state: string;
    public postal_code: string;
    public street_name: string;
    public status: boolean = false;
    public house_number: string;
    public interior_number: string;
    public additional_add_show: boolean = true
    public country: string

    constructor() { }
}