import { Pipe, PipeTransform } from '@angular/core';
  
@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  
  transform(number) {

	const phoneNumberFormat = localStorage.getItem('phone_number_formate');
	// '000-000-0000',
    // '0000-0000',
    // '0-000-000-0000',
    // '00-0000-00000',
    // '(00) 0000-0000',
    // '(000) 000-0000',
    // '(000) 0000-0000'

	if(phoneNumberFormat == '000-000-0000') {
		const phonePart1 = number.slice(0,3);
		const phonePart2 = number.slice(3,6);
		const phonePart3 = number.slice(6,10);
		return phonePart1 + '-' + phonePart2 + '-' + phonePart3;
	}

	if(phoneNumberFormat == '0000-0000') {
		const phonePart1 = number.slice(0,4);
		const phonePart2 = number.slice(4,8);		
		return phonePart1 + '-' + phonePart2;
	}

	if(phoneNumberFormat == '0-000-000-0000') {
		const phonePart1 = number.slice(0,1);
		const phonePart2 = number.slice(1,4);
		const phonePart3 = number.slice(4,7);
		const phonePart4 = number.slice(7,10);
		return phonePart1 + '-' + phonePart2 + '-' + phonePart3 + '-' + phonePart4;
	}

	if(phoneNumberFormat == '00-0000-00000') {
		const phonePart1 = number.slice(0,2);
		const phonePart2 = number.slice(2,6);
		const phonePart3 = number.slice(6,10);
		return phonePart1 + '-' + phonePart2 + '-' + phonePart3;
	}

	if(phoneNumberFormat == '(00) 0000-0000') {
		const phonePart1 = number.slice(0,2);
		const phonePart2 = number.slice(2,6);
		const phonePart3 = number.slice(6,10);
		return '(' + phonePart1 + ') ' + phonePart2 + '-' + phonePart3;
	}

	if(phoneNumberFormat == '(000) 000-0000') {
		const phonePart1 = number.slice(0,3);
		const phonePart2 = number.slice(3,6);
		const phonePart3 = number.slice(6,10);
		return '(' + phonePart1 + ') ' + phonePart2 + '-' + phonePart3;
	}

	if(phoneNumberFormat == '(000) 0000-0000') {
		const phonePart1 = number.slice(0,3);
		const phonePart2 = number.slice(3,6);
		const phonePart3 = number.slice(6,10);
		return '(' + phonePart1 + ') ' + phonePart2 + '-' + phonePart3;
	}
	
	return number;
  }
  
} 