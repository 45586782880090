import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FuseModule } from '@fuse/fuse.module';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTagInputModule } from 'ngx-mat-tag-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TaskEffects } from 'src/app/pages/tasks/effects/task.effects';
import { reducers } from 'src/app/pages/tasks/reducers/reducers';
import { CustomDateFormatPipe } from 'src/app/shared/pipes/custom-date-format/custom-date-format.datepipe';
import { CustomDateTimeFormatPipe } from 'src/app/shared/pipes/custom-date-format/custom-date-time-format.datepipe';
import { CustomTimeFormatPipe } from 'src/app/shared/pipes/custom-date-format/custom-time-format.datepipe';
import { PhoneFormatPipe } from 'src/app/shared/pipes/phone-date-format/phone-date-format.phonepipe';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { MatConfirmDeleteComponent } from './Dialogs/mat-confirm-delete/mat-confirm-delete.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { Auth2LayoutComponent } from './layouts/auth2-layout/auth2-layout.component';
import { Auth3LayoutComponent } from './layouts/auth3-layout/auth3-layout.component';
import { DashLayoutComponent } from './layouts/dash-layout/dash-layout.component';
import { mockApiServices } from './mock-api';
import { SharedModule } from './shared/shared.module';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AuthenticateDomainVerifyComponent } from './Dialogs/authenticate-domain-verify/authenticate-domain-verify.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AutoLogoutDialogModule } from './Dialogs/auto-logout-dialog/auto-logout-dialog.module';
import { HeaderComponent } from './components/dash/header/header.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import { NgxColorsModule } from 'ngx-colors';
//import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ChartsModule } from 'ng2-charts';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CloneQuoteComponent } from './Dialogs/clone-quote/clone-quote.component';
import {MatRadioModule} from '@angular/material/radio';
import { QuoteIdComponent } from './Dialogs/quote-id/quote-id.component';
import { CreateEquoteDialogModule } from './pages/eQuote-templates/create-equote-dialog/create-equote-dialog.module';
import { ReminderPopupComponent } from './reminder-popup/reminder-popup.component';
import { ReminderSnackbarComponent } from './reminder-popup/reminder-popup.component';
import { ReminderDialogModule } from './Dialogs/reminders/reminders.module';
import { SelectContactModule } from './Dialogs/Select-Contact/select_contact.module';


// Export this function
export function playerFactory() {
  return player;
}



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    Auth2LayoutComponent,
    Auth3LayoutComponent,
    // DashLayoutComponent,
    // ReminderPopupComponent,
    MatConfirmDeleteComponent,
    CloneQuoteComponent,
    QuoteIdComponent,
    ReminderPopupComponent,
    ReminderSnackbarComponent
  ],
  imports: [
    CreateEquoteDialogModule,
    //NgxMaterialTimepickerModule,
    BrowserModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSnackBarModule,
    MatChipsModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    NgxSliderModule,   
    SelectContactModule, 
    MatSidenavModule,
    MatDividerModule,
    MatDatepickerModule,
    NgIdleKeepaliveModule.forRoot(),
    AutoLogoutDialogModule,
    MatExpansionModule,
    ScrollingModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMatTagInputModule,
    MatSidenavModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    ChartsModule,
    ReminderDialogModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([TaskEffects]),
    // EffectsModule.forRoot([TaskEffects]),
       // Fuse, FuseConfig & FuseMockAPI
       FuseModule,
       FuseConfigModule.forRoot(appConfig),
       FuseMockApiModule.forRoot(mockApiServices),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FontAwesomeModule,
    LottieModule.forRoot({ player: playerFactory }),  

  ],
  
  providers: [
    MatAutocomplete,
    CustomDateFormatPipe,
    CustomDateTimeFormatPipe,
    CustomTimeFormatPipe,
    PhoneFormatPipe,
    HeaderComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
