<div class="modal-content border-0 cnt-dashboard border-r2 px-5" data-backdrop="static">

    <div class="model-header position-relative">
        <ng-container *ngIf="type !== 'invoice'">
        <h2 class="text-1 fs-4 text-center mb-4">{{"BUSINESS_PROFILE.Quote Number" | translate}}</h2>
    </ng-container>
    <ng-container *ngIf="type === 'invoice'">
        <h2 class="text-1 fs-4 text-center mb-4">{{"QUOTE_TEMPLATE.Invoice Number" | translate}}</h2>


    </ng-container>
        <i (click)="closeDialog()" style="top: -8px !important; right: -43px !important;" type="button" data-bs-dismiss="modal" aria-label="Close"
            class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
    </div>
    <ng-container *ngIf="type !== 'invoice'">
        <p class="text-3 fw-500 m-0">{{"BUSINESS_PROFILE.QuoteNumberHelp" | translate}}</p>
    </ng-container>
    <ng-container *ngIf="type === 'invoice'">
        <p class="text-3 fw-500 m-0">{{"QUOTE_TEMPLATE.QuoteNumberHelp" | translate}}</p>
    </ng-container>
    <p class="text-3 fw-500 m-0">{{"BUSINESS_PROFILE.YouCanAddPrefix" | translate}}</p>
    <p class="text-3 fw-500 m-0">Once an initial number is set, ONLY the number will be sequential. A-2, A-3</p>

    <div class="row">
        <form  [formGroup]="quoteNumberForm" (submit)="addFormValue()">

            <div class="col-12 mt-2">
                <div class="d-inline-block text-1 fw-500 align-top f-15">
                    <i class="fas fa-file-invoice-dollar fs-5 me-2 ligh-main"></i>
                    <p class="d-inline-block text-3 fw-500  align-top m-0">
                        {{"BUSINESS_PROFILE.Prefix" | translate}}
                    </p>
                </div>
                <div class="mt-1">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <input matInput type="text"
                            formControlName="quote_prefix" class="fw-400 firstname" appAlphasOnly>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-12 mt-3">
                <div class="d-inline-block text-1 fw-500 align-top f-15">
                    <i class="fas fa-file-invoice-dollar fs-5 me-2 ligh-main"></i>
                    <p class="d-inline-block text-3 fw-500  align-top m-0">
                        <ng-container *ngIf="type !== 'invoice'">
                            {{"BUSINESS_PROFILE.Quote Number" | translate}}
                        </ng-container>
                        <ng-container *ngIf="type === 'invoice'">
                           {{"QUOTE_TEMPLATE.Invoice Number" | translate}}
                    
                    
                        </ng-container>
                    </p>
                </div>
                <div class="mt-1">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <input matInput type="number" min="1"
                            formControlName="quote_number" class="fw-400 firstname" (input)="onInput($event)">
                    </mat-form-field>
                </div>
            </div>

            <div class="text-3 fw-500  mt-2 rounded-3 d-flex justify-center">
                <button #openReasons type="button" class="text-right btn btn-p-2 btn-skyblue-s-1 primary-btn mx-2 resp_div"
                        data-bs-toggle="collapse" data-bs-target="#collapse-dealStage" aria-expanded="false"
                        aria-controls="collapse-dealStage" (click)="closeDialog()">
                        {{"PROFILE.cancel" | translate}}</button>
                <button class="nav-link default-btn btn-1 btn-p-2  rounded-3" type='submit'>
                    {{"BUSINESS_PROFILE.Save" | translate}}</button>
            </div>
        </form>
    </div>

</div>