import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TasksService {
  BASE_URL: string;
 
  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
  }

  notificationListing(data: any): Observable<any> {
    if(data.viewAll) {
      return this.http.get(`${this.BASE_URL}/merchant/notification/list?viewall=1`, {
        observe: 'response'
      });
    } else {
      return this.http.get(`${this.BASE_URL}/merchant/notification/list`, {
        observe: 'response'
      });
    }
  }

  notificationRead(data:any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchant/delete/update/notification`, data);
  }
  createPassword(user: any, password): Observable<any> {
    return this.http.get(`${this.BASE_URL}/crn-controller?u=${user}&password=${password}`, {
      observe: 'response'
    });
  }

  goaltypeList(resource = '') {
    return this.http.post(this.BASE_URL + '/goaltype/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  merchantUserList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/merchantuser/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  merchantCustomerList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/merchantcustomer/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  goalmeasureList(resource = '') {
    return this.http.post(this.BASE_URL + '/goalmeasure/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  tasktypeList(resource = '') {
    return this.http.post(this.BASE_URL + '/tasktype/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  taskList(resource:any = '') {    
    return this.http.post(this.BASE_URL + '/task/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  taskReminderList(resource:any = '') {
    return this.http.post(this.BASE_URL + '/task/list/reminders', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getgoalList(resource = '') {
    return this.http.post(this.BASE_URL + '/goal/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  inprogressTaskList(resource) {
    return this.http.post(this.BASE_URL + '/task/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  teamList(resource = '') {
    return this.http.post(this.BASE_URL + '/team/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addGoalList(resource) {
    return this.http.post(this.BASE_URL + '/goal', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addNewGoalMeasure(resource) {
    return this.http.post(this.BASE_URL + '/goalmeasure', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addNewTaskType(resource) {
    return this.http.post(this.BASE_URL + '/tasktype', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteTaskType(id: any = '') {
    const resource = {'status': 2, 'id': id};
    return this.http.put(this.BASE_URL + '/tasktype', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  deleteTaskTypeAgenda(id: any = '') {
    // const resource = {'status': 2, 'id': id};
    return this.http.delete(this.BASE_URL + '/tasktype/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateTasktypeData(resource) {
    return this.http.put(this.BASE_URL + '/tasktype', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addtaskList(resource) {
    return this.http.post(this.BASE_URL + '/task', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteTaskList(id) {
    return this.http.delete(this.BASE_URL + '/task/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  editTask(resource) {
    return this.http.put(this.BASE_URL + '/task', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateTaskStatusData(resource) {
    return this.http.put(this.BASE_URL + '/task/taskStatus/edit', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateTaskStatusOrderData(resource) {
    return this.http.put(this.BASE_URL + '/task/taskStatus/orders/edit', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  viewTaskList(id) {
    return this.http.get(this.BASE_URL + '/task/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  viewGoalsList(id) {
    return this.http.get(this.BASE_URL + '/goal/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteGoalsList(id) {
    return this.http.delete(this.BASE_URL + '/goal/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  editGoals(resource) {
    return this.http.put(this.BASE_URL + '/goal', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateTask(data) {
    return this.http.put(this.BASE_URL + '/task/update-status', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
  
  updateTaskStatus(id, status) { //Nirav
    return this.http.put(this.BASE_URL + '/task/taskStatus', { id: id, status: status })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  taskStatusList(resource = '') {
    return this.http.post(this.BASE_URL + '/task/taskStatus/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addTaskStatusList(resource) {
    return this.http.post(this.BASE_URL + '/task/taskStatus', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  editTaskStatus(resource) {
    return this.http.post(this.BASE_URL + '/task/taskStatus', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getIcon() { //Nirav
    return this.http.get(this.BASE_URL + '/icon/getIcon')
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }
}
