import { Component, Input, OnInit, Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { parsePhoneNumberFromString, isValidNumber, CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-country-code',
  templateUrl: './country-code.component.html',
  styleUrls: ['./country-code.component.scss']
})
export class CountryCodeComponent implements OnInit {
  @Input() stylechanged: any;
  @Input() countryIpData: any;
  @Input() country_code: FormControl;
  @Input() mobile_number: FormControl;
  @Input() countryListArr: any[];
  @Output() mobileNumberValidate: EventEmitter<boolean> = new EventEmitter();
  @Output() countryCodeVal: EventEmitter<CountryCode> = new EventEmitter();
  @ViewChild('countryselect') input: ElementRef;
  mobileNumber: string;
  public codeFilterCtrl: FormControl = new FormControl();
  countryForm: FormGroup ;
  newcountryCodedata = [];
  countryCode: CountryCode = 'CA';
  inValidMobileNumber: boolean = false;

  ngOnInit(): void {
 
    if (this.countryIpData?.country_code) {
          this.countryCode = this.countryIpData?.country_code;
          this.countryCodeVal.emit(this.countryCode);
    }

    this.newcountryCodedata =  this.countryListArr;
    
    this.codeFilterCtrl.valueChanges
    .subscribe(() => {
      this.getcodenumber();
    });

    this.mobileNumberChange();
  
  }


  mobileNumberChange() {
    this.mobile_number.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(val => {        
        if (val && this.countryCode) {
           this.mobileNumberValidation();
        } else {
          this.inValidMobileNumber = false;
          this.mobileNumberValidate.emit(this.inValidMobileNumber);
        } 
        this.mobileNumberValidate.emit(this.inValidMobileNumber); 
      });
     
  }
  selectClick(){    
    this.input.nativeElement.value = '';
    this.input.nativeElement.focus();
  }

    getcodenumber() {
      let keyvalue:string = this.codeFilterCtrl.value?.trim();
      if(keyvalue !=''){
      this.search(keyvalue);
      }else{
        this.newcountryCodedata = this.countryListArr;
      }
  }
    search(value: string) {
        let filter = value?.toLowerCase();        
        this.newcountryCodedata = this.countryListArr.filter(option => option.name?.toLowerCase().startsWith(filter) || option.phone_code.startsWith(filter));
    }

    getcode(val){
      // this.countrycode.emit(+val);
      this.newcountryCodedata = this.countryListArr;
      this.countryCode = this.countryListArr.find(c => c.id == val)?.country_code;
      this.countryCodeVal.emit(this.countryCode);
      this.mobile_number.patchValue("");
      // if (this.countryCode && this.mobile_number?.value) {        
      //     this.mobileNumberValidation()
      // } else {
      //   this.inValidMobileNumber = false;
      // }
      // this.mobileNumberValidate.emit(this.inValidMobileNumber);
    }

    getmobileNumber(val){
      console.log(this.country_code, val);
      if (val) {
        let phoneNumber: any = parsePhoneNumberFromString(val, (this.countryIpData?.country_code || 'CA') as CountryCode);
        console.log(phoneNumber?.formatNational());
        this.mobileNumber = phoneNumber?.formatNational();
      }
    }

    mobileNumberValidation() {
      let phoneNumber: any = parsePhoneNumberFromString(this.mobile_number.value, (this.countryCode || 'CA') as CountryCode);
      // console.log( phoneNumber?.formatNational(), "ppppppppppppppppp");
      
      if (this.countryCode && isValidNumber(this.mobile_number.value, (this.countryCode || 'CA') as CountryCode) && parsePhoneNumber(this.mobile_number.value, (this.countryCode || 'CA') as CountryCode).isPossible()
      && phoneNumber?.formatNational() ) {
        // &&  phoneNumber?.formatNational()?.split(' ')?.length >= 2
        this.mobile_number.patchValue(phoneNumber.formatNational());              
        this.inValidMobileNumber = false;        
      }
      //  else {
      //   this.inValidMobileNumber = true;
      // }
    }
}
