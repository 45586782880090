
<mat-select *ngIf="type == 'countryId'" class="mat-select-country-code mt-0 form-select-mat text-1 border-gris" (click)="reloadList()" (selectionChange)="selectChange($event)" value="{{selectedId}}"  placeholder="{{'GENRIC_PLACEHOLDER.COUNTRY_CODE' | translate}}"
[ngClass]="{'mobile_container_org mx-0':moduleName == 'Organization Contact'}" >
    <input class="form-control border-0 select-input-value country-code-search-input" type="text"
        placeholder="{{inputPlaceHolder}}" aria-label="Number" matInput
        (keyup)="onSearchList($event)">
    <mat-option class="text-1" *ngFor="let item of listfordisplay"
        value="{{item.id}}">
        {{item.name}} (+{{item.phone_code}})
    </mat-option>
</mat-select>


<mat-select *ngIf="type == 'contacts'" class=" mat-select-country-code form-select-mat text-1 border-gris" (click)="reloadList()" (selectionChange)="selectChange($event)" value="{{selectedId}}">
    <input class="form-control border-0 select-input-value"  type="text"
        placeholder="{{inputPlaceHolder}}" aria-label="Number" matInput
        (keyup)="onSearchList($event)">
    <mat-option class="text-1" *ngFor="let item of listfordisplay"
        value="{{item.id}}">
        #{{item?.merchant_customers_relations[0].customerNumber}} - 
                    {{item?.firstname}}
                    {{item?.lastname}}
    </mat-option>
</mat-select>
