import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component(
  {
    styles: [`
      .dialog-content {
        position: relative;
        padding-bottom: 16px;
      }
      .image-container {
        margin-top: 8px;
        height: 60vh;
      }
      .ngb-carousel1{
        height: 100%;
        background: #00000059;
        display: block;
        border-radius: 10px;
        padding: 6px 0px;
        }
      .dialog-image {
        width: 100%;
        object-fit: contain;
        height: 100%;
        border-radius: 10px;
      }
      ::ng-deep .carousel-inner{
        height: 100%;
      }
      ::ng-deep .carousel-item{
        height: 100%;
      }
      `],
    template: `
       <div class="dialog-content">
       <i (click)="closeDialog()" type="button" class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0"></i>
       </div>
       <div class="container">
       <div class="row">
          <div class="image-container">
            <ngb-carousel class="ngb-carousel1">
              <ng-template ngbSlide *ngFor="let attach of filteredSrc;let i = index;">
                <img [src]="IMAGE_URL+attach?.name" class="dialog-image" [alt]="'Image'+i">
              </ng-template>
            </ngb-carousel>
          </div>
       </div>
       </div>
        `
  })
export class imgViewComponent implements OnInit {
  filteredSrc: any[] = [];
  IMAGE_URL: string = `${environment.UPLOAD_NOTE_ATTACHMENT}`;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<imgViewComponent>
  ) { }

  ngOnInit(): void {
    if(this.data?.src[0]?.eQuote_id){
      this.filteredSrc = this.data?.src.filter((item: any) => {
        const fileExtension = item?.name?.slice(item?.name?.lastIndexOf('.'));
        return [".jpg", ".jpeg", ".png"].includes(fileExtension.toLowerCase());
      });
    }else{
      const src = this.data?.src?.dealNoteAttachment;
      this.filteredSrc = src.filter((item: any) => {
        const fileExtension = item?.name?.slice(item?.name?.lastIndexOf('.'));
        return [".jpg", ".jpeg", ".png"].includes(fileExtension.toLowerCase());
      });
    }
   

  }

  closeDialog() { this.dialogRef.close(); }

}