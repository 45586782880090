import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface widgetFilterPayload{
  module:string,
  filter_value_name:widgetFilterArr[]
}
export interface widgetFilterArr{
  widgetName: string,
  widgetValue:string,
  dealTypeId?:string,
  endDate?:string,
  startDate?:string
}

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  BASE_URL: any;
  public dealdata$: Subject<any> = new Subject();
  public Lost_Reasons$: Subject<any> = new Subject();
  public Abandoned_Reasons$: Subject<any> = new Subject();
  public Activity_Report$: Subject<any> = new Subject();
  public Deals_Won_By_User_Graph$: Subject<any> = new Subject();
  public Deals_Per_User$: Subject<any> = new Subject();
  public Create_VS_Won_Deals_Graph$: Subject<any> = new Subject();
  public Lost_Deal$: Subject<any> = new Subject();
  public Won_Deal$: Subject<any> = new Subject();
  public Time_Per_Stage$: Subject<any> = new Subject();
  public Source_of_Contact$: Subject<any> = new Subject();
  public Sales_Forecast$: Subject<any> = new Subject();
  public deal_type_id$: Subject<any> = new Subject();
  public dealType_List$: Subject<any> = new Subject();
  public activeWidgets$: BehaviorSubject<any> = new BehaviorSubject([]);
  public Top_Deals$: Subject<any> = new Subject();
  public Deal_By_Type_And_Stages$: Subject<any> = new Subject();
  public Deal_Stages_Data$: Subject<any> = new Subject();
  public dbSaveFilterVal$: Subject<any> = new Subject();
  public showFunnel$: Subject<boolean> = new Subject();
  public showSaveFilterBtn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

constructor(private http: HttpClient) {
    this.BASE_URL = environment.LEAD_MANAGEMENT_URL;
  }

  getWidgetData(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/widget/lead-widget`, data).pipe(
      _.tap(
        data => data,
        error => error
      ));
  }

  Loadmorenewdeals(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/load-more-deals`, data).pipe(
      _.tap(
        data => data,
        error => error
      ));
  }

  storeWidgetFilter(widgetFilterArr:widgetFilterArr[]): Observable<widgetFilterPayload>{
    let payload:widgetFilterPayload = {
      module:"leadWidget",
      filter_value_name:widgetFilterArr
    }
    return this.http.post<widgetFilterPayload>(`${this.BASE_URL}/save-widget-filters`,payload).pipe(
      _.tap(
        data => data,
        error => error
      ));
  }
  
}

