import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemsService } from 'src/app/pages/items/services/items.service';

@Component({
  selector: 'app-upload-images-preview-model',
  templateUrl: './upload-images-preview.component.html',
  styleUrls: ['./upload-images-preview.component.scss']
})
export class UploadImagesPreviewModelComponent implements OnInit {
  editItemImages = []
  selectedFiles:any = [];
  constructor(
    public dialogRef: MatDialogRef<UploadImagesPreviewModelComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private itemsService: ItemsService
    ) { }

  ngOnInit(): void {
  }
file:any
imageButton:boolean=false
imagesToShow;
// imagesList:any=[]; //store all multiple images 
imagesPerline = 5; // images per line 
startIndex = 0;
  closeModel(){
    this.dialogRef.close();
  }
  changeImage(image){

  }
  onFileSelected(event: any): void {
    const files: FileList | null = event.target.files;
  
    if (files && files.length > 0) {
      // Reset selected files array
      this.selectedFiles = [];
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExtension = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
        
        // Check if the file extension is allowed (JPG, JPEG, or PNG)
        if (!this.isAllowedExtension(fileExtension)) {
          this._snackBar.open("This files is not supported. Upload a jpg, jpeg, or png files only.", 'Ok');
          continue;
        }
  
        // Check if the file size is within the limit (3GB)
        if (file.size > 3 * 1024 * 1024 * 1024) {
            this._snackBar.open('File size exceeds the limit of 3GB. Please select a smaller file.', 'Ok');
       
          continue;
        }
  
        // If the file passes the checks, add it to the selected files array
        this.selectedFiles.push(file);
      }
  
      if (this.selectedFiles?.length > 0) {
        this.imageButton = true;
        this.previewFiles();
        console.log("selected files", this.selectedFiles, "this.startIndex + this.imagesPerline", this.startIndex, this.imagesPerline);
        if (this.selectedFiles?.length > 1) this.imagesToShow = this.selectedFiles.slice(this.startIndex, this.startIndex + this.imagesPerline);
        if (this.imagesToShow?.length > 0) this.file = this.imagesToShow[0];
        else this.file = this.selectedFiles[0];
      }
    }
  }
  
  private isAllowedExtension(extension: string): boolean {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    return allowedExtensions.includes(extension);
  }
  
  private previewFiles(): void {
    for (const file of this.selectedFiles) {
      const reader = new FileReader();
  
      reader.onload = () => {
        file['preview'] = reader.result;
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  saveImages(){
    const formData = new FormData();
      
      if (this.selectedFiles.length) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append('files', this.selectedFiles[i]);
        }
      }
      formData.append('item', JSON.stringify(this.data));
    const uploadItemPhotoSubs = this.itemsService.uploadItemPhoto(formData).subscribe(
        res => {
          if (res.message === 'success' || res.status === 200) {
            this.dialogRef.close(true);
            // this.editItemsImages.push({ 'image': res.data.image });
            // this.image = { 'image': res.data.image }
            
          }
        });
      // this.subscriptions.push(uploadItemPhotoSubs);  
  }

  currentImages(direction: 'next' | 'previous') {
    if (direction === 'next') {
      if(!(this.startIndex + this.imagesPerline < this.selectedFiles.length)) return; 
      this.startIndex += 1;
    }
    else if (direction === 'previous') {
      if(!(this.startIndex>0))return;
      this.startIndex -= 1;
    }
    this.imagesToShow = this.selectedFiles.slice(this.startIndex, this.startIndex + this.imagesPerline);
  }


}
