import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-to-another-workspace',
  templateUrl: './switch-to-another-workspace.component.html',
  styleUrls: ['./switch-to-another-workspace.component.scss']
})
export class SwitchToAnotherWorkspaceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SwitchToAnotherWorkspaceComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(data) {
    this.dialogRef.close(data);
  }
}
