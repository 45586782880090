// template.resolver.ts
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { LeadFormService } from './lead.form.service';
// import { YourDataService } from './your-data.service'; // Replace with your actual data service

@Injectable({
  providedIn: 'root'
})
export class FormBuilderResolver implements Resolve<any> {
  constructor(private leadFormService: LeadFormService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    // Call your data service to fetch data based on the 'id'
    return forkJoin({
      leadFormData: this.leadFormService.get_Lead_Form(id),
      customFieldsData: this.leadFormService.getCustomFields(),
      savedCustomFields: this.leadFormService.getSavedCustomFields(),
      formcontacts: this.leadFormService.getFormContacts({label:'phone_name'}),
      // currentCountry: this.leadFormService.getCurrentCountry()
    });
  }
}
