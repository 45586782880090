import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'customDate'
})
export class CustomDateFormatPipe extends DatePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		const userData = localStorage.getItem('loginUserValue') && JSON.parse(localStorage.getItem('loginUserValue'));		
		return super.transform(value, 'EE d MMM, y','',userData?.languages?.locale);
	}
}