import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { takeUntil } from 'rxjs/operators';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-mat-confirm-delete',
  templateUrl: './mat-confirm-delete.component.html',
  styleUrls: ['./mat-confirm-delete.component.scss']
})
export class MatConfirmDeleteComponent implements OnDestroy {
  // message: string = ""
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  subscriptions: Subscription[] = [];
  titleMsg: string;
  titleMsgs: string;
  ItemsDeleteModule:any;
  type
  titleMsgNew:any
  msgFromAgenda
  msgFromDeal: boolean = false;
  tableDelete: boolean = false;
  itemDelete: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MatConfirmDeleteComponent>,
    private signupService: SignUpService,
    private spinner: SpinnerService,
    public snackbar: MatSnackBar,
    public leadService: LeadService,
    private activity:CrudService
    ) {
    //   if(data){
    // this.message = data.message || this.message;
    // if (data.buttonText) {
    //   this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
    //   this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    // }
    //   }
  }

  ngOnInit() {
    if(this.data?.delItems && !this.data?.tableDel){
      this.titleMsg = this.data?.title;
      this.ItemsDeleteModule=this.data?.delItems

    }else if(this.data?.delItemss){
      this.itemDelete=true
      this.titleMsgs = this.data?.title;
      this.ItemsDeleteModule=this.data?.delItemss


    }
    else if(this.data?.tableDel  && this.data?.delItems){
    this.titleMsgNew = this.data?.title;
    this.tableDelete=this.data?.tableDel;
    this.ItemsDeleteModule=this.data?.delItems

    }
    else{
      this.msgFromDeal = this.data?.data?.reAct;
      this.titleMsg = this.data?.data?.title;
      this.type=this.data?.type;
      this.msgFromAgenda = this.data?.data?.delActivity
  
    }
  }

  onConfirmClick(): void {
    if (this.msgFromDeal) {
      if(this.data?.type == 'reactiveLead'){
        this.leadService.reactiveLeadDetail({ lead_id: this.data?.data?.data?.leadId, customer_id: this.data?.data?.data?.customerId }).subscribe((res: any) => {
          if (res)
           { 
            // this.leadService.removeleads$.next(res?.data);
            this.dialogRef.close();
          }
        });

      } else{
        this.reActivate(this.data?.data?.id);
      }
     
    } else if (this.msgFromAgenda) {
      if(this.data?.type == 'cancelActivity'){
        this.activity.deleteAgendaManagement(`activities/${this.data?.data?.data}`).subscribe(result => {
          if(result){
            this.dialogRef.close();
          }

        });

      } (error) => { }
     
    } 
    else if(this.ItemsDeleteModule){
      this.dialogRef.close(true);

    }
    else {
      let payload = {
        id: this.data?.data?.id,
        merchantId: this.data?.data?.merchant_role?.merchantId == null ? localStorage.getItem('merchant_id') : this.data?.data?.merchant_role?.merchantId
      }
      this.spinner.show();
      const deleteInvitedUserSubs = this.signupService.deleteInvitedUser(payload).subscribe((res) => {
        this.spinner.hide();
        this.SuccessSnackBar('User Deleted Successfully', 'Ok');
        // this.leadService.removeleads$.next(res?.data);
        this.dialogRef.close();
      });
      this.subscriptions.push(deleteInvitedUserSubs);
    }

  }

  closeModel() {
    this.dialogRef.close();
  }

  SuccessSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = 'notif-success';
    this.snackbar.open(msg, tag, config);
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reActivate(id: any) {
    this.spinner.show();
    this.leadService.reActivateLostDeal({ merchant_lead_deal_id: id }).subscribe((res: any) => {
      if (res) {
        this.spinner.hide();
        this.SuccessSnackBar('Deal Re-activated Successfully', 'Ok');
        this.dialogRef.close();
      }
    }, (error) => { });
  }

}
