import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadContact'
})
export class LeadContactPipe implements PipeTransform {

  transform(searchListing: any[], searchTxt:string): any[] {
    if(!searchListing || !searchListing.length) return searchListing;
    if(!searchTxt || !searchTxt.length) return searchListing;
    return searchListing.filter(item => {
      return item?.merchant_user_id ? ((item?.merchantCustomer?.firstname + item?.merchantCustomer?.lastname)?.toString()?.toLowerCase().indexOf(searchTxt?.toLowerCase()) > -1) : ((item?.firstname + item?.lastname)?.toString()?.toLowerCase().indexOf(searchTxt?.toLowerCase()) > -1) 
    });
  }

}