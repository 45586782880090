<div class="relative p-6">
  <!-- Close Button -->
  <i type="button" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"
    class="color-4 fs-2 far fa-times-circle absolute top-[-20px] right-[-20px] m-3" #closebutton></i>

  <div class="flex flex-col justify-center items-center w-full">
    <div class="w-[50%] flex flex-col items-center mt-4 space-y-4">
      <h2 for="contactSelect" class="f-poppins fw-500 txt_3 fs-15 mb-2">Select Number</h2>
  <mat-form-field appearance="outline">
      <mat-select id="contactSelect" class="text-1 d-inline-block w-full" (selectionChange)="onSelectedOption($event.value)">
        <mat-option *ngFor="let obj of dialogData" [value]="obj">
          {{ obj.name }} {{ obj.mobile_number }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>

  <div class="text-3 fw-500 mt-4 rounded-3 d-flex justify-center space-x-4">
    <button #openReasons type="button" class="btn btn-p-2 btn-skyblue-s-1 primary-btn mx-2"
            data-bs-toggle="collapse" data-bs-target="#collapse-dealStage" aria-expanded="false"
            aria-controls="collapse-dealStage" (click)="closeDialog()">
      {{"COMMON.Cancel" | translate}}
    </button>
    <button class="nav-link default-btn btn-1 btn-p-2 rounded-3" type='submit' [disabled]="selectedContact ==''" (click)="submit()">
      {{"COMMON.Save" | translate}}
    </button>
  </div>
</div>
