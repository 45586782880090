
<div class="top-bar p-3 justify-content-center align-items-center text-center" *ngIf = "trialEndDay > 0">
    <span>{{"COMMON.You Have" | translate}} {{trialEndDay}} {{"COMMON.Days Remaining On Your" | translate}} "{{planName}}" {{"COMMON.Free Trial Plan Chat With Sales" | translate}} .</span>
    <a class="btn ml-3 fs_11 btn-p-1 top-bar-btn" target="_blank" href="https://messenger.svc.chative.io/site/sc9686198-0c64-475d-b7dd-2f1fcc45626d?mode=fullpage">{{"COMMON.Talk with Sales" | translate}}</a>
</div>
<nav class="navbar navbar-expand-lg px-4 w-100 shadow-sm">
   
    <div class="container-fluid mt-0 p-0 position-relative d-flex">
   
        <div class="d-flex items-center justify-between hamburger-icon gap-3">
         <a class="ico-1" routerLink="/dash/home"><img src="assets/img/Osmos_logo.png" style="height: 83px; width: 150px;"></a>
         <div class="menu-btn p-2 pointer"  (click)="openSideBar()">
            <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83333 22H31.1667C32.175 22 33 21.175 33 20.1667C33 19.1583 32.175 18.3333 31.1667 18.3333H1.83333C0.825 18.3333 0 19.1583 0 20.1667C0 21.175 0.825 22 1.83333 22ZM1.83333 12.8333H31.1667C32.175 12.8333 33 12.0083 33 11C33 9.99167 32.175 9.16667 31.1667 9.16667H1.83333C0.825 9.16667 0 9.99167 0 11C0 12.0083 0.825 12.8333 1.83333 12.8333ZM0 1.83333C0 2.84167 0.825 3.66667 1.83333 3.66667H31.1667C32.175 3.66667 33 2.84167 33 1.83333C33 0.825 32.175 0 31.1667 0H1.83333C0.825 0 0 0.825 0 1.83333Z" fill="#CBDBE4"/>
            </svg>        
        </div>
        </div>
        <div class="input-search-h w-100 my-2 position-relative" style="display: none; margin-right: 200px;">
            <input type="search" class="form-control py-3 ms-5" placeholder='{{"COMMON.Search" | translate}}'
                aria-label="Recipient's username" aria-describedby="basic-addon2">
            <a class="text-4 position-absolute top-0 end-0 fs-4 m-3 py-1" (click)="searchExit()" role="button">✕</a>
        </div>

        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-angle-down"></i>
        </button> -->
        <div class="d-flex align-items-center menu_div">              
        <div class="" id="navbarSupportedContent">
            <div class="w-100 row ico-header d-flex justify-content-end">
                <!-- <div class="col-lg-6 osmos-logo">
                    <img class="rounded-circle" src="assets/img/osmos-logo.png">
                </div> -->
                <!-- <div
                    class="col-12 col-lg-6 d-flex gap-4 align-items-center justify-content-center my-2 justify-content-lg-start">
                    <svg class="ico-menu" width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.4375 0H6.5625C2.93813 0 0 2.93813 0 6.5625V23.4375C0 27.0619 2.93813 30 6.5625 30H23.4375C27.0619 30 30 27.0619 30 23.4375V6.5625C30 2.93813 27.0619 0 23.4375 0ZM28.125 23.4375C28.125 26.0263 26.0263 28.125 23.4375 28.125H6.5625C3.97365 28.125 1.875 26.0263 1.875 23.4375V6.5625C1.875 3.97365 3.97365 1.875 6.5625 1.875H23.4375C26.0263 1.875 28.125 3.97365 28.125 6.5625V23.4375Z" />
                        <path
                            d="M9.375 6.5625H7.5C6.98221 6.5625 6.5625 6.98221 6.5625 7.5V9.375C6.5625 9.89279 6.98221 10.3125 7.5 10.3125H9.375C9.89279 10.3125 10.3125 9.89279 10.3125 9.375V7.5C10.3125 6.98221 9.89279 6.5625 9.375 6.5625Z" />
                        <path
                            d="M9.375 13.125H7.5C6.98221 13.125 6.5625 13.5447 6.5625 14.0625V15.9375C6.5625 16.4553 6.98221 16.875 7.5 16.875H9.375C9.89279 16.875 10.3125 16.4553 10.3125 15.9375V14.0625C10.3125 13.5447 9.89279 13.125 9.375 13.125Z" />
                        <path
                            d="M9.375 19.6875H7.5C6.98221 19.6875 6.5625 20.1072 6.5625 20.625V22.5C6.5625 23.0178 6.98221 23.4375 7.5 23.4375H9.375C9.89279 23.4375 10.3125 23.0178 10.3125 22.5V20.625C10.3125 20.1072 9.89279 19.6875 9.375 19.6875Z" />
                        <path d="M23.4375 7.5H12.1875V9.375H23.4375V7.5Z" />
                        <path d="M23.4375 14.0625H12.1875V15.9375H23.4375V14.0625Z" />
                        <path d="M23.4375 20.625H12.1875V22.5H23.4375V20.625Z" />
                    </svg>
                    <svg class="ico-menu" width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M26.4844 2.34375H25.0781V0H22.7344V2.34375H7.26562V0H4.92188V2.34375H3.51562C1.57711 2.34375 0 3.92086 0 5.85938V26.4844C0 28.4229 1.57711 30 3.51562 30H26.4844C28.4229 30 30 28.4229 30 26.4844V5.85938C30 3.92086 28.4229 2.34375 26.4844 2.34375ZM27.6562 26.4844C27.6562 27.1305 27.1305 27.6562 26.4844 27.6562H3.51562C2.86945 27.6562 2.34375 27.1305 2.34375 26.4844V11.0156H27.6562V26.4844ZM27.6562 8.67188H2.34375V5.85938C2.34375 5.2132 2.86945 4.6875 3.51562 4.6875H4.92188V7.03125H7.26562V4.6875H22.7344V7.03125H25.0781V4.6875H26.4844C27.1305 4.6875 27.6562 5.2132 27.6562 5.85938V8.67188Z" />
                        <path d="M6.79688 13.4766H4.45312V15.8203H6.79688V13.4766Z" />
                        <path d="M11.4844 13.4766H9.14062V15.8203H11.4844V13.4766Z" />
                        <path d="M16.1719 13.4766H13.8281V15.8203H16.1719V13.4766Z" />
                        <path d="M20.8594 13.4766H18.5156V15.8203H20.8594V13.4766Z" />
                        <path d="M25.5469 13.4766H23.2031V15.8203H25.5469V13.4766Z" />
                        <path d="M6.79688 18.1641H4.45312V20.5078H6.79688V18.1641Z" />
                        <path d="M11.4844 18.1641H9.14062V20.5078H11.4844V18.1641Z" />
                        <path d="M16.1719 18.1641H13.8281V20.5078H16.1719V18.1641Z" />
                        <path d="M20.8594 18.1641H18.5156V20.5078H20.8594V18.1641Z" />
                        <path d="M6.79688 22.8516H4.45312V25.1953H6.79688V22.8516Z" />
                        <path d="M11.4844 22.8516H9.14062V25.1953H11.4844V22.8516Z" />
                        <path d="M16.1719 22.8516H13.8281V25.1953H16.1719V22.8516Z" />
                        <path d="M20.8594 22.8516H18.5156V25.1953H20.8594V22.8516Z" />
                        <path d="M25.5469 18.1641H23.2031V20.5078H25.5469V18.1641Z" />
                    </svg>
                    <svg class="ico-menu" width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30 7.08984V1.81641H26.4258V0C25.9885 0 0.427266 0 0 0V30C0.68625 30 26.1169 30 26.4258 30V28.7109H30V23.4375H26.4258V21.1523H30V15.8789H26.4258V14.1211H30V8.84766H26.4258V7.08984H30ZM26.4258 3.57422H28.2422V5.33203H26.4258V3.57422ZM3.51562 28.2422H1.75781V1.75781H3.51562V28.2422ZM24.668 28.2422H5.27344V1.75781H24.668V28.2422ZM28.2422 25.1953V26.9531H26.4258V25.1953H28.2422ZM28.2422 17.6367V19.3945H26.4258V17.6367H28.2422ZM28.2422 10.6055V12.3633H26.4258V10.6055H28.2422Z" />
                        <path
                            d="M21.0057 18.907C19.7527 17.0274 17.7531 15.9926 15.6091 15.8358C17.7454 15.5387 19.3956 13.7012 19.3956 11.4844C19.3956 9.06123 17.4242 7.08984 15.001 7.08984C12.5779 7.08984 10.6065 9.06123 10.6065 11.4844C10.6065 13.7116 12.2724 15.5559 14.4234 15.8398C12.1759 16.0155 10.1785 17.1337 8.99629 18.907L8.84863 19.1285V22.9102H21.1533V19.1284H21.1534L21.0057 18.907ZM12.3643 11.4844C12.3643 10.0305 13.5471 8.84766 15.001 8.84766C16.4549 8.84766 17.6378 10.0305 17.6378 11.4844C17.6378 12.9383 16.4549 14.1211 15.001 14.1211C13.5471 14.1211 12.3643 12.9383 12.3643 11.4844ZM19.3956 21.1523H10.6065V19.6736C11.5839 18.3717 13.261 17.5725 15.0489 17.5725C16.8351 17.5725 18.4045 18.3343 19.3956 19.6721V21.1523Z" />
                    </svg>
                    <svg class="ico-menu" width="30" height="30" viewBox="0 0 26 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.3242 6.91406V5.67111L16.6531 0H0V26.5105H5.159V30H25.8371V6.91406H22.3242ZM17.168 3.00076L19.3235 5.15625H17.168V3.00076ZM1.75781 1.75781H15.4102V6.91406H20.5664V24.7527H1.75781V1.75781ZM24.0792 28.2422H6.91682V26.5105H22.3242V8.67188H24.0793V28.2422H24.0792Z" />
                        <path d="M4.19043 4.39453H11.7408V6.15234H4.19043V4.39453Z" />
                        <path d="M4.19043 7.91016H8.5767V9.66797H4.19043V7.91016Z" />
                        <path d="M4.19043 16.082H5.94824V22.2016H4.19043V16.082Z" />
                        <path d="M8.29199 13.7383H10.0498V22.2016H8.29199V13.7383Z" />
                        <path d="M12.3936 10.2227H14.1514V22.2016H12.3936V10.2227Z" />
                        <path d="M16.4951 11.3945H18.2529V22.2016H16.4951V11.3945Z" />
                    </svg>
                    <div class="dropdown cursor-p">
                        <svg class="color-3" width="30" height="30" id="dropdownMenuButton" data-bs-toggle="dropdown"
                            aria-expanded="false" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.4742 10.4883L16.6975 1.34587C16.1497 -0.448623 13.6185 -0.448623 13.0896 1.34587L10.294 10.4883H1.88818C0.0559076 10.4883 -0.699669 12.8495 0.792595 13.9073L7.66834 18.8186L4.96715 27.5266C4.41936 29.2833 6.45942 30.7 7.9139 29.5855L14.8841 24.2965L21.8543 29.6044C23.3088 30.7189 25.3488 29.3022 24.801 27.5455L22.0998 18.8375L28.9756 13.9262C30.4679 12.8495 29.7123 10.5072 27.88 10.5072H19.4742V10.4883Z" />
                        </svg>
                        <div class="shadow dropdown-menu dropdown-1" aria-labelledby="dropdownMenuButton"
                            style="width: 240px; right: -20px">
                            <div class="">
                                <input class="form-control border-0 border-bottom" type="search"
                                    placeholder='{{"Dash.searchforApp" | translate}}' aria-label="Search">
                            </div>
                            <div class="border-up p-3 d-flex flex-column gap-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex">
                                        <i class="far fa-list-alt fs-5 text-1 d-block"></i>
                                        <div class="ml-2"><a class="text-1" href="#">{{"Dash.allList" | translate}}</a></div>
                                    </div>
                                    <div>
                                        <i class="fas fa-star fs-5 text-1"></i>
                                    </div>             
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex">
                                        <i class="far fa-calendar-alt fs-5 text-1 d-block"></i>
                                        <div class="ml-2"><a class="text-1" href="#">{{"Dash.Calendar" | translate}}</a>
                                        </div>
                                    </div>
                                    <div>
                                        <i class="fas fa-star fs-5 text-1"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex">
                                        <i class="far fa-address-book fs-5 text-1 d-block"></i>
                                        <div class="ml-2"><a class="text-1" href="#">{{"Dash.Contacts" | translate}}</a>
                                        </div>
                                    </div>
                                    <div>
                                        <i class="fas fa-star fs-5 text-1"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex">
                                        <i class="far fa-copy fs-5 text-1 d-block"></i>
                                        <div class="ml-2"><a class="text-1" href="#">{{"Dash.Reports" | translate}}</a></div>
                                    </div>
                                    <div>
                                        <i class="fas fa-star fs-5 text-1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div
                    class="col-lg-6 d-flex gap-2 align-items-center justify-content-center my-2 justify-content-lg-end">

                    <!-- <div class="dropdown">
                        <svg class="ico-menu" width="30" height="30" viewBox="0 0 30 30" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.14286 19.2857C0.964286 19.2857 0 20.25 0 21.4286V27.8571C0 29.0357 0.964286 30 2.14286 30H8.57143C9.75 30 10.7143 29.0357 10.7143 27.8571C10.7143 26.6786 9.75 25.7143 8.57143 25.7143H4.28571V21.4286C4.28571 20.25 3.32143 19.2857 2.14286 19.2857ZM2.14286 10.7143C3.32143 10.7143 4.28571 9.75 4.28571 8.57143V4.28571H8.57143C9.75 4.28571 10.7143 3.32143 10.7143 2.14286C10.7143 0.964286 9.75 0 8.57143 0H2.14286C0.964286 0 0 0.964286 0 2.14286V8.57143C0 9.75 0.964286 10.7143 2.14286 10.7143ZM25.7143 25.7143H21.4286C20.25 25.7143 19.2857 26.6786 19.2857 27.8571C19.2857 29.0357 20.25 30 21.4286 30H27.8571C29.0357 30 30 29.0357 30 27.8571V21.4286C30 20.25 29.0357 19.2857 27.8571 19.2857C26.6786 19.2857 25.7143 20.25 25.7143 21.4286V25.7143ZM19.2857 2.14286C19.2857 3.32143 20.25 4.28571 21.4286 4.28571H25.7143V8.57143C25.7143 9.75 26.6786 10.7143 27.8571 10.7143C29.0357 10.7143 30 9.75 30 8.57143V2.14286C30 0.964286 29.0357 0 27.8571 0H21.4286C20.25 0 19.2857 0.964286 19.2857 2.14286Z" />
                        </svg>
                    </div> -->
                    <!-- <div class="dropdown">
                        <svg class="ico-menu" width="33" height="34" (click)="search()" viewBox="0 0 33 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24.1974 21.7814H22.6682L22.1262 21.2591C24.4491 18.5507 25.6492 14.8557 24.9911 10.9285C24.0813 5.55042 19.5905 1.25569 14.1705 0.597938C5.98251 -0.408034 -0.908566 6.47901 0.0979958 14.6622C0.756133 20.079 5.05338 24.5672 10.4346 25.4764C14.3641 26.1342 18.0613 24.9347 20.7712 22.6133L21.2939 23.1549V24.6832L29.5206 32.9051C30.3142 33.6983 31.6111 33.6983 32.4048 32.9051C33.1984 32.112 33.1984 30.8158 32.4048 30.0226L24.1974 21.7814ZM12.5832 21.7814C7.76335 21.7814 3.8726 17.8929 3.8726 13.0759C3.8726 8.2588 7.76335 4.37033 12.5832 4.37033C17.4031 4.37033 21.2939 8.2588 21.2939 13.0759C21.2939 17.8929 17.4031 21.7814 12.5832 21.7814Z" />
                        </svg>
                    </div> -->
                    <a  routerLink="/dash/meetings-schedules" class="flex gap-2  hover:bg-gray-100 cursor-pointer" (click)="onAgendaClick()">
                        <mat-icon class="icon-size" >calendar_month</mat-icon>
                        <!-- <span class="text-1">Agenda</span> -->
                    </a>
                    <a routerLink="/dash/reporting" class="flex gap-2  hover:bg-gray-100 cursor-pointer" (click)="onReportsClick()">
                        <mat-icon class="icon-size" >insert_chart</mat-icon>
                        <!-- <span class="text-1">Reports</span> -->
                    </a>
                    <!-- <div class="dropdown relative">
                        
                      <img   class="!w-[23px] !h-[34px]" (click)="toggleDropdown()" src="assets/svg-icons/modules.svg" alt="">
                        <div *ngIf="isDropdownOpen" class="absolute bg-white shadow-lg rounded-md p-2 mt-1 w-40 z-50 -right-4 ">
                            <a  routerLink="/dash/meetings-schedules" class="flex gap-2  hover:bg-gray-100 cursor-pointer" (click)="onAgendaClick()">
                                <mat-icon class="help-support-tour" >calendar_month</mat-icon>
                                <span class="text-1">Agenda</span>
                            </a>
                            <a routerLink="/dash/reporting" class="flex gap-2  hover:bg-gray-100 cursor-pointer" (click)="onReportsClick()">
                                <mat-icon class="help-support-tour" >insert_chart</mat-icon>
                                <span class="text-1">Reports</span>
                            </a>
                        </div>
                    </div> -->
                    
                    <div class="dropdown">
                        <svg class="ico-menu" width="23" height="34" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.0278 26.855H10.4564C9.34962 26.855 8.4502 27.7544 8.4502 28.8611V31.0311C8.4502 32.1379 9.34962 33.0373 10.4564 33.0373H12.0278C13.1346 33.0373 14.034 32.1379 14.034 31.0311V28.8611C14.034 27.7511 13.1346 26.855 12.0278 26.855Z" />
                            <path
                                d="M0.945579 10.056L3.84448 10.4171C4.76397 10.5308 5.63665 10.0025 5.96767 9.13652C6.38562 8.04316 6.9674 7.18052 7.71303 6.55192C8.76964 5.66252 10.0836 5.21448 11.6551 5.21448C13.2835 5.21448 14.5808 5.64246 15.5437 6.49508C16.5067 7.35104 16.9848 8.37418 16.9848 9.57453C16.9848 10.4372 16.714 11.2263 16.1723 11.9418C15.8213 12.3965 14.7413 13.3528 12.9391 14.814C11.1335 16.2751 9.93321 17.5925 9.33136 18.7627C8.71614 19.9598 8.50215 21.2871 8.44865 22.6179C8.40187 23.7581 9.3113 24.7077 10.4548 24.7077H12.0932C13.1364 24.7077 13.999 23.9086 14.0926 22.872C14.1595 22.1264 14.2832 21.5614 14.4705 21.1769C14.7848 20.5181 15.5973 19.6488 16.9013 18.5655C19.4257 16.4757 21.0707 14.824 21.8431 13.6069C22.6121 12.3932 23 11.1059 23 9.74506C23 7.28751 21.9535 5.13089 19.857 3.27853C17.7639 1.42618 14.9419 0.5 11.3977 0.5C8.02736 0.5 5.30901 1.4128 3.23598 3.24175C1.47056 4.79987 0.397262 6.64554 0.0194354 8.77541C-0.097624 9.39732 0.327013 9.97911 0.945579 10.056Z" />
                        </svg>
                        <div class=" shadow dropdown-menu dropdown-1 py-1 px-3 help-support-menu" aria-labelledby="dropdownMenuButton1">
                            <!-- <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/contact_tour.png" /><div  (click)="LOU.startTour('851190083431')" class="text-1 cursor-p">{{"Dash.Contact_Tour" | translate}}</div>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/organization_tour.png" /><div  (click)="LOU.startTour('654647772061')" class="text-1 cursor-p">{{"Dash.Organization_Tour" | translate}}</div>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/lead_tour.png" /><div  (click)="LOU.startTour('928636844949')" class="text-1 cursor-p">{{"Dash.Lead_Tour" | translate}}</div>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/deal_tour.png" /><div  (click)="LOU.startTour('514717822757')" class="text-1 cursor-p">{{"Dash.Deal_Tour" | translate}}</div>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/goals_tour.png" /><div  (click)="LOU.startTour('933462370943')" class="text-1 cursor-p">{{"Dash.Goals_Tour" | translate}}</div>
                            </div> -->
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/live_chat.png"/><a class="text-1"
                                    href="https://messenger.svc.chative.io/site/sc9686198-0c64-475d-b7dd-2f1fcc45626d?mode=fullpage" target="_blank">{{"Dash.Live_Chat" | translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/sales_and_onboarding.png" /><a class="text-1"
                                    target="_blank" href="https://app.letsconnect.at/pages/qr-connect?cid=w4VAM9HkZ7qpbskyhf10">{{"Dash.Sales_and_Onboarding" |
                                    translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/knowledge_base.png" />
                                <a class="text-1" target="_blank" href="https://help.osmoscloud.com/">{{"Dash.Knowledge_Base" | translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/quick_support.png" /><a class="text-1"
                                target="_blank" href="https://getscreen.me/go/">{{"Dash.Quick_Support" | translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 help-support-item">
                                <img class="help-support-tour" src="../../../../assets/img/helpsupport/road_map.png" /><a class="text-1"
                                target="_blank" href="https://app.ora.pm/p/ae1caaaa75884deb9a3ad0f0d3ec10af?v=0&t=k">{{"Dash.Road_Map" | translate}}</a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="dropdown" role="button" (click)="sidenav.toggle()">
                        <svg class="color-3" width="27" height="34"  viewBox="0 0 27 34" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 9.2002V28.6552L11.685 25.9402L10.5 25.4302L9.315 25.9402L3 28.6552V9.2002H18ZM24 0.200195H8.985C7.335 0.200195 6 1.5502 6 3.2002H21C22.65 3.2002 24 4.5502 24 6.2002V25.7002L27 27.2002V3.2002C27 1.5502 25.65 0.200195 24 0.200195ZM18 6.2002H3C1.35 6.2002 0 7.5502 0 9.2002V33.2002L10.5 28.7002L21 33.2002V9.2002C21 7.5502 19.65 6.2002 18 6.2002Z" />
                        </svg>
                        <div class="notification bg-3 rounded-pill lh-sm">
                            2
                        </div>
                    </div> -->
                    <mat-sidenav-container class="sidenav-container">
                        <mat-sidenav #sidenav id="mobile-menu-nav" class="sidenav" fixedInViewport="true" mode="over" position="end">
                          <app-bookmarks></app-bookmarks>
                        </mat-sidenav>
                      <mat-sidenav-content>
                   </mat-sidenav-content>
                </mat-sidenav-container>
                <div class="dropdown">
                    <mat-icon class="color-1 progress_icon material-symbols-outlined cursor_pointer" width="30" height="34" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                        cloud_sync
                    </mat-icon>
                    <div class="notification bg-1 rounded-pill lh-sm">
                        {{imagesNotification ? imagesNotification : 0}}
                    </div>
                
                    <div class="shadow dropdown-menu dropdown-1 px-3" aria-labelledby="dropdownMenuButton2" style="width: max-content;">
                        <!-- Adjust the max-height value for responsiveness -->
                        <div style="max-height: 70vh;overflow-y: auto;">
                            <!-- Content of the dropdown menu -->
                            <div *ngFor="let progress of progressData">
                                <div class="d-flex justify-content-between mt-2 " style="gap: 60px;">
                                    <span>{{progress.name}}</span> <span>{{progress.progress}}% Done</span>
                                </div>
                                <mat-progress-bar class="mt-2" mode="determinate" [value]="progress.progress"></mat-progress-bar>
                            </div>
                        </div>
                    </div>
                </div>
                
                    <div class="dropdown">
                        <svg class="color-1 cursor_pointer" width="30" height="34" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                            aria-expanded="false" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M32.3187 32.9667C32.1961 32.8877 31.2735 32.2457 30.3496 30.3034C28.653 26.7368 28.2969 21.7125 28.2969 18.1257C28.2969 18.1101 28.2965 18.0946 28.296 18.0791C28.2775 13.3387 25.4198 9.2523 21.3304 7.41881V4.61474C21.3304 2.07014 19.2497 0 16.6922 0H16.3078C13.7503 0 11.6696 2.07014 11.6696 4.61474V7.41865C7.56652 9.25804 4.70313 13.3653 4.70313 18.1257C4.70313 21.7125 4.347 26.7367 2.65038 30.3034C1.72659 32.2456 0.80395 32.8876 0.681279 32.9667C0.16469 33.2042 -0.09002 33.7528 0.0288231 34.3087C0.148806 34.8701 0.677125 35.2572 1.25407 35.2572H10.2098C10.2598 38.6668 13.0614 41.4255 16.5 41.4255C19.9387 41.4255 22.7402 38.6668 22.7902 35.2572H31.746C32.3229 35.2572 32.8513 34.8701 32.9712 34.3087C33.0899 33.7528 32.8353 33.2041 32.3187 32.9667ZM14.1133 4.61474C14.1133 3.41073 15.0978 2.43124 16.3079 2.43124H16.6923C17.9024 2.43124 18.8868 3.41073 18.8868 4.61474V6.63076C18.1156 6.47242 17.3173 6.38908 16.4997 6.38908C15.6824 6.38908 14.8843 6.47234 14.1134 6.63051V4.61474H14.1133ZM16.5 38.9943C14.4088 38.9943 12.7031 37.3262 12.6535 35.2573H20.3465C20.297 37.3261 18.5912 38.9943 16.5 38.9943ZM21.4199 32.8259C21.4197 32.8259 4.03862 32.8259 4.03862 32.8259C4.24999 32.4987 4.46511 32.1282 4.67893 31.7095C6.31651 28.502 7.14686 23.9317 7.14686 18.1257C7.14686 12.9947 11.3425 8.82032 16.4996 8.82032C21.6567 8.82032 25.8524 12.9947 25.8524 18.1297C25.8524 18.1447 25.8526 18.1596 25.8532 18.1745C25.8578 23.9572 26.6881 28.5111 28.3211 31.7095C28.5349 32.1283 28.7501 32.4987 28.9614 32.8259H21.4199Z" />
                        </svg>
                        <div class="notification bg-1 rounded-pill lh-sm">
                            {{notifictionCount ? notifictionCount : 0}}
                        </div>
                        
                        <div class="shadow dropdown-menu dropdown-1 px-3" aria-labelledby="dropdownMenuButton2"
                            style="width: 300px; max-height: 70vh; overflow: auto;">
                            <div class="text-center border-bottom mark_all_as_read" *ngIf="listingNotification?.length > 0">
                                <a href="javascript:void(0)" (click)="notificationReadAll()">
                                    <i class="fa fa-check" aria-hidden="true"></i> {{'Mark all as read' | translate }}
                                </a>
                            </div>
                            
                            <!-- <div class="d-flex align-items-center padding-notifiction" *ngFor="let notification of listingNotification" (click)="notificationRead(notification)" [ngClass]="{notificationRead: !notification.is_read}">
                                <i class="fas fa fa-cloud-upload fs-4  text-center" style="width: 31px"></i>
                                 <div>&nbsp;&nbsp;{{notification.description}} </div>
                            </div> -->
                            <ng-container *ngFor="let notification of listingNotification">
                                <a href="javascript:void(0)" (click)="notificationRead(notification)" class="notificationRead d-flex align-items-center p-2 border-bottom">
                                    <i class="fas fa-upload fs-4 fw-bold text-center" *ngIf="notification.module == 'csv_upload'" style="width: 31px"></i>
                                    <i class="fas fa-clock fs-4 fw-bold text-center" *ngIf="notification.module != 'csv_upload'" style="width: 31px"></i>
                                    <span class="pl-2 d-"><p class="text-1" *ngIf="notification.module == 'eQuote_module'">{{"Dash.New_Quote_request" | translate}}</p><p [innerHtml]="getSafeHtml(notification.description)"  class="text-1"></p><span class="tags bg-light-primary color-1 p-1 rounded" *ngIf="notification.timeago">{{notification.timeago}}</span></span>
                                </a>
                            </ng-container>
                            <!-- <ng-container *ngFor="let notification of reminderListingNotification">
                                <a href="javascript:void(0)" (click)="viewTask(notification.id)" class="d-flex align-items-center p-2 border-bottom">
                                    <i class="fas fa-clock fs-4  text-center" style="width: 31px"></i>
                                    <span class="pl-2"><p class="text-1 fw-400">{{notification.name}} <span class="tags bg-light-primary color-1 rounded ml-3 text-sm font-medium p-1" *ngIf="notification.timeago">{{notification.timeago}}</span></p></span>
                                </a>
                            </ng-container> -->
                            <ng-container *ngIf="listingNotification?.length <= 0 "><a href="javascript:void(0)" class="d-flex align-items-center p-1 text-3">{{"Dash.No_New_Notifications" | translate}}</a></ng-container>
                            <ng-container>
                                <a href="javascript:void(0)" class="d-flex align-items-center p-2 justify-center border-top text-center font-14" (click)="openAllNotification()">{{"Dash.View_All" | translate}}</a>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <div class="dropdown">
                        <svg class="color-1 cursor_pointer" width="30" height="34" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                            aria-expanded="false" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M32.3187 32.9667C32.1961 32.8877 31.2735 32.2457 30.3496 30.3034C28.653 26.7368 28.2969 21.7125 28.2969 18.1257C28.2969 18.1101 28.2965 18.0946 28.296 18.0791C28.2775 13.3387 25.4198 9.2523 21.3304 7.41881V4.61474C21.3304 2.07014 19.2497 0 16.6922 0H16.3078C13.7503 0 11.6696 2.07014 11.6696 4.61474V7.41865C7.56652 9.25804 4.70313 13.3653 4.70313 18.1257C4.70313 21.7125 4.347 26.7367 2.65038 30.3034C1.72659 32.2456 0.80395 32.8876 0.681279 32.9667C0.16469 33.2042 -0.09002 33.7528 0.0288231 34.3087C0.148806 34.8701 0.677125 35.2572 1.25407 35.2572H10.2098C10.2598 38.6668 13.0614 41.4255 16.5 41.4255C19.9387 41.4255 22.7402 38.6668 22.7902 35.2572H31.746C32.3229 35.2572 32.8513 34.8701 32.9712 34.3087C33.0899 33.7528 32.8353 33.2041 32.3187 32.9667ZM14.1133 4.61474C14.1133 3.41073 15.0978 2.43124 16.3079 2.43124H16.6923C17.9024 2.43124 18.8868 3.41073 18.8868 4.61474V6.63076C18.1156 6.47242 17.3173 6.38908 16.4997 6.38908C15.6824 6.38908 14.8843 6.47234 14.1134 6.63051V4.61474H14.1133ZM16.5 38.9943C14.4088 38.9943 12.7031 37.3262 12.6535 35.2573H20.3465C20.297 37.3261 18.5912 38.9943 16.5 38.9943ZM21.4199 32.8259C21.4197 32.8259 4.03862 32.8259 4.03862 32.8259C4.24999 32.4987 4.46511 32.1282 4.67893 31.7095C6.31651 28.502 7.14686 23.9317 7.14686 18.1257C7.14686 12.9947 11.3425 8.82032 16.4996 8.82032C21.6567 8.82032 25.8524 12.9947 25.8524 18.1297C25.8524 18.1447 25.8526 18.1596 25.8532 18.1745C25.8578 23.9572 26.6881 28.5111 28.3211 31.7095C28.5349 32.1283 28.7501 32.4987 28.9614 32.8259H21.4199Z" />
                        </svg>
                        <div class="notification bg-1 rounded-pill lh-sm">
                            {{notificationArr?.length ? notificationArr?.length : 0}}
                        </div>
                        <div class="shadow dropdown-menu dropdown-1 p-3 py-1" aria-labelledby="dropdownMenuButton2"
                            style="width: 300px">
                            <div  class="d-flex align-items-center gap-2 my-3" *ngFor="let notification of notificationArr">
                                <i class="fas fa-rocket fs-4 text-1 text-center" style="width: 31px"></i>
                                <a class="text-1 cursor_pointer" (click)="viewTask(notification.id)" ><b style="color: #22c8fc">{{"Dash.Reminder" | translate}} : </b>{{notification.name}}
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <div class="dropdown last-dropdown">
                        <!-- <svg class="ico-menu" width="33" height="22" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                            aria-expanded="false" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.83333 22H31.1667C32.175 22 33 21.175 33 20.1667C33 19.1583 32.175 18.3333 31.1667 18.3333H1.83333C0.825 18.3333 0 19.1583 0 20.1667C0 21.175 0.825 22 1.83333 22ZM1.83333 12.8333H31.1667C32.175 12.8333 33 12.0083 33 11C33 9.99167 32.175 9.16667 31.1667 9.16667H1.83333C0.825 9.16667 0 9.99167 0 11C0 12.0083 0.825 12.8333 1.83333 12.8333ZM0 1.83333C0 2.84167 0.825 3.66667 1.83333 3.66667H31.1667C32.175 3.66667 33 2.84167 33 1.83333C33 0.825 32.175 0 31.1667 0H1.83333C0.825 0 0 0.825 0 1.83333Z" />
                        </svg> -->
                        <div class="shadow dropdown-menu dropdown-1 p-3 py-1" aria-labelledby="dropdownMenuButton3">
                            <!-- <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-search fs-5 text-1"></i><a class="text-1" href="#">{{"Dash.Quotes" |
                                    translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-search fs-5 text-1"></i><a class="text-1" href="#"> {{"Dash.Invoices" |
                                    translate}} </a>
                            </div>
                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-search fs-5 text-1"></i><a class="text-1" href="#">{{"Dash.Email" |
                                    translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-search fs-5 text-1"></i><a class="text-1" href="#">{{"Dash.eQuote page"
                                    | translate}}</a>
                            </div> -->
                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                                    [routerLink]="['business-profile']">{{"COMMON.Business Profile" | translate}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                                    [routerLink]="['profile']">{{"COMMON.Plan" | translate}}</a>
                            </div>

                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                                    [routerLink]="['system-users']">{{"COMMON.Users" | translate}}</a>
                            </div>

                            <div class="d-flex align-items-center gap-2 my-3">
                                <i class="fas fa-sign-out-alt fs-5 text-1"></i><a class="text-1" (click)="logout()"
                                    href="#">{{"COMMON.Log out" | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="top: 17px; right: 10px;">
            <!-- <p class="d-inline-block me-3 text-1"><b class="fw-normal text-2"  data-bs-toggle="dropdown" aria-expanded="false">{{"Dash.Helllo" | translate}} </b> {{userName}}
            </p> -->
            <!-- <div class="d-inline-block position-relative dropdown">
                <p class="d-inline-block me-3 text-1 cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false">
                    {{userName}}
                </p>
                <div class="shadow dropdown-menu dropdown-1 p-3 py-1" aria-labelledby="dropdownMenuButton4"
                    style="top: 50px; right: -10px">
                    <div class="d-flex align-items-center gap-2 my-3">
                        <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                            [routerLink]="['business-profile']">{{"Dash.Business Profile" | translate}}</a>
                    </div>
                    <div class="d-flex align-items-center gap-2 my-3">
                        <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                            [routerLink]="['profile']">{{"Dash.Plan" | translate}}</a>
                    </div>

                    <div class="d-flex align-items-center gap-2 my-3">
                        <i class="fas fa-user fs-5 text-1"></i><a class="text-1"
                            [routerLink]="['system-users']">{{"Dash.Users" | translate}}</a>
                    </div>

                    <div class="d-flex align-items-center gap-2 my-3">
                        <i class="fas fa-sign-out-alt fs-5 text-1"></i><a class="text-1" (click)="logout()"
                            href="#">{{"Dash.Log out" | translate}}</a>
                    </div>
                </div>
            </div> -->
            <div class="d-inline-block position-relative">
                <!-- <button mat-button [matMenuTriggerFor]="menu">
                    <img class="rounded-circle" width="20px" src="{{imageUrl + currentMerchant.profile_photo }}">&nbsp;&nbsp;{{currentMerchant.business_name}}
                </button> -->
                <a [matMenuTriggerFor]="menu" class="cursor_pointer"
                    aria-expanded="false"><img class="rounded-circle" width="40px" height="40px"
                        src="{{ currentMerchant && currentMerchant.profile_photo ? getImage(currentMerchant?.profile_photo) : getImage('') }}">&nbsp;&nbsp;{{currentMerchant?.business_name}}</a>
                <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let userMerchant of userMerchants">
                        <button mat-menu-item *ngIf="userMerchant.status == 1" class="btn-profile"
                            (click)="updateMerchant(userMerchant)" [ngClass]="{'active-merchant': currentMerchant?.id === userMerchant?.id, 'non-active-merchant' : currentMerchant?.id !== userMerchant?.id }">
                            <img class="rounded-circle" width="40px" height="40px"
                                src="{{userMerchant.profile_photo ? getImage(userMerchant.profile_photo) : getImage('')}}">&nbsp;&nbsp;{{userMerchant.business_name}}
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <button mat-menu-item routerLink="/dash/profile"  class="ml-2 mt-2 mat-menu-item d-flex justify-content-start align-items-center text-center"><i class="fa fa-user me-2 d-block"></i>

                        <div>{{"COMMON.User_Profile" | translate}}</div>
                    </button>

                    <button *ngIf="permission.includes('business_profile')" mat-menu-item routerLink="/dash/business-profile"  class="ml-2 hide_menu d-flex justify-content-start align-items-center text-center">
                        <i class="fa fa-users me-2 d-block"></i> 
                        <div>{{"COMMON.Business Profile" | translate}}</div>
                    </button>

                    <button mat-menu-item routerLink="/dash/profile" [queryParams]="{tab: 'nav-Appearance-tab'}"  class="ml-2 hide_menu d-flex justify-content-start align-items-center text-center">
                        <i class="fas fa-palette me-2 d-block"></i>
                        <div>{{"COMMON.Appearance" | translate}}</div>
                    </button>

                    <button *ngIf="permission.includes('billing')" mat-menu-item routerLink="/dash/profile" [queryParams]="{tab: 'nav-Billing-tab'}"  class="ml-2 hide_menu d-flex justify-content-start align-items-center text-center">
                        <i class="fa fa-list-alt me-2 d-block"></i> 
                        <div>{{"COMMON.Billing" | translate}}</div>
                    </button>

                    <button *ngIf="permission.includes('plan')" mat-menu-item routerLink="/dash/profile"  [queryParams]="{tab: 'nav-plan-tab'}"  class="ml-2 d-flex hide_menu justify-content-start align-items-center text-center">
                        <i class="fa fa-dollar-sign me-2 d-block"></i> 
                        <div>{{"COMMON.Plan" | translate}}</div>
                    </button>

                    <button mat-menu-item (click)="newAccount()" class="ml-2 d-flex hide_menu justify-content-start align-items-center text-center">
                        <i class="fa fa-plus me-2 d-block"></i><div>{{"SignIn.create_new_workspace" | translate}}</div>
                    </button>

                    <!-- <button mat-menu-item (click)="otherWorkspace()" class="ml-2 d-flex justify-content-start align-items-center text-center">
                        <i class="fas fa-angle-double-right me-2 d-block"></i><div>{{"SignIn.switch_to_another_workspace" | translate}}</div>
                    </button> -->

                    <button mat-menu-item routerLink="/" class="ml-2 d-flex justify-content-start align-items-center text-center" (click)="logout()">
                        <i class="fas fa-sign-out-alt me-2 fs-5 d-block"></i><div>{{"COMMON.Log out" | translate}}</div>
                    </button>

                </mat-menu> 

                <!-- <a id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-expanded="false" style="width: 50px; cursor: pointer; border: 3px solid #03c0fc;">{{userMerchants[0].business_name}}</a>

                <div class="shadow dropdown-menu dropdown-1 p-3 py-1" aria-labelledby="dropdownMenuButton4" style="top: 50px; right: -10px">
                    <div class="d-flex align-items-center gap-2 my-3" *ngFor="let userMerchant of userMerchants">
                        <i class="fas fa-user fs-5 text-1"></i><a class="text-1" (click)="updateMerchant(userMerchant.id)">{{userMerchant.business_name}}</a>
                    </div>
                </div> -->
            </div>
        </div>
        </div>
    </div>
</nav>
<mat-drawer [(opened)]="openDrawer" from="right" position="end" #drawerAddTask mode="over">   
    <div class="offcanvas-header p-3 border-bottom">
        <h2 class="text-1 m-0 d-flex gap-2 align-items-center pl-3">
            {{"Dash.Notifications" | translate}}
        </h2>
        <button type="button" class="color-1 btn" (click)="openAllNotification()"><b>✕</b></button>
    </div>
    <div class="offcanvas-body p-0 max-width-drawer notification_drawer_body">
        <div class="text-center border-bottom mark_all_as_read" *ngIf="listingNotification?.length > 0">
            <a href="javascript:void(0)" (click)="notificationReadAll(true)">
                <i class="fa fa-check" aria-hidden="true"></i> {{'Mark all as read' | translate }}
            </a>
        </div>
        <div class="p-3 pb-3 mb-4">
            <ng-container *ngFor="let notification of listingNotificationAll">
                <a href="javascript:void(0)" (click)="notificationRead(notification)" [ngClass]="{notificationRead: notification.is_read != true }" class="d-flex align-items-center p-2 border-bottom">
                    <i class="fas fa-upload fs-4  text-center" *ngIf="notification.module == 'csv_upload'" style="width: 31px"></i>
                    <i class="fas fa-clock fs-4  text-center" *ngIf="notification.module != 'csv_upload'" style="width: 31px"></i>
                    <span class="pl-2"><p class="text-dark word-break" *ngIf="notification.module !== 'eQuote_module'">{{ notification.description }}<br /><span class="tags bg-light-primary color-1 p-1 rounded text-sm font-medium" *ngIf="notification.timeago">{{notification.timeago}}</span></p></span>
                    <span class="pl-2"><p class="text-dark word-break" *ngIf="notification.module == 'eQuote_module'">{{"Dash.New_Quote_request" | translate}} {{ notification.description }}<br /><span class="tags bg-light-primary color-1 p-1 rounded text-sm font-medium" *ngIf="notification.timeago">{{notification.timeago}}</span></p></span>

                </a>
            </ng-container>
            <!-- <ng-container *ngFor="let notification of reminderListingNotificationAll">
                <a href="javascript:void(0)" (click)="viewTask(notification.id)" class="d-flex align-items-center p-2 border-bottom ">
                    <i class="fas fa-clock fs-4  text-center" style="width: 31px"></i>
                    <span class="pl-2"><p class="text-1 fw-400">{{notification.name}} <span class="tags bg-light-primary color-1 p-1 rounded ml-3 text-sm font-medium" *ngIf="notification.timeago">{{notification.timeago}}</span></p></span>
                </a>
            </ng-container> -->
        </div>
    </div>
</mat-drawer>
