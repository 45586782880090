import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  BASE_URL: string;
  USER_MANAGEMENT_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
    this.USER_MANAGEMENT_URL = environment.USER_MANAGEMENT_URL
  }

  getMerchant(id: number) {
    return this.http.get(`${this.BASE_URL}/merchant/${id}`, {
      observe: 'response'
    });
  }

  getUserMerchant(data: any = ''): Observable<any> {
    return this.http.post(`${this.USER_MANAGEMENT_URL}/merchantuser/getmerchant`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  createSession(data: any = ''): Observable<any> {
    return this.http.post(`${this.USER_MANAGEMENT_URL}/merchantuser/createsession`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  getSession(data: any = ''): Observable<any> {
    return this.http.post(`${this.USER_MANAGEMENT_URL}/merchantuser/getsession`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  login(body: any) {
    return this.http.post(`${this.USER_MANAGEMENT_URL}/auth/login`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return of(err);
        })
      );
  }


 countryList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/country/list', data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }  

  updateMerchant(data): Observable<any> {
    return this.http.put(`${this.USER_MANAGEMENT_URL}/merchantuser/update-merchant-device-id`, data);
  } 
}
