import { Directive, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import * as _ from 'lodash';

declare var google: any;

@Directive({
  selector: '[googlePlaces]'
})
export class GooglePlacesDirective implements OnInit {

  @Input() address: AbstractControl;
  @Input() country: AbstractControl;
  @Input() region: AbstractControl;
  @Input() city: AbstractControl;
  @Input() province: AbstractControl;
  @Input() postal_code: AbstractControl;
  @Input() street_name: AbstractControl;
  @Input() streetNumber: AbstractControl;
  @Input() latitude: AbstractControl;
  @Input() longitude: AbstractControl;
  @Input() formattedAddress: AbstractControl

  @Output() selectAddress: EventEmitter<any> = new EventEmitter();
  private readonly element: HTMLInputElement;

  constructor(
    private elRef: ElementRef
  ) {
    this.element = this.elRef.nativeElement;

  }

  ngOnInit(): void {


    const autocomplete = new google.maps.places.Autocomplete(this.element);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.selectAddress.emit(autocomplete.getPlace());
      const address = autocomplete.getPlace();
      if (address.formatted_address) {
        this.formattedAddress?.setValue(this.element.value);
      }
      let countryName: any;
      let cityName: any;
      let ProvinceName: any;
      let postal_code: any;
      let streetName: any;
      let streetNumber: any;
      let regionName: any;

      if (this.country) {
        this.country.setValue(null);
      }
      if (this.region) {
        this.region.setValue(null);
      }
      if (this.city) {
        this.city.setValue(null);
      }
      if (this.province) {
        this.province.setValue(null);
      }
      if (this.postal_code) {
        this.postal_code.setValue(null);
      }
      if (this.street_name) {
        this.street_name.setValue(null);
      }
      if (this.streetNumber) {
        this.streetNumber.setValue(null);
      }
      if (this.latitude) {
        this.latitude.setValue(null);
      }
      if (this.longitude) {
        this.longitude.setValue(null);
      }

      postal_code = _.find(address.address_components, (component: any) => component.types.indexOf('postal_code') > -1);
      streetName = _.find(address.address_components, (component: any) => component.types.indexOf('route') > -1);
      streetNumber = _.find(address.address_components, (component: any) => component.types.indexOf('street_number') > -1);
      countryName = _.find(address.address_components, (component: any) => component.types.indexOf('country') > -1);
      cityName = _.find(address.address_components, (component: any) => component.types.indexOf('locality') > -1);
      ProvinceName = _.find(address.address_components, (component: any) => component.types.indexOf('administrative_area_level_2') > -1);
      regionName = _.find(address.address_components, (component: any) => component.types.indexOf('administrative_area_level_1') > -1);

      if (postal_code && this.postal_code) {
        this.postal_code.setValue(postal_code.long_name);
      }
      if (streetName && this.street_name) {
        this.street_name.setValue(streetName.long_name);
      }
      if (streetNumber && this.streetNumber) {
        this.streetNumber.setValue(streetNumber.long_name);
      }
    


      if (this.country) {
        this.country.setValue(countryName?.long_name || null);
      }
      this.region.setValue(regionName?.long_name || null);
      this.province.setValue(ProvinceName?.long_name || null);
      this.city.setValue(cityName?.long_name || null);
      if (this.longitude) {
        this.longitude.setValue(address?.geometry?.location?.lng());
      }
      if (this.latitude) {
        this.latitude.setValue(address?.geometry?.location?.lat());
      }



    });
  }
}
