<div>
    <ng-container>
        <div class="model-header position-relative">
            <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
                class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 mr-3 mb-5"></i>
        </div>
        <div class="p-3">
            <div>
                <div class="row">
                    <div class="col-lg-12 my-2">
                        <div class="form-group">
                            <label class="text-3 mb-2">{{'QUOTE_TEMPLATE.Quote Template Name' | translate}}<span class="color-6">*</span></label>
                            <div class="d-flex align-items-center flex-column">
                                <mat-form-field>
                                    <input type="email" matInput [formControl]="template_name">
                                </mat-form-field>
                                <!-- <input type="text" class="form-control"
                                    placeholder='New Quote Template'
                                    name="template_name" >
                                <div class="invalid-feedback">
                                    <div>
                                       Quote form name is Required
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mt-3 text-center" style="display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;">
                    <button type="submit" class="btn btn-p-2 standard-btn" (click)="onSubmit()">
                        <span>{{"COMMON.Get_started"| translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>