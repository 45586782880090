<div class="dialog-content-wrapper google-map-container">


  <div mat-dialog-content class="p-0 m-0" >

    <!-- CONTENT -->
    <div class="content px-16">
      <p fxLayout="row" fxLayoutAlign="start start">
        <agm-map [latitude]="lat" [longitude]="lng" [ngStyle]='{"height":mapHeight}' (mapClick)="mapClicked($event)" [disableDefaultUI]="false" [zoomControl]="true" [zoom]="zoom">

          <agm-marker [latitude]="markerLat" [longitude]="markerLng" [markerDraggable]="true">
          </agm-marker>

        </agm-map>
      </p>

    </div>

  </div>
  <div mat-dialog-actions class="m-0 pt-0" fxLayout="row" fxLayoutAlign="center center">
    <div class="nav-link btn btn-1 m-2"  *ngIf="_data?.showButton" (click)="matDialogRef.close([address, compAddress, coordinates])">
      {{'Pick Address'}}
    </div>

  </div>
</div>
