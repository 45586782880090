<div style="width: 600px;">
    <div class="modal-content cnt-dashboard border-0">
        <div class="modal-header pt-5">
            <h2 class="text-1 fs-4 text-center">{{"CUSTOMER.Save_Filter" | translate}}</h2>
            <i type="button" (click)="closeModel(false)"
                class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-8"></i>
        </div>
        <form [formGroup]="filterSaveForm">
            <div class="modal-body bg-gris-1 px-5">
                <label class="text-3 mb-2">{{"CUSTOMER.Name" | translate}}<span class="color-6">
                        *</span></label>
                <input type="text" class="form-control" placeholder="Enter Filter Name" formControlName="title">
            </div>
            <div class="modal-footer j-c-center mt-4 pb-4">
                <div class="btn btn-2 mx-2" (click)="onDismiss()">
                    {{"SYSTEM_USER.Cancel" | translate}}
                </div>
                <button (click)="saveFilter()" class="btn btn-1 mx-2 btn_continous"> {{"CUSTOMER.Save" | translate}}</button>
            </div>
        </form>
    </div>
</div>