import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth2-layout',
  templateUrl: './auth2-layout.component.html',
  styleUrls: ['./auth2-layout.component.scss']
})
export class Auth2LayoutComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {

  }

}
