import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AppDirectivesModule } from '../directives/directives';
import { AddressFieldsComponent } from './components/address-fields/address-fields.component';
import { InputNumControlsComponent } from './components/controls/input-num-control.component';
import { CustomDateComponent } from './components/custom-date/custom-date.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { InputControlComponent } from './components/input-control/input-control.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { ModalComponent } from './components/modal/modal.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { SelectControlComponent } from './components/select-control/select-control.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomDateFormatPipe } from './pipes/custom-date-format/custom-date-format.datepipe';
import { CustomDateTimeFormatPipe } from './pipes/custom-date-format/custom-date-time-format.datepipe';
import { CustomTimeFormatPipe } from './pipes/custom-date-format/custom-time-format.datepipe';
import { PhoneFormatPipe } from './pipes/phone-date-format/phone-date-format.phonepipe';
import { CustomFormFieldsComponent } from './components/custom-form-fields/custom-form-fields.component'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { AlphasOnlyDirective } from './directives/alpha-only.directive';
import { CountryCodeComponent } from './components/country-code/country-code.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SuccessPopupComponent} from './components/success-popup/success-popup.component';
import { AddressMapModule } from '../directives/maps/address-map/address-map.module';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ListingPageHeaderComponent } from './listing-page-header/listing-page-header.component'
import { FinalConfirmationComponent } from '../Dialogs/final-confirmation/final-confirmation.component';
import { ModelLayoutComponent } from './components/model-layout/model-layout.component';
// import { ModelComponentModule } from './model-component/model-component.module';
import { MatButtonModule } from '@angular/material/button';
import { ModelLayoutHeaderComponent } from './components/model-layout/model-layout-header.component';
import { ModelComponentModule } from './model-component/model-component.module';
import { NewSuccessPopupComponent } from './model-component/new-success-popup/new-success-popup.component';
import { SearchPipe } from './pipes/search/search.pipe';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeleteorgComponent } from './components/deleteorg/deleteorg.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LeadContactPipe } from './pipes/search/lead-contact.pipe';
import { FilterComponent } from './components/filter/filter.component';
import { AdvancefilterComponent } from './components/advancefilter/advancefilter.component';
// import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import { WgtsfilterComponent } from './components/wgtsfilter/wgtsfilter.component';
import { ViewDealDetailComponent } from './components/view-deal-detail/view-deal-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivityNotesComponent } from './components/activity-notes/activity-notes.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
import { imgViewComponent } from './components/activity-notes/image-view.component';
import { TruncateTextDirective } from '../pages/lead-form/directives/truncate-text.directive';
import { AdvancefilterQuoteComponent } from '../quote/quote-listing/advancefilter/advancefilter.component';
import { SignaturePadComponentCreate } from '../quote/signature-pad/signature-pad.component';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { NoWhiteSpaceDirective } from './directives/no-whitespace.directive';
import { AdvancefilterPaymentComponent } from '../pages/payments/advancefilter/advancefilterPayment.component';
import { DealCustomFormFieldsComponent } from './components/deal-custom-form-fields/deal-custom-form-fields.component';

registerLocaleData(localeFr);
registerLocaleData(localeEs);
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    // ModelComponentModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    AppDirectivesModule,
    NgxMaskModule,
    DragDropModule,
    AddressMapModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    RouterModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    // MatDialogActions,
    // MatDialogClose,
    // MatDialogContent,
    // MatDialogTitle,
    // ModelComponentModule,
    MatExpansionModule,
    NgxSliderModule,
    MatChipsModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    NgxDropzoneModule,
    NgbModule
  ],
  declarations: [
    SpinnerComponent,
    InputNumControlsComponent,
    InputControlComponent,
    CustomIconComponent,
    CustomDateComponent,
    FinalConfirmationComponent,
    ModalComponent,
    FilterComponent,
    AddressFieldsComponent,
    ProgressbarComponent,
    SelectControlComponent,
    CustomDateFormatPipe,
    CustomDateTimeFormatPipe,
    CustomTimeFormatPipe,
    PhoneFormatPipe,
    NumbersOnlyDirective,
    AlphasOnlyDirective,
    CountryCodeComponent,
    PhoneNumberComponent,
    CustomFormFieldsComponent,
    SuccessPopupComponent,
    ListingPageHeaderComponent,
    ModelLayoutComponent,
    ModelLayoutHeaderComponent,
    NewSuccessPopupComponent,
    SearchPipe,
    DeleteorgComponent,
    LeadContactPipe,
    AdvancefilterComponent,
    AdvancefilterQuoteComponent,
    AdvancefilterPaymentComponent,
    WgtsfilterComponent,
    ViewDealDetailComponent,
    ActivityNotesComponent,
    imgViewComponent,
    TruncateTextDirective,
    NoWhiteSpaceDirective,
    DealCustomFormFieldsComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    // ModelComponentModule,
    ReactiveFormsModule,
    SpinnerComponent,
    InputNumControlsComponent,
    AddressFieldsComponent,
    InputControlComponent,
    ProgressbarComponent,
    SelectControlComponent,
    CustomDateTimeFormatPipe,
    CustomDateFormatPipe,
    CustomTimeFormatPipe,
    PhoneFormatPipe,
    CustomIconComponent,
    CustomDateComponent,
    TranslateModule,
    ModalComponent,
    FilterComponent,
    NumbersOnlyDirective,
    AlphasOnlyDirective,
    MatSelectModule,
    CountryCodeComponent,
    AppDirectivesModule,
    PhoneNumberComponent,
    CustomFormFieldsComponent,
    NgxMaskModule,
    NgxMaskModule,
    SuccessPopupComponent,
    AddressMapModule,    
    MatIconModule,
    ListingPageHeaderComponent,
    MatButtonModule,
    NewSuccessPopupComponent,
    SearchPipe,
    LeadContactPipe,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    AdvancefilterComponent,
    AdvancefilterQuoteComponent,
    AdvancefilterPaymentComponent,
    MatFormFieldModule,
    MatTooltipModule,
    DragDropModule,
    WgtsfilterComponent,
    ActivityNotesComponent,
    FormsModule,
    TruncateTextDirective,
    NoWhiteSpaceDirective,
    DealCustomFormFieldsComponent
    // ModelComponentModule
    // MatDialogActions,
    // MatDialogClose,
    // MatDialogContent,
    // MatDialogTitle,

  ],
  providers: [
    TranslatePipe

  ]
})
export class SharedModule { }
