import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '../services/spinner.service';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  count = 0;

  constructor(private router: Router, private spinner: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      return next.handle(req)
          .pipe ( tap ((evt) => {
            if (evt instanceof HttpResponse) {
              if(evt.body && evt.body.status == 1) {
                if (evt.body.setting) {
                  if(evt.body.setting.address_format) {
                    localStorage.setItem('address_format', evt.body.setting.address_format);
                  }
                  if(evt.body.setting.phone_number_formate) {
                    localStorage.setItem('phone_number_format', evt.body.setting.phone_number_format);
                  }
                }
              }
                  //       localStorage.setItem('address_formate', businessDetails.address_formate);
                  //       addressFormate = businessDetails.address_formate.split(',');
                  //       addressFormate.forEach((addressFormate, index) => {
                  //         addressFormateArr[addressFormate] = '';
                  //         addressFormateArrView.push(addressFormate)
                  //       })
                  //       addressformArr.push(addressFormateArr)
                  //     }
                  // this.toasterService.success(evt.body.success.message, evt.body.success.title, { positionClass: 'toast-bottom-center' });
            }
            let loadSpinner = req.body ? req.body.hideSpinner ? false : true : true;
            if(req.url.indexOf('/upload/file') <= -1 && !req.body?.hideLoader && req.url.indexOf('notification/list') <= -1 && req.url.indexOf('/task/list?reminders') <= -1 && loadSpinner){
              this.spinner.show();              
            }            
          }), finalize(() => {
            if ( req.url.indexOf('completeregistration') <= -1) {
              if(req.url.split('/')[5] !== 'folders' && this.router.url.indexOf('/dash/profile') <= -1)  { 
                this.spinner.hide(); 
              } else if(req.url.split('/')[5] !== 'folders' && req.url.indexOf('/task/list') <= -1) {
                setTimeout(() => {
                  this.spinner.hide(); 
                }, 1000)
              } else {
                // this.spinner.hide(); 
              }
            }
           

            if ( this.router.url.startsWith('/auth/create-password')) {
              this.spinner.show();
              setTimeout(() => {
                this.spinner.hide(); 
              }, 3000)
            }
          
                  // clearInterval(tmout);
                  // const tmout2 = setInterval(() => {
                  //   this.count--;
                  //   if ( this.count <= 0 ) {
                  //     this.spinner.hide();
                  //     clearInterval(tmout2);
                  //   }

                  // }, 300);
              })
          );
  }
}
