<div class="modal-content">
<div class="modal-header pt-5">
  <h2 class="text-1 fs-4 text-center" style="color:#03c0fc;" *ngIf="titleMsg !=='Alert!' && titleMsg !=='Amount Exceeded' && titleMsg !=='Missing Installments'">{{getTranslatedText ("QUOTE_TEMPLATE." + titleMsg ) || titleMsg}}</h2>
  <h2 class="text-1 fs-4 text-center" style="color:rgb(236, 51, 51);" *ngIf="titleMsg =='Alert!'">{{getTranslatedText ("QUOTE_TEMPLATE." + titleMsg ) || titleMsg}}</h2>
  <h2 class="text-1 fs-4 text-center" style="color:rgb(236, 51, 51);" *ngIf="titleMsg =='Amount Exceeded'">{{getTranslatedText ("QUOTE_TEMPLATE." + titleMsg ) || titleMsg}}</h2>
  <h2 class="text-1 fs-4 text-center" style="color:rgb(236, 51, 51);" *ngIf="titleMsg =='Missing Installments'">{{getTranslatedText ("QUOTE_TEMPLATE." + titleMsg ) || titleMsg}}</h2>
</div>
<div class="modal-body bg-gris-1 px-5 text-center">
  <p>{{getTranslatedText ("QUOTE_TEMPLATE." + ItemsDeleteModule ) || ItemsDeleteModule}}</p>
</div>
<div class="modal-footer j-c-center mt-4 pb-4">
  <button class="btn btn-2 mx-2" (click)="dialogRef.close(false)" *ngIf="titleMsg !=='Amount Exceeded' && titleMsg !=='Missing Installments'">{{"QUOTE_TEMPLATE.No" | translate}}</button>
  <button type="submit" class="btn btn-1 mx-2" *ngIf="titleMsg !=='Amount Exceeded' && titleMsg !=='Missing Installments'" (click)="dialogRef.close(true)">
    {{"QUOTE_TEMPLATE.Yes" | translate}}
  </button>
  <button type="submit" class="btn btn-1 mx-2" *ngIf="titleMsg =='Amount Exceeded'  || titleMsg =='Missing Installments'" (click)="dialogRef.close(false)">
    {{"Ok" | translate}}
  </button>

  <button [matTooltip]="'Preview Quote'" *ngIf="titleMsg =='Alert!' && titleMsg !=='Amount Exceeded' && titleMsg !=='Missing Installments'" type="button" (click)="viewQuote()" class="d-flex gap-2 align-items-center btn btn-1 mx-2 bg-transparent btn-skyblue-s-1">
    <mat-icon class="material-symbols-outlined">
        visibility
    </mat-icon>
</button>
</div>
</div>
