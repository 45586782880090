import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReminderDialogComponent } from './reminders.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    ReminderDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ReminderDialogModule { }
