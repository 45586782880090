import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'auto-logout-dialog',
  templateUrl: './auto-logout-dialog.component.html',
  styleUrls: ['./auto-logout-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutoLogoutDialogComponent implements OnInit {
  public message: string;
  public unit: string;
  timeLeft = 60;
  interval;
  constructor(
    public dialogRef: MatDialogRef<AutoLogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _translateService: TranslateService,
  ) {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    }, 1000);
  }
 
  ngOnInit(): void {
    this.message = this._translateService.instant('Log out due to inactivity in');
    this.unit = this._translateService.instant('sec');
  }

}
