import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEquoteDialogComponent } from './create-equote-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EQuoteTemplatesListModule } from '../e-quote-templates-list/e-quote-templates-list.module';



@NgModule({
  declarations: [
    CreateEquoteDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    ReactiveFormsModule,
    
  ],
  exports:[CreateEquoteDialogComponent]
})
export class CreateEquoteDialogModule { }
