import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectContactComponent } from './select_contact.component';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [
    SelectContactComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatSelectModule, 
    MatFormFieldModule, 

  ]
})
export class SelectContactModule { }
