import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
    selector: '[truncateText]'
})
export class TruncateTextDirective{
    @Input('truncateText') maxLength:number = 18;

    isTruncated:boolean = true;

    constructor(private el:ElementRef , private renderer: Renderer2){

    }

    ngAfterViewInit(){
        this.truncate();
    }

    truncate() {
        const text = this.el.nativeElement.textContent;
    
        if (text.length > this.maxLength) {
          const truncatedText = text.substring(0, this.maxLength) + '...';
          this.renderer.setProperty(this.el.nativeElement, 'textContent', truncatedText);
          this.isTruncated = true;
        } else {
          this.isTruncated = false;
        }
      }
    
      toggleText() {
        if (this.isTruncated) {
          const text = this.el.nativeElement.textContent;
          this.renderer.setProperty(this.el.nativeElement, 'textContent', text.substring(0, text.length - 3)); // Remove the '...'
          this.isTruncated = false;
        }
      }

}